import { css } from "@emotion/react"

// Style
import { align_items_center, px, space, text, border } from "views/style"
import { gray } from "views/style/palette"

export const SideMenuWidth = px._240

export const container = css`
  padding: 0 0 ${space._1};
  width: ${SideMenuWidth};
  height: 100vh;
  position: fixed;
  flex-direction: column;
  ${align_items_center}
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  border-top: 4px solid ${gray._800};
  border-right: 1px solid ${gray._200};
  background-color: ${gray._25};
`

export const container_close = css`
  left: -${SideMenuWidth};
`
export const logo = css`
  background-color: ${gray._850};
  padding: ${space._6} ${space._10} ${space._5};
  img {
    width: 100%;
  }
`
export const logo_spacing = css`
  width: 100%;
  height: ${space._4};
`
export const tenant_name = css`
  background-color: ${gray._500};
  color: ${gray._100};
  width: 100%;
  text-align: center;
  padding: ${space._2} ${space._4};
`

export const accordion = css`
  height: calc(100vh - ${px._40});
  width: 100%;
  overflow-y: auto;
  .title {
    font-size: ${text.base};
  }
  .accordion-item {
    border-bottom: 1px solid ${gray._100};
    cursor: pointer;
    .accordion-item-title-row {
      margin: 0;
      padding: ${space._4} ${space._4};
      border-bottom: none;
      .accordion-item-title {
        label {
          cursor: pointer;
        }
      }
    }
    .accordion-item-contents {
      background-color: ${gray._150};
      padding: 0;
      margin: 0;
      .accordion-item-contents-container {
        padding: ${space._2} 0 ${space._3} ${space._1};
      }
      &-item {
        padding: 0;
        .title {
          font-size: ${text.base} !important;
          font-weight: 400;
          color: ${gray._700};
        }
      }
    }
  }
`
