export enum LoginType {
  Customer = 1,
  Shop,
  Tenant,
  Franchise,
  Master,
}

export enum SexId {
  Male = 1,
  Female = 2,
  Unknown = 3,
}