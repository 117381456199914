// 決済
export enum PaymentStatusId {
  Unsettled = 1,
  Completed = 2,
  PartRefunded = 3,
  Refunded = 4,
  RefundedInculdingTax = 5,
}

export const PaymentStatus = [
  { id: PaymentStatusId.Unsettled, label: "未決済" },
  { id: PaymentStatusId.Completed, label: "決済済" },
  { id: PaymentStatusId.PartRefunded, label: "一部返金済" },
  { id: PaymentStatusId.Refunded, label: "返金済" },
  { id: PaymentStatusId.RefundedInculdingTax, label: "返金済(手数料込)" },
]

// 配送
export enum ShippingStatusId {
  Untreated = 1,
  PartShipped = 2,
  Shipped = 3,
  CannotRecived = 4,
}

export const ShippingStatus = [
  { id: ShippingStatusId.Untreated, label: "未処理" },
  { id: ShippingStatusId.PartShipped, label: "一部発送済" },
  { id: ShippingStatusId.Shipped, label: "発送済" },
  { id: ShippingStatusId.CannotRecived, label: "受取不可" },
]

// 返品
export enum ReturnStatusId {
  NonAction = 1,
  Progress = 2,
  Completed = 3,
}

export const ReturnStatus = [
  { id: ReturnStatusId.NonAction, label: "-" },
  { id: ReturnStatusId.Progress, label: "処理中" },
  { id: ReturnStatusId.Completed, label: "完了" },
]

// 配送業者
export enum DeliveryVendorId {
  Yamato = 1,
  Sagawa = 2,
  Yusei = 3,
}

export const DeliveryVendor = [
  { value: DeliveryVendorId.Yamato, label: "ヤマト運輸" },
  { value: DeliveryVendorId.Sagawa, label: "佐川急便" },
  { value: DeliveryVendorId.Yusei, label: "日本郵政" },
]
