import { User, Event } from "../gen/ws_pb"
import { IEmitCommand } from ".."
import { LoginAccount } from "../../../graphql"

export class UserCommand implements IEmitCommand {
  user: User
  constructor(user: LoginAccount) {
    this.user = new User()
    this.user.setEvent(Event.USER)
    this.user.setUserId(user.id)
    this.user.setLoginType(user.loginType)
    user?.shopId && this.user.setJoinMemberId(user.shopId)
    user?.tenantId && this.user.setTenpoId(user.tenantId)
    user?.franchiseId && this.user.setFranchiseId(user.franchiseId)
  }
  execute = () => {
    return this.user.serializeBinary()
  }
}
