/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"
import { Table as TwTable } from "tw-custom-component"
import { TableProps } from "tw-custom-component/dist/components/molecules/table"

// Style
import { space, text, border } from "views/style"
import { gray } from "views/style/palette"
import Loading from "../../atoms/display/loading"
import Empty from "../../atoms/display/empty"

export const Table = <T,>(props: {
  tableProps: TableProps<T>
  loading?: boolean
  style?: SerializedStyles
  selectMessage?: boolean
  tableType?: boolean
}) => {
  const { tableProps, loading, style, selectMessage, tableType } = props

  return (
    <div
      css={css`
        overflow: auto;
        ${style}
      `}
    >
      {loading ? (
        <Loading />
      ) : !tableProps.dataSource.length || selectMessage ? (
        <Empty selectMessage={selectMessage} tableType={tableType} />
      ) : (
        <TwTable
          {...tableProps}
          style={css`
            width: 100%;
            &.row-clickable {
              tbody tr {
                cursor: pointer;
                transition: background-color 200ms ease;
                &:hover {
                  background-color: ${gray._10};
                }
              }
            }
            thead {
              tr {
                background-color: ${gray._25};
                th {
                  padding: ${space._3} 0 ${space._3} ${space._2};
                  text-align: left;
                  font-weight: bold;
                  color: ${text.light};
                }
              }
            }
            tbody {
              tr {
                ${border.css.bottom};
                td {
                  line-height: 1.25;
                  color: ${text.main};
                  padding: ${space._3} ${space._2};
                  vertical-align: middle;
                }
              }
            }
            ${tableProps?.style}
          `}
        />
      )}
    </div>
  )
}
