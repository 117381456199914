/** @jsxImportSource @emotion/react */
import { ReactNode } from "react"
import { css, SerializedStyles } from "@emotion/react"

// Style
import { space, ui } from "views/style"
import { MAX_WIDTH, MIN_WIDTH, MaxWidthType } from "logics/const/ui"

export type Props = {
  children: ReactNode
  widthType?: MaxWidthType
  style?: SerializedStyles
}

export default function ContentBody({ children, widthType, style }: Props) {
  return (
    <div
      css={css`
        padding: 0 ${space._8} ${space._20};
      `}
    >
      <div
        css={css`
          max-width: ${widthType ? MAX_WIDTH[widthType] : ui.mainMaxWidth};
          min-width: ${widthType ? MIN_WIDTH[widthType] : "auto"};
          margin: 0 auto;
          ${style}
        `}
      >
        {children}
      </div>
    </div>
  )
}
