import { useState, useCallback } from "react"

export const useMainLayout = () => {
  const [close, setClose] = useState(false)
  const onClick = useCallback(() => setClose(!close), [close, setClose])
  return {
    props: { header: { onClick }, sideMenu: { close } },
    state: {
      close,
    },
    handler: { onClick },
  }
}
