/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"
import { useRef } from "react"

export type Props = {
  src: string
  onClick?: () => void
  ablePlaying?: boolean
  style?: SerializedStyles
}

export const VideoThumbnail = (props: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        position: relative;
        ${props?.style}
      `}
      onClick={props.onClick}
    >
      <video
        ref={videoRef}
        css={css`
          width: 100%;
          height: 90%;
          opacity: ${props.ablePlaying ? 1 : 0.6};
        `}
        src={props.src}
        autoPlay={!!props?.ablePlaying}
        controls={!!props?.ablePlaying}
      />
      <i
        className="material-icons-outlined"
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: ${props.ablePlaying ? "pointer" : "default"};
          visibility: ${props.ablePlaying ? "hidden" : "visible"};
        `}
      >
        play_circle
      </i>
    </div>
  )
}
