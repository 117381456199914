type StampCardListLabelType = {
  shopName: string
  description: string
  createdAt: string
}

export const StampCardListLabel: StampCardListLabelType = {
  shopName: "クーポン名",
  description: "説明",
  createdAt: "登録日",
}

export const STAMP_CARD_DESC_MAX_LENGTH: number = 15

export const formatStampCardDescription = (description: string): string => {
  return description.length > STAMP_CARD_DESC_MAX_LENGTH
    ? `${description.substring(0, STAMP_CARD_DESC_MAX_LENGTH)}...`
    : description
}
