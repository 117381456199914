import { useFormik } from "formik"
import {
  ActionStatus,
  DEFAULT_SKIP_VALUE,
  ErrorMessages,
  SuccessMessages,
  DEFAULT_TAKES_VALUE,
} from "logics/const"
import {
  useCouponsQuery,
  useFileUrlCreateMutation,
  useFranchiseQuery,
  useStampCardCreateMutation,
} from "logics/graphql"
import { onDrop as onDropImage, onUpload, createPath } from "logics/utils/image"
import { MessageType, useToast } from "logics/store/toast"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { PATHS } from "views/routes"
import { createSchema, numberValidation } from "tw-custom-component"
import * as Yup from "yup"
import { dateOptional, stringRequired } from "logics/utils/yup"
import { useMe } from "logics/account"

type stampCardCreateInput = {
  uploadedFileInfo: (File & { path: string }) | null
  couponId: string
  description: string
  from: Date | null
  franchiseId: number
  to: Date | null
}

const initialValues: stampCardCreateInput = {
  uploadedFileInfo: null,
  couponId: "",
  description: "",
  from: null,
  franchiseId: 0,
  to: null,
}

const validations = createSchema([
  {
    id: "uploadedFileInfo",
    validation: Yup.object()
      .test("required", ErrorMessages.Required, (value) => {
        if (!value?.name || !value?.type || !value?.path) {
          return false
        }
        return true
      })
      .test("InvalidFile", ErrorMessages.InvalidFile, (value) => {
        if (!/video|image|pdf/.test(value?.type)) {
          return false
        }
        return true
      })
      .nullable(),
  },
  {
    id: "couponId",
    validation: stringRequired(),
  },
  {
    id: "description",
    validation: stringRequired(),
  },
  {
    id: "from",
    validation: dateOptional(),
  },
  {
    id: "to",
    validation: dateOptional(),
  },
])

export const useStampCardRegister = () => {
  const { props: formProps } = useForm()
  const { companyName } = useFranchise(formProps.state.franchiseId)
  const { couponOptions } = useCoupons()

  return {
    ...formProps,
    couponOptions,
    companyName,
  }
}

/**フランチャイズ取得 */
const useFranchise = (franchiseId: number | undefined) => {
  const { data, loading } = useFranchiseQuery({
    fetchPolicy: "network-only",
    variables: {
      franchiseId: franchiseId,
    },
  })
  const companyName = useMemo(() => data?.franchise?.companyName ?? "", [data])
  return { companyName, loading }
}

/**クーポン一覧取得 */
const useCoupons = () => {
  const { data, loading } = useCouponsQuery({
    fetchPolicy: "network-only",
    variables: {
      request: {
        pages: {
          take: DEFAULT_TAKES_VALUE,
          skip: DEFAULT_SKIP_VALUE,
        },
        param: {},
      },
    },
  })

  const couponOptions = useMemo(() => {
    return data?.coupons.datasource.map(
      (coupon: { id: string; name: string }) => ({
        value: coupon.id,
        label: coupon.name,
      })
    )
  }, [data])

  return { couponOptions, loading }
}

/*

Form

*/

const useForm = () => {
  const navigate = useNavigate()
  const {
    state: { id: franchiseId },
  } = useMe()
  const {
    handler: { onOpen },
  } = useToast()

  const [createUrl] = useFileUrlCreateMutation()

  // キャンセル
  const onCancel = useCallback(() => {
    navigate(`${PATHS.stampCard.list}/1`)
  }, [])

  // Api
  const [fetch] = useStampCardCreateMutation({
    onCompleted: ({ stampCardCreate: actionReply }) => {
      if (actionReply.status === ActionStatus.Success) {
        onOpen({
          message: SuccessMessages?.Register,
          type: MessageType.Success,
        })
        onCancel()
        return
      }
      if (actionReply.status === ActionStatus.Fail)
        return onOpen({
          message: actionReply?.message ?? ErrorMessages.Register,
          type: MessageType.Fail,
        })
    },
    onError: (e) =>
      onOpen({
        message: e?.message ?? ErrorMessages.Register,
        type: MessageType.Fail,
      }),
  })

  const onSubmit = useCallback(
    async (stampCardCreate: stampCardCreateInput) => {
      const { data } = await createUrl({
        variables: {
          request: {
            fileName: stampCardCreate.uploadedFileInfo?.name,
            fileType: stampCardCreate.uploadedFileInfo?.type as string,
          },
        },
      })
      if (!data) return
      await onUpload({
        data: [data?.fileUrlCreate],
        targets: [
          {
            name: data?.fileUrlCreate?.fileName ?? "",
            type: data?.fileUrlCreate?.fileType,
            path: stampCardCreate?.uploadedFileInfo?.path ?? "",
          },
        ],
      })
      fetch({
        variables: {
          request: {
            couponId: stampCardCreate.couponId,
            description: stampCardCreate.description,
            from: stampCardCreate.from ? new Date(stampCardCreate.from) : null,
            franchiseId: Number(franchiseId),
            thumbnail: {
              fileType: data.fileUrlCreate.fileType,
              key: data.fileUrlCreate.key,
              path: createPath(data.fileUrlCreate.url),
            },
            to: stampCardCreate.to ? new Date(stampCardCreate.to) : null,
          },
        },
      })
    },
    [fetch, createUrl]
  )

  const form = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit,
  })

  // onDrop
  const onDrop = useCallback(
    async <T,>(data: Array<T & File>) => {
      const uploadedFilesInfo = await onDropImage(data)
      const uploadedFileInfo = uploadedFilesInfo.pop()
      form.setFieldValue("uploadedFileInfo", uploadedFileInfo)
    },
    [form]
  )
  // onDelete
  const onDelete = useCallback(
    () => form.setFieldValue("uploadedFileInfo", null),
    [form]
  )

  // Date
  const setFormDate = useCallback(
    (date: Date | null) => form.setFieldValue("from", date),
    [form]
  )
  const setToDate = useCallback(
    (date: Date | null) => form.setFieldValue("to", date),
    [form]
  )

  return {
    props: {
      form,
      uploadedFileInfo: {
        dataSource: form?.values?.uploadedFileInfo
          ? [form?.values?.uploadedFileInfo]
          : [],
        onDrop,
        onDelete,
      },
      date: {
        setFormDate,
        setToDate,
      },
      onCancel,
      state: { franchiseId },
    },
  }
}
