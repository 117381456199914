import BigNumber from "bignumber.js"
import dayjs from "dayjs"
import { isNull, isNil } from "lodash"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault("Asia/Tokyo")

export class DateHandler {
  value: Date
  constructor(value: Date) {
    this.value = value
  }
  static fromISOString = (value: string) =>
    new DateHandler(dayjs(value).tz().toDate())
  static from = (value: Date | string) => {
    if (typeof value === "string") {
      return DateHandler.fromISOString(value)
    }
    if (!!DateHandler.typeOfDate(value)) {
      return new DateHandler(value)
    }
    throw new Error()
  }
  static typeOfDate = <T>(value: T) => {
    const toString = Object.prototype.toString
    return toString.call(value) === "[object Date]"
  }
  toISOString = (): string => dayjs(this.value).tz().format()
  toDate = (): Date => dayjs(this.value).tz().toDate()
  static now = (): DateHandler => DateHandler.from(dayjs().tz().format())
  getStartDate = (): DateHandler =>
    DateHandler.from(
      dayjs(
        new Date(
          this.value.getFullYear(),
          this.value.getMonth(),
          this.value.getDate()
        )
      )
        .tz()
        .format()
    )
  getEndOfTheMonth = (): Date => dayjs(this.value).tz().endOf("month").toDate()
  getEndOfTheMonthISOString = (): string =>
    dayjs(this.value).tz().endOf("month").format()
  getBeginningOfTheMonth = (): Date =>
    dayjs(this.value).tz().startOf("month").toDate()
  getBeginningOfTheMonthISOString = (): string =>
    dayjs(this.value).tz().startOf("month").format()
  getNextBeginningOfTheMonth = (): Date =>
    dayjs(this.value).add(1, "month").tz().startOf("month").toDate()
  getNextBeginningOfTheMonthISOString = (): string =>
    dayjs(this.value).tz().add(1, "month").startOf("month").format()
  getNextEndOfTheMonth = (): Date =>
    dayjs(this.value).add(1, "month").tz().endOf("month").toDate()
  getNextEndOfTheMonthISOString = (): string =>
    dayjs(this.value).tz().add(1, "month").endOf("month").format()
  getPreviousBeginningOfTheMonth = (): Date =>
    dayjs(this.value).add(-1, "month").tz().startOf("month").toDate()
  getPreviousBeginningOfTheMonthISOString = (): string =>
    dayjs(this.value).tz().add(-1, "month").startOf("month").format()
  getPreviousEndOfTheMonth = (): Date =>
    dayjs(this.value).add(-1, "month").tz().endOf("month").toDate()
  getPreviousEndOfTheMonthISOString = (): string =>
    dayjs(this.value).tz().add(-1, "month").endOf("month").format()
  addWeek = (value: number): DateHandler =>
    DateHandler.from(dayjs(this.value).add(value, "weeks").tz().format())
  addMonth = (value: number): DateHandler =>
    DateHandler.from(dayjs(this.value).add(value, "months").tz().format())
  addYear = (value: number): DateHandler =>
    DateHandler.from(dayjs(this.value).add(value, "years").tz().format())
  addDay = (value: number): DateHandler =>
    DateHandler.from(dayjs(this.value).add(value, "days").tz().format())
  addHour = (value: number): DateHandler =>
    DateHandler.from(dayjs(this.value).add(value, "hours").tz().format())
  format = (format: DayFormat = DayFormat["YYYYMMDD"]) =>
    dayjs(this.value).tz().format(format)
}

export enum DayFormat {
  "YYYYMMDD" = "YYYY/MM/DD",
  "YYYY.MM.DD" = "YYYY.MM.DD",
  "HHmm" = "HH:mm",
  "YYYYMMDDHHmm" = "YYYY/MM/DD HH:mm",
  "YYYY年M月D日" = "YYYY年M月D日",
  "YYYY.MM.DD HH:mm" = "YYYY.MM.DD HH:mm",
  "M/D" = "M/D",
  "YYYY/MM" = "YYYY/MM",
  "YYYYMM" = "YYYYMM",
  "YYYYMMDDHHmmss" = "YYYY/MM/DD HH:mm:ss",
}

export const day = (val: string | Date | null, type?: DayFormat) => {
  return !!val
    ? dayjs(val)
        .tz()
        .format(type ?? DayFormat["YYYYMMDD"])
    : "-"
}
export const dayAndTime = (val: string | Date | null, type?: DayFormat) => {
  return !!val
    ? dayjs(val)
        .tz()
        .format(type ?? DayFormat["YYYYMMDDHHmm"])
    : "-"
}

export const number = (
  num: string | number,
  decimal: number = 0,
  type: string = "floor",
  sign: boolean = false,
  def: string = "-",
  ifZeroReturnDef: boolean = false
) => {
  if (isNull(decimal)) {
    decimal = 0
  }
  if (
    //numがfalseであればdefで指定した任意の値に（0は表示させたい）
    (!num && num !== 0) ||
    //ifZeroReturnDefがtrueであれば、0か'0'はdefで指定した任意の値に
    (ifZeroReturnDef && (num === 0 || num === "0"))
  ) {
    return def
  } else {
    let converted = decimalConverter(num, type, decimal)
    if (converted === -0) {
      converted = 0
    }
    const formatted = new Intl.NumberFormat("ja", {
      minimumFractionDigits: decimal,
    }).format(converted)
    if (sign) {
      return converted > 0 ? "+" + formatted : formatted
    } else {
      return formatted
    }
  }
}

export const decimalConverter = (
  num: string | number,
  type: string = "around",
  decimal: number = 0
): number => {
  const def = 0
  switch (type) {
    case "round":
      return Number(
        new BigNumber(num).decimalPlaces(decimal, BigNumber.ROUND_HALF_UP)
      )
    case "floor":
      return Number(
        new BigNumber(num).decimalPlaces(decimal, BigNumber.ROUND_DOWN)
      )
    case "ceil":
      return Number(
        new BigNumber(num).decimalPlaces(decimal, BigNumber.ROUND_UP)
      )
    default:
      return def
  }
}

export const price = (val: number | null) => (val ? `￥${number(val)}` : "￥-")
export const pricePerMonth = (val: number | null) =>
  val ? `￥${number(val)}/1ヶ月` : "￥-/1ヶ月"
export const priceOther = (val: number | null) =>
  val ? `￥${number(val)} 他` : "￥-"
export const postalCode = (val: string | null) =>
  !!val ? `〒${val.slice(0, 3)}-${val.slice(3, val?.length)}` : "〒-"
export const size = (val: number | null) => (val ? `${val}cm` : "-cm")
export const month = (val: number | string | null) =>
  !!val ? `${val}ヶ月` : "-ヶ月"
// 文字列の任意の位置に文字列を挿入する
export const strInFunc = (str: string, i: number, val: string) => {
  if (!str) return
  return str.slice(0, i) + val + str.slice(i)
}

// 全角 → 半角（英数字）
export const FormatToHankaku = (str: string) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 65248)
  })
}

// 半角 → 全角（英数字）
export const FormatToZenkaku = (str: string) => {
  return str.replace(/[A-Za-z0-9]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) + 65248)
  })
}

//　10:00:00 => 10:00 (HH:mm:ss => HH:mm:ss)
export const FormatTime = (str: string) => {
  const time = str?.split(":")
  return `${time[0]}:${time[1]}`
}

//〇ヶ月前/後を取得
export const getMonthAgoOrLater = (
  type: DayFormat,
  adjustMonth: number = 0,
  selectedDay?: string
) => {
  let date = isNil(selectedDay) ? new Date() : new Date(selectedDay)
  date.setDate(1)
  date.setMonth(date.getMonth() + adjustMonth)
  return day(date, type)
}

export const groupBy = (list: Array<any>, key: string | number) => {
  return list.reduce((d, x) => {
    ;(d[x[key]] = d[x[key]] || []).push(x)
    return d
  }, {})
}

export const displayValueFormatter = (value?: string | number | null) => {
  return !!value ? value : "-"
}
