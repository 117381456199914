// React
import React from "react"
import ReactDOM from "react-dom"

// Component
import { App } from "views/components/pages/index"
import reportWebVitals from "./reportWebVitals"
import { basicStyle } from "tw-custom-component"

// Style
import { error, gray, primary } from "views/style/palette"
import "views/style/iconfonts/material-icons.css"

basicStyle.setColor({
  primary: primary._500,
  tag_font: primary._500,
  gray: gray._500,
  error: error.main,
})
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
