/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

import { SerializedStyles } from "@emotion/react"
import { ReactNode } from "react"
import text from "views/style/text"

export default function PageTitle({
  css: _css,
  children,
  ...props
}: {
  css?: SerializedStyles
  children: ReactNode
} & JSX.IntrinsicElements["h1"]) {
  return (
    <div
      {...props}
      css={css`
        font-size: ${text.xl};
        font-weight: 400;
        ${_css}
      `}
    >
      {children}
    </div>
  )
}
