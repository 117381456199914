import * as yup from "yup"
import { ErrorMessages } from "logics/const"

export default function numberOptional() {
  return yup
    .number()
    .nullable()
    .typeError(ErrorMessages.NotNumber)
    .min(0, ErrorMessages.MinusNumber)
}
