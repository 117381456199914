// React
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

// Apollo
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  from,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"

import { Config } from "config"
import { PATHS } from "views/routes"
import { useToast, MessageType } from "logics/store/toast"

export const useApolloClient = () => {
  const {
    handler: { onOpen },
  } = useToast()
  const navigate =useNavigate()
  const httpLink = useMemo(
    () =>
      createHttpLink({
        uri: Config.api.graphqlUrl.http,
        credentials: "include",
        headers: {
          withCredentials: true,
          "x-token": "ADMIN"
        },
      }),
    []
  )
  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        if (networkError) console.log(`[Network error]: ${networkError}`)
        if(graphQLErrors?.map(i => i?.extensions?.code)?.includes("UNAUTHENTICATED")) {
          navigate(PATHS.login.top)
          onOpen({ message: "セッションが切れました", type: MessageType.Info })
        }
      }),
    []
  )
  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([errorLink, httpLink]),
        cache: new InMemoryCache(),
      }),
    [httpLink]
  )
  return client
}
