// Components
import { MainLayout } from "views/components/layout/main"
import { ListLayout } from "views/components/layout/list"
import ContentHeader from "views/components/atoms/layout/contentHeader"
import { PaginationWrapper } from "views/components/organisms/table/paginationWrapper"
import Button from "views/components/atoms/input/button"
import { Table } from "views/components/organisms/table"
// Hooks
import { useStampCards } from "./hooks"
export const StampCards = () => {
  const { props } = useStampCards()
  const { pagination, table, loading } = props
  return (
    <MainLayout>
      <ListLayout
        {...props}
        header={
          <ContentHeader
            aside={
              <Button type="submit" onClick={props?.onToRegister}>
                <>新規作成</>
              </Button>
            }
          >
            スタンプカード一覧
          </ContentHeader>
        }
      >
        <PaginationWrapper pagination={pagination}>
          <Table tableProps={table} loading={loading} />
        </PaginationWrapper>
      </ListLayout>
    </MainLayout>
  )
}
