import { IEvent } from "./interface"
import { CafeOrder } from "../../gen/ws_pb"
import { event } from "./interface"

export const CAFE_ORDER_COMPLETED_EVENT = "CAFE_ORDER_COMPLETED_EVENT"
export type ICafeOrder = CafeOrder
export class CafeOrderCompletedEvent implements IEvent<ICafeOrder> {
  constructor() {}
  execute = (value: ICafeOrder) => {
    event.emit(CAFE_ORDER_COMPLETED_EVENT, value)
  }
}
