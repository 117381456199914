import { sortBy } from "lodash"

export type CategoryData = {
  id: number
  name: string
  parentCategoryId: number | null
  sortNo?: number
  children: CategoryData[] | null
}
export const CategoryTree: CategoryData[] = [
  {
    id: 20000,
    name: "グルメ・飲料",
    parentCategoryId: null,
    sortNo: 1,
    children: [
      {
        id: 21000,
        name: "食品",
        parentCategoryId: 20000,
        children: [
          {
            id: 21100,
            name: "米・雑穀",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 21200,
            name: "魚介類・水産加工品",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 21300,
            name: "精肉・肉加工品",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 21400,
            name: "野菜・果物",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 21500,
            name: "卵・チーズ・乳製品",
            parentCategoryId: 21000,
            children: null,
          },
          { id: 21600, name: "惣菜", parentCategoryId: 21000, children: null },
          { id: 21700, name: "麺類", parentCategoryId: 21000, children: null },
          {
            id: 21800,
            name: "パン・ジャム・シリアル",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 21900,
            name: "豆腐・納豆・こんにゃく",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 22000,
            name: "漬け物・梅干し・キムチ",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 22100,
            name: "調味料",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 22200,
            name: "粉類・乾物・缶詰・瓶詰",
            parentCategoryId: 21000,
            children: null,
          },
          {
            id: 22300,
            name: "その他",
            parentCategoryId: 21000,
            children: null,
          },
        ],
      },
      {
        id: 23000,
        name: "スイーツ・お菓子",
        parentCategoryId: 20000,
        children: [
          {
            id: 23100,
            name: "洋菓子・クッキー・チョコレート",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23200,
            name: "スナック菓子・せんべい・米菓",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23300,
            name: "ナッツ・ドライフルーツ",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23400,
            name: "ケーキ",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23500,
            name: "アイスクリーム・シャーベット",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23600,
            name: "製菓・製パン材料",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23700,
            name: "和菓子",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23800,
            name: "駄菓子",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 23900,
            name: "栄養補助スナック",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 24000,
            name: "各種スイーツ・お菓子セット",
            parentCategoryId: 23000,
            children: null,
          },
          {
            id: 24100,
            name: "その他",
            parentCategoryId: 23000,
            children: null,
          },
        ],
      },
      {
        id: 25000,
        name: "水・ソフトドリンク",
        parentCategoryId: 20000,
        children: [
          {
            id: 25100,
            name: "水・ミネラルウォーター",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25200,
            name: "炭酸飲料",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25300,
            name: "スポーツドリンク",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25400,
            name: "野菜・果実飲料",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25500,
            name: "コーヒー",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25600,
            name: "ココア・チョコレートドリンク",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25700,
            name: "牛乳・乳酸菌飲料",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25800,
            name: "お茶・紅茶",
            parentCategoryId: 25000,
            children: null,
          },
          {
            id: 25900,
            name: "その他",
            parentCategoryId: 25000,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 30000,
    name: "日用品・ヘルスケア",
    parentCategoryId: null,
    sortNo: 3,
    children: [
      {
        id: 31000,
        name: "日用品雑貨",
        parentCategoryId: 30000,
        children: [
          {
            id: 31100,
            name: "日用消耗品",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31200,
            name: "生活雑貨",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31300,
            name: "文房具・事務用品",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31400,
            name: "手芸・クラフト・生地",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31500,
            name: "タオル",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31600,
            name: "バス用品",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31700,
            name: "洗濯用品",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31800,
            name: "掃除用品",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 31900,
            name: "トイレ用品",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 32000,
            name: "旅行用品",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 32100,
            name: "防災関連グッズ",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 32200,
            name: "防犯関連グッズ",
            parentCategoryId: 31000,
            children: null,
          },
          {
            id: 32300,
            name: "その他",
            parentCategoryId: 31000,
            children: null,
          },
        ],
      },
      {
        id: 33000,
        name: "ダイエット・健康",
        parentCategoryId: 30000,
        children: [
          {
            id: 33100,
            name: "ダイエット",
            parentCategoryId: 33000,
            children: null,
          },
          {
            id: 33200,
            name: "サプリメント",
            parentCategoryId: 33000,
            children: null,
          },
          {
            id: 33300,
            name: "健康食品",
            parentCategoryId: 33000,
            children: null,
          },
          {
            id: 33400,
            name: "リラックス・マッサージ用品",
            parentCategoryId: 33000,
            children: null,
          },
          {
            id: 33500,
            name: "健康グッズ",
            parentCategoryId: 33000,
            children: null,
          },
          {
            id: 33600,
            name: "デンタルケア",
            parentCategoryId: 33000,
            children: null,
          },
          {
            id: 33700,
            name: "その他",
            parentCategoryId: 33000,
            children: null,
          },
        ],
      },
      {
        id: 34000,
        name: "医薬品・コンタクト・介護",
        parentCategoryId: 30000,
        children: [
          {
            id: 34100,
            name: "衛生日用品・衛生医療品",
            parentCategoryId: 34000,
            children: null,
          },
          {
            id: 34200,
            name: "身体測定器・医療計測器",
            parentCategoryId: 34000,
            children: null,
          },
          {
            id: 34300,
            name: "その他",
            parentCategoryId: 34000,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 40000,
    name: "コスメ・ヘアケア",
    parentCategoryId: null,
    sortNo: 5,
    children: [
      {
        id: 41000,
        name: "美容・コスメ・香水",
        parentCategoryId: 40000,
        children: [
          {
            id: 41100,
            name: "スキンケア",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41200,
            name: "ベースメイク・メイクアップ",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41300,
            name: "日焼け止め・UVケア",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41400,
            name: "メイク道具・ケアグッズ",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41500,
            name: "ヘアケア・スタイリング",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41600,
            name: "香水・フレグランス",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41700,
            name: "アロマ・お香",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41800,
            name: "ボディケア",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 41900,
            name: "ネイル",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 42000,
            name: "シェービング",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 42100,
            name: "美顔器・スチーマー",
            parentCategoryId: 41000,
            children: null,
          },
          {
            id: 42200,
            name: "その他",
            parentCategoryId: 41000,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 80000,
    name: "インテリア・住まい・ペット",
    parentCategoryId: null,
    sortNo: 5,
    children: [
      {
        id: 81000,
        name: "インテリア・収納",
        parentCategoryId: 80000,
        children: [
          {
            id: 81100,
            name: "収納家具",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81200,
            name: "ライト・照明器具",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81300,
            name: "テーブル",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81400,
            name: "デスク",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81500,
            name: "イス・チェア",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81600,
            name: "ソファ・ソファベッド",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81700,
            name: "ダイニングセット",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81800,
            name: "オフィス家具",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 81900,
            name: "子供部屋用インテリア・寝具・収納",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 82000,
            name: "カーテン・ブラインド",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 82100,
            name: "カーペット・マット・畳",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 82200,
            name: "クッション・座布団",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 82300,
            name: "こたつ用布団・カバー",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 82400,
            name: "インテリア小物・置物",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 82500,
            name: "ゴミ箱",
            parentCategoryId: 81000,
            children: null,
          },
          {
            id: 82600,
            name: "その他",
            parentCategoryId: 81000,
            children: null,
          },
        ],
      },
      {
        id: 83000,
        name: "寝具・ベッド・マットレス",
        parentCategoryId: 80000,
        children: [
          { id: 83100, name: "寝具", parentCategoryId: 83000, children: null },
          {
            id: 83200,
            name: "ベッド",
            parentCategoryId: 83000,
            children: null,
          },
        ],
      },
      {
        id: 84000,
        name: "キッチン用品・食器・調理器具",
        parentCategoryId: 80000,
        children: [
          {
            id: 84100,
            name: "食器・カトラリー・グラス",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84200,
            name: "鍋・フライパン",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84300,
            name: "調理・製菓道具",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84400,
            name: "弁当箱・水筒",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84500,
            name: "保存容器・調味料入れ",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84600,
            name: "コーヒー・お茶用品",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84700,
            name: "ワイン・バー・酒用品",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84800,
            name: "水まわり用品",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 84900,
            name: "キッチン整理用品",
            parentCategoryId: 84000,
            children: null,
          },
          {
            id: 85000,
            name: "その他",
            parentCategoryId: 84000,
            children: null,
          },
        ],
      },
      {
        id: 86000,
        name: "ペットフード・ペット用品",
        parentCategoryId: 80000,
        children: [
          {
            id: 86100,
            name: "犬用品",
            parentCategoryId: 86000,
            children: null,
          },
          {
            id: 86200,
            name: "猫用品",
            parentCategoryId: 86000,
            children: null,
          },
          {
            id: 86300,
            name: "小動物用品",
            parentCategoryId: 86000,
            children: null,
          },
          {
            id: 86400,
            name: "その他",
            parentCategoryId: 86000,
            children: null,
          },
        ],
      },
      {
        id: 87000,
        name: "花・ガーデン・DIY",
        parentCategoryId: 80000,
        children: [
          {
            id: 87100,
            name: "花・観葉植物",
            parentCategoryId: 87000,
            children: null,
          },
          {
            id: 87200,
            name: "ガーデニング・農業",
            parentCategoryId: 87000,
            children: null,
          },
          {
            id: 87300,
            name: "DIY・工具",
            parentCategoryId: 87000,
            children: null,
          },
          {
            id: 87400,
            name: "その他",
            parentCategoryId: 87000,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 90000,
    name: "エンタメ・ホビー・アート",
    parentCategoryId: null,
    sortNo: 7,
    children: [
      { id: 90100, name: "ゲーム", parentCategoryId: 90000, children: null },
      {
        id: 90200,
        name: "パーティー・イベント用品",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 90300,
        name: "コスプレ・変装・仮装",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 90400,
        name: "コレクション",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 90500,
        name: "アート・美術品・骨董品・民芸品",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 90600,
        name: "占い・開運・風水・パワーストーン",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 90700,
        name: "ラジコン・ドローン",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 90800,
        name: "ホビー工具・材料",
        parentCategoryId: 90000,
        children: null,
      },
      { id: 90900, name: "パズル", parentCategoryId: 90000, children: null },
      {
        id: 91000,
        name: "手品・マジック",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 91100,
        name: "標本・化石",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 91200,
        name: "楽器・音楽機材",
        parentCategoryId: 90000,
        children: null,
      },
      {
        id: 92000,
        name: "プラモデル・模型",
        parentCategoryId: 90000,
        children: [
          {
            id: 92100,
            name: "ロボット",
            parentCategoryId: 92000,
            children: null,
          },
          {
            id: 92200,
            name: "車・バイク",
            parentCategoryId: 92000,
            children: null,
          },
          {
            id: 92300,
            name: "その他",
            parentCategoryId: 92000,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 10000,
    name: "ファッション",
    parentCategoryId: null,
    sortNo: 6,
    children: [
      {
        id: 11000,
        name: "レディースファッション",
        parentCategoryId: 10000,
        children: [
          {
            id: 11100,
            name: "トップス",
            parentCategoryId: 11000,
            children: null,
          },
          {
            id: 11200,
            name: "ボトムス",
            parentCategoryId: 11000,
            children: null,
          },
          {
            id: 11300,
            name: "コート・ジャケット",
            parentCategoryId: 11000,
            children: null,
          },
          {
            id: 11400,
            name: "ワンピース",
            parentCategoryId: 11000,
            children: null,
          },
          {
            id: 11500,
            name: "ドレス",
            parentCategoryId: 11000,
            children: null,
          },
          {
            id: 11600,
            name: "スーツ・セットアップ",
            parentCategoryId: 11000,
            children: null,
          },
          {
            id: 11700,
            name: "レインウェア",
            parentCategoryId: 11000,
            children: null,
          },
          { id: 11800, name: "水着", parentCategoryId: 11000, children: null },
          {
            id: 11900,
            name: "その他",
            parentCategoryId: 11000,
            children: null,
          },
        ],
      },
      {
        id: 12000,
        name: "メンズファッション",
        parentCategoryId: 10000,
        children: [
          {
            id: 12100,
            name: "トップス",
            parentCategoryId: 12000,
            children: null,
          },
          {
            id: 12200,
            name: "ズボン・パンツ",
            parentCategoryId: 12000,
            children: null,
          },
          {
            id: 12300,
            name: "コート・ジャケット",
            parentCategoryId: 12000,
            children: null,
          },
          {
            id: 12400,
            name: "スーツ・セットアップ",
            parentCategoryId: 12000,
            children: null,
          },
          {
            id: 12500,
            name: "レインウェア",
            parentCategoryId: 12000,
            children: null,
          },
          { id: 12600, name: "水着", parentCategoryId: 12000, children: null },
          {
            id: 12700,
            name: "その他",
            parentCategoryId: 12000,
            children: null,
          },
        ],
      },
      {
        id: 13000,
        name: "靴",
        parentCategoryId: 10000,
        children: [
          {
            id: 13100,
            name: "レディース靴",
            parentCategoryId: 13000,
            children: null,
          },
          {
            id: 13200,
            name: "メンズ靴",
            parentCategoryId: 13000,
            children: null,
          },
          {
            id: 13300,
            name: "靴ケア用品・アクセサリー",
            parentCategoryId: 13000,
            children: null,
          },
          {
            id: 13400,
            name: "その他",
            parentCategoryId: 13000,
            children: null,
          },
        ],
      },
      {
        id: 14000,
        name: "バッグ・小物・ブランド雑貨",
        parentCategoryId: 10000,
        children: [
          {
            id: 14100,
            name: "バッグ",
            parentCategoryId: 14000,
            children: null,
          },
          {
            id: 14200,
            name: "財布・ケース",
            parentCategoryId: 14000,
            children: null,
          },
          {
            id: 14300,
            name: "眼鏡・サングラス",
            parentCategoryId: 14000,
            children: null,
          },
          {
            id: 14400,
            name: "ファッション小物",
            parentCategoryId: 14000,
            children: null,
          },
          {
            id: 14500,
            name: "ウィッグ・つけ毛靴",
            parentCategoryId: 14000,
            children: null,
          },
          {
            id: 14600,
            name: "ウェディング小物",
            parentCategoryId: 14000,
            children: null,
          },
          {
            id: 14700,
            name: "その他",
            parentCategoryId: 14000,
            children: null,
          },
        ],
      },
      {
        id: 15000,
        name: "ジュエリー・アクセサリー",
        parentCategoryId: 10000,
        children: [
          {
            id: 15100,
            name: "レディースジュエリー・アクセサリー",
            parentCategoryId: 15000,
            children: null,
          },
          {
            id: 15200,
            name: "メンズジュエリー・アクセサリー",
            parentCategoryId: 15000,
            children: null,
          },
          {
            id: 15300,
            name: "ペアアクセサリー",
            parentCategoryId: 15000,
            children: null,
          },
          {
            id: 15400,
            name: "その他",
            parentCategoryId: 15000,
            children: null,
          },
        ],
      },
      {
        id: 16000,
        name: "腕時計",
        parentCategoryId: 10000,
        children: [
          {
            id: 16100,
            name: "メンズ腕時計",
            parentCategoryId: 16000,
            children: null,
          },
          {
            id: 16200,
            name: "レディース腕時計",
            parentCategoryId: 16000,
            children: null,
          },
          {
            id: 16300,
            name: "キッズ用腕時計",
            parentCategoryId: 16000,
            children: null,
          },
          {
            id: 16400,
            name: "その他",
            parentCategoryId: 16000,
            children: null,
          },
        ],
      },
      {
        id: 17000,
        name: "インナー・下着・ナイトウェア",
        parentCategoryId: 10000,
        children: [
          {
            id: 17100,
            name: "レディース",
            parentCategoryId: 17000,
            children: null,
          },
          {
            id: 17200,
            name: "メンズ",
            parentCategoryId: 17000,
            children: null,
          },
          {
            id: 17300,
            name: "その他",
            parentCategoryId: 17000,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 110000,
    name: "書籍",
    parentCategoryId: null,
    sortNo: 8,
    children: [
      {
        id: 111000,
        name: "本",
        parentCategoryId: 110000,
        children: [
          {
            id: 111100,
            name: "雑誌",
            parentCategoryId: 111000,
            children: null,
          },
          {
            id: 111200,
            name: "コミック",
            parentCategoryId: 111000,
            children: null,
          },
          {
            id: 111300,
            name: "絵本・児童書・図鑑",
            parentCategoryId: 111000,
            children: null,
          },
          {
            id: 111400,
            name: "化学・医学・技術",
            parentCategoryId: 111000,
            children: null,
          },
        ],
      },
    ],
  },
  {
    id: 100000,
    name: "酒類（アルコール）",
    parentCategoryId: null,
    sortNo: 2,
    children: [
      {
        id: 101000,
        name: "ビール・洋酒",
        parentCategoryId: 100000,
        children: [
          {
            id: 101100,
            name: "ビール・発泡酒",
            parentCategoryId: 101000,
            children: null,
          },
          {
            id: 101200,
            name: "ウイスキー",
            parentCategoryId: 101000,
            children: null,
          },
          {
            id: 101300,
            name: "チューハイ・ハイボール・カクテル",
            parentCategoryId: 101000,
            children: null,
          },
        ],
      },
      {
        id: 102000,
        name: "日本酒・焼酎",
        parentCategoryId: 100000,
        children: [
          {
            id: 102100,
            name: "焼酎",
            parentCategoryId: 102000,
            children: null,
          },
          {
            id: 102200,
            name: "日本酒",
            parentCategoryId: 102000,
            children: null,
          },
          {
            id: 102300,
            name: "梅酒",
            parentCategoryId: 102000,
            children: null,
          },
        ],
      },
      {
        id: 103000,
        name: "ワイン",
        parentCategoryId: 100000,
        children: [
          {
            id: 103100,
            name: "赤ワイン",
            parentCategoryId: 103000,
            children: null,
          },
          {
            id: 103200,
            name: "白ワイン",
            parentCategoryId: 103000,
            children: null,
          },
          {
            id: 103300,
            name: "飲み比べセット",
            parentCategoryId: 103000,
            children: null,
          },
          {
            id: 103400,
            name: "スパークリングワイン・シャンパン",
            parentCategoryId: 103000,
            children: null,
          },
        ],
      },
    ],
  },
]
export const SortedCategoryTree = sortBy([...CategoryTree], ["sortNo"])
export const CategoryFlat = (() => {
  let categories: CategoryData[] = []
  CategoryTree.forEach((c1) => {
    categories = [...categories, c1]
    if (c1?.children) {
      c1?.children?.forEach((c2) => {
        categories = [...categories, c2]
        if (c2?.children) {
          c2?.children?.forEach((c3) => {
            categories = [...categories, c3]
          })
        }
      })
    }
  })
  return categories
})()
