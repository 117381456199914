import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  Void: any;
};

export type Account = {
  __typename?: 'Account';
  accountStatusId?: Maybe<Scalars['Int']>;
  accountTypeId?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  kana?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prefId?: Maybe<Scalars['Int']>;
  sexId?: Maybe<Scalars['Int']>;
};

export type AccountConfirmRequest = {
  hash: Scalars['String'];
};

export type AccountLoginRequest = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Action = {
  action: Scalars['String'];
  category: Scalars['String'];
  label?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Array<Scalars['String']>>;
  value?: InputMaybe<Scalars['String']>;
};

export type ActionReply = {
  __typename?: 'ActionReply';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type ActionSearchRequest = {
  from: Scalars['DateTime'];
  joinMemberId?: InputMaybe<Scalars['Int']>;
  to: Scalars['DateTime'];
};

export type AddSkuRequest = {
  barcode?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  pattern: Array<PatternInput>;
  price: Scalars['Float'];
  qty: Scalars['Float'];
  sku?: InputMaybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  addr01: Scalars['String'];
  addr02?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
};

export type AdminLogin = {
  code: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Answer = {
  __typename?: 'Answer';
  birthday: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  customerId: Scalars['Int'];
  description: Scalars['String'];
  details: Array<AnswerDetail>;
  id: Scalars['String'];
  name: Scalars['String'];
  prefId: Scalars['Int'];
  sex: Scalars['Int'];
  surveyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AnswerCreateInput = {
  birthday: Scalars['DateTime'];
  details: Array<AnswerDetailInput>;
  prefId: Scalars['Int'];
  sex: Scalars['Int'];
  surveyId: Scalars['String'];
};

export type AnswerDetail = {
  __typename?: 'AnswerDetail';
  content: AnswerDetailContent;
  question: Scalars['String'];
  questionType: QuestionType;
  question_id: Scalars['String'];
};

export type AnswerDetailContent = AnswerSelection | AnswerSelections | AnswerText;

export type AnswerDetailInput = {
  selection?: InputMaybe<AnswerSelectionInput>;
  selections?: InputMaybe<AnswerMultipleSelectionInput>;
  text?: InputMaybe<AnswerTextInput>;
};

export type AnswerMultipleSelectionInput = {
  optionIds: Array<Scalars['String']>;
  questionId: Scalars['String'];
};

export type AnswerSearchInput = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<AnswerSearchParam>;
};

export type AnswerSearchParam = {
  survey_id?: InputMaybe<Scalars['String']>;
};

export type AnswerSearchResponse = {
  __typename?: 'AnswerSearchResponse';
  datasource: Array<Answer>;
  page: PageReply;
};

export type AnswerSelection = {
  __typename?: 'AnswerSelection';
  id: Scalars['String'];
  option?: Maybe<Scalars['String']>;
  questionOptionId?: Maybe<Scalars['String']>;
};

export type AnswerSelectionInput = {
  questionOptionId: Scalars['String'];
  question_id: Scalars['String'];
};

export type AnswerSelections = {
  __typename?: 'AnswerSelections';
  items: Array<AnswerSelection>;
};

export type AnswerText = {
  __typename?: 'AnswerText';
  answer?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type AnswerTextInput = {
  answer: Scalars['String'];
  question_id: Scalars['String'];
};

export type Banner = {
  __typename?: 'Banner';
  bannerTypeId: Scalars['Int'];
  closingDate?: Maybe<Scalars['DateTime']>;
  createDate: Scalars['DateTime'];
  id: Scalars['Int'];
  image: BannerImage;
  isExternal: Scalars['Boolean'];
  sortNo: Scalars['Int'];
  startingDate: Scalars['DateTime'];
  title: Scalars['String'];
  updateDate: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type BannerImage = {
  __typename?: 'BannerImage';
  bannerId: Scalars['Int'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  id: Scalars['Int'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type BusinessDay = {
  __typename?: 'BusinessDay';
  closingTime?: Maybe<Scalars['String']>;
  friday?: Maybe<Scalars['Boolean']>;
  holiday?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  monday?: Maybe<Scalars['Boolean']>;
  openingTime?: Maybe<Scalars['String']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

export type BusinessDayParam = {
  closingTime?: InputMaybe<Scalars['String']>;
  friday?: InputMaybe<Scalars['Boolean']>;
  holiday?: InputMaybe<Scalars['Boolean']>;
  monday?: InputMaybe<Scalars['Boolean']>;
  openingTime?: InputMaybe<Scalars['String']>;
  saturday?: InputMaybe<Scalars['Boolean']>;
  sunday?: InputMaybe<Scalars['Boolean']>;
  thursday?: InputMaybe<Scalars['Boolean']>;
  tuesday?: InputMaybe<Scalars['Boolean']>;
  wednesday?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessDayRequest = {
  closingTime?: InputMaybe<Scalars['String']>;
  friday?: InputMaybe<Scalars['Boolean']>;
  holiday?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  monday?: InputMaybe<Scalars['Boolean']>;
  openingTime?: InputMaybe<Scalars['String']>;
  saturday?: InputMaybe<Scalars['Boolean']>;
  sunday?: InputMaybe<Scalars['Boolean']>;
  thursday?: InputMaybe<Scalars['Boolean']>;
  tuesday?: InputMaybe<Scalars['Boolean']>;
  wednesday?: InputMaybe<Scalars['Boolean']>;
};

export type CafeCart = {
  __typename?: 'CafeCart';
  cartItems: Array<CafeCartItem>;
  quantity: Scalars['Int'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
};

export type CafeCartItem = {
  __typename?: 'CafeCartItem';
  id: Scalars['String'];
  isReducedTax: Scalars['Boolean'];
  name: Scalars['String'];
  nonManagedStock: Scalars['Boolean'];
  pattern: Array<CafePattern>;
  price: Scalars['Int'];
  priceWithTax: Scalars['Int'];
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  skuId: Scalars['String'];
  soldOut: Scalars['Boolean'];
  stock?: Maybe<Scalars['Int']>;
  thumbnail: Image;
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
};

export type CafeOrder = {
  __typename?: 'CafeOrder';
  codeUrl?: Maybe<Scalars['String']>;
  createDate: Scalars['DateTime'];
  customerId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  orderItems: Array<CafeOrderItem>;
  orderStatusId: Scalars['Int'];
  paymentId?: Maybe<Scalars['String']>;
  paymentStatusId: Scalars['Int'];
  paymentVendorId: Scalars['Int'];
  recordStatusId: Scalars['Int'];
  tenpoId: Scalars['Int'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
  updateDate: Scalars['DateTime'];
};

export type CafeOrderAggregate = {
  __typename?: 'CafeOrderAggregate';
  totalPayment: Scalars['Int'];
  totalQuantity: Scalars['Int'];
};

export type CafeOrderItem = {
  __typename?: 'CafeOrderItem';
  id: Scalars['String'];
  orderId: Scalars['String'];
  pattern: Scalars['String'];
  price: Scalars['Int'];
  productId: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  recordStatusId: Scalars['Int'];
  skuId: Scalars['String'];
  taxRate: Scalars['Float'];
  thumbnail: Scalars['String'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
};

export type CafeOrdersParam = {
  customerId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  orderStatusId?: InputMaybe<Scalars['Int']>;
  paymentStatusId?: InputMaybe<Scalars['Int']>;
  recordStatusId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tenpoId?: InputMaybe<Scalars['Int']>;
};

export type CafeOrdersReply = {
  __typename?: 'CafeOrdersReply';
  aggregate: CafeOrderAggregate;
  dataSource: Array<CafeOrder>;
  pages: PageReply;
};

export type CafeOrdersRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<CafeOrdersParam>;
};

export type CafeOrdersSummaryByProduct = {
  __typename?: 'CafeOrdersSummaryByProduct';
  pattern: Scalars['String'];
  productId: Scalars['String'];
  productName: Scalars['String'];
  skuId: Scalars['String'];
  totalPayment: Scalars['Int'];
  totalQuantity: Scalars['Int'];
};

export type CafeOrdersSummaryParam = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  tenpoId?: InputMaybe<Scalars['Int']>;
};

export type CafeOrdersSummaryRequest = {
  pages?: InputMaybe<PageRequest>;
  param: CafeOrdersSummaryParam;
};

export type CafeOrdersSummayrReply = {
  __typename?: 'CafeOrdersSummayrReply';
  dataSource: Array<CafeOrdersSummaryByProduct>;
  pages: PageReply;
};

export type CafePattern = {
  __typename?: 'CafePattern';
  label: Scalars['String'];
  option: Scalars['String'];
};

export type CafePatternInput = {
  label: Scalars['String'];
  option: Scalars['String'];
};

export type CafePrice = {
  __typename?: 'CafePrice';
  amount: Scalars['Float'];
  isReducedTax: Scalars['Boolean'];
  priceWithTax: Scalars['Int'];
  type: Scalars['Int'];
};

export type CafePriceInput = {
  amount: Scalars['Float'];
  isReducedTax: Scalars['Boolean'];
  type: Scalars['Int'];
};

export type CafeProduct = {
  __typename?: 'CafeProduct';
  categoryId: Scalars['Int'];
  createDate?: Maybe<Scalars['DateTime']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images: Array<Image>;
  isReducedTax: Scalars['Boolean'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nonManagedStock: Scalars['Boolean'];
  overview: Scalars['String'];
  productType: Scalars['Int'];
  skus: Array<CafeSku>;
  status: Scalars['Int'];
  tags: Array<Scalars['String']>;
  tenpoId: Scalars['Int'];
  tenpoName: Scalars['String'];
  updateDate?: Maybe<Scalars['DateTime']>;
  variations: Array<CafeVariation>;
};

export type CafeSku = {
  __typename?: 'CafeSku';
  cost?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  pattern: Array<CafePattern>;
  prices: Array<CafePrice>;
  qty?: Maybe<Scalars['Int']>;
  soldOut: Scalars['Boolean'];
};

export type CafeStandardProduct = {
  __typename?: 'CafeStandardProduct';
  categoryId: Scalars['Int'];
  createDate?: Maybe<Scalars['DateTime']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images: Array<Image>;
  isReducedTax: Scalars['Boolean'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nonManagedStock: Scalars['Boolean'];
  overview: Scalars['String'];
  productType: Scalars['Int'];
  skus: Array<CafeStandardSku>;
  status: Scalars['Int'];
  tags: Array<Scalars['String']>;
  updateDate?: Maybe<Scalars['DateTime']>;
  variations: Array<CafeVariation>;
};

export type CafeStandardProductForm = {
  id: Scalars['String'];
  standardProductIds: Array<Scalars['String']>;
};

export type CafeStandardProductGroup = {
  __typename?: 'CafeStandardProductGroup';
  createDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  standardProducts: Array<CafeStandardProduct>;
  startDate: Scalars['DateTime'];
  tenpoSizeId: Scalars['Int'];
  updateDate: Scalars['DateTime'];
};

export type CafeStandardSku = {
  __typename?: 'CafeStandardSku';
  cost?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  pattern: Array<CafePattern>;
  prices: Array<CafePrice>;
};

export type CafeVariation = {
  __typename?: 'CafeVariation';
  label: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type CafeVariationInput = {
  label: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type Cart = {
  __typename?: 'Cart';
  cartItems: Array<CartItem>;
  deliveryFee: Scalars['Int'];
  ownnerId: Scalars['Int'];
  ownnerName: Scalars['String'];
  quantity: Scalars['Int'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
};

export type CartFilter = {
  ownnerId: Scalars['Int'];
  typeId: Scalars['Int'];
};

export type CartItem = {
  __typename?: 'CartItem';
  id: Scalars['Int'];
  image?: Maybe<Image>;
  pattern: Array<Pattern>;
  price: Scalars['Int'];
  priceWithTax: Scalars['Int'];
  productId: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  skuId: Scalars['String'];
  stock: Scalars['Int'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
  typeId: Scalars['Int'];
};

export type ChangeSoldOutRrequest = {
  id: Scalars['String'];
  skuId: Scalars['String'];
  soldOut: Scalars['Boolean'];
};

export type Coupon = {
  __typename?: 'Coupon';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  expirySpan?: Maybe<Scalars['Int']>;
  franchiseId: Scalars['Int'];
  id: Scalars['String'];
  isPublished: Scalars['Boolean'];
  max?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numberOfCardsIssued: Scalars['Int'];
  thumbnail: Thumbnail;
  updatedAt: Scalars['DateTime'];
};

export type CouponBurnInput = {
  id: Scalars['String'];
};

export type CouponCreateInput = {
  description: Scalars['String'];
  expirySpan?: InputMaybe<Scalars['Int']>;
  franchiseId?: InputMaybe<Scalars['Int']>;
  isPublished: Scalars['Boolean'];
  max?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  thumbnail: ImageInput;
};

export type CouponSearchInput = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<CouponSearchParam>;
};

export type CouponSearchParam = {
  franchiseId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CouponSearchResponse = {
  __typename?: 'CouponSearchResponse';
  datasource: Array<Coupon>;
  page: PageReply;
};

export type CouponUpdateInput = {
  description: Scalars['String'];
  expirySpan?: InputMaybe<Scalars['Int']>;
  franchiseId: Scalars['Int'];
  id: Scalars['String'];
  isPublished: Scalars['Boolean'];
  max?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  thumbnail: ImageUpdateInput;
};

export type CreateAccountRequest = {
  birthday?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  kana?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  prefId?: InputMaybe<Scalars['Int']>;
  sexId?: InputMaybe<Scalars['Int']>;
};

export type CreateAddressRequest = {
  addr01: Scalars['String'];
  addr02?: InputMaybe<Scalars['String']>;
  customerId: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
};

export type CreateAdminOneTimePasswordRequest = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type CreateBannerImageRequest = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type CreateBannerRequest = {
  bannerTypeId: Scalars['Int'];
  closingDate?: InputMaybe<Scalars['DateTime']>;
  image: CreateBannerImageRequest;
  isExternal: Scalars['Boolean'];
  sortNo: Scalars['Int'];
  startingDate: Scalars['DateTime'];
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type CreateCafeCartItemRequest = {
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  skuId: Scalars['String'];
};

export type CreateCafeOrderRequest = {
  deviceId?: InputMaybe<Scalars['String']>;
  orderType: Scalars['Int'];
  paymentTypeId: Scalars['Int'];
  priceType: Scalars['Int'];
};

export type CreateCafeProductRequest = {
  categoryId: Scalars['Int'];
  detail?: InputMaybe<Scalars['String']>;
  images: Array<ImageInput>;
  isReducedTax: Scalars['Boolean'];
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nonManagedStock: Scalars['Boolean'];
  overview: Scalars['String'];
  skus: Array<CreateCafeSkuRequest>;
  status: Scalars['Int'];
  tags: Array<Scalars['String']>;
  tenpoId?: InputMaybe<Scalars['Int']>;
  variations: Array<CafeVariationInput>;
};

export type CreateCafeSkuRequest = {
  cost?: InputMaybe<Scalars['Float']>;
  pattern: Array<CafePatternInput>;
  prices: Array<InputMaybe<CafePriceInput>>;
  qty?: InputMaybe<Scalars['Int']>;
  soldOut?: InputMaybe<Scalars['Boolean']>;
};

export type CreateCafeStandardProductGroupRequest = {
  endDate: Scalars['DateTime'];
  name?: InputMaybe<Scalars['String']>;
  standardProductIds: Array<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tenpoSizeId: Scalars['Int'];
};

export type CreateCafeStandardProductRequest = {
  categoryId: Scalars['Int'];
  detail?: InputMaybe<Scalars['String']>;
  images: Array<ImageInput>;
  isReducedTax: Scalars['Boolean'];
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nonManagedStock: Scalars['Boolean'];
  overview: Scalars['String'];
  skus: Array<CreateCafeStandardSkuRequest>;
  status: Scalars['Int'];
  tags: Array<Scalars['String']>;
  variations: Array<CafeVariationInput>;
};

export type CreateCafeStandardSkuRequest = {
  cost?: InputMaybe<Scalars['Float']>;
  pattern: Array<CafePatternInput>;
  prices: Array<InputMaybe<CafePriceInput>>;
};

export type CreateCartItemRequest = {
  ownnerId: Scalars['Int'];
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  skuId: Scalars['String'];
  typeId: Scalars['Int'];
};

export type CreateDisplayImage = {
  fileType: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
  tenpoId?: InputMaybe<Scalars['Int']>;
};

export type CreateDisplayProductImage = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type CreateDisplayProductRequest = {
  attributeId: Scalars['Int'];
  displayEndDate: Scalars['Int'];
  displayStartDate: Scalars['Int'];
  feeId: Scalars['Int'];
  images?: InputMaybe<Array<CreateDisplayProductImage>>;
  isDelivery: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  saleType: Scalars['Int'];
  statusNote?: InputMaybe<Scalars['String']>;
  tenantId: Scalars['Int'];
};

export type CreateDisplaySpaceAttributeRequest = {
  depth: Scalars['Int'];
  displaySpaceTypeId: Scalars['Int'];
  fees: Array<CreateDisplaySpaceFeeRequest>;
  height: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  tenpoId?: InputMaybe<Scalars['Int']>;
  width: Scalars['Int'];
};

export type CreateDisplaySpaceFeeRequest = {
  fee: Scalars['Int'];
  span: Scalars['Int'];
  tenpoId?: InputMaybe<Scalars['Int']>;
};

export type CreateDisplaySpaceOnProductRequest = {
  displaySpaceId: Scalars['Int'];
  displaySpaceProductId: Scalars['Int'];
};

export type CreateDisplaySpaceRequest = {
  attributeId: Scalars['Int'];
  blockNumber: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  statusId: Scalars['Int'];
  tenpoId?: InputMaybe<Scalars['Int']>;
};

export type CreateFranchiseMember = {
  authorityId: Scalars['Int'];
  email: Scalars['String'];
  franchiseId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateFranchiseRequest = {
  addr01: Scalars['String'];
  addr02: Scalars['String'];
  companyKana: Scalars['String'];
  companyName: Scalars['String'];
  email01: Scalars['String'];
  email02?: InputMaybe<Scalars['String']>;
  email03?: InputMaybe<Scalars['String']>;
  email04?: InputMaybe<Scalars['String']>;
  member: FranchiseMemberParam;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  url?: InputMaybe<Scalars['String']>;
};

export type CreateManualRequest = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  key: Scalars['String'];
  manualTypeId: Scalars['Int'];
  path: Scalars['String'];
};

export type CreateMasterMember = {
  authorityId: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateOneTimePasswordRequest = {
  email: Scalars['String'];
};

export type CreateOrderFilter = {
  ownnerId: Scalars['Int'];
  typeId: Scalars['Int'];
};

export type CreateOrderReply = {
  __typename?: 'CreateOrderReply';
  cartItems: Array<OrderedCartItem>;
  customerId: Scalars['Int'];
  orderId: Scalars['String'];
  orderItems: Array<OrderItem>;
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
};

export type CreateOrderRequest = {
  filter?: InputMaybe<CreateOrderFilter>;
  shippingAddressId: Scalars['Int'];
};

export type CreateProductRequest = {
  categoryId: Scalars['Int'];
  detail?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  images: Array<ImageInput>;
  isReducedTax: Scalars['Boolean'];
  joinMemberId?: InputMaybe<Scalars['Int']>;
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  overview: Scalars['String'];
  saleType: Scalars['Int'];
  skus: Array<CreateSkuRequest>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status: Scalars['Int'];
  tags: Array<Scalars['String']>;
  variations: Array<VariationInput>;
};

export type CreateShippedItem = {
  orderItemId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type CreateShippingInformationRequest = {
  arrivalDate?: InputMaybe<Scalars['DateTime']>;
  deliveryVendorId: Scalars['Int'];
  orderId: Scalars['Int'];
  shippedItems: Array<CreateShippedItem>;
  shippingDate?: InputMaybe<Scalars['DateTime']>;
  trackingNumber: Scalars['String'];
};

export type CreateShop = {
  addr01: Scalars['String'];
  addr02?: InputMaybe<Scalars['String']>;
  banckBranch?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountType?: InputMaybe<Scalars['Int']>;
  bankName?: InputMaybe<Scalars['String']>;
  businessDay?: InputMaybe<BusinessDayParam>;
  companyKana?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  email01: Scalars['String'];
  email02?: InputMaybe<Scalars['String']>;
  email03?: InputMaybe<Scalars['String']>;
  email04?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  franchiseId?: InputMaybe<Scalars['Int']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  member: ShopMemberParam;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pic?: InputMaybe<Scalars['String']>;
  picDivision?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  representative: Scalars['String'];
  shopName: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type CreateShopMember = {
  authorityId: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['Int']>;
};

export type CreateShopSubscriptionOrderRequest = {
  customerId: Scalars['Int'];
  deviceId?: InputMaybe<Scalars['String']>;
  paymentTypeId: Scalars['Int'];
  planId: Scalars['Int'];
};

export type CreateSkuRequest = {
  barcode?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Float']>;
  pattern: Array<PatternInput>;
  price: Scalars['Float'];
  qty: Scalars['Float'];
  sku?: InputMaybe<Scalars['String']>;
};

export type CreateTenant = {
  addr01: Scalars['String'];
  addr02?: InputMaybe<Scalars['String']>;
  businessDay?: InputMaybe<BusinessDayParam>;
  closingTime?: InputMaybe<Scalars['String']>;
  companyKana?: InputMaybe<Scalars['String']>;
  companyName: Scalars['String'];
  email01: Scalars['String'];
  email02?: InputMaybe<Scalars['String']>;
  email03?: InputMaybe<Scalars['String']>;
  email04?: InputMaybe<Scalars['String']>;
  member: TenantMemberParam;
  openingTime?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  tenantKana?: InputMaybe<Scalars['String']>;
  tenantName: Scalars['String'];
  tenantNameEng?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type CreateTenantMember = {
  authorityId: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['Int']>;
};

export type CreateTenpoOnlyProductRequest = {
  externalUrl: Scalars['String'];
  joinMemberId?: InputMaybe<Scalars['Int']>;
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateUrlReply = {
  __typename?: 'CreateUrlReply';
  fileName?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  url: Scalars['String'];
};

export type CreateUrlRequest = {
  fileName?: InputMaybe<Scalars['String']>;
  fileType: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type CustomerAction = {
  __typename?: 'CustomerAction';
  addCart: Scalars['Int'];
  externalSite: Scalars['Int'];
  joinMemberId: Scalars['Int'];
  joinMemberName: Scalars['String'];
  productId: Scalars['String'];
  productName: Scalars['String'];
  qr: Scalars['Int'];
  thumbnail: Scalars['String'];
};

export type DeleteCafeOrderRequest = {
  id: Scalars['String'];
  paymentId: Scalars['String'];
  paymentVendorId: Scalars['Int'];
};

export type DeleteDisplayImage = {
  id: Scalars['Int'];
  key: Scalars['String'];
};

export type DeleteShopSubscriptionOrderRequest = {
  id: Scalars['String'];
  paymentId: Scalars['String'];
  paymentVendorId: Scalars['Int'];
};

export type DeleteSkuRequest = {
  id: Scalars['String'];
  skuId: Scalars['String'];
};

export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  addr01: Scalars['String'];
  addr02?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  tenantId: Scalars['Int'];
};

export type DisplayProduct = {
  __typename?: 'DisplayProduct';
  applyStatusId: Scalars['Int'];
  attribute?: Maybe<DisplaySpaceAttribute>;
  attributeId: Scalars['Int'];
  categoryId?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['DateTime']>;
  detail?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['Int'];
  displayImages?: Maybe<Array<DisplaySpaceProductImage>>;
  displayStartDate: Scalars['Int'];
  externalUrl?: Maybe<Scalars['String']>;
  fee: DisplaySpaceFee;
  feeId: Scalars['Int'];
  id: Scalars['Int'];
  images?: Maybe<Array<Image>>;
  isDelivery: Scalars['Boolean'];
  isReducedTax?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  saleType: Scalars['Int'];
  shippedStatusId: Scalars['Int'];
  shop: Shop;
  shopId: Scalars['Int'];
  skus?: Maybe<Array<Sku>>;
  space?: Maybe<DisplaySpaceForProduct>;
  spaceId?: Maybe<Scalars['Int']>;
  statusNote?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tenantId: Scalars['Int'];
  tenantName: Scalars['String'];
  updateDate?: Maybe<Scalars['DateTime']>;
  variations?: Maybe<Array<Variation>>;
};

export type DisplayProductParam = {
  displayEndDate?: InputMaybe<Scalars['Int']>;
  displayStartDate?: InputMaybe<Scalars['Int']>;
  tenpotId?: InputMaybe<Scalars['Int']>;
};

export type DisplayProducts = {
  __typename?: 'DisplayProducts';
  dataSource: Array<DisplayProduct>;
  pages: PageReply;
};

export type DisplayProductsParam = {
  applyStatusId?: InputMaybe<Scalars['Int']>;
  attributeId?: InputMaybe<Scalars['Int']>;
  displayEndDate?: InputMaybe<Scalars['Int']>;
  displayStartDate?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['String']>;
  shippedStatusId?: InputMaybe<Scalars['Int']>;
  shopId?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
  tenantId?: InputMaybe<Scalars['Int']>;
};

export type DisplayProductsRequest = {
  pages: PageRequest;
  param?: InputMaybe<DisplayProductsParam>;
};

export type DisplaySpace = {
  __typename?: 'DisplaySpace';
  attribute: DisplaySpaceAttribute;
  attributeId: Scalars['Int'];
  blockNumber: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  products: Array<DisplayProduct>;
  statusId: Scalars['Int'];
};

export type DisplaySpaceAttribute = {
  __typename?: 'DisplaySpaceAttribute';
  depth: Scalars['Int'];
  displaySpaceTypeId: Scalars['Int'];
  fees: Array<DisplaySpaceFee>;
  height: Scalars['Int'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  tenantId: Scalars['Int'];
  width: Scalars['Int'];
};

export type DisplaySpaceAttributes = {
  __typename?: 'DisplaySpaceAttributes';
  dataSource: Array<DisplaySpaceAttribute>;
  pages: PageReply;
};

export type DisplaySpaceFee = {
  __typename?: 'DisplaySpaceFee';
  attributeId: Scalars['Int'];
  fee: Scalars['Int'];
  id: Scalars['Int'];
  span: Scalars['Int'];
};

export type DisplaySpaceForProduct = {
  __typename?: 'DisplaySpaceForProduct';
  attributeId: Scalars['Int'];
  blockNumber: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  statusId: Scalars['Int'];
};

export type DisplaySpaceImage = {
  __typename?: 'DisplaySpaceImage';
  fileType: Scalars['String'];
  id: Scalars['Int'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type DisplaySpaceImages = {
  __typename?: 'DisplaySpaceImages';
  images: Array<DisplaySpaceImage>;
};

export type DisplaySpaceProductImage = {
  __typename?: 'DisplaySpaceProductImage';
  displaySpaceProductId: Scalars['Int'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  id: Scalars['Int'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type DisplaySpaceStock = {
  __typename?: 'DisplaySpaceStock';
  max: Scalars['Int'];
  stock: Scalars['Int'];
};

export type DisplaySpaceStocks = {
  __typename?: 'DisplaySpaceStocks';
  dataSource: Array<DisplaySpaceStocksDto>;
};

export type DisplaySpaceStocksDto = {
  __typename?: 'DisplaySpaceStocksDTO';
  date: Scalars['Int'];
  max: Scalars['Int'];
  stock: Scalars['Int'];
};

export type DisplaySpacesParam = {
  statusId?: InputMaybe<Scalars['Int']>;
  tenantId?: InputMaybe<Scalars['Int']>;
};

export type DisplaySpacesReply = {
  __typename?: 'DisplaySpacesReply';
  dataSource: Array<DisplaySpace>;
  pages: PageReply;
};

export type DisplaySpacesRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<DisplaySpacesParam>;
};

export type ElepayPayment = {
  __typename?: 'ElepayPayment';
  amount: Scalars['Int'];
  chargeId: Scalars['String'];
  codeId: Scalars['String'];
  id: Scalars['String'];
  orderNo: Scalars['String'];
  recordStatusId: Scalars['Int'];
  refunds: Array<ElepayRefund>;
};

export type ElepayRefund = {
  __typename?: 'ElepayRefund';
  amount: Scalars['Int'];
  id: Scalars['String'];
  refundId?: Maybe<Scalars['String']>;
};

export type ForgotPasswordRequest = {
  code: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Franchise = {
  __typename?: 'Franchise';
  addr01: Scalars['String'];
  addr02: Scalars['String'];
  companyKana: Scalars['String'];
  companyName: Scalars['String'];
  createDate: Scalars['DateTime'];
  email01: Scalars['String'];
  email02?: Maybe<Scalars['String']>;
  email03?: Maybe<Scalars['String']>;
  email04?: Maybe<Scalars['String']>;
  franchiseCode: Scalars['String'];
  id: Scalars['Int'];
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  updateDate: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type FranchiseMemberDetail = {
  __typename?: 'FranchiseMemberDetail';
  authorityId: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  franchiseId: Scalars['Int'];
  id: Scalars['Int'];
  isFirst: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type FranchiseMemberParam = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type GetBannersParam = {
  bannerTypeId?: InputMaybe<Scalars['Int']>;
  closingDate?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  keyword?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
  startingDate?: InputMaybe<Scalars['DateTime']>;
};

export type GetBannersReply = {
  __typename?: 'GetBannersReply';
  dataSource: Array<Banner>;
  pages: PageReply;
};

export type GetBannersRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetBannersParam>;
};

export type GetCafeCartRequest = {
  orderType: Scalars['Int'];
  priceType: Scalars['Int'];
};

export type GetCartRequest = {
  filter?: InputMaybe<CartFilter>;
};

export type GetDisplaySpaceAttributesParam = {
  tenantId: Scalars['Int'];
};

export type GetDisplaySpaceAttributesRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetDisplaySpaceAttributesParam>;
};

export type GetDisplaySpaceStockRequest = {
  attributeId?: InputMaybe<Scalars['Int']>;
  date: Scalars['Int'];
  tenantId: Scalars['Int'];
};

export type GetDisplaySpaceStocksRequest = {
  attributeId?: InputMaybe<Scalars['Int']>;
  endDate: Scalars['Int'];
  startDate: Scalars['Int'];
  tenantId: Scalars['Int'];
};

export type GetFranchiseMembersParam = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type GetFranchiseMembersReply = {
  __typename?: 'GetFranchiseMembersReply';
  dataSource: Array<FranchiseMemberDetail>;
  pages: PageReply;
};

export type GetFranchiseMembersRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetFranchiseMembersParam>;
};

export type GetFranchisesParam = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type GetFranchisesReply = {
  __typename?: 'GetFranchisesReply';
  dataSource: Array<Franchise>;
  pages: PageReply;
};

export type GetFranchisesRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetFranchisesParam>;
};

export type GetManualsParam = {
  manualTypeId?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Sort>;
};

export type GetManualsReply = {
  __typename?: 'GetManualsReply';
  dataSource: Array<Manual>;
  pages: PageReply;
};

export type GetManualsRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetManualsParam>;
};

export type GetMasterMembersParam = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type GetMasterMembersReply = {
  __typename?: 'GetMasterMembersReply';
  dataSource: Array<MasterMember>;
  pages: PageReply;
};

export type GetMasterMembersRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetMasterMembersParam>;
};

export type GetSTenantMembersParam = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type GetShopMembersParam = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type GetShopMembersReply = {
  __typename?: 'GetShopMembersReply';
  dataSource: Array<ShopMemberDetail>;
  pages: PageReply;
};

export type GetShopMembersRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetShopMembersParam>;
};

export type GetShopsParam = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type GetShopsReply = {
  __typename?: 'GetShopsReply';
  dataSource: Array<Shop>;
  pages: PageReply;
};

export type GetShopsRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetShopsParam>;
};

export type GetTenantMembersReply = {
  __typename?: 'GetTenantMembersReply';
  dataSource: Array<TenantMemberDetail>;
  pages: PageReply;
};

export type GetTenantMembersRequest = {
  pages: PageRequest;
  param?: InputMaybe<GetSTenantMembersParam>;
};

export type IOrder = {
  createDate?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['Int'];
  deliveryFeeTotal: Scalars['Int'];
  id: Scalars['Int'];
  orderId: Scalars['String'];
  ownnerId: Scalars['Int'];
  ownnerTypeId: Scalars['Int'];
  paymentStatusId: Scalars['Int'];
  recordStatusId: Scalars['Int'];
  returnStatusId: Scalars['Int'];
  shippingStatusId: Scalars['Int'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
};

export type Image = {
  __typename?: 'Image';
  fileType: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type ImageInput = {
  fileType: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type ImageUpdateInput = {
  fileType: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  path: Scalars['String'];
};

export type LoginAccount = {
  __typename?: 'LoginAccount';
  accountStatusId?: Maybe<Scalars['Int']>;
  accountTypeId?: Maybe<Scalars['Int']>;
  authorityId?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['DateTime']>;
  cartId?: Maybe<Scalars['Int']>;
  email: Scalars['String'];
  franchiseId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isFirst: Scalars['Boolean'];
  loginType: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  prefId?: Maybe<Scalars['Int']>;
  sexId?: Maybe<Scalars['Int']>;
  shopId?: Maybe<Scalars['Int']>;
  tenantId?: Maybe<Scalars['Int']>;
};

export type Manual = {
  __typename?: 'Manual';
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  id: Scalars['Int'];
  key: Scalars['String'];
  manualTypeId: Scalars['Int'];
  path: Scalars['String'];
};

export type MasterLogin = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MasterMember = {
  __typename?: 'MasterMember';
  authorityId: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['Int'];
  isFirst: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  accountConfirm: ActionReply;
  accountCreate: LoginAccount;
  accountDelete: ActionReply;
  accountForgotPassword: ActionReply;
  accountLogin: LoginAccount;
  accountOneTimePasswordCreate: ActionReply;
  accountPasswordUpdate: ActionReply;
  accountTemporaryMailSend?: Maybe<Scalars['Void']>;
  accountUpdate: ActionReply;
  actionMetricsCreate: ActionReply;
  addressCreate: ActionReply;
  addressDelete: ActionReply;
  addressUpdate: ActionReply;
  answerCreate: ActionReply;
  bannerCreate: ActionReply;
  bannerDelete: ActionReply;
  bannerFileUrlCreate: CreateUrlReply;
  bannerUpdate: ActionReply;
  cafeCartDelete: ActionReply;
  cafeCartItemCreate: ActionReply;
  cafeCartItemDelete: ActionReply;
  cafeCartItemUpdate: ActionReply;
  cafeOrderCreate: CafeOrder;
  cafeOrderDelete: ActionReply;
  cafeOrderItemUpdate: ActionReply;
  cafeOrderUpdate: ActionReply;
  cafeProductChangeSoldOut: ActionReply;
  cafeProductCreate: ActionReply;
  cafeProductDelete: ActionReply;
  cafeProductUpdate: ActionReply;
  cafeStandardProductCreate: ActionReply;
  cafeStandardProductDelete: ActionReply;
  cafeStandardProductGroupCreate: ActionReply;
  cafeStandardProductGroupUpdate: ActionReply;
  cafeStandardProductUpdate: ActionReply;
  cafeStandardProductsAdd: ActionReply;
  cafeStandardProductsDelete: ActionReply;
  cartCreate: Scalars['Int'];
  cartItemCreate: ActionReply;
  cartItemDelete: ActionReply;
  cartItemUpdate: ActionReply;
  couponBurn: ActionReply;
  couponCreate: ActionReply;
  couponUpdate: ActionReply;
  displayProductCreate: ActionReply;
  displayProductDelete: ActionReply;
  displayProductFileUrlCreate: CreateUrlReply;
  displayProductFileUrlsCreate: Array<CreateUrlReply>;
  displayProductUpdate: ActionReply;
  displaySpaceAttributeCreate: ActionReply;
  displaySpaceAttributeDelete: ActionReply;
  displaySpaceAttributeUpdate: ActionReply;
  displaySpaceCreate: ActionReply;
  displaySpaceDelete: ActionReply;
  displaySpaceImageCreate: ActionReply;
  displaySpaceImageDelete: ActionReply;
  displaySpaceImageFileUrlCreate: CreateUrlReply;
  displaySpaceImageFileUrlsCreate: Array<CreateUrlReply>;
  displaySpaceOnProductCreate: ActionReply;
  displaySpaceOnProductDelete: ActionReply;
  displaySpaceUpdate: ActionReply;
  fileUrlCreate: CreateUrlReply;
  fileUrlsCreate: Array<CreateUrlReply>;
  franchiseCreate: ActionReply;
  franchiseDelete: ActionReply;
  franchiseForgotPassword: ActionReply;
  franchiseLogin: LoginAccount;
  franchiseMemberCreate: ActionReply;
  franchiseMemberDelete: ActionReply;
  franchiseMemberUpdate: ActionReply;
  franchiseOneTimePasswordCreate: ActionReply;
  franchisePasswordUpdate: ActionReply;
  franchiseUpdate: ActionReply;
  logout: Scalars['Boolean'];
  manualCreate: ActionReply;
  manualDelete: ActionReply;
  manualUrlCreate: CreateUrlReply;
  manualUrlsCreate: Array<CreateUrlReply>;
  masterForgotPassword: ActionReply;
  masterLogin: LoginAccount;
  masterMemberCreate: ActionReply;
  masterMemberDelete: ActionReply;
  masterMemberUpdate: ActionReply;
  masterOneTimePasswordCreate: ActionReply;
  masterPasswordUpdate: ActionReply;
  orderCreate: CreateOrderReply;
  orderUpdate: ActionReply;
  productCreate: ActionReply;
  productDelete: ActionReply;
  productFileUrlCreate: CreateUrlReply;
  productFileUrlsCreate: Array<CreateUrlReply>;
  productUpdate: ActionReply;
  shippingInformationCreate: ActionReply;
  shopBusinessDayUpdate: ActionReply;
  shopCreate: ActionReply;
  shopDelete: ActionReply;
  shopForgotPassword: ActionReply;
  shopLogin: LoginAccount;
  shopMemberCreate: ActionReply;
  shopMemberDelete: ActionReply;
  shopMemberUpdate: ActionReply;
  shopOneTimePasswordCreate: ActionReply;
  shopPasswordUpdate: ActionReply;
  shopUpdate: ActionReply;
  skuCreate: ActionReply;
  skuDelete: ActionReply;
  skuUpdate: ActionReply;
  squareDeviceCodeCreate: Scalars['String'];
  stampCardCreate: ActionReply;
  stampCardStamp: ActionReply;
  stampCardUpdate: ActionReply;
  surveyCreate: ActionReply;
  surveyUpdate: ActionReply;
  tenantBusinessDayCreate: ActionReply;
  tenantBusinessDayUpdate: ActionReply;
  tenantCreate: ActionReply;
  tenantDelete: ActionReply;
  tenantDeliveryAddressUpdate: ActionReply;
  tenantForgotPassword: ActionReply;
  tenantLogin: LoginAccount;
  tenantMemberCreate: ActionReply;
  tenantMemberDelete: ActionReply;
  tenantMemberUpdate: ActionReply;
  tenantOneTimePasswordCreate: ActionReply;
  tenantPasswordUpdate: ActionReply;
  tenantUpdate: ActionReply;
  tenpoOnlyProductCreate: ActionReply;
  tenpoOnlyProductDelete: ActionReply;
  tenpoOnlyProductUpdate: ActionReply;
  test?: Maybe<Scalars['String']>;
};


export type MutationAccountConfirmArgs = {
  request: AccountConfirmRequest;
};


export type MutationAccountCreateArgs = {
  request: CreateAccountRequest;
};


export type MutationAccountForgotPasswordArgs = {
  request: ForgotPasswordRequest;
};


export type MutationAccountLoginArgs = {
  request: AccountLoginRequest;
};


export type MutationAccountOneTimePasswordCreateArgs = {
  request: CreateOneTimePasswordRequest;
};


export type MutationAccountPasswordUpdateArgs = {
  request: UpdatePasswordRequest;
};


export type MutationAccountUpdateArgs = {
  request: UpdateAccountRequest;
};


export type MutationActionMetricsCreateArgs = {
  request: Action;
};


export type MutationAddressCreateArgs = {
  request: CreateAddressRequest;
};


export type MutationAddressDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationAddressUpdateArgs = {
  request: UpdateAddressRequest;
};


export type MutationAnswerCreateArgs = {
  request: AnswerCreateInput;
};


export type MutationBannerCreateArgs = {
  request: CreateBannerRequest;
};


export type MutationBannerDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationBannerFileUrlCreateArgs = {
  request: CreateUrlRequest;
};


export type MutationBannerUpdateArgs = {
  request: UpdateBannerRequest;
};


export type MutationCafeCartItemCreateArgs = {
  request: CreateCafeCartItemRequest;
};


export type MutationCafeCartItemDeleteArgs = {
  id: Scalars['String'];
};


export type MutationCafeCartItemUpdateArgs = {
  request: UpdateCafeCartItemRequest;
};


export type MutationCafeOrderCreateArgs = {
  request: CreateCafeOrderRequest;
};


export type MutationCafeOrderDeleteArgs = {
  request: DeleteCafeOrderRequest;
};


export type MutationCafeOrderItemUpdateArgs = {
  request: UpdateCafeOrderItemRequest;
};


export type MutationCafeOrderUpdateArgs = {
  request: UpdateCafeOrderRequest;
};


export type MutationCafeProductChangeSoldOutArgs = {
  request: ChangeSoldOutRrequest;
};


export type MutationCafeProductCreateArgs = {
  request: CreateCafeProductRequest;
};


export type MutationCafeProductDeleteArgs = {
  id: Scalars['String'];
};


export type MutationCafeProductUpdateArgs = {
  request: UpdateCafeProductRequest;
};


export type MutationCafeStandardProductCreateArgs = {
  request: CreateCafeStandardProductRequest;
};


export type MutationCafeStandardProductDeleteArgs = {
  id: Scalars['String'];
};


export type MutationCafeStandardProductGroupCreateArgs = {
  request: CreateCafeStandardProductGroupRequest;
};


export type MutationCafeStandardProductGroupUpdateArgs = {
  request: UpdateCafeStandardProductGroupRequest;
};


export type MutationCafeStandardProductUpdateArgs = {
  request: UpdateCafeStandardProductRequest;
};


export type MutationCafeStandardProductsAddArgs = {
  request: CafeStandardProductForm;
};


export type MutationCafeStandardProductsDeleteArgs = {
  request: CafeStandardProductForm;
};


export type MutationCartCreateArgs = {
  id: Scalars['Int'];
};


export type MutationCartItemCreateArgs = {
  request: CreateCartItemRequest;
};


export type MutationCartItemDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationCartItemUpdateArgs = {
  request: UpdateCartItemRequest;
};


export type MutationCouponBurnArgs = {
  request: CouponBurnInput;
};


export type MutationCouponCreateArgs = {
  request: CouponCreateInput;
};


export type MutationCouponUpdateArgs = {
  request: CouponUpdateInput;
};


export type MutationDisplayProductCreateArgs = {
  request: CreateDisplayProductRequest;
};


export type MutationDisplayProductDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationDisplayProductFileUrlCreateArgs = {
  request: CreateUrlRequest;
};


export type MutationDisplayProductFileUrlsCreateArgs = {
  request: Array<CreateUrlRequest>;
};


export type MutationDisplayProductUpdateArgs = {
  request: UpdateDisplayProductRequest;
};


export type MutationDisplaySpaceAttributeCreateArgs = {
  request?: InputMaybe<CreateDisplaySpaceAttributeRequest>;
};


export type MutationDisplaySpaceAttributeDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationDisplaySpaceAttributeUpdateArgs = {
  request?: InputMaybe<UpdateDisplaySpaceAttributeRequest>;
};


export type MutationDisplaySpaceCreateArgs = {
  request: CreateDisplaySpaceRequest;
};


export type MutationDisplaySpaceDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationDisplaySpaceImageCreateArgs = {
  request: Array<CreateDisplayImage>;
};


export type MutationDisplaySpaceImageDeleteArgs = {
  request: DeleteDisplayImage;
};


export type MutationDisplaySpaceImageFileUrlCreateArgs = {
  request: CreateUrlRequest;
};


export type MutationDisplaySpaceImageFileUrlsCreateArgs = {
  request: Array<CreateUrlRequest>;
};


export type MutationDisplaySpaceOnProductCreateArgs = {
  request: CreateDisplaySpaceOnProductRequest;
};


export type MutationDisplaySpaceOnProductDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationDisplaySpaceUpdateArgs = {
  request: UpdateDisplaySpaceRequest;
};


export type MutationFileUrlCreateArgs = {
  request: CreateUrlRequest;
};


export type MutationFileUrlsCreateArgs = {
  request: Array<CreateUrlRequest>;
};


export type MutationFranchiseCreateArgs = {
  request: CreateFranchiseRequest;
};


export type MutationFranchiseDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationFranchiseForgotPasswordArgs = {
  request: ForgotPasswordRequest;
};


export type MutationFranchiseLoginArgs = {
  request: AdminLogin;
};


export type MutationFranchiseMemberCreateArgs = {
  request: CreateFranchiseMember;
};


export type MutationFranchiseMemberDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationFranchiseMemberUpdateArgs = {
  request: UpdateFranchiseMember;
};


export type MutationFranchiseOneTimePasswordCreateArgs = {
  request: CreateAdminOneTimePasswordRequest;
};


export type MutationFranchisePasswordUpdateArgs = {
  request: UpdatePasswordRequest;
};


export type MutationFranchiseUpdateArgs = {
  request: UpdateFranchiseRequest;
};


export type MutationManualCreateArgs = {
  request: CreateManualRequest;
};


export type MutationManualDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationManualUrlCreateArgs = {
  request: CreateUrlRequest;
};


export type MutationManualUrlsCreateArgs = {
  request: Array<CreateUrlRequest>;
};


export type MutationMasterForgotPasswordArgs = {
  request: ForgotPasswordRequest;
};


export type MutationMasterLoginArgs = {
  request?: InputMaybe<MasterLogin>;
};


export type MutationMasterMemberCreateArgs = {
  request: CreateMasterMember;
};


export type MutationMasterMemberDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationMasterMemberUpdateArgs = {
  request: UpdateMasterMember;
};


export type MutationMasterOneTimePasswordCreateArgs = {
  request: CreateAdminOneTimePasswordRequest;
};


export type MutationMasterPasswordUpdateArgs = {
  request: UpdatePasswordRequest;
};


export type MutationOrderCreateArgs = {
  request: CreateOrderRequest;
};


export type MutationOrderUpdateArgs = {
  request: UpdateOrderRequest;
};


export type MutationProductCreateArgs = {
  request: CreateProductRequest;
};


export type MutationProductDeleteArgs = {
  id: Scalars['String'];
};


export type MutationProductFileUrlCreateArgs = {
  request: CreateUrlRequest;
};


export type MutationProductFileUrlsCreateArgs = {
  request: Array<CreateUrlRequest>;
};


export type MutationProductUpdateArgs = {
  request: UpdateProductRequest;
};


export type MutationShippingInformationCreateArgs = {
  request: CreateShippingInformationRequest;
};


export type MutationShopBusinessDayUpdateArgs = {
  request: BusinessDayRequest;
};


export type MutationShopCreateArgs = {
  request: CreateShop;
};


export type MutationShopDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationShopForgotPasswordArgs = {
  request: ForgotPasswordRequest;
};


export type MutationShopLoginArgs = {
  request: AdminLogin;
};


export type MutationShopMemberCreateArgs = {
  request: CreateShopMember;
};


export type MutationShopMemberDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationShopMemberUpdateArgs = {
  request: UpdateShopMember;
};


export type MutationShopOneTimePasswordCreateArgs = {
  request: CreateAdminOneTimePasswordRequest;
};


export type MutationShopPasswordUpdateArgs = {
  request: UpdatePasswordRequest;
};


export type MutationShopUpdateArgs = {
  request: UpdateShop;
};


export type MutationSkuCreateArgs = {
  request: AddSkuRequest;
};


export type MutationSkuDeleteArgs = {
  request: DeleteSkuRequest;
};


export type MutationSkuUpdateArgs = {
  request: UpdateSkuRequest;
};


export type MutationSquareDeviceCodeCreateArgs = {
  tenpoId: Scalars['Int'];
};


export type MutationStampCardCreateArgs = {
  request: StampCardCreateInput;
};


export type MutationStampCardStampArgs = {
  request: StampCardStampInput;
};


export type MutationStampCardUpdateArgs = {
  request: StampCardUpdateInput;
};


export type MutationSurveyCreateArgs = {
  request: SurveyCreateInput;
};


export type MutationSurveyUpdateArgs = {
  request: SurveyUpdateInput;
};


export type MutationTenantBusinessDayCreateArgs = {
  request: BusinessDayRequest;
};


export type MutationTenantBusinessDayUpdateArgs = {
  request: BusinessDayRequest;
};


export type MutationTenantCreateArgs = {
  request: CreateTenant;
};


export type MutationTenantDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationTenantDeliveryAddressUpdateArgs = {
  request: UpdateDeliveryAddressRequest;
};


export type MutationTenantForgotPasswordArgs = {
  request: ForgotPasswordRequest;
};


export type MutationTenantLoginArgs = {
  request: AdminLogin;
};


export type MutationTenantMemberCreateArgs = {
  request: CreateTenantMember;
};


export type MutationTenantMemberDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationTenantMemberUpdateArgs = {
  request: UpdateTenantMember;
};


export type MutationTenantOneTimePasswordCreateArgs = {
  request: CreateAdminOneTimePasswordRequest;
};


export type MutationTenantPasswordUpdateArgs = {
  request: UpdatePasswordRequest;
};


export type MutationTenantUpdateArgs = {
  request: UpdateTenant;
};


export type MutationTenpoOnlyProductCreateArgs = {
  request: CreateTenpoOnlyProductRequest;
};


export type MutationTenpoOnlyProductDeleteArgs = {
  id: Scalars['String'];
};


export type MutationTenpoOnlyProductUpdateArgs = {
  request: UpdateTenpoOnlyProductRequest;
};

export type MyCoupon = {
  __typename?: 'MyCoupon';
  birthday?: Maybe<Scalars['DateTime']>;
  burned?: Maybe<Scalars['DateTime']>;
  couponId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId: Scalars['Int'];
  customerName: Scalars['String'];
  description: Scalars['String'];
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pref?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  thumbnail: Thumbnail;
  updatedAt: Scalars['DateTime'];
};

export type MyCouponRequest = {
  customerId?: InputMaybe<Scalars['Int']>;
  stampCardId: Scalars['String'];
};

export type MyCouponSearchInput = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<MyCouponSearchParam>;
};

export type MyCouponSearchParam = {
  burned?: InputMaybe<Scalars['Boolean']>;
  burnedFrom?: InputMaybe<Scalars['DateTime']>;
  burnedTo?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['Int']>;
  franchiseId?: InputMaybe<Scalars['Int']>;
};

export type MyCouponSearchResponse = {
  __typename?: 'MyCouponSearchResponse';
  datasource: Array<MyCoupon>;
  page: PageReply;
};

export type MyStampCard = {
  __typename?: 'MyStampCard';
  count: Scalars['Int'];
  couponId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId: Scalars['Int'];
  description: Scalars['String'];
  from?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  limit: Scalars['Int'];
  stampCardId: Scalars['String'];
  thumbnail: Thumbnail;
  to?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type MyStampCardRequest = {
  customerId?: InputMaybe<Scalars['Int']>;
  stampCardId: Scalars['String'];
};

export type MyStampCardSearchInput = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<MyStampCardSearchParam>;
};

export type MyStampCardSearchParam = {
  customerId?: InputMaybe<Scalars['Int']>;
};

export type MyStampCardSearchResponse = {
  __typename?: 'MyStampCardSearchResponse';
  datasource: Array<MyStampCard>;
  page: PageReply;
};

export type Order = IOrder & {
  __typename?: 'Order';
  account?: Maybe<Account>;
  createDate?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['Int'];
  deliveryFeeTotal: Scalars['Int'];
  id: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  orderItems: Array<OrderItem>;
  ownnerId: Scalars['Int'];
  ownnerTypeId: Scalars['Int'];
  paymentStatusId: Scalars['Int'];
  recordStatusId: Scalars['Int'];
  refundedItems: Array<RefundedItem>;
  returnStatusId: Scalars['Int'];
  returnedItems: Array<ReturnedItem>;
  shippedItems: Array<ShippedItem>;
  shippingAddress?: Maybe<ShippingAddress>;
  shippingInformations: Array<ShippingInformation>;
  shippingStatusId: Scalars['Int'];
  shop?: Maybe<Shop>;
  tenant?: Maybe<Tenant>;
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  createDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  pattern: Scalars['String'];
  price: Scalars['Int'];
  priceWithTax: Scalars['Int'];
  productId: Scalars['String'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
  sku: Scalars['String'];
  skuId: Scalars['String'];
  taxRate: Scalars['Float'];
  thumbnail: Scalars['String'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type OrderedCartItem = {
  __typename?: 'OrderedCartItem';
  id: Scalars['Int'];
  productId: Scalars['String'];
  quantity: Scalars['Int'];
  skuId: Scalars['String'];
  typeId: Scalars['Int'];
};

export type OrdersDto = IOrder & {
  __typename?: 'OrdersDTO';
  account?: Maybe<Account>;
  createDate?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['Int'];
  deliveryFeeTotal: Scalars['Int'];
  id: Scalars['Int'];
  orderId: Scalars['String'];
  orderItems: Array<OrderItem>;
  ownnerId: Scalars['Int'];
  ownnerTypeId: Scalars['Int'];
  paymentStatusId: Scalars['Int'];
  recordStatusId: Scalars['Int'];
  returnStatusId: Scalars['Int'];
  shippingAddress?: Maybe<ShippingAddress>;
  shippingStatusId: Scalars['Int'];
  shop?: Maybe<Shop>;
  tenant?: Maybe<Tenant>;
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type OrdersParam = {
  customerId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  ownnerId?: InputMaybe<Scalars['Int']>;
  ownnerTypeId?: InputMaybe<Scalars['Int']>;
  paymentStatusId?: InputMaybe<Scalars['Int']>;
  shippingStatusId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type OrdersReply = {
  __typename?: 'OrdersReply';
  dataSource: Array<OrdersDto>;
  pages: PageReply;
};

export type OrdersRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<OrdersParam>;
};

export type PageReply = {
  __typename?: 'PageReply';
  currentPage: Scalars['Int'];
  range: Range;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export type PageRequest = {
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type Pattern = {
  __typename?: 'Pattern';
  label: Scalars['String'];
  option: Scalars['String'];
};

export type PatternInput = {
  label: Scalars['String'];
  option: Scalars['String'];
};

export type Product = {
  __typename?: 'Product';
  categoryId: Scalars['Int'];
  createDate: Scalars['DateTime'];
  detail?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images: Array<Image>;
  isReducedTax: Scalars['Boolean'];
  joinMemberId: Scalars['Int'];
  joinMemberName: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  overview: Scalars['String'];
  saleType: Scalars['Int'];
  skus: Array<Sku>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['Int'];
  tags: Array<Scalars['String']>;
  updateDate: Scalars['DateTime'];
  variations: Array<Variation>;
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  accounts: SearchAccountsReply;
  address: Address;
  addresses: Array<Address>;
  answer: Answer;
  answers: AnswerSearchResponse;
  banner?: Maybe<Banner>;
  banners: GetBannersReply;
  cafeCart: CafeCart;
  cafeOrder: CafeOrder;
  cafeOrders: CafeOrdersReply;
  cafeOrdersSummary: CafeOrdersSummayrReply;
  cafeProduct: CafeProduct;
  cafeProductSearch: SearchCafeProductReply;
  cafeProducts?: Maybe<Array<CafeProduct>>;
  cafeStandardProduct: CafeStandardProduct;
  cafeStandardProductGroup: CafeStandardProductGroup;
  cafeStandardProductGroupSearch: SearchCafeStandardProductGroupReply;
  cafeStandardProductSearch: SearchCafeStandardProductReply;
  cafeStandardProducts?: Maybe<Array<CafeStandardProduct>>;
  cart: Array<Cart>;
  coupon: Coupon;
  coupons: CouponSearchResponse;
  customerActions: Array<CustomerAction>;
  displayProduct: DisplayProduct;
  displayProducts: DisplayProducts;
  displaySpace?: Maybe<DisplaySpace>;
  displaySpaceAttribute: DisplaySpaceAttribute;
  displaySpaceAttributes: DisplaySpaceAttributes;
  displaySpaceImage: DisplaySpaceImages;
  displaySpaceStock?: Maybe<DisplaySpaceStock>;
  displaySpaceStocks: DisplaySpaceStocks;
  displaySpaces: DisplaySpacesReply;
  elepayPayment: ElepayPayment;
  franchise: Franchise;
  franchiseMember: FranchiseMemberDetail;
  franchiseMembers: GetFranchiseMembersReply;
  franchises: GetFranchisesReply;
  isAnswered: Scalars['Boolean'];
  manual: Manual;
  manuals: GetManualsReply;
  masterMember?: Maybe<MasterMember>;
  masterMembers: GetMasterMembersReply;
  me?: Maybe<LoginAccount>;
  myCoupon: MyCoupon;
  myCoupons: MyCouponSearchResponse;
  myStampCard: MyStampCard;
  myStampCards: MyStampCardSearchResponse;
  order: Order;
  orders: OrdersReply;
  product: Product;
  productSearch: SearchProductReply;
  products?: Maybe<Array<Product>>;
  shop: Shop;
  shopBusinessDay: BusinessDay;
  shopMember: ShopMemberDetail;
  shopMembers: GetShopMembersReply;
  shops: GetShopsReply;
  squareDevices: Array<SquareDevice>;
  stampCard: StampCard;
  stampCards: StampCardSearchResponse;
  survey: Survey;
  surveys: SurveySearchResponse;
  tenant?: Maybe<Tenant>;
  tenantBusinessDay: BusinessDay;
  tenantDeliveryAddress: DeliveryAddress;
  tenantMember: TenantMemberDetail;
  tenantMembers: GetTenantMembersReply;
  tenants: TenantsReply;
  tenpoOnlyProduct: TenpoOnlyProduct;
  tenpoOnlyProductSearch: SearchTenpoOnlyProductReply;
  tenpoOnlyProducts?: Maybe<Array<TenpoOnlyProduct>>;
  test?: Maybe<Scalars['String']>;
  testAdminRegisterMail: Scalars['Boolean'];
  testDisplaySpaceApply: Scalars['Boolean'];
  testDisplaySpaceApproved: Scalars['Boolean'];
  testDisplaySpaceRejected: Scalars['Boolean'];
  testForgotPasswordMail: Scalars['Boolean'];
  testMainMemberMail: Scalars['Boolean'];
  testOrderReceivedMail: Scalars['Boolean'];
  testShippingInfoMail: Scalars['Boolean'];
  testTemporaryMemberMail: Scalars['Boolean'];
};


export type QueryAccountArgs = {
  id: Scalars['Int'];
};


export type QueryAccountsArgs = {
  request: SearchAccountsRequest;
};


export type QueryAddressArgs = {
  id: Scalars['Int'];
};


export type QueryAddressesArgs = {
  customerId: Scalars['Int'];
};


export type QueryAnswerArgs = {
  id: Scalars['String'];
};


export type QueryAnswersArgs = {
  request: AnswerSearchInput;
};


export type QueryBannerArgs = {
  id: Scalars['Int'];
};


export type QueryBannersArgs = {
  request: GetBannersRequest;
};


export type QueryCafeCartArgs = {
  request: GetCafeCartRequest;
};


export type QueryCafeOrderArgs = {
  id: Scalars['String'];
};


export type QueryCafeOrdersArgs = {
  request: CafeOrdersRequest;
};


export type QueryCafeOrdersSummaryArgs = {
  request?: InputMaybe<CafeOrdersSummaryRequest>;
};


export type QueryCafeProductArgs = {
  id: Scalars['String'];
};


export type QueryCafeProductSearchArgs = {
  request?: InputMaybe<SearchCafeProductRequest>;
};


export type QueryCafeProductsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryCafeStandardProductArgs = {
  id: Scalars['String'];
};


export type QueryCafeStandardProductGroupArgs = {
  id: Scalars['String'];
};


export type QueryCafeStandardProductGroupSearchArgs = {
  request: SearchCafeStandardProductGroupRequest;
};


export type QueryCafeStandardProductSearchArgs = {
  request?: InputMaybe<SearchCafeStandardProductRequest>;
};


export type QueryCafeStandardProductsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryCartArgs = {
  request: GetCartRequest;
};


export type QueryCouponArgs = {
  id: Scalars['String'];
};


export type QueryCouponsArgs = {
  request: CouponSearchInput;
};


export type QueryCustomerActionsArgs = {
  request: ActionSearchRequest;
};


export type QueryDisplayProductArgs = {
  id: Scalars['Int'];
};


export type QueryDisplayProductsArgs = {
  request?: InputMaybe<DisplayProductsRequest>;
};


export type QueryDisplaySpaceArgs = {
  id: Scalars['Int'];
};


export type QueryDisplaySpaceAttributeArgs = {
  id: Scalars['Int'];
};


export type QueryDisplaySpaceAttributesArgs = {
  request: GetDisplaySpaceAttributesRequest;
};


export type QueryDisplaySpaceImageArgs = {
  tenantId?: InputMaybe<Scalars['Int']>;
};


export type QueryDisplaySpaceStockArgs = {
  request: GetDisplaySpaceStockRequest;
};


export type QueryDisplaySpaceStocksArgs = {
  request: GetDisplaySpaceStocksRequest;
};


export type QueryDisplaySpacesArgs = {
  request: DisplaySpacesRequest;
};


export type QueryElepayPaymentArgs = {
  id: Scalars['String'];
};


export type QueryFranchiseArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryFranchiseMemberArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryFranchiseMembersArgs = {
  request: GetFranchiseMembersRequest;
};


export type QueryFranchisesArgs = {
  request?: InputMaybe<GetFranchiseMembersRequest>;
};


export type QueryIsAnsweredArgs = {
  surveyId: Scalars['String'];
};


export type QueryManualArgs = {
  id: Scalars['Int'];
};


export type QueryManualsArgs = {
  request: GetManualsRequest;
};


export type QueryMasterMemberArgs = {
  id: Scalars['Int'];
};


export type QueryMasterMembersArgs = {
  request: GetMasterMembersRequest;
};


export type QueryMyCouponArgs = {
  request: MyCouponRequest;
};


export type QueryMyCouponsArgs = {
  request: MyCouponSearchInput;
};


export type QueryMyStampCardArgs = {
  request: MyStampCardRequest;
};


export type QueryMyStampCardsArgs = {
  request: MyStampCardSearchInput;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrdersArgs = {
  request: OrdersRequest;
};


export type QueryProductArgs = {
  id: Scalars['String'];
};


export type QueryProductSearchArgs = {
  request?: InputMaybe<SearchProductRequest>;
};


export type QueryProductsArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryShopArgs = {
  id: Scalars['Int'];
};


export type QueryShopBusinessDayArgs = {
  shopId: Scalars['Int'];
};


export type QueryShopMemberArgs = {
  id: Scalars['Int'];
};


export type QueryShopMembersArgs = {
  request: GetShopMembersRequest;
};


export type QueryShopsArgs = {
  request: GetShopsRequest;
};


export type QueryStampCardArgs = {
  id: Scalars['String'];
};


export type QueryStampCardsArgs = {
  request: StampCardSearchInput;
};


export type QuerySurveyArgs = {
  id: Scalars['String'];
};


export type QuerySurveysArgs = {
  request: SurveySearchInput;
};


export type QueryTenantArgs = {
  id: Scalars['Int'];
};


export type QueryTenantBusinessDayArgs = {
  tenantId: Scalars['Int'];
};


export type QueryTenantDeliveryAddressArgs = {
  tenantId?: InputMaybe<Scalars['Int']>;
};


export type QueryTenantMemberArgs = {
  id: Scalars['Int'];
};


export type QueryTenantMembersArgs = {
  request: GetTenantMembersRequest;
};


export type QueryTenantsArgs = {
  request: TenantsRequest;
};


export type QueryTenpoOnlyProductArgs = {
  id: Scalars['String'];
};


export type QueryTenpoOnlyProductSearchArgs = {
  request?: InputMaybe<SearchTenpoOnlyProductRequest>;
};


export type QueryTenpoOnlyProductsArgs = {
  ids: Array<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['String'];
  options: Array<QuestionOption>;
  question: Scalars['String'];
  questionType: QuestionType;
  required: Scalars['Boolean'];
  sortNo: Scalars['Int'];
};

export type QuestionCreateInput = {
  options?: InputMaybe<Array<Scalars['String']>>;
  question: Scalars['String'];
  questionType: QuestionType;
  required: Scalars['Boolean'];
  sortNo: Scalars['Int'];
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  id: Scalars['String'];
  option: Scalars['String'];
};

export type QuestionOptionUpdateInput = {
  id?: InputMaybe<Scalars['String']>;
  option: Scalars['String'];
};

export enum QuestionType {
  MultipleSelection = 'MultipleSelection',
  SingleSelection = 'SingleSelection',
  Text = 'Text'
}

export type QuestionUpdateInput = {
  id?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<QuestionOptionUpdateInput>>;
  question: Scalars['String'];
  questionType: QuestionType;
  required: Scalars['Boolean'];
  sortNo: Scalars['Int'];
};

export type Range = {
  __typename?: 'Range';
  end: Scalars['Int'];
  first: Scalars['Int'];
};

export type RefundedItem = {
  __typename?: 'RefundedItem';
  completedDate?: Maybe<Scalars['DateTime']>;
  createDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  orderItemId: Scalars['Int'];
  price: Scalars['Int'];
  refundReasonId: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type ReturnedItem = {
  __typename?: 'ReturnedItem';
  completedDate?: Maybe<Scalars['DateTime']>;
  createDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  orderItemId: Scalars['Int'];
  quantity: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type SearchAccountsParam = {
  accountStatusId?: InputMaybe<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};

export type SearchAccountsReply = {
  __typename?: 'SearchAccountsReply';
  dataSource: Array<Account>;
  pages: PageReply;
};

export type SearchAccountsRequest = {
  pages: PageRequest;
  param?: InputMaybe<SearchAccountsParam>;
};

export type SearchCafeProductParam = {
  categoryId?: InputMaybe<Scalars['Int']>;
  ids: Array<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  tenpoIds: Array<Scalars['Int']>;
};

export type SearchCafeProductReply = {
  __typename?: 'SearchCafeProductReply';
  dataSource: Array<CafeProduct>;
  pages: PageReply;
};

export type SearchCafeProductRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<SearchCafeProductParam>;
};

export type SearchCafeStandardProductGroupParam = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  ids: Array<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SearchCafeStandardProductGroupReply = {
  __typename?: 'SearchCafeStandardProductGroupReply';
  dataSource: Array<CafeStandardProductGroup>;
  pages: PageReply;
};

export type SearchCafeStandardProductGroupRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<SearchCafeStandardProductGroupParam>;
};

export type SearchCafeStandardProductParam = {
  categoryId?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
};

export type SearchCafeStandardProductReply = {
  __typename?: 'SearchCafeStandardProductReply';
  dataSource: Array<CafeStandardProduct>;
  pages: PageReply;
};

export type SearchCafeStandardProductRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<SearchCafeStandardProductParam>;
};

export type SearchProductParam = {
  categoryId?: InputMaybe<Scalars['Int']>;
  displayProduct?: InputMaybe<DisplayProductParam>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  ids: Array<Scalars['String']>;
  joinMemberIds: Array<Scalars['Int']>;
  keyword?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type SearchProductReply = {
  __typename?: 'SearchProductReply';
  dataSource: Array<Product>;
  pages: PageReply;
};

export type SearchProductRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<SearchProductParam>;
};

export type SearchTenpoOnlyProductReply = {
  __typename?: 'SearchTenpoOnlyProductReply';
  dataSource: Array<TenpoOnlyProduct>;
  pages: PageReply;
};

export type SearchTenpoOnlyProductRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<SearchProductParam>;
};

export type ShippedItem = {
  __typename?: 'ShippedItem';
  createDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  orderItemId: Scalars['Int'];
  quantity: Scalars['Int'];
  shippingInformationId: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type ShippingAddress = {
  __typename?: 'ShippingAddress';
  addr01: Scalars['String'];
  addr02?: Maybe<Scalars['String']>;
  createDate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  kana?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type ShippingInformation = {
  __typename?: 'ShippingInformation';
  arrivalDate?: Maybe<Scalars['DateTime']>;
  completedDate?: Maybe<Scalars['DateTime']>;
  createDate?: Maybe<Scalars['DateTime']>;
  deliveryVendorId: Scalars['Int'];
  id: Scalars['Int'];
  orderId: Scalars['Int'];
  shippingDate?: Maybe<Scalars['DateTime']>;
  trackingNumber: Scalars['String'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type Shop = {
  __typename?: 'Shop';
  addr01: Scalars['String'];
  addr02: Scalars['String'];
  banckBranch?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankAccountType?: Maybe<Scalars['Int']>;
  bankName?: Maybe<Scalars['String']>;
  companyKana: Scalars['String'];
  companyName: Scalars['String'];
  email01: Scalars['String'];
  email02?: Maybe<Scalars['String']>;
  email03?: Maybe<Scalars['String']>;
  email04?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceNumber?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  pic?: Maybe<Scalars['String']>;
  picDivision?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  representative: Scalars['String'];
  shopCode: Scalars['String'];
  shopName: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type ShopMemberDetail = {
  __typename?: 'ShopMemberDetail';
  authorityId: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  isFirst: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  shopId: Scalars['Int'];
};

export type ShopMemberParam = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type ShopSubscription = {
  __typename?: 'ShopSubscription';
  codeUrl?: Maybe<Scalars['String']>;
  customerId: Scalars['Int'];
  duration: Scalars['Int'];
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  paymentStatusId: Scalars['Int'];
  planId: Scalars['Int'];
  price: Scalars['Int'];
  startDate: Scalars['DateTime'];
  totalPayment: Scalars['Int'];
  totalPrice: Scalars['Int'];
  totalTax: Scalars['Int'];
};

export type ShopSubscriptionPlan = {
  __typename?: 'ShopSubscriptionPlan';
  duration: Scalars['Int'];
  id: Scalars['Int'];
  price: Scalars['Int'];
};

export type Sku = {
  __typename?: 'Sku';
  barcode?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  pattern: Array<Pattern>;
  price: Scalars['Float'];
  priceWithTax: Scalars['Int'];
  qty: Scalars['Int'];
  sku?: Maybe<Scalars['String']>;
};

export type Sort = {
  key: Scalars['String'];
  order: Scalars['Int'];
};

export type SquareDevice = {
  __typename?: 'SquareDevice';
  code: Scalars['String'];
  deviceId: Scalars['String'];
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type StampCard = {
  __typename?: 'StampCard';
  couponId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  franchiseId: Scalars['Int'];
  from?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  limit: Scalars['Int'];
  thumbnail: Thumbnail;
  to?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type StampCardCreateInput = {
  couponId: Scalars['String'];
  description: Scalars['String'];
  franchiseId?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['DateTime']>;
  thumbnail: ImageInput;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type StampCardSearchInput = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<StampCardSearchParam>;
};

export type StampCardSearchParam = {
  franchiseIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type StampCardSearchResponse = {
  __typename?: 'StampCardSearchResponse';
  datasource: Array<StampCard>;
  page: PageReply;
};

export type StampCardStampInput = {
  stampCardId: Scalars['String'];
};

export type StampCardUpdateInput = {
  couponId: Scalars['String'];
  description: Scalars['String'];
  from?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  thumbnail: ImageUpdateInput;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionStatus = {
  __typename?: 'SubscriptionStatus';
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  price?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['Int'];
};

export type Survey = {
  __typename?: 'Survey';
  couponId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  franchiseId: Scalars['Int'];
  id: Scalars['String'];
  is_published: Scalars['Boolean'];
  name: Scalars['String'];
  questions: Array<Question>;
  stampCardId?: Maybe<Scalars['String']>;
  thumbnail: Thumbnail;
  updatedAt: Scalars['DateTime'];
};

export type SurveyCreateInput = {
  couponId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  franchiseId?: InputMaybe<Scalars['Int']>;
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  questions: Array<QuestionCreateInput>;
  stampCardId?: InputMaybe<Scalars['String']>;
  thumbnail: ImageInput;
};

export type SurveySearchInput = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<SurveySearchParam>;
};

export type SurveySearchParam = {
  franchiseId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SurveySearchResponse = {
  __typename?: 'SurveySearchResponse';
  datasource: Array<Survey>;
  page: PageReply;
};

export type SurveyUpdateInput = {
  couponId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  isPublished: Scalars['Boolean'];
  name: Scalars['String'];
  questions: Array<QuestionUpdateInput>;
  stampCardId?: InputMaybe<Scalars['String']>;
  thumbnail: ImageUpdateInput;
};

export type Tenant = {
  __typename?: 'Tenant';
  addr01: Scalars['String'];
  addr02: Scalars['String'];
  closingTime?: Maybe<Scalars['String']>;
  companyKana: Scalars['String'];
  companyName: Scalars['String'];
  email01: Scalars['String'];
  email02?: Maybe<Scalars['String']>;
  email03?: Maybe<Scalars['String']>;
  email04?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  openingTime?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  postalCode: Scalars['String'];
  prefId: Scalars['Int'];
  tenantCode: Scalars['String'];
  tenantKana: Scalars['String'];
  tenantName: Scalars['String'];
  tenantNameEng?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type TenantMember = {
  __typename?: 'TenantMember';
  authorityId: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['Int'];
  isFirst: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  tenantId: Scalars['Int'];
};

export type TenantMemberDetail = {
  __typename?: 'TenantMemberDetail';
  authorityId: Scalars['Int'];
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  isFirst: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  tenantId: Scalars['Int'];
};

export type TenantMemberParam = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type TenantsParam = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type TenantsReply = {
  __typename?: 'TenantsReply';
  dataSource: Array<Tenant>;
  pages: PageReply;
};

export type TenantsRequest = {
  pages?: InputMaybe<PageRequest>;
  param?: InputMaybe<TenantsParam>;
};

export type TenpoOnlyProduct = {
  __typename?: 'TenpoOnlyProduct';
  createDate?: Maybe<Scalars['DateTime']>;
  externalUrl: Scalars['String'];
  id: Scalars['String'];
  joinMemberId: Scalars['Int'];
  joinMemberName: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  saleType: Scalars['Int'];
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  fileType: Scalars['String'];
  id: Scalars['String'];
  key: Scalars['String'];
  path: Scalars['String'];
};

export type UpdateAccountRequest = {
  birthday?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  kana?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  prefId?: InputMaybe<Scalars['Int']>;
  sexId?: InputMaybe<Scalars['Int']>;
};

export type UpdateAddressRequest = {
  addr01?: InputMaybe<Scalars['String']>;
  addr02?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefId?: InputMaybe<Scalars['Int']>;
};

export type UpdateBannerRequest = {
  closingDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isExternal?: InputMaybe<Scalars['Boolean']>;
  sortNo?: InputMaybe<Scalars['Int']>;
  startingDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateCafeCartItemRequest = {
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

export type UpdateCafeOrderItemRequest = {
  id: Scalars['String'];
  orderItemId: Scalars['String'];
  recordStatusId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCafeOrderRequest = {
  id: Scalars['String'];
  orderStatusId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCafeProductRequest = {
  categoryId?: InputMaybe<Scalars['Int']>;
  detail?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  images?: InputMaybe<Array<ImageInput>>;
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  variations?: InputMaybe<Array<CafeVariationInput>>;
};

export type UpdateCafeStandardProductGroupRequest = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tenpoSizeId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCafeStandardProductRequest = {
  categoryId?: InputMaybe<Scalars['Int']>;
  detail?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  images?: InputMaybe<Array<ImageInput>>;
  isReducedTax?: InputMaybe<Scalars['Boolean']>;
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nonManagedStock?: InputMaybe<Scalars['Boolean']>;
  overview?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  variations?: InputMaybe<Array<CafeVariationInput>>;
};

export type UpdateCartItemRequest = {
  id: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type UpdateDeliveryAddressRequest = {
  addr01?: InputMaybe<Scalars['String']>;
  addr02?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefId?: InputMaybe<Scalars['Int']>;
};

export type UpdateDisplayProductRequest = {
  applyStatusId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  isDelivery?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  shippedStatusId?: InputMaybe<Scalars['Int']>;
  statusNote?: InputMaybe<Scalars['String']>;
};

export type UpdateDisplaySpaceAttributeRequest = {
  depth?: InputMaybe<Scalars['Int']>;
  displaySpaceTypeId?: InputMaybe<Scalars['Int']>;
  fee?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  span?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UpdateDisplaySpaceRequest = {
  blockNumber?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['Int']>;
};

export type UpdateFranchiseMember = {
  authorityId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateFranchiseRequest = {
  addr01?: InputMaybe<Scalars['String']>;
  addr02?: InputMaybe<Scalars['String']>;
  companyKana?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  email01?: InputMaybe<Scalars['String']>;
  email02?: InputMaybe<Scalars['String']>;
  email03?: InputMaybe<Scalars['String']>;
  email04?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefId?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateMasterMember = {
  authorityId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderRequest = {
  id: Scalars['Int'];
  memo?: InputMaybe<Scalars['String']>;
};

export type UpdatePasswordRequest = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdateProductRequest = {
  categoryId?: InputMaybe<Scalars['Int']>;
  detail?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  images?: InputMaybe<Array<ImageInput>>;
  isReducedTax?: InputMaybe<Scalars['Boolean']>;
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  overview?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  variations?: InputMaybe<Array<VariationInput>>;
};

export type UpdateShop = {
  addr01?: InputMaybe<Scalars['String']>;
  addr02?: InputMaybe<Scalars['String']>;
  banckBranch?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bankAccountType?: InputMaybe<Scalars['Int']>;
  bankName?: InputMaybe<Scalars['String']>;
  companyKana?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  email01?: InputMaybe<Scalars['String']>;
  email02?: InputMaybe<Scalars['String']>;
  email03?: InputMaybe<Scalars['String']>;
  email04?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pic?: InputMaybe<Scalars['String']>;
  picDivision?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefId?: InputMaybe<Scalars['Int']>;
  representative?: InputMaybe<Scalars['String']>;
  shopName?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateShopMember = {
  authorityId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateSkuRequest = {
  barcode?: InputMaybe<Scalars['String']>;
  cost?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  qty?: InputMaybe<Scalars['Int']>;
  sku?: InputMaybe<Scalars['String']>;
  skuId: Scalars['String'];
};

export type UpdateTenant = {
  addr01?: InputMaybe<Scalars['String']>;
  addr02?: InputMaybe<Scalars['String']>;
  closingTime?: InputMaybe<Scalars['String']>;
  companyKana?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  email01?: InputMaybe<Scalars['String']>;
  email02?: InputMaybe<Scalars['String']>;
  email03?: InputMaybe<Scalars['String']>;
  email04?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  openingTime?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  prefId?: InputMaybe<Scalars['Int']>;
  tenantKana?: InputMaybe<Scalars['String']>;
  tenantName?: InputMaybe<Scalars['String']>;
  tenantNameEng?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateTenantMember = {
  authorityId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateTenpoOnlyProductRequest = {
  externalUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Variation = {
  __typename?: 'Variation';
  label: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type VariationInput = {
  label: Scalars['String'];
  options: Array<Scalars['String']>;
};

export type LoginResponseFragment = { __typename?: 'LoginAccount', id: number, authorityId?: number | null, email: string, loginType: number, name?: string | null, shopId?: number | null, tenantId?: number | null, isFirst: boolean };

export type ShopLoginMutationVariables = Exact<{
  request: AdminLogin;
}>;


export type ShopLoginMutation = { __typename?: 'Mutation', shopLogin: { __typename?: 'LoginAccount', id: number, authorityId?: number | null, email: string, loginType: number, name?: string | null, shopId?: number | null, tenantId?: number | null, isFirst: boolean } };

export type TenantLoginMutationVariables = Exact<{
  request: AdminLogin;
}>;


export type TenantLoginMutation = { __typename?: 'Mutation', tenantLogin: { __typename?: 'LoginAccount', id: number, authorityId?: number | null, email: string, loginType: number, name?: string | null, shopId?: number | null, tenantId?: number | null, isFirst: boolean } };

export type FranchiseLoginMutationVariables = Exact<{
  request: AdminLogin;
}>;


export type FranchiseLoginMutation = { __typename?: 'Mutation', franchiseLogin: { __typename?: 'LoginAccount', id: number, authorityId?: number | null, email: string, loginType: number, name?: string | null, shopId?: number | null, tenantId?: number | null, isFirst: boolean } };

export type MasterLoginMutationVariables = Exact<{
  request: MasterLogin;
}>;


export type MasterLoginMutation = { __typename?: 'Mutation', masterLogin: { __typename?: 'LoginAccount', id: number, authorityId?: number | null, email: string, loginType: number, name?: string | null, shopId?: number | null, tenantId?: number | null, isFirst: boolean } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'LoginAccount', id: number, authorityId?: number | null, email: string, loginType: number, name?: string | null, shopId?: number | null, tenantId?: number | null, isFirst: boolean } | null };

export type ActionReplyFragment = { __typename?: 'ActionReply', status?: number | null, message?: string | null };

export type UrlReplyFragment = { __typename?: 'CreateUrlReply', id?: string | null, fileName?: string | null, fileType: string, url: string, key: string };

export type PageFragment = { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } };

export type AddCafeCartItemMutationVariables = Exact<{
  request: CreateCafeCartItemRequest;
}>;


export type AddCafeCartItemMutation = { __typename?: 'Mutation', cafeCartItemCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type OrderUpdateMutationVariables = Exact<{
  request: UpdateOrderRequest;
}>;


export type OrderUpdateMutation = { __typename?: 'Mutation', orderUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CustomerContainerFragment = { __typename?: 'ShippingAddress', email?: string | null, phoneNumber?: string | null, postalCode: string, prefId: number, addr01: string, addr02?: string | null, name: string };

export type OrderItemRrowFragment = { __typename?: 'OrderItem', thumbnail: string, productName: string, quantity: number, price: number, totalPrice: number };

export type OrderTotalContainerFragment = { __typename?: 'Order', paymentStatusId: number, deliveryFeeTotal: number, totalPrice: number, totalTax: number, totalPayment: number };

export type SquareDeviceFragment = { __typename?: 'SquareDevice', deviceId: string, id: string, name?: string | null };

export type GetSquareDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSquareDevicesQuery = { __typename?: 'Query', squareDevices: Array<{ __typename?: 'SquareDevice', deviceId: string, id: string, name?: string | null }> };

export type CafeOrderCreateMutationVariables = Exact<{
  request: CreateCafeOrderRequest;
}>;


export type CafeOrderCreateMutation = { __typename?: 'Mutation', cafeOrderCreate: { __typename?: 'CafeOrder', codeUrl?: string | null, id: string, totalPayment: number, paymentVendorId: number, orderItems: Array<{ __typename?: 'CafeOrderItem', productName: string, pattern: string, price: number, quantity: number, thumbnail: string }> } };

export type CafeOrderFragment = { __typename?: 'CafeOrder', codeUrl?: string | null, id: string, totalPayment: number, paymentVendorId: number, orderItems: Array<{ __typename?: 'CafeOrderItem', productName: string, pattern: string, price: number, quantity: number, thumbnail: string }> };

export type CafeOrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CafeOrderQuery = { __typename?: 'Query', cafeOrder: { __typename?: 'CafeOrder', codeUrl?: string | null, id: string, totalPayment: number, paymentVendorId: number, orderItems: Array<{ __typename?: 'CafeOrderItem', productName: string, pattern: string, price: number, quantity: number, thumbnail: string }> } };

export type CrreateShippingInformationMutationVariables = Exact<{
  request: CreateShippingInformationRequest;
}>;


export type CrreateShippingInformationMutation = { __typename?: 'Mutation', shippingInformationCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type BannerFragment = { __typename?: 'Banner', id: number, bannerTypeId: number, title: string, url?: string | null, startingDate: Date, closingDate?: Date | null, sortNo: number, isExternal: boolean, image: { __typename?: 'BannerImage', path: string } };

export type GetBannerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetBannerQuery = { __typename?: 'Query', banner?: { __typename?: 'Banner', id: number, bannerTypeId: number, title: string, url?: string | null, startingDate: Date, closingDate?: Date | null, sortNo: number, isExternal: boolean, image: { __typename?: 'BannerImage', path: string } } | null };

export type BannerItemFragment = { __typename?: 'Banner', id: number, bannerTypeId: number, title: string, startingDate: Date, closingDate?: Date | null, image: { __typename?: 'BannerImage', path: string } };

export type GetBannersQueryVariables = Exact<{
  request: GetBannersRequest;
}>;


export type GetBannersQuery = { __typename?: 'Query', banners: { __typename?: 'GetBannersReply', dataSource: Array<{ __typename?: 'Banner', id: number, bannerTypeId: number, title: string, startingDate: Date, closingDate?: Date | null, image: { __typename?: 'BannerImage', path: string } }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type CreateBannerImageUrlMutationVariables = Exact<{
  request: CreateUrlRequest;
}>;


export type CreateBannerImageUrlMutation = { __typename?: 'Mutation', bannerFileUrlCreate: { __typename?: 'CreateUrlReply', id?: string | null, fileName?: string | null, fileType: string, url: string, key: string } };

export type CreateBannerMutationVariables = Exact<{
  request: CreateBannerRequest;
}>;


export type CreateBannerMutation = { __typename?: 'Mutation', bannerCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateBannerMutationVariables = Exact<{
  request: UpdateBannerRequest;
}>;


export type UpdateBannerMutation = { __typename?: 'Mutation', bannerUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteBannerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBannerMutation = { __typename?: 'Mutation', bannerDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type TenantOptionFragment = { __typename?: 'Tenant', id: number, tenantName: string };

export type BlockStockFragment = { __typename?: 'DisplaySpaceStocksDTO', date: number, stock: number, max: number };

export type BlockStocksQueryVariables = Exact<{
  request: GetDisplaySpaceStocksRequest;
}>;


export type BlockStocksQuery = { __typename?: 'Query', displaySpaceStocks: { __typename?: 'DisplaySpaceStocks', dataSource: Array<{ __typename?: 'DisplaySpaceStocksDTO', date: number, stock: number, max: number }> } };

export type BlockProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type BlockProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, name: string, overview: string, joinMemberId: number, saleType: number, images: Array<{ __typename?: 'Image', path: string, fileType: string }> } };

export type BlockOnlyShopProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type BlockOnlyShopProductQuery = { __typename?: 'Query', tenpoOnlyProduct: { __typename?: 'TenpoOnlyProduct', id: string, name: string, externalUrl: string, saleType: number, memo?: string | null } };

export type UpdateBlockProductMutationVariables = Exact<{
  request: UpdateDisplayProductRequest;
}>;


export type UpdateBlockProductMutation = { __typename?: 'Mutation', displayProductUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DisplaySpaceScheduleTableFragment = { __typename?: 'DisplaySpace', id: number, blockNumber: string, products: Array<{ __typename?: 'DisplayProduct', id: number, displayStartDate: number, displayEndDate: number, name: string, shop: { __typename?: 'Shop', shopName: string } }>, attribute: { __typename?: 'DisplaySpaceAttribute', tenantId: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number }> } };

export type BlockScheduleTableQueryVariables = Exact<{
  request: DisplaySpacesRequest;
}>;


export type BlockScheduleTableQuery = { __typename?: 'Query', displaySpaces: { __typename?: 'DisplaySpacesReply', dataSource: Array<{ __typename?: 'DisplaySpace', id: number, blockNumber: string, products: Array<{ __typename?: 'DisplayProduct', id: number, displayStartDate: number, displayEndDate: number, name: string, shop: { __typename?: 'Shop', shopName: string } }>, attribute: { __typename?: 'DisplaySpaceAttribute', tenantId: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number }> } }> } };

export type BlockFragment = { __typename?: 'DisplaySpace', id: number, statusId: number, attributeId: number, blockNumber: string, description?: string | null, note?: string | null, attribute: { __typename?: 'DisplaySpaceAttribute', name?: string | null, displaySpaceTypeId: number, width: number, height: number, depth: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> }, products: Array<{ __typename?: 'DisplayProduct', name: string, displayStartDate: number, displayEndDate: number, images?: Array<{ __typename?: 'Image', path: string }> | null, shop: { __typename?: 'Shop', shopName: string } }> };

export type BlockQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type BlockQuery = { __typename?: 'Query', displaySpace?: { __typename?: 'DisplaySpace', id: number, statusId: number, attributeId: number, blockNumber: string, description?: string | null, note?: string | null, attribute: { __typename?: 'DisplaySpaceAttribute', name?: string | null, displaySpaceTypeId: number, width: number, height: number, depth: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> }, products: Array<{ __typename?: 'DisplayProduct', name: string, displayStartDate: number, displayEndDate: number, images?: Array<{ __typename?: 'Image', path: string }> | null, shop: { __typename?: 'Shop', shopName: string } }> } | null };

export type BlockUpdateMutationVariables = Exact<{
  request: UpdateDisplaySpaceRequest;
}>;


export type BlockUpdateMutation = { __typename?: 'Mutation', displaySpaceUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type BlockDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type BlockDeleteMutation = { __typename?: 'Mutation', displaySpaceDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DisplaySpaceColumnFragment = { __typename?: 'DisplaySpace', id: number, statusId: number, blockNumber: string, description?: string | null, attribute: { __typename?: 'DisplaySpaceAttribute', width: number, height: number, depth: number, displaySpaceTypeId: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> } };

export type BlockListQueryVariables = Exact<{
  request: DisplaySpacesRequest;
  tenantId?: InputMaybe<Scalars['Int']>;
}>;


export type BlockListQuery = { __typename?: 'Query', displaySpaces: { __typename?: 'DisplaySpacesReply', dataSource: Array<{ __typename?: 'DisplaySpace', id: number, statusId: number, blockNumber: string, description?: string | null, attribute: { __typename?: 'DisplaySpaceAttribute', width: number, height: number, depth: number, displaySpaceTypeId: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> } }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } }, displaySpaceImage: { __typename?: 'DisplaySpaceImages', images: Array<{ __typename?: 'DisplaySpaceImage', path: string }> }, tenantDeliveryAddress: { __typename?: 'DeliveryAddress', id: number, name: string, prefId: number, postalCode: string, addr01: string, addr02?: string | null, phoneNumber: string } };

export type DisplaySpaceItemFragment = { __typename?: 'DisplaySpace', id: number, blockNumber: string, products: Array<{ __typename?: 'DisplayProduct', id: number, name: string, displayStartDate: number }> };

export type DisplayRegisteredProductFragment = { __typename?: 'DisplayProduct', productId: string, name: string, id: number, tenantId: number, quantity: number, displayStartDate: number, displayEndDate: number, shippedStatusId: number, applyStatusId: number, spaceId?: number | null, createDate?: Date | null, note?: string | null, isDelivery: boolean, saleType: number, externalUrl?: string | null, images?: Array<{ __typename?: 'Image', path: string, fileType: string }> | null, displayImages?: Array<{ __typename?: 'DisplaySpaceProductImage', path: string, fileType: string }> | null, skus?: Array<{ __typename?: 'Sku', price: number, qty: number }> | null, space?: { __typename?: 'DisplaySpaceForProduct', attributeId: number, blockNumber: string, description?: string | null, id: number, note?: string | null, statusId: number } | null };

export type DisplayProductFragment = { __typename?: 'Product', id: string, name: string, joinMemberId: number, createDate: Date, saleType: number, images: Array<{ __typename?: 'Image', path: string, fileType: string }>, skus: Array<{ __typename?: 'Sku', price: number, qty: number }> };

export type DisplayProductDetailFragment = { __typename?: 'DisplayProduct', applyStatusId: number, attributeId: number, categoryId?: number | null, createDate?: Date | null, detail?: string | null, displayEndDate: number, displayStartDate: number, externalUrl?: string | null, id: number, isDelivery: boolean, isReducedTax?: boolean | null, memo?: string | null, name: string, note?: string | null, overview?: string | null, productId: string, quantity: number, saleType: number, shippedStatusId: number, shopId: number, spaceId?: number | null, statusNote?: string | null, tags?: Array<string> | null, tenantId: number, tenantName: string, updateDate?: Date | null, attribute?: { __typename?: 'DisplaySpaceAttribute', id: number, displaySpaceTypeId: number, name?: string | null, width: number, height: number, depth: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> } | null, fee: { __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }, displayImages?: Array<{ __typename?: 'DisplaySpaceProductImage', displaySpaceProductId: number, id: number, key: string, path: string, fileType: string, fileName: string }> | null, images?: Array<{ __typename?: 'Image', path: string, fileType: string, key: string }> | null, shop: { __typename?: 'Shop', shopName: string, companyName: string, addr01: string, addr02: string, phoneNumber: string, email01: string }, skus?: Array<{ __typename?: 'Sku', barcode?: string | null, cost?: number | null, id: string, price: number, priceWithTax: number, qty: number, sku?: string | null, pattern: Array<{ __typename?: 'Pattern', label: string, option: string }> }> | null, space?: { __typename?: 'DisplaySpaceForProduct', attributeId: number, blockNumber: string, description?: string | null, id: number, note?: string | null, statusId: number } | null, variations?: Array<{ __typename?: 'Variation', label: string, options: Array<string> }> | null };

export type GetDisplayApplyProductsQueryVariables = Exact<{
  request: DisplayProductsRequest;
}>;


export type GetDisplayApplyProductsQuery = { __typename?: 'Query', displayProducts: { __typename?: 'DisplayProducts', dataSource: Array<{ __typename?: 'DisplayProduct', productId: string, name: string, id: number, tenantId: number, quantity: number, displayStartDate: number, displayEndDate: number, shippedStatusId: number, applyStatusId: number, spaceId?: number | null, createDate?: Date | null, note?: string | null, isDelivery: boolean, saleType: number, externalUrl?: string | null, images?: Array<{ __typename?: 'Image', path: string, fileType: string }> | null, displayImages?: Array<{ __typename?: 'DisplaySpaceProductImage', path: string, fileType: string }> | null, skus?: Array<{ __typename?: 'Sku', price: number, qty: number }> | null, space?: { __typename?: 'DisplaySpaceForProduct', attributeId: number, blockNumber: string, description?: string | null, id: number, note?: string | null, statusId: number } | null }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type GetDisplayProductsQueryVariables = Exact<{
  request: SearchProductRequest;
}>;


export type GetDisplayProductsQuery = { __typename?: 'Query', productSearch: { __typename?: 'SearchProductReply', dataSource: Array<{ __typename?: 'Product', id: string, name: string, joinMemberId: number, createDate: Date, saleType: number, images: Array<{ __typename?: 'Image', path: string, fileType: string }>, skus: Array<{ __typename?: 'Sku', price: number, qty: number }> }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type GetDisplayProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDisplayProductQuery = { __typename?: 'Query', displayProduct: { __typename?: 'DisplayProduct', applyStatusId: number, attributeId: number, categoryId?: number | null, createDate?: Date | null, detail?: string | null, displayEndDate: number, displayStartDate: number, externalUrl?: string | null, id: number, isDelivery: boolean, isReducedTax?: boolean | null, memo?: string | null, name: string, note?: string | null, overview?: string | null, productId: string, quantity: number, saleType: number, shippedStatusId: number, shopId: number, spaceId?: number | null, statusNote?: string | null, tags?: Array<string> | null, tenantId: number, tenantName: string, updateDate?: Date | null, attribute?: { __typename?: 'DisplaySpaceAttribute', id: number, displaySpaceTypeId: number, name?: string | null, width: number, height: number, depth: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> } | null, fee: { __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }, displayImages?: Array<{ __typename?: 'DisplaySpaceProductImage', displaySpaceProductId: number, id: number, key: string, path: string, fileType: string, fileName: string }> | null, images?: Array<{ __typename?: 'Image', path: string, fileType: string, key: string }> | null, shop: { __typename?: 'Shop', shopName: string, companyName: string, addr01: string, addr02: string, phoneNumber: string, email01: string }, skus?: Array<{ __typename?: 'Sku', barcode?: string | null, cost?: number | null, id: string, price: number, priceWithTax: number, qty: number, sku?: string | null, pattern: Array<{ __typename?: 'Pattern', label: string, option: string }> }> | null, space?: { __typename?: 'DisplaySpaceForProduct', attributeId: number, blockNumber: string, description?: string | null, id: number, note?: string | null, statusId: number } | null, variations?: Array<{ __typename?: 'Variation', label: string, options: Array<string> }> | null } };

export type GetDisplaySpacesQueryVariables = Exact<{
  request: DisplaySpacesRequest;
}>;


export type GetDisplaySpacesQuery = { __typename?: 'Query', displaySpaces: { __typename?: 'DisplaySpacesReply', dataSource: Array<{ __typename?: 'DisplaySpace', id: number, blockNumber: string, products: Array<{ __typename?: 'DisplayProduct', id: number, name: string, displayStartDate: number }> }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type CreateDisplaySpaceOnProductMutationVariables = Exact<{
  request: CreateDisplaySpaceOnProductRequest;
}>;


export type CreateDisplaySpaceOnProductMutation = { __typename?: 'Mutation', displaySpaceOnProductCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteDisplaySpaceOnProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDisplaySpaceOnProductMutation = { __typename?: 'Mutation', displaySpaceOnProductDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateDisplayProductMutationVariables = Exact<{
  request: CreateDisplayProductRequest;
}>;


export type CreateDisplayProductMutation = { __typename?: 'Mutation', displayProductCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteDisplayProductMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteDisplayProductMutation = { __typename?: 'Mutation', displayProductDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateDisplaySpaceMutationVariables = Exact<{
  request: CreateDisplaySpaceRequest;
}>;


export type CreateDisplaySpaceMutation = { __typename?: 'Mutation', displaySpaceCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DisplaySpaceScheduleFragment = { __typename?: 'DisplaySpace', id: number, blockNumber: string, products: Array<{ __typename?: 'DisplayProduct', id: number, displayStartDate: number, displayEndDate: number, name: string, shop: { __typename?: 'Shop', shopName: string } }>, attribute: { __typename?: 'DisplaySpaceAttribute', tenantId: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number }> } };

export type BlockScheduleQueryVariables = Exact<{
  request: DisplaySpacesRequest;
}>;


export type BlockScheduleQuery = { __typename?: 'Query', displaySpaces: { __typename?: 'DisplaySpacesReply', dataSource: Array<{ __typename?: 'DisplaySpace', id: number, blockNumber: string, products: Array<{ __typename?: 'DisplayProduct', id: number, displayStartDate: number, displayEndDate: number, name: string, shop: { __typename?: 'Shop', shopName: string } }>, attribute: { __typename?: 'DisplaySpaceAttribute', tenantId: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number }> } }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type BlockImageFragment = { __typename?: 'DisplaySpaceImage', id: number, path: string, key: string };

export type BlockImagesQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['Int']>;
}>;


export type BlockImagesQuery = { __typename?: 'Query', displaySpaceImage: { __typename?: 'DisplaySpaceImages', images: Array<{ __typename?: 'DisplaySpaceImage', id: number, path: string, key: string }> } };

export type DeleteBlockImageMutationVariables = Exact<{
  request: DeleteDisplayImage;
}>;


export type DeleteBlockImageMutation = { __typename?: 'Mutation', displaySpaceImageDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateBlockImagesUrlMutationVariables = Exact<{
  request: Array<CreateUrlRequest> | CreateUrlRequest;
}>;


export type CreateBlockImagesUrlMutation = { __typename?: 'Mutation', displaySpaceImageFileUrlsCreate: Array<{ __typename?: 'CreateUrlReply', id?: string | null, fileName?: string | null, fileType: string, url: string, key: string }> };

export type CreateBlockImagesMutationVariables = Exact<{
  request: Array<CreateDisplayImage> | CreateDisplayImage;
}>;


export type CreateBlockImagesMutation = { __typename?: 'Mutation', displaySpaceImageCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type BlockAttributeFragment = { __typename?: 'DisplaySpaceAttribute', id: number, displaySpaceTypeId: number, name?: string | null, width: number, height: number, depth: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> };

export type BlockAttributesQueryVariables = Exact<{
  request: GetDisplaySpaceAttributesRequest;
}>;


export type BlockAttributesQuery = { __typename?: 'Query', displaySpaceAttributes: { __typename?: 'DisplaySpaceAttributes', dataSource: Array<{ __typename?: 'DisplaySpaceAttribute', id: number, displaySpaceTypeId: number, name?: string | null, width: number, height: number, depth: number, fees: Array<{ __typename?: 'DisplaySpaceFee', id: number, fee: number, span: number }> }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type CreateBlockAttributeMutationVariables = Exact<{
  request?: InputMaybe<CreateDisplaySpaceAttributeRequest>;
}>;


export type CreateBlockAttributeMutation = { __typename?: 'Mutation', displaySpaceAttributeCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteBlockAttributeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBlockAttributeMutation = { __typename?: 'Mutation', displaySpaceAttributeDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type BlockDeliveryAddressFragment = { __typename?: 'DeliveryAddress', id: number, name: string, prefId: number, postalCode: string, addr01: string, addr02?: string | null, phoneNumber: string };

export type BlockDeliveryAddressQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['Int']>;
}>;


export type BlockDeliveryAddressQuery = { __typename?: 'Query', tenantDeliveryAddress: { __typename?: 'DeliveryAddress', id: number, name: string, prefId: number, postalCode: string, addr01: string, addr02?: string | null, phoneNumber: string } };

export type BlockDeliveryAddressUpdateMutationVariables = Exact<{
  request: UpdateDeliveryAddressRequest;
}>;


export type BlockDeliveryAddressUpdateMutation = { __typename?: 'Mutation', tenantDeliveryAddressUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CafeOrderItemFragment = { __typename?: 'CafeOrder', createDate: Date, customerId?: number | null, id: string, orderId: string, orderStatusId: number, paymentId?: string | null, paymentStatusId: number, paymentVendorId: number, recordStatusId: number, totalPayment: number, totalPrice: number, totalTax: number, orderItems: Array<{ __typename?: 'CafeOrderItem', id: string, orderId: string, pattern: string, price: number, productName: string, quantity: number, recordStatusId: number, thumbnail: string, totalPayment: number, totalPrice: number, totalTax: number }> };

export type CafeOrderAggregateFragment = { __typename?: 'CafeOrderAggregate', totalPayment: number, totalQuantity: number };

export type GetCafeOrderItemsQueryVariables = Exact<{
  request: CafeOrdersRequest;
}>;


export type GetCafeOrderItemsQuery = { __typename?: 'Query', cafeOrders: { __typename?: 'CafeOrdersReply', dataSource: Array<{ __typename?: 'CafeOrder', createDate: Date, customerId?: number | null, id: string, orderId: string, orderStatusId: number, paymentId?: string | null, paymentStatusId: number, paymentVendorId: number, recordStatusId: number, totalPayment: number, totalPrice: number, totalTax: number, orderItems: Array<{ __typename?: 'CafeOrderItem', id: string, orderId: string, pattern: string, price: number, productName: string, quantity: number, recordStatusId: number, thumbnail: string, totalPayment: number, totalPrice: number, totalTax: number }> }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, aggregate: { __typename?: 'CafeOrderAggregate', totalPayment: number, totalQuantity: number } } };

export type UpdateCafeOrderMutationVariables = Exact<{
  request: UpdateCafeOrderRequest;
}>;


export type UpdateCafeOrderMutation = { __typename?: 'Mutation', cafeOrderUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateCafeOrderItemMutationVariables = Exact<{
  request: UpdateCafeOrderItemRequest;
}>;


export type UpdateCafeOrderItemMutation = { __typename?: 'Mutation', cafeOrderItemUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CafeOrdersSummaryFragment = { __typename?: 'CafeOrdersSummaryByProduct', pattern: string, productId: string, productName: string, skuId: string, totalPayment: number, totalQuantity: number };

export type GetCafeOrdersSummaryQueryVariables = Exact<{
  request?: InputMaybe<CafeOrdersSummaryRequest>;
}>;


export type GetCafeOrdersSummaryQuery = { __typename?: 'Query', cafeOrdersSummary: { __typename?: 'CafeOrdersSummayrReply', dataSource: Array<{ __typename?: 'CafeOrdersSummaryByProduct', pattern: string, productId: string, productName: string, skuId: string, totalPayment: number, totalQuantity: number }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type CafeProductColumnFragment = { __typename?: 'CafeProduct', id: string, name: string, tenpoName: string, nonManagedStock: boolean, createDate?: Date | null, updateDate?: Date | null, images: Array<{ __typename?: 'Image', path: string }>, skus: Array<{ __typename?: 'CafeSku', qty?: number | null, prices: Array<{ __typename?: 'CafePrice', amount: number, isReducedTax: boolean, priceWithTax: number, type: number }> }> };

export type CafeProductsQueryVariables = Exact<{
  request?: InputMaybe<SearchCafeProductRequest>;
}>;


export type CafeProductsQuery = { __typename?: 'Query', cafeProductSearch: { __typename?: 'SearchCafeProductReply', dataSource: Array<{ __typename?: 'CafeProduct', id: string, name: string, tenpoName: string, nonManagedStock: boolean, createDate?: Date | null, updateDate?: Date | null, images: Array<{ __typename?: 'Image', path: string }>, skus: Array<{ __typename?: 'CafeSku', qty?: number | null, prices: Array<{ __typename?: 'CafePrice', amount: number, isReducedTax: boolean, priceWithTax: number, type: number }> }> }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type CreateCafeProductMutationVariables = Exact<{
  request: CreateCafeProductRequest;
}>;


export type CreateCafeProductMutation = { __typename?: 'Mutation', cafeProductCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CafeProductDetailFragment = { __typename?: 'CafeProduct', id: string, name: string, overview: string, categoryId: number, isReducedTax: boolean, memo?: string | null, status: number, nonManagedStock: boolean, variations: Array<{ __typename?: 'CafeVariation', label: string, options: Array<string> }>, skus: Array<{ __typename?: 'CafeSku', id: string, qty?: number | null, prices: Array<{ __typename?: 'CafePrice', amount: number, isReducedTax: boolean, priceWithTax: number, type: number }>, pattern: Array<{ __typename?: 'CafePattern', label: string, option: string }> }>, images: Array<{ __typename?: 'Image', path: string, key: string, fileType: string }> };

export type GetCafeProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCafeProductQuery = { __typename?: 'Query', cafeProduct: { __typename?: 'CafeProduct', id: string, name: string, overview: string, categoryId: number, isReducedTax: boolean, memo?: string | null, status: number, nonManagedStock: boolean, variations: Array<{ __typename?: 'CafeVariation', label: string, options: Array<string> }>, skus: Array<{ __typename?: 'CafeSku', id: string, qty?: number | null, prices: Array<{ __typename?: 'CafePrice', amount: number, isReducedTax: boolean, priceWithTax: number, type: number }>, pattern: Array<{ __typename?: 'CafePattern', label: string, option: string }> }>, images: Array<{ __typename?: 'Image', path: string, key: string, fileType: string }> } };

export type DeleteCafeProductMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCafeProductMutation = { __typename?: 'Mutation', cafeProductDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateCafeProductMutationVariables = Exact<{
  request: UpdateCafeProductRequest;
}>;


export type UpdateCafeProductMutation = { __typename?: 'Mutation', cafeProductUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CouponsQueryVariables = Exact<{
  request: CouponSearchInput;
}>;


export type CouponsQuery = { __typename?: 'Query', coupons: { __typename?: 'CouponSearchResponse', page: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, datasource: Array<{ __typename?: 'Coupon', createdAt: Date, description: string, franchiseId: number, expirySpan?: number | null, id: string, isPublished: boolean, max?: number | null, name: string, numberOfCardsIssued: number, updatedAt: Date, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } }> } };

export type CouponFragment = { __typename?: 'Coupon', createdAt: Date, description: string, franchiseId: number, expirySpan?: number | null, id: string, isPublished: boolean, max?: number | null, name: string, numberOfCardsIssued: number, updatedAt: Date, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } };

export type MyCouponsQueryVariables = Exact<{
  request: MyCouponSearchInput;
}>;


export type MyCouponsQuery = { __typename?: 'Query', myCoupons: { __typename?: 'MyCouponSearchResponse', page: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, datasource: Array<{ __typename?: 'MyCoupon', birthday?: Date | null, burned?: Date | null, couponId: string, createdAt: Date, customerName: string, customerId: number, description: string, expiryDate?: Date | null, id: string, memo?: string | null, name: string, pref?: string | null, sex?: string | null, updatedAt: Date, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } }> } };

export type MyCouponFragment = { __typename?: 'MyCoupon', birthday?: Date | null, burned?: Date | null, couponId: string, createdAt: Date, customerName: string, customerId: number, description: string, expiryDate?: Date | null, id: string, memo?: string | null, name: string, pref?: string | null, sex?: string | null, updatedAt: Date, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } };

export type CouponCreateMutationVariables = Exact<{
  request: CouponCreateInput;
}>;


export type CouponCreateMutation = { __typename?: 'Mutation', couponCreate: { __typename?: 'ActionReply', message?: string | null, status?: number | null } };

export type FileUrlCreateMutationVariables = Exact<{
  request: CreateUrlRequest;
}>;


export type FileUrlCreateMutation = { __typename?: 'Mutation', fileUrlCreate: { __typename?: 'CreateUrlReply', fileName?: string | null, fileType: string, id?: string | null, key: string, url: string } };

export type CouponUpdateMutationVariables = Exact<{
  request: CouponUpdateInput;
}>;


export type CouponUpdateMutation = { __typename?: 'Mutation', couponUpdate: { __typename?: 'ActionReply', message?: string | null, status?: number | null } };

export type CouponQueryVariables = Exact<{
  couponId: Scalars['String'];
}>;


export type CouponQuery = { __typename?: 'Query', coupon: { __typename?: 'Coupon', createdAt: Date, description: string, franchiseId: number, expirySpan?: number | null, id: string, isPublished: boolean, max?: number | null, name: string, numberOfCardsIssued: number, updatedAt: Date, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } } };

export type AccountColumnFragment = { __typename?: 'Account', accountStatusId?: number | null, accountTypeId?: number | null, email: string, id: number, name?: string | null, sexId?: number | null };

export type AccountsQueryVariables = Exact<{
  request: SearchAccountsRequest;
}>;


export type AccountsQuery = { __typename?: 'Query', accounts: { __typename?: 'SearchAccountsReply', dataSource: Array<{ __typename?: 'Account', accountStatusId?: number | null, accountTypeId?: number | null, email: string, id: number, name?: string | null, sexId?: number | null }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type FranchiseMemberDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FranchiseMemberDetailQuery = { __typename?: 'Query', franchiseMember: { __typename?: 'FranchiseMemberDetail', authorityId: number, email: string, id: number, isFirst: boolean, name: string, phoneNumber?: string | null, franchiseId: number, companyName?: string | null } };

export type FranchiseMemberDetailFragment = { __typename?: 'FranchiseMemberDetail', authorityId: number, email: string, id: number, isFirst: boolean, name: string, phoneNumber?: string | null, franchiseId: number, companyName?: string | null };

export type FranchiseMembersQueryVariables = Exact<{
  request: GetFranchiseMembersRequest;
}>;


export type FranchiseMembersQuery = { __typename?: 'Query', franchiseMembers: { __typename?: 'GetFranchiseMembersReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'FranchiseMemberDetail', id: number, name: string, email: string, authorityId: number, franchiseId: number, isFirst: boolean, phoneNumber?: string | null, companyName?: string | null }> } };

export type FranchiseMemberFragment = { __typename?: 'FranchiseMemberDetail', id: number, name: string, email: string, authorityId: number, franchiseId: number, isFirst: boolean, phoneNumber?: string | null, companyName?: string | null };

export type PasswordChangeFranchiseMemberMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type PasswordChangeFranchiseMemberMutation = { __typename?: 'Mutation', franchisePasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateFranchiseMemberMutationVariables = Exact<{
  request: CreateFranchiseMember;
}>;


export type CreateFranchiseMemberMutation = { __typename?: 'Mutation', franchiseMemberCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateFranchiseMemberMutationVariables = Exact<{
  request: UpdateFranchiseMember;
}>;


export type UpdateFranchiseMemberMutation = { __typename?: 'Mutation', franchiseMemberUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteFranchiseMemberMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFranchiseMemberMutation = { __typename?: 'Mutation', franchiseMemberDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type FranchiseMemberUpdateFragment = { __typename?: 'FranchiseMemberDetail', authorityId: number, email: string, id: number, name: string, phoneNumber?: string | null };

export type FranchiseDetailQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type FranchiseDetailQuery = { __typename?: 'Query', franchise: { __typename?: 'Franchise', addr01: string, addr02: string, companyKana: string, companyName: string, createDate: Date, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, franchiseCode: string, id: number, phoneNumber: string, postalCode: string, prefId: number, updateDate: Date, url?: string | null } };

export type FranchiseDetailFragment = { __typename?: 'Franchise', addr01: string, addr02: string, companyKana: string, companyName: string, createDate: Date, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, franchiseCode: string, id: number, phoneNumber: string, postalCode: string, prefId: number, updateDate: Date, url?: string | null };

export type FranchisesQueryVariables = Exact<{
  request: GetFranchiseMembersRequest;
}>;


export type FranchisesQuery = { __typename?: 'Query', franchises: { __typename?: 'GetFranchisesReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'Franchise', id: number, companyName: string, franchiseCode: string }> } };

export type FranchiseFragment = { __typename?: 'Franchise', id: number, companyName: string, franchiseCode: string };

export type CreateFranchiseMutationVariables = Exact<{
  request: CreateFranchiseRequest;
}>;


export type CreateFranchiseMutation = { __typename?: 'Mutation', franchiseCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateFranchiseMutationVariables = Exact<{
  request: UpdateFranchiseRequest;
}>;


export type UpdateFranchiseMutation = { __typename?: 'Mutation', franchiseUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteFranchiseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFranchiseMutation = { __typename?: 'Mutation', franchiseDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type FranchiseUpdateFragment = { __typename?: 'Franchise', addr01: string, addr02: string, companyKana: string, companyName: string, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, id: number, phoneNumber: string, postalCode: string, prefId: number, url?: string | null };

export type FranchiseQueryVariables = Exact<{
  franchiseId?: InputMaybe<Scalars['Int']>;
}>;


export type FranchiseQuery = { __typename?: 'Query', franchise: { __typename?: 'Franchise', companyName: string } };

export type UpdateAccountPasswordMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type UpdateAccountPasswordMutation = { __typename?: 'Mutation', accountPasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateFranchisePasswordMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type UpdateFranchisePasswordMutation = { __typename?: 'Mutation', franchisePasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateTenantPasswordMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type UpdateTenantPasswordMutation = { __typename?: 'Mutation', tenantPasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateShopPasswordMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type UpdateShopPasswordMutation = { __typename?: 'Mutation', shopPasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateMasterPasswordMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type UpdateMasterPasswordMutation = { __typename?: 'Mutation', masterPasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ForgotPasswordFranchiseMutationVariables = Exact<{
  request: ForgotPasswordRequest;
}>;


export type ForgotPasswordFranchiseMutation = { __typename?: 'Mutation', franchiseForgotPassword: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ForgotPasswordTenantMutationVariables = Exact<{
  request: ForgotPasswordRequest;
}>;


export type ForgotPasswordTenantMutation = { __typename?: 'Mutation', tenantForgotPassword: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ForgotPasswordShopMutationVariables = Exact<{
  request: ForgotPasswordRequest;
}>;


export type ForgotPasswordShopMutation = { __typename?: 'Mutation', shopForgotPassword: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ForgotPasswordMasterMutationVariables = Exact<{
  request: ForgotPasswordRequest;
}>;


export type ForgotPasswordMasterMutation = { __typename?: 'Mutation', masterForgotPassword: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateFranchiseOneTimePasswordMutationVariables = Exact<{
  request: CreateAdminOneTimePasswordRequest;
}>;


export type CreateFranchiseOneTimePasswordMutation = { __typename?: 'Mutation', franchiseOneTimePasswordCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateTenantOneTimePasswordMutationVariables = Exact<{
  request: CreateAdminOneTimePasswordRequest;
}>;


export type CreateTenantOneTimePasswordMutation = { __typename?: 'Mutation', tenantOneTimePasswordCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateShopOneTimePasswordMutationVariables = Exact<{
  request: CreateAdminOneTimePasswordRequest;
}>;


export type CreateShopOneTimePasswordMutation = { __typename?: 'Mutation', shopOneTimePasswordCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ManualPathFragment = { __typename?: 'Manual', path: string };

export type ManualItemFragment = { __typename?: 'Manual', id: number, path: string, fileName: string, manualTypeId: number };

export type GetManualsQueryVariables = Exact<{
  request: GetManualsRequest;
}>;


export type GetManualsQuery = { __typename?: 'Query', manuals: { __typename?: 'GetManualsReply', dataSource: Array<{ __typename?: 'Manual', id: number, path: string, fileName: string, manualTypeId: number }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type CreateManualUrlMutationVariables = Exact<{
  request: CreateUrlRequest;
}>;


export type CreateManualUrlMutation = { __typename?: 'Mutation', manualUrlCreate: { __typename?: 'CreateUrlReply', id?: string | null, fileName?: string | null, fileType: string, url: string, key: string } };

export type CreateManualMutationVariables = Exact<{
  request: CreateManualRequest;
}>;


export type CreateManualMutation = { __typename?: 'Mutation', manualCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type MasterMemberDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MasterMemberDetailQuery = { __typename?: 'Query', masterMember?: { __typename?: 'MasterMember', authorityId: number, email: string, id: number, name: string, phoneNumber?: string | null } | null };

export type MasterMemberDetailFragment = { __typename?: 'MasterMember', authorityId: number, email: string, id: number, name: string, phoneNumber?: string | null };

export type MasterMembersQueryVariables = Exact<{
  request: GetMasterMembersRequest;
}>;


export type MasterMembersQuery = { __typename?: 'Query', masterMembers: { __typename?: 'GetMasterMembersReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'MasterMember', id: number, name: string, email: string, authorityId: number }> } };

export type MasterMemberFragment = { __typename?: 'MasterMember', id: number, name: string, email: string, authorityId: number };

export type PasswordChangeMasterMemberMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type PasswordChangeMasterMemberMutation = { __typename?: 'Mutation', masterPasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateMasterMemberMutationVariables = Exact<{
  request: CreateMasterMember;
}>;


export type CreateMasterMemberMutation = { __typename?: 'Mutation', masterMemberCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateMasterMemberMutationVariables = Exact<{
  request: UpdateMasterMember;
}>;


export type UpdateMasterMemberMutation = { __typename?: 'Mutation', masterMemberUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteMasterMemberMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMasterMemberMutation = { __typename?: 'Mutation', masterMemberDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ShippedConteinerFragment = { __typename?: 'Order', shippingInformations: Array<{ __typename?: 'ShippingInformation', id: number, deliveryVendorId: number, arrivalDate?: Date | null, shippingDate?: Date | null, trackingNumber: string }>, shippedItems: Array<{ __typename?: 'ShippedItem', orderItemId: number, shippingInformationId: number, quantity: number, createDate?: Date | null }>, orderItems: Array<{ __typename?: 'OrderItem', id: number, productName: string, thumbnail: string, pattern: string, price: number, quantity: number, totalPrice: number }> };

export type UndispatchedConteinerFragment = { __typename?: 'Order', shippedItems: Array<{ __typename?: 'ShippedItem', orderItemId: number, quantity: number }>, orderItems: Array<{ __typename?: 'OrderItem', id: number, productName: string, thumbnail: string, pattern: string, price: number, quantity: number, totalPrice: number }> };

export type ReturnedContainerFragment = { __typename?: 'Order', returnedItems: Array<{ __typename?: 'ReturnedItem', orderItemId: number, quantity: number, completedDate?: Date | null }>, orderItems: Array<{ __typename?: 'OrderItem', id: number, productName: string, thumbnail: string, pattern: string, price: number, quantity: number, totalPrice: number }> };

export type RefundedContainerFragment = { __typename?: 'Order', refundedItems: Array<{ __typename?: 'RefundedItem', orderItemId: number, refundReasonId: number, price: number, completedDate?: Date | null }>, orderItems: Array<{ __typename?: 'OrderItem', id: number, productName: string, thumbnail: string, pattern: string, price: number, quantity: number, totalPrice: number }> };

export type OrderDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type OrderDetailQuery = { __typename?: 'Query', order: { __typename?: 'Order', memo?: string | null, paymentStatusId: number, deliveryFeeTotal: number, totalPrice: number, totalTax: number, totalPayment: number, orderItems: Array<{ __typename?: 'OrderItem', id: number, productName: string, thumbnail: string, pattern: string, price: number, quantity: number, totalPrice: number }>, shippingAddress?: { __typename?: 'ShippingAddress', email?: string | null, phoneNumber?: string | null, postalCode: string, prefId: number, addr01: string, addr02?: string | null, name: string } | null, shippingInformations: Array<{ __typename?: 'ShippingInformation', id: number, deliveryVendorId: number, arrivalDate?: Date | null, shippingDate?: Date | null, trackingNumber: string }>, shippedItems: Array<{ __typename?: 'ShippedItem', orderItemId: number, shippingInformationId: number, quantity: number, createDate?: Date | null }> } };

export type OrderColumnFragment = { __typename?: 'OrdersDTO', id: number, orderId: string, totalPayment: number, paymentStatusId: number, shippingStatusId: number, returnStatusId: number, createDate?: Date | null, account?: { __typename?: 'Account', name?: string | null, email: string, id: number } | null, tenant?: { __typename?: 'Tenant', tenantName: string } | null, shop?: { __typename?: 'Shop', shopName: string } | null, orderItems: Array<{ __typename?: 'OrderItem', quantity: number }> };

export type OrdersQueryVariables = Exact<{
  request: OrdersRequest;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrdersReply', dataSource: Array<{ __typename?: 'OrdersDTO', id: number, orderId: string, totalPayment: number, paymentStatusId: number, shippingStatusId: number, returnStatusId: number, createDate?: Date | null, account?: { __typename?: 'Account', name?: string | null, email: string, id: number } | null, tenant?: { __typename?: 'Tenant', tenantName: string } | null, shop?: { __typename?: 'Shop', shopName: string } | null, orderItems: Array<{ __typename?: 'OrderItem', quantity: number }> }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type CustomerActionFragment = { __typename?: 'CustomerAction', addCart: number, externalSite: number, joinMemberId: number, joinMemberName: string, productId: string, productName: string, qr: number, thumbnail: string };

export type GetCustomerActionsQueryVariables = Exact<{
  request: ActionSearchRequest;
}>;


export type GetCustomerActionsQuery = { __typename?: 'Query', customerActions: Array<{ __typename?: 'CustomerAction', addCart: number, externalSite: number, joinMemberId: number, joinMemberName: string, productId: string, productName: string, qr: number, thumbnail: string }> };

export type GetProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, name: string, overview: string, detail?: string | null, isReducedTax: boolean, categoryId: number, skus: Array<{ __typename?: 'Sku', id: string, price: number, sku?: string | null, barcode?: string | null, qty: number, cost?: number | null, pattern: Array<{ __typename?: 'Pattern', label: string, option: string }> }>, variations: Array<{ __typename?: 'Variation', label: string, options: Array<string> }> } };

export type ProductColumnFragment = { __typename?: 'Product', id: string, name: string, joinMemberName: string, categoryId: number, createDate: Date, updateDate: Date, saleType: number, images: Array<{ __typename?: 'Image', fileType: string, path: string, key: string }>, skus: Array<{ __typename?: 'Sku', id: string, price: number, qty: number, sku?: string | null, cost?: number | null }> };

export type ProductsQueryVariables = Exact<{
  request?: InputMaybe<SearchProductRequest>;
}>;


export type ProductsQuery = { __typename?: 'Query', productSearch: { __typename?: 'SearchProductReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'Product', id: string, name: string, joinMemberName: string, categoryId: number, createDate: Date, updateDate: Date, saleType: number, images: Array<{ __typename?: 'Image', fileType: string, path: string, key: string }>, skus: Array<{ __typename?: 'Sku', id: string, price: number, qty: number, sku?: string | null, cost?: number | null }> }> } };

export type CreateProductMutationVariables = Exact<{
  request: CreateProductRequest;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', productCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ProductFileUploadsMutationVariables = Exact<{
  request: Array<CreateUrlRequest> | CreateUrlRequest;
}>;


export type ProductFileUploadsMutation = { __typename?: 'Mutation', productFileUrlsCreate: Array<{ __typename?: 'CreateUrlReply', id?: string | null, key: string, fileName?: string | null, fileType: string, url: string }> };

export type UpdateProductFragment = { __typename?: 'Product', id: string, name: string, overview: string, detail?: string | null, categoryId: number, tags: Array<string>, isReducedTax: boolean, memo?: string | null, saleType: number, externalUrl?: string | null, status: number, startDate?: Date | null, endDate?: Date | null, variations: Array<{ __typename?: 'Variation', label: string, options: Array<string> }>, images: Array<{ __typename?: 'Image', fileType: string, key: string, path: string }>, skus: Array<{ __typename?: 'Sku', id: string, price: number, sku?: string | null, barcode?: string | null, cost?: number | null, qty: number, pattern: Array<{ __typename?: 'Pattern', label: string, option: string }> }> };

export type GetUpdateProductQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUpdateProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, name: string, overview: string, detail?: string | null, categoryId: number, tags: Array<string>, isReducedTax: boolean, memo?: string | null, saleType: number, externalUrl?: string | null, status: number, startDate?: Date | null, endDate?: Date | null, variations: Array<{ __typename?: 'Variation', label: string, options: Array<string> }>, images: Array<{ __typename?: 'Image', fileType: string, key: string, path: string }>, skus: Array<{ __typename?: 'Sku', id: string, price: number, sku?: string | null, barcode?: string | null, cost?: number | null, qty: number, pattern: Array<{ __typename?: 'Pattern', label: string, option: string }> }> } };

export type UpdateProductMutationVariables = Exact<{
  request: UpdateProductRequest;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', productUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateSkuMutationVariables = Exact<{
  request: UpdateSkuRequest;
}>;


export type UpdateSkuMutation = { __typename?: 'Mutation', skuUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateSkuMutationVariables = Exact<{
  request: AddSkuRequest;
}>;


export type CreateSkuMutation = { __typename?: 'Mutation', skuCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteSkuMutationVariables = Exact<{
  request: DeleteSkuRequest;
}>;


export type DeleteSkuMutation = { __typename?: 'Mutation', skuDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', productDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ShopMemberDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ShopMemberDetailQuery = { __typename?: 'Query', shopMember: { __typename?: 'ShopMemberDetail', authorityId: number, email: string, id: number, isFirst: boolean, name: string, phoneNumber?: string | null, shopId: number, companyName?: string | null } };

export type ShopMemberDetailFragment = { __typename?: 'ShopMemberDetail', authorityId: number, email: string, id: number, isFirst: boolean, name: string, phoneNumber?: string | null, shopId: number, companyName?: string | null };

export type ShopMembersQueryVariables = Exact<{
  request: GetShopMembersRequest;
}>;


export type ShopMembersQuery = { __typename?: 'Query', shopMembers: { __typename?: 'GetShopMembersReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'ShopMemberDetail', id: number, name: string, email: string, authorityId: number, shopId: number, isFirst: boolean, phoneNumber?: string | null, companyName?: string | null }> } };

export type ShopMemberFragment = { __typename?: 'ShopMemberDetail', id: number, name: string, email: string, authorityId: number, shopId: number, isFirst: boolean, phoneNumber?: string | null, companyName?: string | null };

export type PasswordChangeShopMemberMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type PasswordChangeShopMemberMutation = { __typename?: 'Mutation', shopPasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ShopMemberCreateMutationVariables = Exact<{
  request: CreateShopMember;
}>;


export type ShopMemberCreateMutation = { __typename?: 'Mutation', shopMemberCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ShopMemberUpdateMutationVariables = Exact<{
  request: UpdateShopMember;
}>;


export type ShopMemberUpdateMutation = { __typename?: 'Mutation', shopMemberUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ShopMemberDeleteMutationVariables = Exact<{
  shopMemberDeleteId: Scalars['Int'];
}>;


export type ShopMemberDeleteMutation = { __typename?: 'Mutation', shopMemberDelete: { __typename?: 'ActionReply', message?: string | null, status?: number | null } };

export type ShopMemberUpdateFragment = { __typename?: 'ShopMemberDetail', authorityId: number, email: string, id: number, name: string, phoneNumber?: string | null };

export type ShopQueryVariables = Exact<{
  shopId: Scalars['Int'];
}>;


export type ShopQuery = { __typename?: 'Query', shop: { __typename?: 'Shop', addr01: string, addr02: string, banckBranch?: string | null, bankAccountNumber?: string | null, bankAccountType?: number | null, bankName?: string | null, companyKana: string, companyName: string, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, fax?: string | null, id: number, invoiceNumber?: string | null, phoneNumber: string, pic?: string | null, picDivision?: string | null, postalCode: string, prefId: number, representative: string, shopCode: string, shopName: string, url?: string | null } };

export type ShopDetailFragment = { __typename?: 'Shop', addr01: string, addr02: string, banckBranch?: string | null, bankAccountNumber?: string | null, bankAccountType?: number | null, bankName?: string | null, companyKana: string, companyName: string, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, fax?: string | null, id: number, invoiceNumber?: string | null, phoneNumber: string, pic?: string | null, picDivision?: string | null, postalCode: string, prefId: number, representative: string, shopCode: string, shopName: string, url?: string | null };

export type ShopsQueryVariables = Exact<{
  request: GetShopsRequest;
}>;


export type ShopsQuery = { __typename?: 'Query', shops: { __typename?: 'GetShopsReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'Shop', id: number, shopName: string, companyName: string, shopCode: string }> } };

export type ShopFragment = { __typename?: 'Shop', id: number, shopName: string, companyName: string, shopCode: string };

export type ShopCreateMutationVariables = Exact<{
  request: CreateShop;
}>;


export type ShopCreateMutation = { __typename?: 'Mutation', shopCreate: { __typename?: 'ActionReply', message?: string | null, status?: number | null } };

export type ShopUpdateMutationVariables = Exact<{
  request: UpdateShop;
}>;


export type ShopUpdateMutation = { __typename?: 'Mutation', shopUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteShopMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteShopMutation = { __typename?: 'Mutation', shopDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ShopUpdateFragment = { __typename?: 'Shop', url?: string | null, shopName: string, representative: string, prefId: number, postalCode: string, picDivision?: string | null, pic?: string | null, phoneNumber: string, invoiceNumber?: string | null, id: number, fax?: string | null, email04?: string | null, email03?: string | null, email02?: string | null, email01: string, companyName: string, companyKana: string, bankName?: string | null, bankAccountType?: number | null, bankAccountNumber?: string | null, banckBranch?: string | null, addr02: string, addr01: string };

export type StampCardsQueryVariables = Exact<{
  request: StampCardSearchInput;
}>;


export type StampCardsQuery = { __typename?: 'Query', stampCards: { __typename?: 'StampCardSearchResponse', datasource: Array<{ __typename?: 'StampCard', couponId: string, createdAt: Date, description: string, from?: Date | null, id: string, limit: number, franchiseId: number, updatedAt: Date, to?: Date | null, thumbnail: { __typename?: 'Thumbnail', fileType: string, id: string, key: string, path: string } }>, page: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type StampCardFragment = { __typename?: 'StampCard', couponId: string, createdAt: Date, description: string, from?: Date | null, id: string, limit: number, franchiseId: number, updatedAt: Date, to?: Date | null, thumbnail: { __typename?: 'Thumbnail', fileType: string, id: string, key: string, path: string } };

export type StampCardCreateMutationVariables = Exact<{
  request: StampCardCreateInput;
}>;


export type StampCardCreateMutation = { __typename?: 'Mutation', stampCardCreate: { __typename?: 'ActionReply', message?: string | null, status?: number | null } };

export type StampCardUpdateMutationVariables = Exact<{
  request: StampCardUpdateInput;
}>;


export type StampCardUpdateMutation = { __typename?: 'Mutation', stampCardUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type StampCardQueryVariables = Exact<{
  stampCardId: Scalars['String'];
}>;


export type StampCardQuery = { __typename?: 'Query', stampCard: { __typename?: 'StampCard', couponId: string, createdAt: Date, description: string, from?: Date | null, id: string, limit: number, franchiseId: number, updatedAt: Date, to?: Date | null, thumbnail: { __typename?: 'Thumbnail', fileType: string, id: string, key: string, path: string } } };

export type SurveysQueryVariables = Exact<{
  request: SurveySearchInput;
}>;


export type SurveysQuery = { __typename?: 'Query', surveys: { __typename?: 'SurveySearchResponse', page: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, datasource: Array<{ __typename?: 'Survey', couponId?: string | null, createdAt: Date, description: string, id: string, franchiseId: number, name: string, is_published: boolean, stampCardId?: string | null, updatedAt: Date, questions: Array<{ __typename?: 'Question', id: string, question: string, questionType: QuestionType, required: boolean, sortNo: number, options: Array<{ __typename?: 'QuestionOption', option: string, id: string }> }>, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } }> } };

export type SurveyFragment = { __typename?: 'Survey', couponId?: string | null, createdAt: Date, description: string, id: string, franchiseId: number, name: string, is_published: boolean, stampCardId?: string | null, updatedAt: Date, questions: Array<{ __typename?: 'Question', id: string, question: string, questionType: QuestionType, required: boolean, sortNo: number, options: Array<{ __typename?: 'QuestionOption', option: string, id: string }> }>, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } };

export type SurveyCreateMutationVariables = Exact<{
  request: SurveyCreateInput;
}>;


export type SurveyCreateMutation = { __typename?: 'Mutation', surveyCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type SurveyUpdateMutationVariables = Exact<{
  request: SurveyUpdateInput;
}>;


export type SurveyUpdateMutation = { __typename?: 'Mutation', surveyUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type SurveyQueryVariables = Exact<{
  surveyId: Scalars['String'];
}>;


export type SurveyQuery = { __typename?: 'Query', survey: { __typename?: 'Survey', couponId?: string | null, createdAt: Date, description: string, id: string, franchiseId: number, name: string, is_published: boolean, stampCardId?: string | null, updatedAt: Date, questions: Array<{ __typename?: 'Question', id: string, question: string, questionType: QuestionType, required: boolean, sortNo: number, options: Array<{ __typename?: 'QuestionOption', option: string, id: string }> }>, thumbnail: { __typename?: 'Thumbnail', path: string, key: string, id: string, fileType: string } } };

export type TenantMemberDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TenantMemberDetailQuery = { __typename?: 'Query', tenantMember: { __typename?: 'TenantMemberDetail', authorityId: number, email: string, id: number, isFirst: boolean, name: string, phoneNumber?: string | null, tenantId: number, companyName?: string | null } };

export type TenantMemberDetailFragment = { __typename?: 'TenantMemberDetail', authorityId: number, email: string, id: number, isFirst: boolean, name: string, phoneNumber?: string | null, tenantId: number, companyName?: string | null };

export type TenantMembersQueryVariables = Exact<{
  request: GetTenantMembersRequest;
}>;


export type TenantMembersQuery = { __typename?: 'Query', tenantMembers: { __typename?: 'GetTenantMembersReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'TenantMemberDetail', id: number, name: string, email: string, authorityId: number, tenantId: number, isFirst: boolean, phoneNumber?: string | null, companyName?: string | null }> } };

export type TenantMemberFragment = { __typename?: 'TenantMemberDetail', id: number, name: string, email: string, authorityId: number, tenantId: number, isFirst: boolean, phoneNumber?: string | null, companyName?: string | null };

export type PasswordChangeTenantMemberMutationVariables = Exact<{
  request: UpdatePasswordRequest;
}>;


export type PasswordChangeTenantMemberMutation = { __typename?: 'Mutation', tenantPasswordUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type CreateTenantMemberMutationVariables = Exact<{
  request: CreateTenantMember;
}>;


export type CreateTenantMemberMutation = { __typename?: 'Mutation', tenantMemberCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateTenantMemberMutationVariables = Exact<{
  request: UpdateTenantMember;
}>;


export type UpdateTenantMemberMutation = { __typename?: 'Mutation', tenantMemberUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteTenantMemberMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTenantMemberMutation = { __typename?: 'Mutation', tenantMemberDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type TenantMemberUpdateFragment = { __typename?: 'TenantMember', authorityId: number, email: string, id: number, name: string, phoneNumber?: string | null };

export type TenantDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type TenantDetailQuery = { __typename?: 'Query', tenant?: { __typename?: 'Tenant', addr01: string, addr02: string, closingTime?: string | null, companyKana: string, companyName: string, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, id: number, openingTime?: string | null, phoneNumber: string, postalCode: string, prefId: number, tenantCode: string, tenantKana: string, tenantName: string, tenantNameEng?: string | null, url?: string | null } | null };

export type TenantDetailFragment = { __typename?: 'Tenant', addr01: string, addr02: string, closingTime?: string | null, companyKana: string, companyName: string, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, id: number, openingTime?: string | null, phoneNumber: string, postalCode: string, prefId: number, tenantCode: string, tenantKana: string, tenantName: string, tenantNameEng?: string | null, url?: string | null };

export type TenantsQueryVariables = Exact<{
  request: TenantsRequest;
}>;


export type TenantsQuery = { __typename?: 'Query', tenants: { __typename?: 'TenantsReply', pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } }, dataSource: Array<{ __typename?: 'Tenant', id: number, tenantName: string, companyName: string, tenantCode: string }> } };

export type TenantFragment = { __typename?: 'Tenant', id: number, tenantName: string, companyName: string, tenantCode: string };

export type CreateTenantMutationVariables = Exact<{
  request: CreateTenant;
}>;


export type CreateTenantMutation = { __typename?: 'Mutation', tenantCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateTenantMutationVariables = Exact<{
  request: UpdateTenant;
}>;


export type UpdateTenantMutation = { __typename?: 'Mutation', tenantUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteTenantMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTenantMutation = { __typename?: 'Mutation', tenantDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type TenantUpdateFragment = { __typename?: 'Tenant', addr01: string, addr02: string, closingTime?: string | null, companyKana: string, companyName: string, email01: string, email02?: string | null, email03?: string | null, email04?: string | null, id: number, openingTime?: string | null, phoneNumber: string, postalCode: string, prefId: number, tenantKana: string, tenantName: string, tenantNameEng?: string | null, url?: string | null };

export type TenantQueryVariables = Exact<{
  tenantId: Scalars['Int'];
}>;


export type TenantQuery = { __typename?: 'Query', tenant?: { __typename?: 'Tenant', tenantName: string } | null };

export type CreateOnlyShopProductMutationVariables = Exact<{
  request: CreateTenpoOnlyProductRequest;
}>;


export type CreateOnlyShopProductMutation = { __typename?: 'Mutation', tenpoOnlyProductCreate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type UpdateOnlyShopProductMutationVariables = Exact<{
  request: UpdateTenpoOnlyProductRequest;
}>;


export type UpdateOnlyShopProductMutation = { __typename?: 'Mutation', tenpoOnlyProductUpdate: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type DeleteOnlyShopProductMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOnlyShopProductMutation = { __typename?: 'Mutation', tenpoOnlyProductDelete: { __typename?: 'ActionReply', status?: number | null, message?: string | null } };

export type ShopProductItemFragment = { __typename?: 'TenpoOnlyProduct', id: string, name: string, externalUrl: string, memo?: string | null, createDate?: Date | null };

export type ShopProductSearchQueryVariables = Exact<{
  request?: InputMaybe<SearchTenpoOnlyProductRequest>;
}>;


export type ShopProductSearchQuery = { __typename?: 'Query', tenpoOnlyProductSearch: { __typename?: 'SearchTenpoOnlyProductReply', dataSource: Array<{ __typename?: 'TenpoOnlyProduct', id: string, name: string, externalUrl: string, memo?: string | null, createDate?: Date | null }>, pages: { __typename?: 'PageReply', totalCount: number, totalPage: number, currentPage: number, range: { __typename?: 'Range', first: number, end: number } } } };

export type ProductCategoryContainerFragment = { __typename?: 'Product', id: string, categoryId: number };

export type ProductPriceContainerFragment = { __typename?: 'Product', id: string, skus: Array<{ __typename?: 'Sku', id: string, price: number, sku?: string | null, barcode?: string | null, qty: number, cost?: number | null }> };

export type ProductTaxContainerFragment = { __typename?: 'Product', id: string, isReducedTax: boolean };

export type ProductTitleContainerFragment = { __typename?: 'Product', id: string, name: string, overview: string, detail?: string | null };

export type VariationContainerFragment = { __typename?: 'Product', id: string, variations: Array<{ __typename?: 'Variation', label: string, options: Array<string> }>, skus: Array<{ __typename?: 'Sku', id: string, price: number, sku?: string | null, barcode?: string | null, qty: number, cost?: number | null, pattern: Array<{ __typename?: 'Pattern', label: string, option: string }> }> };

export const LoginResponseFragmentDoc = gql`
    fragment LoginResponse on LoginAccount {
  id
  authorityId
  email
  loginType
  name
  shopId
  tenantId
  isFirst
}
    `;
export const ActionReplyFragmentDoc = gql`
    fragment ActionReply on ActionReply {
  status
  message
}
    `;
export const UrlReplyFragmentDoc = gql`
    fragment UrlReply on CreateUrlReply {
  id
  fileName
  fileType
  url
  key
}
    `;
export const PageFragmentDoc = gql`
    fragment Page on PageReply {
  totalCount
  totalPage
  currentPage
  range {
    first
    end
  }
}
    `;
export const CustomerContainerFragmentDoc = gql`
    fragment CustomerContainer on ShippingAddress {
  email
  phoneNumber
  postalCode
  prefId
  addr01
  addr02
  name
}
    `;
export const OrderItemRrowFragmentDoc = gql`
    fragment OrderItemRrow on OrderItem {
  thumbnail
  productName
  quantity
  price
  totalPrice
}
    `;
export const OrderTotalContainerFragmentDoc = gql`
    fragment OrderTotalContainer on Order {
  paymentStatusId
  deliveryFeeTotal
  totalPrice
  totalTax
  totalPayment
}
    `;
export const SquareDeviceFragmentDoc = gql`
    fragment SquareDevice on SquareDevice {
  deviceId
  id
  name
}
    `;
export const CafeOrderFragmentDoc = gql`
    fragment CafeOrder on CafeOrder {
  codeUrl
  id
  orderItems {
    productName
    pattern
    price
    quantity
    thumbnail
  }
  totalPayment
  paymentVendorId
}
    `;
export const BannerFragmentDoc = gql`
    fragment Banner on Banner {
  id
  bannerTypeId
  title
  url
  startingDate
  closingDate
  sortNo
  isExternal
  image {
    path
  }
}
    `;
export const BannerItemFragmentDoc = gql`
    fragment BannerItem on Banner {
  id
  bannerTypeId
  title
  startingDate
  closingDate
  image {
    path
  }
}
    `;
export const TenantOptionFragmentDoc = gql`
    fragment TenantOption on Tenant {
  id
  tenantName
}
    `;
export const BlockStockFragmentDoc = gql`
    fragment BlockStock on DisplaySpaceStocksDTO {
  date
  stock
  max
}
    `;
export const DisplaySpaceScheduleTableFragmentDoc = gql`
    fragment DisplaySpaceScheduleTable on DisplaySpace {
  id
  blockNumber
  products {
    id
    displayStartDate
    displayEndDate
    name
    shop {
      shopName
    }
  }
  attribute {
    tenantId
    fees {
      id
      fee
    }
  }
}
    `;
export const BlockFragmentDoc = gql`
    fragment Block on DisplaySpace {
  id
  statusId
  attributeId
  blockNumber
  description
  note
  attribute {
    name
    displaySpaceTypeId
    width
    height
    depth
    fees {
      id
      fee
      span
    }
  }
  products {
    name
    images {
      path
    }
    shop {
      shopName
    }
    displayStartDate
    displayEndDate
  }
}
    `;
export const DisplaySpaceColumnFragmentDoc = gql`
    fragment DisplaySpaceColumn on DisplaySpace {
  id
  statusId
  blockNumber
  description
  attribute {
    width
    height
    depth
    displaySpaceTypeId
    fees {
      id
      fee
      span
    }
  }
}
    `;
export const DisplaySpaceItemFragmentDoc = gql`
    fragment DisplaySpaceItem on DisplaySpace {
  id
  blockNumber
  products {
    id
    name
    displayStartDate
  }
}
    `;
export const DisplayRegisteredProductFragmentDoc = gql`
    fragment DisplayRegisteredProduct on DisplayProduct {
  productId
  name
  images {
    path
    fileType
  }
  id
  tenantId
  quantity
  displayStartDate
  displayEndDate
  shippedStatusId
  applyStatusId
  spaceId
  createDate
  note
  isDelivery
  displayImages {
    path
    fileType
  }
  skus {
    price
    qty
  }
  space {
    attributeId
    blockNumber
    description
    id
    note
    statusId
  }
  saleType
  externalUrl
}
    `;
export const DisplayProductFragmentDoc = gql`
    fragment DisplayProduct on Product {
  id
  name
  joinMemberId
  images {
    path
    fileType
  }
  skus {
    price
    qty
  }
  createDate
  saleType
}
    `;
export const BlockAttributeFragmentDoc = gql`
    fragment BlockAttribute on DisplaySpaceAttribute {
  id
  displaySpaceTypeId
  name
  width
  height
  depth
  fees {
    id
    fee
    span
  }
}
    `;
export const DisplayProductDetailFragmentDoc = gql`
    fragment DisplayProductDetail on DisplayProduct {
  applyStatusId
  attribute {
    ...BlockAttribute
  }
  fee {
    id
    fee
    span
  }
  attributeId
  categoryId
  createDate
  detail
  displayEndDate
  displayImages {
    displaySpaceProductId
    id
    key
    path
    fileType
    fileName
  }
  displayStartDate
  externalUrl
  id
  images {
    path
    fileType
    key
  }
  isDelivery
  isReducedTax
  memo
  name
  note
  overview
  productId
  quantity
  saleType
  shippedStatusId
  shop {
    shopName
    companyName
    addr01
    addr02
    phoneNumber
    email01
  }
  shopId
  skus {
    barcode
    cost
    id
    pattern {
      label
      option
    }
    price
    priceWithTax
    qty
    sku
  }
  space {
    attributeId
    blockNumber
    description
    id
    note
    statusId
  }
  spaceId
  statusNote
  tags
  tenantId
  tenantName
  updateDate
  variations {
    label
    options
  }
}
    ${BlockAttributeFragmentDoc}`;
export const DisplaySpaceScheduleFragmentDoc = gql`
    fragment DisplaySpaceSchedule on DisplaySpace {
  id
  blockNumber
  products {
    id
    displayStartDate
    displayEndDate
    name
    shop {
      shopName
    }
  }
  attribute {
    tenantId
    fees {
      id
      fee
    }
  }
}
    `;
export const BlockImageFragmentDoc = gql`
    fragment BlockImage on DisplaySpaceImage {
  id
  path
  key
}
    `;
export const BlockDeliveryAddressFragmentDoc = gql`
    fragment BlockDeliveryAddress on DeliveryAddress {
  id
  name
  prefId
  postalCode
  addr01
  addr02
  phoneNumber
}
    `;
export const CafeOrderItemFragmentDoc = gql`
    fragment CafeOrderItem on CafeOrder {
  createDate
  customerId
  id
  orderId
  orderItems {
    id
    orderId
    pattern
    price
    productName
    quantity
    recordStatusId
    thumbnail
    totalPayment
    totalPrice
    totalTax
  }
  orderStatusId
  paymentId
  paymentStatusId
  paymentVendorId
  recordStatusId
  totalPayment
  totalPrice
  totalTax
}
    `;
export const CafeOrderAggregateFragmentDoc = gql`
    fragment CafeOrderAggregate on CafeOrderAggregate {
  totalPayment
  totalQuantity
}
    `;
export const CafeOrdersSummaryFragmentDoc = gql`
    fragment CafeOrdersSummary on CafeOrdersSummaryByProduct {
  pattern
  productId
  productName
  skuId
  totalPayment
  totalQuantity
}
    `;
export const CafeProductColumnFragmentDoc = gql`
    fragment CafeProductColumn on CafeProduct {
  id
  name
  tenpoName
  images {
    path
  }
  skus {
    prices {
      amount
      isReducedTax
      priceWithTax
      type
    }
    qty
  }
  nonManagedStock
  createDate
  updateDate
}
    `;
export const CafeProductDetailFragmentDoc = gql`
    fragment CafeProductDetail on CafeProduct {
  id
  name
  overview
  categoryId
  isReducedTax
  memo
  variations {
    label
    options
  }
  skus {
    id
    prices {
      amount
      isReducedTax
      priceWithTax
      type
    }
    qty
    pattern {
      label
      option
    }
  }
  images {
    path
    key
    fileType
  }
  status
  nonManagedStock
}
    `;
export const CouponFragmentDoc = gql`
    fragment Coupon on Coupon {
  createdAt
  description
  franchiseId
  expirySpan
  id
  isPublished
  max
  name
  numberOfCardsIssued
  thumbnail {
    path
    key
    id
    fileType
  }
  updatedAt
}
    `;
export const MyCouponFragmentDoc = gql`
    fragment MyCoupon on MyCoupon {
  birthday
  burned
  couponId
  createdAt
  customerName
  customerId
  description
  expiryDate
  id
  memo
  name
  pref
  sex
  thumbnail {
    path
    key
    id
    fileType
  }
  updatedAt
}
    `;
export const AccountColumnFragmentDoc = gql`
    fragment AccountColumn on Account {
  accountStatusId
  accountTypeId
  email
  id
  name
  sexId
}
    `;
export const FranchiseMemberDetailFragmentDoc = gql`
    fragment FranchiseMemberDetail on FranchiseMemberDetail {
  authorityId
  email
  id
  isFirst
  name
  phoneNumber
  franchiseId
  companyName
}
    `;
export const FranchiseMemberFragmentDoc = gql`
    fragment FranchiseMember on FranchiseMemberDetail {
  id
  name
  email
  authorityId
  franchiseId
  isFirst
  phoneNumber
  companyName
}
    `;
export const FranchiseMemberUpdateFragmentDoc = gql`
    fragment FranchiseMemberUpdate on FranchiseMemberDetail {
  authorityId
  email
  id
  name
  phoneNumber
}
    `;
export const FranchiseDetailFragmentDoc = gql`
    fragment FranchiseDetail on Franchise {
  addr01
  addr02
  companyKana
  companyName
  createDate
  email01
  email02
  email03
  email04
  franchiseCode
  id
  phoneNumber
  postalCode
  prefId
  updateDate
  url
}
    `;
export const FranchiseFragmentDoc = gql`
    fragment Franchise on Franchise {
  id
  companyName
  franchiseCode
}
    `;
export const FranchiseUpdateFragmentDoc = gql`
    fragment FranchiseUpdate on Franchise {
  addr01
  addr02
  companyKana
  companyName
  email01
  email02
  email03
  email04
  id
  phoneNumber
  postalCode
  prefId
  url
}
    `;
export const ManualPathFragmentDoc = gql`
    fragment ManualPath on Manual {
  path
}
    `;
export const ManualItemFragmentDoc = gql`
    fragment ManualItem on Manual {
  id
  path
  fileName
  manualTypeId
}
    `;
export const MasterMemberDetailFragmentDoc = gql`
    fragment MasterMemberDetail on MasterMember {
  authorityId
  email
  id
  name
  phoneNumber
}
    `;
export const MasterMemberFragmentDoc = gql`
    fragment MasterMember on MasterMember {
  id
  name
  email
  authorityId
}
    `;
export const ShippedConteinerFragmentDoc = gql`
    fragment ShippedConteiner on Order {
  shippingInformations {
    id
    deliveryVendorId
    arrivalDate
    shippingDate
    trackingNumber
  }
  shippedItems {
    orderItemId
    shippingInformationId
    quantity
    createDate
  }
  orderItems {
    id
    productName
    thumbnail
    pattern
    price
    quantity
    totalPrice
  }
}
    `;
export const UndispatchedConteinerFragmentDoc = gql`
    fragment UndispatchedConteiner on Order {
  shippedItems {
    orderItemId
    quantity
  }
  orderItems {
    id
    productName
    thumbnail
    pattern
    price
    quantity
    totalPrice
  }
}
    `;
export const ReturnedContainerFragmentDoc = gql`
    fragment ReturnedContainer on Order {
  returnedItems {
    orderItemId
    quantity
    completedDate
  }
  orderItems {
    id
    productName
    thumbnail
    pattern
    price
    quantity
    totalPrice
  }
}
    `;
export const RefundedContainerFragmentDoc = gql`
    fragment RefundedContainer on Order {
  refundedItems {
    orderItemId
    refundReasonId
    price
    completedDate
  }
  orderItems {
    id
    productName
    thumbnail
    pattern
    price
    quantity
    totalPrice
  }
}
    `;
export const OrderColumnFragmentDoc = gql`
    fragment OrderColumn on OrdersDTO {
  id
  orderId
  totalPayment
  paymentStatusId
  shippingStatusId
  returnStatusId
  createDate
  account {
    name
    email
    id
  }
  tenant {
    tenantName
  }
  shop {
    shopName
  }
  orderItems {
    quantity
  }
}
    `;
export const CustomerActionFragmentDoc = gql`
    fragment CustomerAction on CustomerAction {
  addCart
  externalSite
  joinMemberId
  joinMemberName
  productId
  productName
  qr
  thumbnail
}
    `;
export const ProductColumnFragmentDoc = gql`
    fragment ProductColumn on Product {
  id
  name
  images {
    fileType
    path
    key
  }
  joinMemberName
  categoryId
  skus {
    id
    price
    qty
    sku
    cost
  }
  createDate
  updateDate
  saleType
}
    `;
export const UpdateProductFragmentDoc = gql`
    fragment UpdateProduct on Product {
  id
  name
  overview
  detail
  categoryId
  tags
  isReducedTax
  memo
  variations {
    label
    options
  }
  images {
    fileType
    key
    path
  }
  skus {
    id
    price
    sku
    barcode
    cost
    qty
    pattern {
      label
      option
    }
  }
  saleType
  externalUrl
  status
  startDate
  endDate
}
    `;
export const ShopMemberDetailFragmentDoc = gql`
    fragment ShopMemberDetail on ShopMemberDetail {
  authorityId
  email
  id
  isFirst
  name
  phoneNumber
  shopId
  companyName
}
    `;
export const ShopMemberFragmentDoc = gql`
    fragment ShopMember on ShopMemberDetail {
  id
  name
  email
  authorityId
  shopId
  isFirst
  phoneNumber
  companyName
}
    `;
export const ShopMemberUpdateFragmentDoc = gql`
    fragment ShopMemberUpdate on ShopMemberDetail {
  authorityId
  email
  id
  name
  phoneNumber
}
    `;
export const ShopDetailFragmentDoc = gql`
    fragment ShopDetail on Shop {
  addr01
  addr02
  banckBranch
  bankAccountNumber
  bankAccountType
  bankName
  companyKana
  companyName
  email01
  email02
  email03
  email04
  fax
  id
  invoiceNumber
  phoneNumber
  pic
  picDivision
  postalCode
  prefId
  representative
  shopCode
  shopName
  url
}
    `;
export const ShopFragmentDoc = gql`
    fragment Shop on Shop {
  id
  shopName
  companyName
  shopCode
}
    `;
export const ShopUpdateFragmentDoc = gql`
    fragment ShopUpdate on Shop {
  url
  shopName
  representative
  prefId
  postalCode
  picDivision
  pic
  phoneNumber
  invoiceNumber
  id
  fax
  email04
  email03
  email02
  email01
  companyName
  companyKana
  bankName
  bankAccountType
  bankAccountNumber
  banckBranch
  addr02
  addr01
}
    `;
export const StampCardFragmentDoc = gql`
    fragment StampCard on StampCard {
  couponId
  createdAt
  description
  from
  id
  thumbnail {
    fileType
    id
    key
    path
  }
  limit
  franchiseId
  updatedAt
  to
}
    `;
export const SurveyFragmentDoc = gql`
    fragment Survey on Survey {
  couponId
  createdAt
  description
  id
  franchiseId
  name
  is_published
  questions {
    id
    options {
      option
      id
    }
    question
    questionType
    required
    sortNo
  }
  stampCardId
  thumbnail {
    path
    key
    id
    fileType
  }
  updatedAt
}
    `;
export const TenantMemberDetailFragmentDoc = gql`
    fragment TenantMemberDetail on TenantMemberDetail {
  authorityId
  email
  id
  isFirst
  name
  phoneNumber
  tenantId
  companyName
}
    `;
export const TenantMemberFragmentDoc = gql`
    fragment TenantMember on TenantMemberDetail {
  id
  name
  email
  authorityId
  tenantId
  isFirst
  phoneNumber
  companyName
}
    `;
export const TenantMemberUpdateFragmentDoc = gql`
    fragment TenantMemberUpdate on TenantMember {
  authorityId
  email
  id
  name
  phoneNumber
}
    `;
export const TenantDetailFragmentDoc = gql`
    fragment TenantDetail on Tenant {
  addr01
  addr02
  closingTime
  companyKana
  companyName
  email01
  email02
  email03
  email04
  id
  openingTime
  phoneNumber
  postalCode
  prefId
  tenantCode
  tenantKana
  tenantName
  tenantNameEng
  url
}
    `;
export const TenantFragmentDoc = gql`
    fragment Tenant on Tenant {
  id
  tenantName
  companyName
  tenantCode
}
    `;
export const TenantUpdateFragmentDoc = gql`
    fragment TenantUpdate on Tenant {
  addr01
  addr02
  closingTime
  companyKana
  companyName
  email01
  email02
  email03
  email04
  id
  openingTime
  phoneNumber
  postalCode
  prefId
  tenantKana
  tenantName
  tenantNameEng
  url
}
    `;
export const ShopProductItemFragmentDoc = gql`
    fragment ShopProductItem on TenpoOnlyProduct {
  id
  name
  externalUrl
  memo
  createDate
}
    `;
export const ProductCategoryContainerFragmentDoc = gql`
    fragment ProductCategoryContainer on Product {
  id
  categoryId
}
    `;
export const ProductPriceContainerFragmentDoc = gql`
    fragment ProductPriceContainer on Product {
  id
  skus {
    id
    price
    sku
    barcode
    qty
    cost
  }
}
    `;
export const ProductTaxContainerFragmentDoc = gql`
    fragment ProductTaxContainer on Product {
  id
  isReducedTax
}
    `;
export const ProductTitleContainerFragmentDoc = gql`
    fragment ProductTitleContainer on Product {
  id
  name
  overview
  detail
}
    `;
export const VariationContainerFragmentDoc = gql`
    fragment VariationContainer on Product {
  id
  variations {
    label
    options
  }
  skus {
    id
    price
    sku
    barcode
    qty
    cost
    pattern {
      label
      option
    }
  }
}
    `;
export const ShopLoginDocument = gql`
    mutation ShopLogin($request: AdminLogin!) {
  shopLogin(request: $request) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type ShopLoginMutationFn = Apollo.MutationFunction<ShopLoginMutation, ShopLoginMutationVariables>;

/**
 * __useShopLoginMutation__
 *
 * To run a mutation, you first call `useShopLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopLoginMutation, { data, loading, error }] = useShopLoginMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopLoginMutation(baseOptions?: Apollo.MutationHookOptions<ShopLoginMutation, ShopLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopLoginMutation, ShopLoginMutationVariables>(ShopLoginDocument, options);
      }
export type ShopLoginMutationHookResult = ReturnType<typeof useShopLoginMutation>;
export type ShopLoginMutationResult = Apollo.MutationResult<ShopLoginMutation>;
export type ShopLoginMutationOptions = Apollo.BaseMutationOptions<ShopLoginMutation, ShopLoginMutationVariables>;
export const TenantLoginDocument = gql`
    mutation TenantLogin($request: AdminLogin!) {
  tenantLogin(request: $request) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type TenantLoginMutationFn = Apollo.MutationFunction<TenantLoginMutation, TenantLoginMutationVariables>;

/**
 * __useTenantLoginMutation__
 *
 * To run a mutation, you first call `useTenantLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTenantLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tenantLoginMutation, { data, loading, error }] = useTenantLoginMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useTenantLoginMutation(baseOptions?: Apollo.MutationHookOptions<TenantLoginMutation, TenantLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TenantLoginMutation, TenantLoginMutationVariables>(TenantLoginDocument, options);
      }
export type TenantLoginMutationHookResult = ReturnType<typeof useTenantLoginMutation>;
export type TenantLoginMutationResult = Apollo.MutationResult<TenantLoginMutation>;
export type TenantLoginMutationOptions = Apollo.BaseMutationOptions<TenantLoginMutation, TenantLoginMutationVariables>;
export const FranchiseLoginDocument = gql`
    mutation FranchiseLogin($request: AdminLogin!) {
  franchiseLogin(request: $request) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type FranchiseLoginMutationFn = Apollo.MutationFunction<FranchiseLoginMutation, FranchiseLoginMutationVariables>;

/**
 * __useFranchiseLoginMutation__
 *
 * To run a mutation, you first call `useFranchiseLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFranchiseLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [franchiseLoginMutation, { data, loading, error }] = useFranchiseLoginMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useFranchiseLoginMutation(baseOptions?: Apollo.MutationHookOptions<FranchiseLoginMutation, FranchiseLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FranchiseLoginMutation, FranchiseLoginMutationVariables>(FranchiseLoginDocument, options);
      }
export type FranchiseLoginMutationHookResult = ReturnType<typeof useFranchiseLoginMutation>;
export type FranchiseLoginMutationResult = Apollo.MutationResult<FranchiseLoginMutation>;
export type FranchiseLoginMutationOptions = Apollo.BaseMutationOptions<FranchiseLoginMutation, FranchiseLoginMutationVariables>;
export const MasterLoginDocument = gql`
    mutation MasterLogin($request: MasterLogin!) {
  masterLogin(request: $request) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type MasterLoginMutationFn = Apollo.MutationFunction<MasterLoginMutation, MasterLoginMutationVariables>;

/**
 * __useMasterLoginMutation__
 *
 * To run a mutation, you first call `useMasterLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMasterLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [masterLoginMutation, { data, loading, error }] = useMasterLoginMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useMasterLoginMutation(baseOptions?: Apollo.MutationHookOptions<MasterLoginMutation, MasterLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MasterLoginMutation, MasterLoginMutationVariables>(MasterLoginDocument, options);
      }
export type MasterLoginMutationHookResult = ReturnType<typeof useMasterLoginMutation>;
export type MasterLoginMutationResult = Apollo.MutationResult<MasterLoginMutation>;
export type MasterLoginMutationOptions = Apollo.BaseMutationOptions<MasterLoginMutation, MasterLoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AddCafeCartItemDocument = gql`
    mutation AddCafeCartItem($request: CreateCafeCartItemRequest!) {
  cafeCartItemCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type AddCafeCartItemMutationFn = Apollo.MutationFunction<AddCafeCartItemMutation, AddCafeCartItemMutationVariables>;

/**
 * __useAddCafeCartItemMutation__
 *
 * To run a mutation, you first call `useAddCafeCartItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCafeCartItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCafeCartItemMutation, { data, loading, error }] = useAddCafeCartItemMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAddCafeCartItemMutation(baseOptions?: Apollo.MutationHookOptions<AddCafeCartItemMutation, AddCafeCartItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCafeCartItemMutation, AddCafeCartItemMutationVariables>(AddCafeCartItemDocument, options);
      }
export type AddCafeCartItemMutationHookResult = ReturnType<typeof useAddCafeCartItemMutation>;
export type AddCafeCartItemMutationResult = Apollo.MutationResult<AddCafeCartItemMutation>;
export type AddCafeCartItemMutationOptions = Apollo.BaseMutationOptions<AddCafeCartItemMutation, AddCafeCartItemMutationVariables>;
export const OrderUpdateDocument = gql`
    mutation OrderUpdate($request: UpdateOrderRequest!) {
  orderUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type OrderUpdateMutationFn = Apollo.MutationFunction<OrderUpdateMutation, OrderUpdateMutationVariables>;

/**
 * __useOrderUpdateMutation__
 *
 * To run a mutation, you first call `useOrderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderUpdateMutation, { data, loading, error }] = useOrderUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useOrderUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OrderUpdateMutation, OrderUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderUpdateMutation, OrderUpdateMutationVariables>(OrderUpdateDocument, options);
      }
export type OrderUpdateMutationHookResult = ReturnType<typeof useOrderUpdateMutation>;
export type OrderUpdateMutationResult = Apollo.MutationResult<OrderUpdateMutation>;
export type OrderUpdateMutationOptions = Apollo.BaseMutationOptions<OrderUpdateMutation, OrderUpdateMutationVariables>;
export const GetSquareDevicesDocument = gql`
    query GetSquareDevices {
  squareDevices {
    ...SquareDevice
  }
}
    ${SquareDeviceFragmentDoc}`;

/**
 * __useGetSquareDevicesQuery__
 *
 * To run a query within a React component, call `useGetSquareDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSquareDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSquareDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSquareDevicesQuery(baseOptions?: Apollo.QueryHookOptions<GetSquareDevicesQuery, GetSquareDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSquareDevicesQuery, GetSquareDevicesQueryVariables>(GetSquareDevicesDocument, options);
      }
export function useGetSquareDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSquareDevicesQuery, GetSquareDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSquareDevicesQuery, GetSquareDevicesQueryVariables>(GetSquareDevicesDocument, options);
        }
export type GetSquareDevicesQueryHookResult = ReturnType<typeof useGetSquareDevicesQuery>;
export type GetSquareDevicesLazyQueryHookResult = ReturnType<typeof useGetSquareDevicesLazyQuery>;
export type GetSquareDevicesQueryResult = Apollo.QueryResult<GetSquareDevicesQuery, GetSquareDevicesQueryVariables>;
export const CafeOrderCreateDocument = gql`
    mutation CafeOrderCreate($request: CreateCafeOrderRequest!) {
  cafeOrderCreate(request: $request) {
    ...CafeOrder
  }
}
    ${CafeOrderFragmentDoc}`;
export type CafeOrderCreateMutationFn = Apollo.MutationFunction<CafeOrderCreateMutation, CafeOrderCreateMutationVariables>;

/**
 * __useCafeOrderCreateMutation__
 *
 * To run a mutation, you first call `useCafeOrderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCafeOrderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cafeOrderCreateMutation, { data, loading, error }] = useCafeOrderCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCafeOrderCreateMutation(baseOptions?: Apollo.MutationHookOptions<CafeOrderCreateMutation, CafeOrderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CafeOrderCreateMutation, CafeOrderCreateMutationVariables>(CafeOrderCreateDocument, options);
      }
export type CafeOrderCreateMutationHookResult = ReturnType<typeof useCafeOrderCreateMutation>;
export type CafeOrderCreateMutationResult = Apollo.MutationResult<CafeOrderCreateMutation>;
export type CafeOrderCreateMutationOptions = Apollo.BaseMutationOptions<CafeOrderCreateMutation, CafeOrderCreateMutationVariables>;
export const CafeOrderDocument = gql`
    query CafeOrder($id: String!) {
  cafeOrder(id: $id) {
    ...CafeOrder
  }
}
    ${CafeOrderFragmentDoc}`;

/**
 * __useCafeOrderQuery__
 *
 * To run a query within a React component, call `useCafeOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCafeOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCafeOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCafeOrderQuery(baseOptions: Apollo.QueryHookOptions<CafeOrderQuery, CafeOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CafeOrderQuery, CafeOrderQueryVariables>(CafeOrderDocument, options);
      }
export function useCafeOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CafeOrderQuery, CafeOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CafeOrderQuery, CafeOrderQueryVariables>(CafeOrderDocument, options);
        }
export type CafeOrderQueryHookResult = ReturnType<typeof useCafeOrderQuery>;
export type CafeOrderLazyQueryHookResult = ReturnType<typeof useCafeOrderLazyQuery>;
export type CafeOrderQueryResult = Apollo.QueryResult<CafeOrderQuery, CafeOrderQueryVariables>;
export const CrreateShippingInformationDocument = gql`
    mutation CrreateShippingInformation($request: CreateShippingInformationRequest!) {
  shippingInformationCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CrreateShippingInformationMutationFn = Apollo.MutationFunction<CrreateShippingInformationMutation, CrreateShippingInformationMutationVariables>;

/**
 * __useCrreateShippingInformationMutation__
 *
 * To run a mutation, you first call `useCrreateShippingInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrreateShippingInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crreateShippingInformationMutation, { data, loading, error }] = useCrreateShippingInformationMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCrreateShippingInformationMutation(baseOptions?: Apollo.MutationHookOptions<CrreateShippingInformationMutation, CrreateShippingInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrreateShippingInformationMutation, CrreateShippingInformationMutationVariables>(CrreateShippingInformationDocument, options);
      }
export type CrreateShippingInformationMutationHookResult = ReturnType<typeof useCrreateShippingInformationMutation>;
export type CrreateShippingInformationMutationResult = Apollo.MutationResult<CrreateShippingInformationMutation>;
export type CrreateShippingInformationMutationOptions = Apollo.BaseMutationOptions<CrreateShippingInformationMutation, CrreateShippingInformationMutationVariables>;
export const GetBannerDocument = gql`
    query GetBanner($id: Int!) {
  banner(id: $id) {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

/**
 * __useGetBannerQuery__
 *
 * To run a query within a React component, call `useGetBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBannerQuery(baseOptions: Apollo.QueryHookOptions<GetBannerQuery, GetBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBannerQuery, GetBannerQueryVariables>(GetBannerDocument, options);
      }
export function useGetBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannerQuery, GetBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBannerQuery, GetBannerQueryVariables>(GetBannerDocument, options);
        }
export type GetBannerQueryHookResult = ReturnType<typeof useGetBannerQuery>;
export type GetBannerLazyQueryHookResult = ReturnType<typeof useGetBannerLazyQuery>;
export type GetBannerQueryResult = Apollo.QueryResult<GetBannerQuery, GetBannerQueryVariables>;
export const GetBannersDocument = gql`
    query GetBanners($request: GetBannersRequest!) {
  banners(request: $request) {
    dataSource {
      ...BannerItem
    }
    pages {
      ...Page
    }
  }
}
    ${BannerItemFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useGetBannersQuery__
 *
 * To run a query within a React component, call `useGetBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannersQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetBannersQuery(baseOptions: Apollo.QueryHookOptions<GetBannersQuery, GetBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBannersQuery, GetBannersQueryVariables>(GetBannersDocument, options);
      }
export function useGetBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBannersQuery, GetBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBannersQuery, GetBannersQueryVariables>(GetBannersDocument, options);
        }
export type GetBannersQueryHookResult = ReturnType<typeof useGetBannersQuery>;
export type GetBannersLazyQueryHookResult = ReturnType<typeof useGetBannersLazyQuery>;
export type GetBannersQueryResult = Apollo.QueryResult<GetBannersQuery, GetBannersQueryVariables>;
export const CreateBannerImageUrlDocument = gql`
    mutation CreateBannerImageUrl($request: CreateUrlRequest!) {
  bannerFileUrlCreate(request: $request) {
    ...UrlReply
  }
}
    ${UrlReplyFragmentDoc}`;
export type CreateBannerImageUrlMutationFn = Apollo.MutationFunction<CreateBannerImageUrlMutation, CreateBannerImageUrlMutationVariables>;

/**
 * __useCreateBannerImageUrlMutation__
 *
 * To run a mutation, you first call `useCreateBannerImageUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBannerImageUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBannerImageUrlMutation, { data, loading, error }] = useCreateBannerImageUrlMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateBannerImageUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateBannerImageUrlMutation, CreateBannerImageUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBannerImageUrlMutation, CreateBannerImageUrlMutationVariables>(CreateBannerImageUrlDocument, options);
      }
export type CreateBannerImageUrlMutationHookResult = ReturnType<typeof useCreateBannerImageUrlMutation>;
export type CreateBannerImageUrlMutationResult = Apollo.MutationResult<CreateBannerImageUrlMutation>;
export type CreateBannerImageUrlMutationOptions = Apollo.BaseMutationOptions<CreateBannerImageUrlMutation, CreateBannerImageUrlMutationVariables>;
export const CreateBannerDocument = gql`
    mutation CreateBanner($request: CreateBannerRequest!) {
  bannerCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateBannerMutationFn = Apollo.MutationFunction<CreateBannerMutation, CreateBannerMutationVariables>;

/**
 * __useCreateBannerMutation__
 *
 * To run a mutation, you first call `useCreateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBannerMutation, { data, loading, error }] = useCreateBannerMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBannerMutation, CreateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBannerMutation, CreateBannerMutationVariables>(CreateBannerDocument, options);
      }
export type CreateBannerMutationHookResult = ReturnType<typeof useCreateBannerMutation>;
export type CreateBannerMutationResult = Apollo.MutationResult<CreateBannerMutation>;
export type CreateBannerMutationOptions = Apollo.BaseMutationOptions<CreateBannerMutation, CreateBannerMutationVariables>;
export const UpdateBannerDocument = gql`
    mutation UpdateBanner($request: UpdateBannerRequest!) {
  bannerUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateBannerMutationFn = Apollo.MutationFunction<UpdateBannerMutation, UpdateBannerMutationVariables>;

/**
 * __useUpdateBannerMutation__
 *
 * To run a mutation, you first call `useUpdateBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBannerMutation, { data, loading, error }] = useUpdateBannerMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBannerMutation, UpdateBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(UpdateBannerDocument, options);
      }
export type UpdateBannerMutationHookResult = ReturnType<typeof useUpdateBannerMutation>;
export type UpdateBannerMutationResult = Apollo.MutationResult<UpdateBannerMutation>;
export type UpdateBannerMutationOptions = Apollo.BaseMutationOptions<UpdateBannerMutation, UpdateBannerMutationVariables>;
export const DeleteBannerDocument = gql`
    mutation DeleteBanner($id: Int!) {
  bannerDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteBannerMutationFn = Apollo.MutationFunction<DeleteBannerMutation, DeleteBannerMutationVariables>;

/**
 * __useDeleteBannerMutation__
 *
 * To run a mutation, you first call `useDeleteBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBannerMutation, { data, loading, error }] = useDeleteBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBannerMutation, DeleteBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBannerMutation, DeleteBannerMutationVariables>(DeleteBannerDocument, options);
      }
export type DeleteBannerMutationHookResult = ReturnType<typeof useDeleteBannerMutation>;
export type DeleteBannerMutationResult = Apollo.MutationResult<DeleteBannerMutation>;
export type DeleteBannerMutationOptions = Apollo.BaseMutationOptions<DeleteBannerMutation, DeleteBannerMutationVariables>;
export const BlockStocksDocument = gql`
    query BlockStocks($request: GetDisplaySpaceStocksRequest!) {
  displaySpaceStocks(request: $request) {
    dataSource {
      ...BlockStock
    }
  }
}
    ${BlockStockFragmentDoc}`;

/**
 * __useBlockStocksQuery__
 *
 * To run a query within a React component, call `useBlockStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockStocksQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBlockStocksQuery(baseOptions: Apollo.QueryHookOptions<BlockStocksQuery, BlockStocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockStocksQuery, BlockStocksQueryVariables>(BlockStocksDocument, options);
      }
export function useBlockStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockStocksQuery, BlockStocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockStocksQuery, BlockStocksQueryVariables>(BlockStocksDocument, options);
        }
export type BlockStocksQueryHookResult = ReturnType<typeof useBlockStocksQuery>;
export type BlockStocksLazyQueryHookResult = ReturnType<typeof useBlockStocksLazyQuery>;
export type BlockStocksQueryResult = Apollo.QueryResult<BlockStocksQuery, BlockStocksQueryVariables>;
export const BlockProductDocument = gql`
    query BlockProduct($id: String!) {
  product(id: $id) {
    id
    name
    overview
    joinMemberId
    images {
      path
      fileType
    }
    saleType
  }
}
    `;

/**
 * __useBlockProductQuery__
 *
 * To run a query within a React component, call `useBlockProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockProductQuery(baseOptions: Apollo.QueryHookOptions<BlockProductQuery, BlockProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockProductQuery, BlockProductQueryVariables>(BlockProductDocument, options);
      }
export function useBlockProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockProductQuery, BlockProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockProductQuery, BlockProductQueryVariables>(BlockProductDocument, options);
        }
export type BlockProductQueryHookResult = ReturnType<typeof useBlockProductQuery>;
export type BlockProductLazyQueryHookResult = ReturnType<typeof useBlockProductLazyQuery>;
export type BlockProductQueryResult = Apollo.QueryResult<BlockProductQuery, BlockProductQueryVariables>;
export const BlockOnlyShopProductDocument = gql`
    query BlockOnlyShopProduct($id: String!) {
  tenpoOnlyProduct(id: $id) {
    id
    name
    externalUrl
    saleType
    memo
  }
}
    `;

/**
 * __useBlockOnlyShopProductQuery__
 *
 * To run a query within a React component, call `useBlockOnlyShopProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockOnlyShopProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockOnlyShopProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockOnlyShopProductQuery(baseOptions: Apollo.QueryHookOptions<BlockOnlyShopProductQuery, BlockOnlyShopProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockOnlyShopProductQuery, BlockOnlyShopProductQueryVariables>(BlockOnlyShopProductDocument, options);
      }
export function useBlockOnlyShopProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockOnlyShopProductQuery, BlockOnlyShopProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockOnlyShopProductQuery, BlockOnlyShopProductQueryVariables>(BlockOnlyShopProductDocument, options);
        }
export type BlockOnlyShopProductQueryHookResult = ReturnType<typeof useBlockOnlyShopProductQuery>;
export type BlockOnlyShopProductLazyQueryHookResult = ReturnType<typeof useBlockOnlyShopProductLazyQuery>;
export type BlockOnlyShopProductQueryResult = Apollo.QueryResult<BlockOnlyShopProductQuery, BlockOnlyShopProductQueryVariables>;
export const UpdateBlockProductDocument = gql`
    mutation UpdateBlockProduct($request: UpdateDisplayProductRequest!) {
  displayProductUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateBlockProductMutationFn = Apollo.MutationFunction<UpdateBlockProductMutation, UpdateBlockProductMutationVariables>;

/**
 * __useUpdateBlockProductMutation__
 *
 * To run a mutation, you first call `useUpdateBlockProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockProductMutation, { data, loading, error }] = useUpdateBlockProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateBlockProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlockProductMutation, UpdateBlockProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlockProductMutation, UpdateBlockProductMutationVariables>(UpdateBlockProductDocument, options);
      }
export type UpdateBlockProductMutationHookResult = ReturnType<typeof useUpdateBlockProductMutation>;
export type UpdateBlockProductMutationResult = Apollo.MutationResult<UpdateBlockProductMutation>;
export type UpdateBlockProductMutationOptions = Apollo.BaseMutationOptions<UpdateBlockProductMutation, UpdateBlockProductMutationVariables>;
export const BlockScheduleTableDocument = gql`
    query BlockScheduleTable($request: DisplaySpacesRequest!) {
  displaySpaces(request: $request) {
    dataSource {
      ...DisplaySpaceScheduleTable
    }
  }
}
    ${DisplaySpaceScheduleTableFragmentDoc}`;

/**
 * __useBlockScheduleTableQuery__
 *
 * To run a query within a React component, call `useBlockScheduleTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockScheduleTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockScheduleTableQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBlockScheduleTableQuery(baseOptions: Apollo.QueryHookOptions<BlockScheduleTableQuery, BlockScheduleTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockScheduleTableQuery, BlockScheduleTableQueryVariables>(BlockScheduleTableDocument, options);
      }
export function useBlockScheduleTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockScheduleTableQuery, BlockScheduleTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockScheduleTableQuery, BlockScheduleTableQueryVariables>(BlockScheduleTableDocument, options);
        }
export type BlockScheduleTableQueryHookResult = ReturnType<typeof useBlockScheduleTableQuery>;
export type BlockScheduleTableLazyQueryHookResult = ReturnType<typeof useBlockScheduleTableLazyQuery>;
export type BlockScheduleTableQueryResult = Apollo.QueryResult<BlockScheduleTableQuery, BlockScheduleTableQueryVariables>;
export const BlockDocument = gql`
    query Block($id: Int!) {
  displaySpace(id: $id) {
    ...Block
  }
}
    ${BlockFragmentDoc}`;

/**
 * __useBlockQuery__
 *
 * To run a query within a React component, call `useBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockQuery(baseOptions: Apollo.QueryHookOptions<BlockQuery, BlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockQuery, BlockQueryVariables>(BlockDocument, options);
      }
export function useBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockQuery, BlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockQuery, BlockQueryVariables>(BlockDocument, options);
        }
export type BlockQueryHookResult = ReturnType<typeof useBlockQuery>;
export type BlockLazyQueryHookResult = ReturnType<typeof useBlockLazyQuery>;
export type BlockQueryResult = Apollo.QueryResult<BlockQuery, BlockQueryVariables>;
export const BlockUpdateDocument = gql`
    mutation BlockUpdate($request: UpdateDisplaySpaceRequest!) {
  displaySpaceUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type BlockUpdateMutationFn = Apollo.MutationFunction<BlockUpdateMutation, BlockUpdateMutationVariables>;

/**
 * __useBlockUpdateMutation__
 *
 * To run a mutation, you first call `useBlockUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUpdateMutation, { data, loading, error }] = useBlockUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBlockUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BlockUpdateMutation, BlockUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockUpdateMutation, BlockUpdateMutationVariables>(BlockUpdateDocument, options);
      }
export type BlockUpdateMutationHookResult = ReturnType<typeof useBlockUpdateMutation>;
export type BlockUpdateMutationResult = Apollo.MutationResult<BlockUpdateMutation>;
export type BlockUpdateMutationOptions = Apollo.BaseMutationOptions<BlockUpdateMutation, BlockUpdateMutationVariables>;
export const BlockDeleteDocument = gql`
    mutation BlockDelete($id: Int!) {
  displaySpaceDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type BlockDeleteMutationFn = Apollo.MutationFunction<BlockDeleteMutation, BlockDeleteMutationVariables>;

/**
 * __useBlockDeleteMutation__
 *
 * To run a mutation, you first call `useBlockDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockDeleteMutation, { data, loading, error }] = useBlockDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BlockDeleteMutation, BlockDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockDeleteMutation, BlockDeleteMutationVariables>(BlockDeleteDocument, options);
      }
export type BlockDeleteMutationHookResult = ReturnType<typeof useBlockDeleteMutation>;
export type BlockDeleteMutationResult = Apollo.MutationResult<BlockDeleteMutation>;
export type BlockDeleteMutationOptions = Apollo.BaseMutationOptions<BlockDeleteMutation, BlockDeleteMutationVariables>;
export const BlockListDocument = gql`
    query BlockList($request: DisplaySpacesRequest!, $tenantId: Int) {
  displaySpaces(request: $request) {
    dataSource {
      ...DisplaySpaceColumn
    }
    pages {
      ...Page
    }
  }
  displaySpaceImage(tenantId: $tenantId) {
    images {
      path
    }
  }
  tenantDeliveryAddress(tenantId: $tenantId) {
    ...BlockDeliveryAddress
  }
}
    ${DisplaySpaceColumnFragmentDoc}
${PageFragmentDoc}
${BlockDeliveryAddressFragmentDoc}`;

/**
 * __useBlockListQuery__
 *
 * To run a query within a React component, call `useBlockListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockListQuery({
 *   variables: {
 *      request: // value for 'request'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useBlockListQuery(baseOptions: Apollo.QueryHookOptions<BlockListQuery, BlockListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockListQuery, BlockListQueryVariables>(BlockListDocument, options);
      }
export function useBlockListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockListQuery, BlockListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockListQuery, BlockListQueryVariables>(BlockListDocument, options);
        }
export type BlockListQueryHookResult = ReturnType<typeof useBlockListQuery>;
export type BlockListLazyQueryHookResult = ReturnType<typeof useBlockListLazyQuery>;
export type BlockListQueryResult = Apollo.QueryResult<BlockListQuery, BlockListQueryVariables>;
export const GetDisplayApplyProductsDocument = gql`
    query GetDisplayApplyProducts($request: DisplayProductsRequest!) {
  displayProducts(request: $request) {
    dataSource {
      ...DisplayRegisteredProduct
    }
    pages {
      ...Page
    }
  }
}
    ${DisplayRegisteredProductFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useGetDisplayApplyProductsQuery__
 *
 * To run a query within a React component, call `useGetDisplayApplyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayApplyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayApplyProductsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetDisplayApplyProductsQuery(baseOptions: Apollo.QueryHookOptions<GetDisplayApplyProductsQuery, GetDisplayApplyProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisplayApplyProductsQuery, GetDisplayApplyProductsQueryVariables>(GetDisplayApplyProductsDocument, options);
      }
export function useGetDisplayApplyProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayApplyProductsQuery, GetDisplayApplyProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisplayApplyProductsQuery, GetDisplayApplyProductsQueryVariables>(GetDisplayApplyProductsDocument, options);
        }
export type GetDisplayApplyProductsQueryHookResult = ReturnType<typeof useGetDisplayApplyProductsQuery>;
export type GetDisplayApplyProductsLazyQueryHookResult = ReturnType<typeof useGetDisplayApplyProductsLazyQuery>;
export type GetDisplayApplyProductsQueryResult = Apollo.QueryResult<GetDisplayApplyProductsQuery, GetDisplayApplyProductsQueryVariables>;
export const GetDisplayProductsDocument = gql`
    query GetDisplayProducts($request: SearchProductRequest!) {
  productSearch(request: $request) {
    dataSource {
      ...DisplayProduct
    }
    pages {
      ...Page
    }
  }
}
    ${DisplayProductFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useGetDisplayProductsQuery__
 *
 * To run a query within a React component, call `useGetDisplayProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayProductsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetDisplayProductsQuery(baseOptions: Apollo.QueryHookOptions<GetDisplayProductsQuery, GetDisplayProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisplayProductsQuery, GetDisplayProductsQueryVariables>(GetDisplayProductsDocument, options);
      }
export function useGetDisplayProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayProductsQuery, GetDisplayProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisplayProductsQuery, GetDisplayProductsQueryVariables>(GetDisplayProductsDocument, options);
        }
export type GetDisplayProductsQueryHookResult = ReturnType<typeof useGetDisplayProductsQuery>;
export type GetDisplayProductsLazyQueryHookResult = ReturnType<typeof useGetDisplayProductsLazyQuery>;
export type GetDisplayProductsQueryResult = Apollo.QueryResult<GetDisplayProductsQuery, GetDisplayProductsQueryVariables>;
export const GetDisplayProductDocument = gql`
    query GetDisplayProduct($id: Int!) {
  displayProduct(id: $id) {
    ...DisplayProductDetail
  }
}
    ${DisplayProductDetailFragmentDoc}`;

/**
 * __useGetDisplayProductQuery__
 *
 * To run a query within a React component, call `useGetDisplayProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDisplayProductQuery(baseOptions: Apollo.QueryHookOptions<GetDisplayProductQuery, GetDisplayProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisplayProductQuery, GetDisplayProductQueryVariables>(GetDisplayProductDocument, options);
      }
export function useGetDisplayProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayProductQuery, GetDisplayProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisplayProductQuery, GetDisplayProductQueryVariables>(GetDisplayProductDocument, options);
        }
export type GetDisplayProductQueryHookResult = ReturnType<typeof useGetDisplayProductQuery>;
export type GetDisplayProductLazyQueryHookResult = ReturnType<typeof useGetDisplayProductLazyQuery>;
export type GetDisplayProductQueryResult = Apollo.QueryResult<GetDisplayProductQuery, GetDisplayProductQueryVariables>;
export const GetDisplaySpacesDocument = gql`
    query GetDisplaySpaces($request: DisplaySpacesRequest!) {
  displaySpaces(request: $request) {
    dataSource {
      ...DisplaySpaceItem
    }
    pages {
      ...Page
    }
  }
}
    ${DisplaySpaceItemFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useGetDisplaySpacesQuery__
 *
 * To run a query within a React component, call `useGetDisplaySpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplaySpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplaySpacesQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetDisplaySpacesQuery(baseOptions: Apollo.QueryHookOptions<GetDisplaySpacesQuery, GetDisplaySpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisplaySpacesQuery, GetDisplaySpacesQueryVariables>(GetDisplaySpacesDocument, options);
      }
export function useGetDisplaySpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisplaySpacesQuery, GetDisplaySpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisplaySpacesQuery, GetDisplaySpacesQueryVariables>(GetDisplaySpacesDocument, options);
        }
export type GetDisplaySpacesQueryHookResult = ReturnType<typeof useGetDisplaySpacesQuery>;
export type GetDisplaySpacesLazyQueryHookResult = ReturnType<typeof useGetDisplaySpacesLazyQuery>;
export type GetDisplaySpacesQueryResult = Apollo.QueryResult<GetDisplaySpacesQuery, GetDisplaySpacesQueryVariables>;
export const CreateDisplaySpaceOnProductDocument = gql`
    mutation CreateDisplaySpaceOnProduct($request: CreateDisplaySpaceOnProductRequest!) {
  displaySpaceOnProductCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateDisplaySpaceOnProductMutationFn = Apollo.MutationFunction<CreateDisplaySpaceOnProductMutation, CreateDisplaySpaceOnProductMutationVariables>;

/**
 * __useCreateDisplaySpaceOnProductMutation__
 *
 * To run a mutation, you first call `useCreateDisplaySpaceOnProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisplaySpaceOnProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisplaySpaceOnProductMutation, { data, loading, error }] = useCreateDisplaySpaceOnProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateDisplaySpaceOnProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateDisplaySpaceOnProductMutation, CreateDisplaySpaceOnProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDisplaySpaceOnProductMutation, CreateDisplaySpaceOnProductMutationVariables>(CreateDisplaySpaceOnProductDocument, options);
      }
export type CreateDisplaySpaceOnProductMutationHookResult = ReturnType<typeof useCreateDisplaySpaceOnProductMutation>;
export type CreateDisplaySpaceOnProductMutationResult = Apollo.MutationResult<CreateDisplaySpaceOnProductMutation>;
export type CreateDisplaySpaceOnProductMutationOptions = Apollo.BaseMutationOptions<CreateDisplaySpaceOnProductMutation, CreateDisplaySpaceOnProductMutationVariables>;
export const DeleteDisplaySpaceOnProductDocument = gql`
    mutation DeleteDisplaySpaceOnProduct($id: Int!) {
  displaySpaceOnProductDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteDisplaySpaceOnProductMutationFn = Apollo.MutationFunction<DeleteDisplaySpaceOnProductMutation, DeleteDisplaySpaceOnProductMutationVariables>;

/**
 * __useDeleteDisplaySpaceOnProductMutation__
 *
 * To run a mutation, you first call `useDeleteDisplaySpaceOnProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisplaySpaceOnProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisplaySpaceOnProductMutation, { data, loading, error }] = useDeleteDisplaySpaceOnProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDisplaySpaceOnProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDisplaySpaceOnProductMutation, DeleteDisplaySpaceOnProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDisplaySpaceOnProductMutation, DeleteDisplaySpaceOnProductMutationVariables>(DeleteDisplaySpaceOnProductDocument, options);
      }
export type DeleteDisplaySpaceOnProductMutationHookResult = ReturnType<typeof useDeleteDisplaySpaceOnProductMutation>;
export type DeleteDisplaySpaceOnProductMutationResult = Apollo.MutationResult<DeleteDisplaySpaceOnProductMutation>;
export type DeleteDisplaySpaceOnProductMutationOptions = Apollo.BaseMutationOptions<DeleteDisplaySpaceOnProductMutation, DeleteDisplaySpaceOnProductMutationVariables>;
export const CreateDisplayProductDocument = gql`
    mutation CreateDisplayProduct($request: CreateDisplayProductRequest!) {
  displayProductCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateDisplayProductMutationFn = Apollo.MutationFunction<CreateDisplayProductMutation, CreateDisplayProductMutationVariables>;

/**
 * __useCreateDisplayProductMutation__
 *
 * To run a mutation, you first call `useCreateDisplayProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisplayProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisplayProductMutation, { data, loading, error }] = useCreateDisplayProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateDisplayProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateDisplayProductMutation, CreateDisplayProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDisplayProductMutation, CreateDisplayProductMutationVariables>(CreateDisplayProductDocument, options);
      }
export type CreateDisplayProductMutationHookResult = ReturnType<typeof useCreateDisplayProductMutation>;
export type CreateDisplayProductMutationResult = Apollo.MutationResult<CreateDisplayProductMutation>;
export type CreateDisplayProductMutationOptions = Apollo.BaseMutationOptions<CreateDisplayProductMutation, CreateDisplayProductMutationVariables>;
export const DeleteDisplayProductDocument = gql`
    mutation DeleteDisplayProduct($id: Int!) {
  displayProductDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteDisplayProductMutationFn = Apollo.MutationFunction<DeleteDisplayProductMutation, DeleteDisplayProductMutationVariables>;

/**
 * __useDeleteDisplayProductMutation__
 *
 * To run a mutation, you first call `useDeleteDisplayProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDisplayProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDisplayProductMutation, { data, loading, error }] = useDeleteDisplayProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDisplayProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDisplayProductMutation, DeleteDisplayProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDisplayProductMutation, DeleteDisplayProductMutationVariables>(DeleteDisplayProductDocument, options);
      }
export type DeleteDisplayProductMutationHookResult = ReturnType<typeof useDeleteDisplayProductMutation>;
export type DeleteDisplayProductMutationResult = Apollo.MutationResult<DeleteDisplayProductMutation>;
export type DeleteDisplayProductMutationOptions = Apollo.BaseMutationOptions<DeleteDisplayProductMutation, DeleteDisplayProductMutationVariables>;
export const CreateDisplaySpaceDocument = gql`
    mutation CreateDisplaySpace($request: CreateDisplaySpaceRequest!) {
  displaySpaceCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateDisplaySpaceMutationFn = Apollo.MutationFunction<CreateDisplaySpaceMutation, CreateDisplaySpaceMutationVariables>;

/**
 * __useCreateDisplaySpaceMutation__
 *
 * To run a mutation, you first call `useCreateDisplaySpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDisplaySpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDisplaySpaceMutation, { data, loading, error }] = useCreateDisplaySpaceMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateDisplaySpaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDisplaySpaceMutation, CreateDisplaySpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDisplaySpaceMutation, CreateDisplaySpaceMutationVariables>(CreateDisplaySpaceDocument, options);
      }
export type CreateDisplaySpaceMutationHookResult = ReturnType<typeof useCreateDisplaySpaceMutation>;
export type CreateDisplaySpaceMutationResult = Apollo.MutationResult<CreateDisplaySpaceMutation>;
export type CreateDisplaySpaceMutationOptions = Apollo.BaseMutationOptions<CreateDisplaySpaceMutation, CreateDisplaySpaceMutationVariables>;
export const BlockScheduleDocument = gql`
    query BlockSchedule($request: DisplaySpacesRequest!) {
  displaySpaces(request: $request) {
    dataSource {
      ...DisplaySpaceSchedule
    }
    pages {
      ...Page
    }
  }
}
    ${DisplaySpaceScheduleFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useBlockScheduleQuery__
 *
 * To run a query within a React component, call `useBlockScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockScheduleQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBlockScheduleQuery(baseOptions: Apollo.QueryHookOptions<BlockScheduleQuery, BlockScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockScheduleQuery, BlockScheduleQueryVariables>(BlockScheduleDocument, options);
      }
export function useBlockScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockScheduleQuery, BlockScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockScheduleQuery, BlockScheduleQueryVariables>(BlockScheduleDocument, options);
        }
export type BlockScheduleQueryHookResult = ReturnType<typeof useBlockScheduleQuery>;
export type BlockScheduleLazyQueryHookResult = ReturnType<typeof useBlockScheduleLazyQuery>;
export type BlockScheduleQueryResult = Apollo.QueryResult<BlockScheduleQuery, BlockScheduleQueryVariables>;
export const BlockImagesDocument = gql`
    query BlockImages($tenantId: Int) {
  displaySpaceImage(tenantId: $tenantId) {
    images {
      ...BlockImage
    }
  }
}
    ${BlockImageFragmentDoc}`;

/**
 * __useBlockImagesQuery__
 *
 * To run a query within a React component, call `useBlockImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockImagesQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useBlockImagesQuery(baseOptions?: Apollo.QueryHookOptions<BlockImagesQuery, BlockImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockImagesQuery, BlockImagesQueryVariables>(BlockImagesDocument, options);
      }
export function useBlockImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockImagesQuery, BlockImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockImagesQuery, BlockImagesQueryVariables>(BlockImagesDocument, options);
        }
export type BlockImagesQueryHookResult = ReturnType<typeof useBlockImagesQuery>;
export type BlockImagesLazyQueryHookResult = ReturnType<typeof useBlockImagesLazyQuery>;
export type BlockImagesQueryResult = Apollo.QueryResult<BlockImagesQuery, BlockImagesQueryVariables>;
export const DeleteBlockImageDocument = gql`
    mutation DeleteBlockImage($request: DeleteDisplayImage!) {
  displaySpaceImageDelete(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteBlockImageMutationFn = Apollo.MutationFunction<DeleteBlockImageMutation, DeleteBlockImageMutationVariables>;

/**
 * __useDeleteBlockImageMutation__
 *
 * To run a mutation, you first call `useDeleteBlockImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockImageMutation, { data, loading, error }] = useDeleteBlockImageMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteBlockImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockImageMutation, DeleteBlockImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlockImageMutation, DeleteBlockImageMutationVariables>(DeleteBlockImageDocument, options);
      }
export type DeleteBlockImageMutationHookResult = ReturnType<typeof useDeleteBlockImageMutation>;
export type DeleteBlockImageMutationResult = Apollo.MutationResult<DeleteBlockImageMutation>;
export type DeleteBlockImageMutationOptions = Apollo.BaseMutationOptions<DeleteBlockImageMutation, DeleteBlockImageMutationVariables>;
export const CreateBlockImagesUrlDocument = gql`
    mutation CreateBlockImagesUrl($request: [CreateUrlRequest!]!) {
  displaySpaceImageFileUrlsCreate(request: $request) {
    ...UrlReply
  }
}
    ${UrlReplyFragmentDoc}`;
export type CreateBlockImagesUrlMutationFn = Apollo.MutationFunction<CreateBlockImagesUrlMutation, CreateBlockImagesUrlMutationVariables>;

/**
 * __useCreateBlockImagesUrlMutation__
 *
 * To run a mutation, you first call `useCreateBlockImagesUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockImagesUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockImagesUrlMutation, { data, loading, error }] = useCreateBlockImagesUrlMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateBlockImagesUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlockImagesUrlMutation, CreateBlockImagesUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlockImagesUrlMutation, CreateBlockImagesUrlMutationVariables>(CreateBlockImagesUrlDocument, options);
      }
export type CreateBlockImagesUrlMutationHookResult = ReturnType<typeof useCreateBlockImagesUrlMutation>;
export type CreateBlockImagesUrlMutationResult = Apollo.MutationResult<CreateBlockImagesUrlMutation>;
export type CreateBlockImagesUrlMutationOptions = Apollo.BaseMutationOptions<CreateBlockImagesUrlMutation, CreateBlockImagesUrlMutationVariables>;
export const CreateBlockImagesDocument = gql`
    mutation CreateBlockImages($request: [CreateDisplayImage!]!) {
  displaySpaceImageCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateBlockImagesMutationFn = Apollo.MutationFunction<CreateBlockImagesMutation, CreateBlockImagesMutationVariables>;

/**
 * __useCreateBlockImagesMutation__
 *
 * To run a mutation, you first call `useCreateBlockImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockImagesMutation, { data, loading, error }] = useCreateBlockImagesMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateBlockImagesMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlockImagesMutation, CreateBlockImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlockImagesMutation, CreateBlockImagesMutationVariables>(CreateBlockImagesDocument, options);
      }
export type CreateBlockImagesMutationHookResult = ReturnType<typeof useCreateBlockImagesMutation>;
export type CreateBlockImagesMutationResult = Apollo.MutationResult<CreateBlockImagesMutation>;
export type CreateBlockImagesMutationOptions = Apollo.BaseMutationOptions<CreateBlockImagesMutation, CreateBlockImagesMutationVariables>;
export const BlockAttributesDocument = gql`
    query BlockAttributes($request: GetDisplaySpaceAttributesRequest!) {
  displaySpaceAttributes(request: $request) {
    dataSource {
      ...BlockAttribute
    }
    pages {
      ...Page
    }
  }
}
    ${BlockAttributeFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useBlockAttributesQuery__
 *
 * To run a query within a React component, call `useBlockAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockAttributesQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBlockAttributesQuery(baseOptions: Apollo.QueryHookOptions<BlockAttributesQuery, BlockAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockAttributesQuery, BlockAttributesQueryVariables>(BlockAttributesDocument, options);
      }
export function useBlockAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockAttributesQuery, BlockAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockAttributesQuery, BlockAttributesQueryVariables>(BlockAttributesDocument, options);
        }
export type BlockAttributesQueryHookResult = ReturnType<typeof useBlockAttributesQuery>;
export type BlockAttributesLazyQueryHookResult = ReturnType<typeof useBlockAttributesLazyQuery>;
export type BlockAttributesQueryResult = Apollo.QueryResult<BlockAttributesQuery, BlockAttributesQueryVariables>;
export const CreateBlockAttributeDocument = gql`
    mutation CreateBlockAttribute($request: CreateDisplaySpaceAttributeRequest) {
  displaySpaceAttributeCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateBlockAttributeMutationFn = Apollo.MutationFunction<CreateBlockAttributeMutation, CreateBlockAttributeMutationVariables>;

/**
 * __useCreateBlockAttributeMutation__
 *
 * To run a mutation, you first call `useCreateBlockAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockAttributeMutation, { data, loading, error }] = useCreateBlockAttributeMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateBlockAttributeMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlockAttributeMutation, CreateBlockAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlockAttributeMutation, CreateBlockAttributeMutationVariables>(CreateBlockAttributeDocument, options);
      }
export type CreateBlockAttributeMutationHookResult = ReturnType<typeof useCreateBlockAttributeMutation>;
export type CreateBlockAttributeMutationResult = Apollo.MutationResult<CreateBlockAttributeMutation>;
export type CreateBlockAttributeMutationOptions = Apollo.BaseMutationOptions<CreateBlockAttributeMutation, CreateBlockAttributeMutationVariables>;
export const DeleteBlockAttributeDocument = gql`
    mutation DeleteBlockAttribute($id: Int!) {
  displaySpaceAttributeDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteBlockAttributeMutationFn = Apollo.MutationFunction<DeleteBlockAttributeMutation, DeleteBlockAttributeMutationVariables>;

/**
 * __useDeleteBlockAttributeMutation__
 *
 * To run a mutation, you first call `useDeleteBlockAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockAttributeMutation, { data, loading, error }] = useDeleteBlockAttributeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlockAttributeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockAttributeMutation, DeleteBlockAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlockAttributeMutation, DeleteBlockAttributeMutationVariables>(DeleteBlockAttributeDocument, options);
      }
export type DeleteBlockAttributeMutationHookResult = ReturnType<typeof useDeleteBlockAttributeMutation>;
export type DeleteBlockAttributeMutationResult = Apollo.MutationResult<DeleteBlockAttributeMutation>;
export type DeleteBlockAttributeMutationOptions = Apollo.BaseMutationOptions<DeleteBlockAttributeMutation, DeleteBlockAttributeMutationVariables>;
export const BlockDeliveryAddressDocument = gql`
    query BlockDeliveryAddress($tenantId: Int) {
  tenantDeliveryAddress(tenantId: $tenantId) {
    ...BlockDeliveryAddress
  }
}
    ${BlockDeliveryAddressFragmentDoc}`;

/**
 * __useBlockDeliveryAddressQuery__
 *
 * To run a query within a React component, call `useBlockDeliveryAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockDeliveryAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockDeliveryAddressQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useBlockDeliveryAddressQuery(baseOptions?: Apollo.QueryHookOptions<BlockDeliveryAddressQuery, BlockDeliveryAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockDeliveryAddressQuery, BlockDeliveryAddressQueryVariables>(BlockDeliveryAddressDocument, options);
      }
export function useBlockDeliveryAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockDeliveryAddressQuery, BlockDeliveryAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockDeliveryAddressQuery, BlockDeliveryAddressQueryVariables>(BlockDeliveryAddressDocument, options);
        }
export type BlockDeliveryAddressQueryHookResult = ReturnType<typeof useBlockDeliveryAddressQuery>;
export type BlockDeliveryAddressLazyQueryHookResult = ReturnType<typeof useBlockDeliveryAddressLazyQuery>;
export type BlockDeliveryAddressQueryResult = Apollo.QueryResult<BlockDeliveryAddressQuery, BlockDeliveryAddressQueryVariables>;
export const BlockDeliveryAddressUpdateDocument = gql`
    mutation BlockDeliveryAddressUpdate($request: UpdateDeliveryAddressRequest!) {
  tenantDeliveryAddressUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type BlockDeliveryAddressUpdateMutationFn = Apollo.MutationFunction<BlockDeliveryAddressUpdateMutation, BlockDeliveryAddressUpdateMutationVariables>;

/**
 * __useBlockDeliveryAddressUpdateMutation__
 *
 * To run a mutation, you first call `useBlockDeliveryAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockDeliveryAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockDeliveryAddressUpdateMutation, { data, loading, error }] = useBlockDeliveryAddressUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useBlockDeliveryAddressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BlockDeliveryAddressUpdateMutation, BlockDeliveryAddressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockDeliveryAddressUpdateMutation, BlockDeliveryAddressUpdateMutationVariables>(BlockDeliveryAddressUpdateDocument, options);
      }
export type BlockDeliveryAddressUpdateMutationHookResult = ReturnType<typeof useBlockDeliveryAddressUpdateMutation>;
export type BlockDeliveryAddressUpdateMutationResult = Apollo.MutationResult<BlockDeliveryAddressUpdateMutation>;
export type BlockDeliveryAddressUpdateMutationOptions = Apollo.BaseMutationOptions<BlockDeliveryAddressUpdateMutation, BlockDeliveryAddressUpdateMutationVariables>;
export const GetCafeOrderItemsDocument = gql`
    query GetCafeOrderItems($request: CafeOrdersRequest!) {
  cafeOrders(request: $request) {
    dataSource {
      ...CafeOrderItem
    }
    pages {
      ...Page
    }
    aggregate {
      ...CafeOrderAggregate
    }
  }
}
    ${CafeOrderItemFragmentDoc}
${PageFragmentDoc}
${CafeOrderAggregateFragmentDoc}`;

/**
 * __useGetCafeOrderItemsQuery__
 *
 * To run a query within a React component, call `useGetCafeOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCafeOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCafeOrderItemsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetCafeOrderItemsQuery(baseOptions: Apollo.QueryHookOptions<GetCafeOrderItemsQuery, GetCafeOrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCafeOrderItemsQuery, GetCafeOrderItemsQueryVariables>(GetCafeOrderItemsDocument, options);
      }
export function useGetCafeOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCafeOrderItemsQuery, GetCafeOrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCafeOrderItemsQuery, GetCafeOrderItemsQueryVariables>(GetCafeOrderItemsDocument, options);
        }
export type GetCafeOrderItemsQueryHookResult = ReturnType<typeof useGetCafeOrderItemsQuery>;
export type GetCafeOrderItemsLazyQueryHookResult = ReturnType<typeof useGetCafeOrderItemsLazyQuery>;
export type GetCafeOrderItemsQueryResult = Apollo.QueryResult<GetCafeOrderItemsQuery, GetCafeOrderItemsQueryVariables>;
export const UpdateCafeOrderDocument = gql`
    mutation UpdateCafeOrder($request: UpdateCafeOrderRequest!) {
  cafeOrderUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateCafeOrderMutationFn = Apollo.MutationFunction<UpdateCafeOrderMutation, UpdateCafeOrderMutationVariables>;

/**
 * __useUpdateCafeOrderMutation__
 *
 * To run a mutation, you first call `useUpdateCafeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCafeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCafeOrderMutation, { data, loading, error }] = useUpdateCafeOrderMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateCafeOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCafeOrderMutation, UpdateCafeOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCafeOrderMutation, UpdateCafeOrderMutationVariables>(UpdateCafeOrderDocument, options);
      }
export type UpdateCafeOrderMutationHookResult = ReturnType<typeof useUpdateCafeOrderMutation>;
export type UpdateCafeOrderMutationResult = Apollo.MutationResult<UpdateCafeOrderMutation>;
export type UpdateCafeOrderMutationOptions = Apollo.BaseMutationOptions<UpdateCafeOrderMutation, UpdateCafeOrderMutationVariables>;
export const UpdateCafeOrderItemDocument = gql`
    mutation UpdateCafeOrderItem($request: UpdateCafeOrderItemRequest!) {
  cafeOrderItemUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateCafeOrderItemMutationFn = Apollo.MutationFunction<UpdateCafeOrderItemMutation, UpdateCafeOrderItemMutationVariables>;

/**
 * __useUpdateCafeOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateCafeOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCafeOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCafeOrderItemMutation, { data, loading, error }] = useUpdateCafeOrderItemMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateCafeOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCafeOrderItemMutation, UpdateCafeOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCafeOrderItemMutation, UpdateCafeOrderItemMutationVariables>(UpdateCafeOrderItemDocument, options);
      }
export type UpdateCafeOrderItemMutationHookResult = ReturnType<typeof useUpdateCafeOrderItemMutation>;
export type UpdateCafeOrderItemMutationResult = Apollo.MutationResult<UpdateCafeOrderItemMutation>;
export type UpdateCafeOrderItemMutationOptions = Apollo.BaseMutationOptions<UpdateCafeOrderItemMutation, UpdateCafeOrderItemMutationVariables>;
export const GetCafeOrdersSummaryDocument = gql`
    query GetCafeOrdersSummary($request: CafeOrdersSummaryRequest) {
  cafeOrdersSummary(request: $request) {
    dataSource {
      ...CafeOrdersSummary
    }
    pages {
      ...Page
    }
  }
}
    ${CafeOrdersSummaryFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useGetCafeOrdersSummaryQuery__
 *
 * To run a query within a React component, call `useGetCafeOrdersSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCafeOrdersSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCafeOrdersSummaryQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetCafeOrdersSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetCafeOrdersSummaryQuery, GetCafeOrdersSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCafeOrdersSummaryQuery, GetCafeOrdersSummaryQueryVariables>(GetCafeOrdersSummaryDocument, options);
      }
export function useGetCafeOrdersSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCafeOrdersSummaryQuery, GetCafeOrdersSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCafeOrdersSummaryQuery, GetCafeOrdersSummaryQueryVariables>(GetCafeOrdersSummaryDocument, options);
        }
export type GetCafeOrdersSummaryQueryHookResult = ReturnType<typeof useGetCafeOrdersSummaryQuery>;
export type GetCafeOrdersSummaryLazyQueryHookResult = ReturnType<typeof useGetCafeOrdersSummaryLazyQuery>;
export type GetCafeOrdersSummaryQueryResult = Apollo.QueryResult<GetCafeOrdersSummaryQuery, GetCafeOrdersSummaryQueryVariables>;
export const CafeProductsDocument = gql`
    query CafeProducts($request: SearchCafeProductRequest) {
  cafeProductSearch(request: $request) {
    dataSource {
      ...CafeProductColumn
    }
    pages {
      ...Page
    }
  }
}
    ${CafeProductColumnFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useCafeProductsQuery__
 *
 * To run a query within a React component, call `useCafeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCafeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCafeProductsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCafeProductsQuery(baseOptions?: Apollo.QueryHookOptions<CafeProductsQuery, CafeProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CafeProductsQuery, CafeProductsQueryVariables>(CafeProductsDocument, options);
      }
export function useCafeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CafeProductsQuery, CafeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CafeProductsQuery, CafeProductsQueryVariables>(CafeProductsDocument, options);
        }
export type CafeProductsQueryHookResult = ReturnType<typeof useCafeProductsQuery>;
export type CafeProductsLazyQueryHookResult = ReturnType<typeof useCafeProductsLazyQuery>;
export type CafeProductsQueryResult = Apollo.QueryResult<CafeProductsQuery, CafeProductsQueryVariables>;
export const CreateCafeProductDocument = gql`
    mutation CreateCafeProduct($request: CreateCafeProductRequest!) {
  cafeProductCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateCafeProductMutationFn = Apollo.MutationFunction<CreateCafeProductMutation, CreateCafeProductMutationVariables>;

/**
 * __useCreateCafeProductMutation__
 *
 * To run a mutation, you first call `useCreateCafeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCafeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCafeProductMutation, { data, loading, error }] = useCreateCafeProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateCafeProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateCafeProductMutation, CreateCafeProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCafeProductMutation, CreateCafeProductMutationVariables>(CreateCafeProductDocument, options);
      }
export type CreateCafeProductMutationHookResult = ReturnType<typeof useCreateCafeProductMutation>;
export type CreateCafeProductMutationResult = Apollo.MutationResult<CreateCafeProductMutation>;
export type CreateCafeProductMutationOptions = Apollo.BaseMutationOptions<CreateCafeProductMutation, CreateCafeProductMutationVariables>;
export const GetCafeProductDocument = gql`
    query GetCafeProduct($id: String!) {
  cafeProduct(id: $id) {
    ...CafeProductDetail
  }
}
    ${CafeProductDetailFragmentDoc}`;

/**
 * __useGetCafeProductQuery__
 *
 * To run a query within a React component, call `useGetCafeProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCafeProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCafeProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCafeProductQuery(baseOptions: Apollo.QueryHookOptions<GetCafeProductQuery, GetCafeProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCafeProductQuery, GetCafeProductQueryVariables>(GetCafeProductDocument, options);
      }
export function useGetCafeProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCafeProductQuery, GetCafeProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCafeProductQuery, GetCafeProductQueryVariables>(GetCafeProductDocument, options);
        }
export type GetCafeProductQueryHookResult = ReturnType<typeof useGetCafeProductQuery>;
export type GetCafeProductLazyQueryHookResult = ReturnType<typeof useGetCafeProductLazyQuery>;
export type GetCafeProductQueryResult = Apollo.QueryResult<GetCafeProductQuery, GetCafeProductQueryVariables>;
export const DeleteCafeProductDocument = gql`
    mutation DeleteCafeProduct($id: String!) {
  cafeProductDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteCafeProductMutationFn = Apollo.MutationFunction<DeleteCafeProductMutation, DeleteCafeProductMutationVariables>;

/**
 * __useDeleteCafeProductMutation__
 *
 * To run a mutation, you first call `useDeleteCafeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCafeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCafeProductMutation, { data, loading, error }] = useDeleteCafeProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCafeProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCafeProductMutation, DeleteCafeProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCafeProductMutation, DeleteCafeProductMutationVariables>(DeleteCafeProductDocument, options);
      }
export type DeleteCafeProductMutationHookResult = ReturnType<typeof useDeleteCafeProductMutation>;
export type DeleteCafeProductMutationResult = Apollo.MutationResult<DeleteCafeProductMutation>;
export type DeleteCafeProductMutationOptions = Apollo.BaseMutationOptions<DeleteCafeProductMutation, DeleteCafeProductMutationVariables>;
export const UpdateCafeProductDocument = gql`
    mutation UpdateCafeProduct($request: UpdateCafeProductRequest!) {
  cafeProductUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateCafeProductMutationFn = Apollo.MutationFunction<UpdateCafeProductMutation, UpdateCafeProductMutationVariables>;

/**
 * __useUpdateCafeProductMutation__
 *
 * To run a mutation, you first call `useUpdateCafeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCafeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCafeProductMutation, { data, loading, error }] = useUpdateCafeProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateCafeProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCafeProductMutation, UpdateCafeProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCafeProductMutation, UpdateCafeProductMutationVariables>(UpdateCafeProductDocument, options);
      }
export type UpdateCafeProductMutationHookResult = ReturnType<typeof useUpdateCafeProductMutation>;
export type UpdateCafeProductMutationResult = Apollo.MutationResult<UpdateCafeProductMutation>;
export type UpdateCafeProductMutationOptions = Apollo.BaseMutationOptions<UpdateCafeProductMutation, UpdateCafeProductMutationVariables>;
export const CouponsDocument = gql`
    query Coupons($request: CouponSearchInput!) {
  coupons(request: $request) {
    page {
      ...Page
    }
    datasource {
      ...Coupon
    }
  }
}
    ${PageFragmentDoc}
${CouponFragmentDoc}`;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCouponsQuery(baseOptions: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
      }
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
        }
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const MyCouponsDocument = gql`
    query MyCoupons($request: MyCouponSearchInput!) {
  myCoupons(request: $request) {
    page {
      ...Page
    }
    datasource {
      ...MyCoupon
    }
  }
}
    ${PageFragmentDoc}
${MyCouponFragmentDoc}`;

/**
 * __useMyCouponsQuery__
 *
 * To run a query within a React component, call `useMyCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCouponsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useMyCouponsQuery(baseOptions: Apollo.QueryHookOptions<MyCouponsQuery, MyCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCouponsQuery, MyCouponsQueryVariables>(MyCouponsDocument, options);
      }
export function useMyCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCouponsQuery, MyCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCouponsQuery, MyCouponsQueryVariables>(MyCouponsDocument, options);
        }
export type MyCouponsQueryHookResult = ReturnType<typeof useMyCouponsQuery>;
export type MyCouponsLazyQueryHookResult = ReturnType<typeof useMyCouponsLazyQuery>;
export type MyCouponsQueryResult = Apollo.QueryResult<MyCouponsQuery, MyCouponsQueryVariables>;
export const CouponCreateDocument = gql`
    mutation CouponCreate($request: CouponCreateInput!) {
  couponCreate(request: $request) {
    message
    status
  }
}
    `;
export type CouponCreateMutationFn = Apollo.MutationFunction<CouponCreateMutation, CouponCreateMutationVariables>;

/**
 * __useCouponCreateMutation__
 *
 * To run a mutation, you first call `useCouponCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponCreateMutation, { data, loading, error }] = useCouponCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCouponCreateMutation(baseOptions?: Apollo.MutationHookOptions<CouponCreateMutation, CouponCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CouponCreateMutation, CouponCreateMutationVariables>(CouponCreateDocument, options);
      }
export type CouponCreateMutationHookResult = ReturnType<typeof useCouponCreateMutation>;
export type CouponCreateMutationResult = Apollo.MutationResult<CouponCreateMutation>;
export type CouponCreateMutationOptions = Apollo.BaseMutationOptions<CouponCreateMutation, CouponCreateMutationVariables>;
export const FileUrlCreateDocument = gql`
    mutation FileUrlCreate($request: CreateUrlRequest!) {
  fileUrlCreate(request: $request) {
    fileName
    fileType
    id
    key
    url
  }
}
    `;
export type FileUrlCreateMutationFn = Apollo.MutationFunction<FileUrlCreateMutation, FileUrlCreateMutationVariables>;

/**
 * __useFileUrlCreateMutation__
 *
 * To run a mutation, you first call `useFileUrlCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUrlCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUrlCreateMutation, { data, loading, error }] = useFileUrlCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useFileUrlCreateMutation(baseOptions?: Apollo.MutationHookOptions<FileUrlCreateMutation, FileUrlCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileUrlCreateMutation, FileUrlCreateMutationVariables>(FileUrlCreateDocument, options);
      }
export type FileUrlCreateMutationHookResult = ReturnType<typeof useFileUrlCreateMutation>;
export type FileUrlCreateMutationResult = Apollo.MutationResult<FileUrlCreateMutation>;
export type FileUrlCreateMutationOptions = Apollo.BaseMutationOptions<FileUrlCreateMutation, FileUrlCreateMutationVariables>;
export const CouponUpdateDocument = gql`
    mutation CouponUpdate($request: CouponUpdateInput!) {
  couponUpdate(request: $request) {
    message
    status
  }
}
    `;
export type CouponUpdateMutationFn = Apollo.MutationFunction<CouponUpdateMutation, CouponUpdateMutationVariables>;

/**
 * __useCouponUpdateMutation__
 *
 * To run a mutation, you first call `useCouponUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponUpdateMutation, { data, loading, error }] = useCouponUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCouponUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CouponUpdateMutation, CouponUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CouponUpdateMutation, CouponUpdateMutationVariables>(CouponUpdateDocument, options);
      }
export type CouponUpdateMutationHookResult = ReturnType<typeof useCouponUpdateMutation>;
export type CouponUpdateMutationResult = Apollo.MutationResult<CouponUpdateMutation>;
export type CouponUpdateMutationOptions = Apollo.BaseMutationOptions<CouponUpdateMutation, CouponUpdateMutationVariables>;
export const CouponDocument = gql`
    query Coupon($couponId: String!) {
  coupon(id: $couponId) {
    ...Coupon
  }
}
    ${CouponFragmentDoc}`;

/**
 * __useCouponQuery__
 *
 * To run a query within a React component, call `useCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponQuery({
 *   variables: {
 *      couponId: // value for 'couponId'
 *   },
 * });
 */
export function useCouponQuery(baseOptions: Apollo.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
      }
export function useCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
        }
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;
export type CouponQueryResult = Apollo.QueryResult<CouponQuery, CouponQueryVariables>;
export const AccountsDocument = gql`
    query accounts($request: SearchAccountsRequest!) {
  accounts(request: $request) {
    dataSource {
      ...AccountColumn
    }
    pages {
      ...Page
    }
  }
}
    ${AccountColumnFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const FranchiseMemberDetailDocument = gql`
    query FranchiseMemberDetail($id: Int!) {
  franchiseMember(id: $id) {
    ...FranchiseMemberDetail
  }
}
    ${FranchiseMemberDetailFragmentDoc}`;

/**
 * __useFranchiseMemberDetailQuery__
 *
 * To run a query within a React component, call `useFranchiseMemberDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFranchiseMemberDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFranchiseMemberDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFranchiseMemberDetailQuery(baseOptions: Apollo.QueryHookOptions<FranchiseMemberDetailQuery, FranchiseMemberDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FranchiseMemberDetailQuery, FranchiseMemberDetailQueryVariables>(FranchiseMemberDetailDocument, options);
      }
export function useFranchiseMemberDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FranchiseMemberDetailQuery, FranchiseMemberDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FranchiseMemberDetailQuery, FranchiseMemberDetailQueryVariables>(FranchiseMemberDetailDocument, options);
        }
export type FranchiseMemberDetailQueryHookResult = ReturnType<typeof useFranchiseMemberDetailQuery>;
export type FranchiseMemberDetailLazyQueryHookResult = ReturnType<typeof useFranchiseMemberDetailLazyQuery>;
export type FranchiseMemberDetailQueryResult = Apollo.QueryResult<FranchiseMemberDetailQuery, FranchiseMemberDetailQueryVariables>;
export const FranchiseMembersDocument = gql`
    query FranchiseMembers($request: GetFranchiseMembersRequest!) {
  franchiseMembers(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...FranchiseMember
    }
  }
}
    ${PageFragmentDoc}
${FranchiseMemberFragmentDoc}`;

/**
 * __useFranchiseMembersQuery__
 *
 * To run a query within a React component, call `useFranchiseMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFranchiseMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFranchiseMembersQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useFranchiseMembersQuery(baseOptions: Apollo.QueryHookOptions<FranchiseMembersQuery, FranchiseMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FranchiseMembersQuery, FranchiseMembersQueryVariables>(FranchiseMembersDocument, options);
      }
export function useFranchiseMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FranchiseMembersQuery, FranchiseMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FranchiseMembersQuery, FranchiseMembersQueryVariables>(FranchiseMembersDocument, options);
        }
export type FranchiseMembersQueryHookResult = ReturnType<typeof useFranchiseMembersQuery>;
export type FranchiseMembersLazyQueryHookResult = ReturnType<typeof useFranchiseMembersLazyQuery>;
export type FranchiseMembersQueryResult = Apollo.QueryResult<FranchiseMembersQuery, FranchiseMembersQueryVariables>;
export const PasswordChangeFranchiseMemberDocument = gql`
    mutation PasswordChangeFranchiseMember($request: UpdatePasswordRequest!) {
  franchisePasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type PasswordChangeFranchiseMemberMutationFn = Apollo.MutationFunction<PasswordChangeFranchiseMemberMutation, PasswordChangeFranchiseMemberMutationVariables>;

/**
 * __usePasswordChangeFranchiseMemberMutation__
 *
 * To run a mutation, you first call `usePasswordChangeFranchiseMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeFranchiseMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeFranchiseMemberMutation, { data, loading, error }] = usePasswordChangeFranchiseMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function usePasswordChangeFranchiseMemberMutation(baseOptions?: Apollo.MutationHookOptions<PasswordChangeFranchiseMemberMutation, PasswordChangeFranchiseMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordChangeFranchiseMemberMutation, PasswordChangeFranchiseMemberMutationVariables>(PasswordChangeFranchiseMemberDocument, options);
      }
export type PasswordChangeFranchiseMemberMutationHookResult = ReturnType<typeof usePasswordChangeFranchiseMemberMutation>;
export type PasswordChangeFranchiseMemberMutationResult = Apollo.MutationResult<PasswordChangeFranchiseMemberMutation>;
export type PasswordChangeFranchiseMemberMutationOptions = Apollo.BaseMutationOptions<PasswordChangeFranchiseMemberMutation, PasswordChangeFranchiseMemberMutationVariables>;
export const CreateFranchiseMemberDocument = gql`
    mutation CreateFranchiseMember($request: CreateFranchiseMember!) {
  franchiseMemberCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateFranchiseMemberMutationFn = Apollo.MutationFunction<CreateFranchiseMemberMutation, CreateFranchiseMemberMutationVariables>;

/**
 * __useCreateFranchiseMemberMutation__
 *
 * To run a mutation, you first call `useCreateFranchiseMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFranchiseMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFranchiseMemberMutation, { data, loading, error }] = useCreateFranchiseMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateFranchiseMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateFranchiseMemberMutation, CreateFranchiseMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFranchiseMemberMutation, CreateFranchiseMemberMutationVariables>(CreateFranchiseMemberDocument, options);
      }
export type CreateFranchiseMemberMutationHookResult = ReturnType<typeof useCreateFranchiseMemberMutation>;
export type CreateFranchiseMemberMutationResult = Apollo.MutationResult<CreateFranchiseMemberMutation>;
export type CreateFranchiseMemberMutationOptions = Apollo.BaseMutationOptions<CreateFranchiseMemberMutation, CreateFranchiseMemberMutationVariables>;
export const UpdateFranchiseMemberDocument = gql`
    mutation UpdateFranchiseMember($request: UpdateFranchiseMember!) {
  franchiseMemberUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateFranchiseMemberMutationFn = Apollo.MutationFunction<UpdateFranchiseMemberMutation, UpdateFranchiseMemberMutationVariables>;

/**
 * __useUpdateFranchiseMemberMutation__
 *
 * To run a mutation, you first call `useUpdateFranchiseMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFranchiseMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFranchiseMemberMutation, { data, loading, error }] = useUpdateFranchiseMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateFranchiseMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFranchiseMemberMutation, UpdateFranchiseMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFranchiseMemberMutation, UpdateFranchiseMemberMutationVariables>(UpdateFranchiseMemberDocument, options);
      }
export type UpdateFranchiseMemberMutationHookResult = ReturnType<typeof useUpdateFranchiseMemberMutation>;
export type UpdateFranchiseMemberMutationResult = Apollo.MutationResult<UpdateFranchiseMemberMutation>;
export type UpdateFranchiseMemberMutationOptions = Apollo.BaseMutationOptions<UpdateFranchiseMemberMutation, UpdateFranchiseMemberMutationVariables>;
export const DeleteFranchiseMemberDocument = gql`
    mutation DeleteFranchiseMember($id: Int!) {
  franchiseMemberDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteFranchiseMemberMutationFn = Apollo.MutationFunction<DeleteFranchiseMemberMutation, DeleteFranchiseMemberMutationVariables>;

/**
 * __useDeleteFranchiseMemberMutation__
 *
 * To run a mutation, you first call `useDeleteFranchiseMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFranchiseMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFranchiseMemberMutation, { data, loading, error }] = useDeleteFranchiseMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFranchiseMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFranchiseMemberMutation, DeleteFranchiseMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFranchiseMemberMutation, DeleteFranchiseMemberMutationVariables>(DeleteFranchiseMemberDocument, options);
      }
export type DeleteFranchiseMemberMutationHookResult = ReturnType<typeof useDeleteFranchiseMemberMutation>;
export type DeleteFranchiseMemberMutationResult = Apollo.MutationResult<DeleteFranchiseMemberMutation>;
export type DeleteFranchiseMemberMutationOptions = Apollo.BaseMutationOptions<DeleteFranchiseMemberMutation, DeleteFranchiseMemberMutationVariables>;
export const FranchiseDetailDocument = gql`
    query FranchiseDetail($id: Int) {
  franchise(id: $id) {
    ...FranchiseDetail
  }
}
    ${FranchiseDetailFragmentDoc}`;

/**
 * __useFranchiseDetailQuery__
 *
 * To run a query within a React component, call `useFranchiseDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFranchiseDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFranchiseDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFranchiseDetailQuery(baseOptions?: Apollo.QueryHookOptions<FranchiseDetailQuery, FranchiseDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FranchiseDetailQuery, FranchiseDetailQueryVariables>(FranchiseDetailDocument, options);
      }
export function useFranchiseDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FranchiseDetailQuery, FranchiseDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FranchiseDetailQuery, FranchiseDetailQueryVariables>(FranchiseDetailDocument, options);
        }
export type FranchiseDetailQueryHookResult = ReturnType<typeof useFranchiseDetailQuery>;
export type FranchiseDetailLazyQueryHookResult = ReturnType<typeof useFranchiseDetailLazyQuery>;
export type FranchiseDetailQueryResult = Apollo.QueryResult<FranchiseDetailQuery, FranchiseDetailQueryVariables>;
export const FranchisesDocument = gql`
    query Franchises($request: GetFranchiseMembersRequest!) {
  franchises(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...Franchise
    }
  }
}
    ${PageFragmentDoc}
${FranchiseFragmentDoc}`;

/**
 * __useFranchisesQuery__
 *
 * To run a query within a React component, call `useFranchisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFranchisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFranchisesQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useFranchisesQuery(baseOptions: Apollo.QueryHookOptions<FranchisesQuery, FranchisesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FranchisesQuery, FranchisesQueryVariables>(FranchisesDocument, options);
      }
export function useFranchisesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FranchisesQuery, FranchisesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FranchisesQuery, FranchisesQueryVariables>(FranchisesDocument, options);
        }
export type FranchisesQueryHookResult = ReturnType<typeof useFranchisesQuery>;
export type FranchisesLazyQueryHookResult = ReturnType<typeof useFranchisesLazyQuery>;
export type FranchisesQueryResult = Apollo.QueryResult<FranchisesQuery, FranchisesQueryVariables>;
export const CreateFranchiseDocument = gql`
    mutation CreateFranchise($request: CreateFranchiseRequest!) {
  franchiseCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateFranchiseMutationFn = Apollo.MutationFunction<CreateFranchiseMutation, CreateFranchiseMutationVariables>;

/**
 * __useCreateFranchiseMutation__
 *
 * To run a mutation, you first call `useCreateFranchiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFranchiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFranchiseMutation, { data, loading, error }] = useCreateFranchiseMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateFranchiseMutation(baseOptions?: Apollo.MutationHookOptions<CreateFranchiseMutation, CreateFranchiseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFranchiseMutation, CreateFranchiseMutationVariables>(CreateFranchiseDocument, options);
      }
export type CreateFranchiseMutationHookResult = ReturnType<typeof useCreateFranchiseMutation>;
export type CreateFranchiseMutationResult = Apollo.MutationResult<CreateFranchiseMutation>;
export type CreateFranchiseMutationOptions = Apollo.BaseMutationOptions<CreateFranchiseMutation, CreateFranchiseMutationVariables>;
export const UpdateFranchiseDocument = gql`
    mutation UpdateFranchise($request: UpdateFranchiseRequest!) {
  franchiseUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateFranchiseMutationFn = Apollo.MutationFunction<UpdateFranchiseMutation, UpdateFranchiseMutationVariables>;

/**
 * __useUpdateFranchiseMutation__
 *
 * To run a mutation, you first call `useUpdateFranchiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFranchiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFranchiseMutation, { data, loading, error }] = useUpdateFranchiseMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateFranchiseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFranchiseMutation, UpdateFranchiseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFranchiseMutation, UpdateFranchiseMutationVariables>(UpdateFranchiseDocument, options);
      }
export type UpdateFranchiseMutationHookResult = ReturnType<typeof useUpdateFranchiseMutation>;
export type UpdateFranchiseMutationResult = Apollo.MutationResult<UpdateFranchiseMutation>;
export type UpdateFranchiseMutationOptions = Apollo.BaseMutationOptions<UpdateFranchiseMutation, UpdateFranchiseMutationVariables>;
export const DeleteFranchiseDocument = gql`
    mutation DeleteFranchise($id: Int!) {
  franchiseDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteFranchiseMutationFn = Apollo.MutationFunction<DeleteFranchiseMutation, DeleteFranchiseMutationVariables>;

/**
 * __useDeleteFranchiseMutation__
 *
 * To run a mutation, you first call `useDeleteFranchiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFranchiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFranchiseMutation, { data, loading, error }] = useDeleteFranchiseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFranchiseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFranchiseMutation, DeleteFranchiseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFranchiseMutation, DeleteFranchiseMutationVariables>(DeleteFranchiseDocument, options);
      }
export type DeleteFranchiseMutationHookResult = ReturnType<typeof useDeleteFranchiseMutation>;
export type DeleteFranchiseMutationResult = Apollo.MutationResult<DeleteFranchiseMutation>;
export type DeleteFranchiseMutationOptions = Apollo.BaseMutationOptions<DeleteFranchiseMutation, DeleteFranchiseMutationVariables>;
export const FranchiseDocument = gql`
    query Franchise($franchiseId: Int) {
  franchise(id: $franchiseId) {
    companyName
  }
}
    `;

/**
 * __useFranchiseQuery__
 *
 * To run a query within a React component, call `useFranchiseQuery` and pass it any options that fit your needs.
 * When your component renders, `useFranchiseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFranchiseQuery({
 *   variables: {
 *      franchiseId: // value for 'franchiseId'
 *   },
 * });
 */
export function useFranchiseQuery(baseOptions?: Apollo.QueryHookOptions<FranchiseQuery, FranchiseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FranchiseQuery, FranchiseQueryVariables>(FranchiseDocument, options);
      }
export function useFranchiseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FranchiseQuery, FranchiseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FranchiseQuery, FranchiseQueryVariables>(FranchiseDocument, options);
        }
export type FranchiseQueryHookResult = ReturnType<typeof useFranchiseQuery>;
export type FranchiseLazyQueryHookResult = ReturnType<typeof useFranchiseLazyQuery>;
export type FranchiseQueryResult = Apollo.QueryResult<FranchiseQuery, FranchiseQueryVariables>;
export const UpdateAccountPasswordDocument = gql`
    mutation UpdateAccountPassword($request: UpdatePasswordRequest!) {
  accountPasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateAccountPasswordMutationFn = Apollo.MutationFunction<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>;

/**
 * __useUpdateAccountPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPasswordMutation, { data, loading, error }] = useUpdateAccountPasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateAccountPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>(UpdateAccountPasswordDocument, options);
      }
export type UpdateAccountPasswordMutationHookResult = ReturnType<typeof useUpdateAccountPasswordMutation>;
export type UpdateAccountPasswordMutationResult = Apollo.MutationResult<UpdateAccountPasswordMutation>;
export type UpdateAccountPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>;
export const UpdateFranchisePasswordDocument = gql`
    mutation UpdateFranchisePassword($request: UpdatePasswordRequest!) {
  franchisePasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateFranchisePasswordMutationFn = Apollo.MutationFunction<UpdateFranchisePasswordMutation, UpdateFranchisePasswordMutationVariables>;

/**
 * __useUpdateFranchisePasswordMutation__
 *
 * To run a mutation, you first call `useUpdateFranchisePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFranchisePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFranchisePasswordMutation, { data, loading, error }] = useUpdateFranchisePasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateFranchisePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFranchisePasswordMutation, UpdateFranchisePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFranchisePasswordMutation, UpdateFranchisePasswordMutationVariables>(UpdateFranchisePasswordDocument, options);
      }
export type UpdateFranchisePasswordMutationHookResult = ReturnType<typeof useUpdateFranchisePasswordMutation>;
export type UpdateFranchisePasswordMutationResult = Apollo.MutationResult<UpdateFranchisePasswordMutation>;
export type UpdateFranchisePasswordMutationOptions = Apollo.BaseMutationOptions<UpdateFranchisePasswordMutation, UpdateFranchisePasswordMutationVariables>;
export const UpdateTenantPasswordDocument = gql`
    mutation UpdateTenantPassword($request: UpdatePasswordRequest!) {
  tenantPasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateTenantPasswordMutationFn = Apollo.MutationFunction<UpdateTenantPasswordMutation, UpdateTenantPasswordMutationVariables>;

/**
 * __useUpdateTenantPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateTenantPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantPasswordMutation, { data, loading, error }] = useUpdateTenantPasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateTenantPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantPasswordMutation, UpdateTenantPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantPasswordMutation, UpdateTenantPasswordMutationVariables>(UpdateTenantPasswordDocument, options);
      }
export type UpdateTenantPasswordMutationHookResult = ReturnType<typeof useUpdateTenantPasswordMutation>;
export type UpdateTenantPasswordMutationResult = Apollo.MutationResult<UpdateTenantPasswordMutation>;
export type UpdateTenantPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateTenantPasswordMutation, UpdateTenantPasswordMutationVariables>;
export const UpdateShopPasswordDocument = gql`
    mutation UpdateShopPassword($request: UpdatePasswordRequest!) {
  shopPasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateShopPasswordMutationFn = Apollo.MutationFunction<UpdateShopPasswordMutation, UpdateShopPasswordMutationVariables>;

/**
 * __useUpdateShopPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateShopPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopPasswordMutation, { data, loading, error }] = useUpdateShopPasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateShopPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopPasswordMutation, UpdateShopPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopPasswordMutation, UpdateShopPasswordMutationVariables>(UpdateShopPasswordDocument, options);
      }
export type UpdateShopPasswordMutationHookResult = ReturnType<typeof useUpdateShopPasswordMutation>;
export type UpdateShopPasswordMutationResult = Apollo.MutationResult<UpdateShopPasswordMutation>;
export type UpdateShopPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateShopPasswordMutation, UpdateShopPasswordMutationVariables>;
export const UpdateMasterPasswordDocument = gql`
    mutation UpdateMasterPassword($request: UpdatePasswordRequest!) {
  masterPasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateMasterPasswordMutationFn = Apollo.MutationFunction<UpdateMasterPasswordMutation, UpdateMasterPasswordMutationVariables>;

/**
 * __useUpdateMasterPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateMasterPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterPasswordMutation, { data, loading, error }] = useUpdateMasterPasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateMasterPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMasterPasswordMutation, UpdateMasterPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMasterPasswordMutation, UpdateMasterPasswordMutationVariables>(UpdateMasterPasswordDocument, options);
      }
export type UpdateMasterPasswordMutationHookResult = ReturnType<typeof useUpdateMasterPasswordMutation>;
export type UpdateMasterPasswordMutationResult = Apollo.MutationResult<UpdateMasterPasswordMutation>;
export type UpdateMasterPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateMasterPasswordMutation, UpdateMasterPasswordMutationVariables>;
export const ForgotPasswordFranchiseDocument = gql`
    mutation ForgotPasswordFranchise($request: ForgotPasswordRequest!) {
  franchiseForgotPassword(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type ForgotPasswordFranchiseMutationFn = Apollo.MutationFunction<ForgotPasswordFranchiseMutation, ForgotPasswordFranchiseMutationVariables>;

/**
 * __useForgotPasswordFranchiseMutation__
 *
 * To run a mutation, you first call `useForgotPasswordFranchiseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordFranchiseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordFranchiseMutation, { data, loading, error }] = useForgotPasswordFranchiseMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useForgotPasswordFranchiseMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordFranchiseMutation, ForgotPasswordFranchiseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordFranchiseMutation, ForgotPasswordFranchiseMutationVariables>(ForgotPasswordFranchiseDocument, options);
      }
export type ForgotPasswordFranchiseMutationHookResult = ReturnType<typeof useForgotPasswordFranchiseMutation>;
export type ForgotPasswordFranchiseMutationResult = Apollo.MutationResult<ForgotPasswordFranchiseMutation>;
export type ForgotPasswordFranchiseMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordFranchiseMutation, ForgotPasswordFranchiseMutationVariables>;
export const ForgotPasswordTenantDocument = gql`
    mutation ForgotPasswordTenant($request: ForgotPasswordRequest!) {
  tenantForgotPassword(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type ForgotPasswordTenantMutationFn = Apollo.MutationFunction<ForgotPasswordTenantMutation, ForgotPasswordTenantMutationVariables>;

/**
 * __useForgotPasswordTenantMutation__
 *
 * To run a mutation, you first call `useForgotPasswordTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordTenantMutation, { data, loading, error }] = useForgotPasswordTenantMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useForgotPasswordTenantMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordTenantMutation, ForgotPasswordTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordTenantMutation, ForgotPasswordTenantMutationVariables>(ForgotPasswordTenantDocument, options);
      }
export type ForgotPasswordTenantMutationHookResult = ReturnType<typeof useForgotPasswordTenantMutation>;
export type ForgotPasswordTenantMutationResult = Apollo.MutationResult<ForgotPasswordTenantMutation>;
export type ForgotPasswordTenantMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordTenantMutation, ForgotPasswordTenantMutationVariables>;
export const ForgotPasswordShopDocument = gql`
    mutation ForgotPasswordShop($request: ForgotPasswordRequest!) {
  shopForgotPassword(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type ForgotPasswordShopMutationFn = Apollo.MutationFunction<ForgotPasswordShopMutation, ForgotPasswordShopMutationVariables>;

/**
 * __useForgotPasswordShopMutation__
 *
 * To run a mutation, you first call `useForgotPasswordShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordShopMutation, { data, loading, error }] = useForgotPasswordShopMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useForgotPasswordShopMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordShopMutation, ForgotPasswordShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordShopMutation, ForgotPasswordShopMutationVariables>(ForgotPasswordShopDocument, options);
      }
export type ForgotPasswordShopMutationHookResult = ReturnType<typeof useForgotPasswordShopMutation>;
export type ForgotPasswordShopMutationResult = Apollo.MutationResult<ForgotPasswordShopMutation>;
export type ForgotPasswordShopMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordShopMutation, ForgotPasswordShopMutationVariables>;
export const ForgotPasswordMasterDocument = gql`
    mutation ForgotPasswordMaster($request: ForgotPasswordRequest!) {
  masterForgotPassword(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type ForgotPasswordMasterMutationFn = Apollo.MutationFunction<ForgotPasswordMasterMutation, ForgotPasswordMasterMutationVariables>;

/**
 * __useForgotPasswordMasterMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMasterMutation, { data, loading, error }] = useForgotPasswordMasterMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useForgotPasswordMasterMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMasterMutation, ForgotPasswordMasterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMasterMutation, ForgotPasswordMasterMutationVariables>(ForgotPasswordMasterDocument, options);
      }
export type ForgotPasswordMasterMutationHookResult = ReturnType<typeof useForgotPasswordMasterMutation>;
export type ForgotPasswordMasterMutationResult = Apollo.MutationResult<ForgotPasswordMasterMutation>;
export type ForgotPasswordMasterMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMasterMutation, ForgotPasswordMasterMutationVariables>;
export const CreateFranchiseOneTimePasswordDocument = gql`
    mutation CreateFranchiseOneTimePassword($request: CreateAdminOneTimePasswordRequest!) {
  franchiseOneTimePasswordCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateFranchiseOneTimePasswordMutationFn = Apollo.MutationFunction<CreateFranchiseOneTimePasswordMutation, CreateFranchiseOneTimePasswordMutationVariables>;

/**
 * __useCreateFranchiseOneTimePasswordMutation__
 *
 * To run a mutation, you first call `useCreateFranchiseOneTimePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFranchiseOneTimePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFranchiseOneTimePasswordMutation, { data, loading, error }] = useCreateFranchiseOneTimePasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateFranchiseOneTimePasswordMutation(baseOptions?: Apollo.MutationHookOptions<CreateFranchiseOneTimePasswordMutation, CreateFranchiseOneTimePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFranchiseOneTimePasswordMutation, CreateFranchiseOneTimePasswordMutationVariables>(CreateFranchiseOneTimePasswordDocument, options);
      }
export type CreateFranchiseOneTimePasswordMutationHookResult = ReturnType<typeof useCreateFranchiseOneTimePasswordMutation>;
export type CreateFranchiseOneTimePasswordMutationResult = Apollo.MutationResult<CreateFranchiseOneTimePasswordMutation>;
export type CreateFranchiseOneTimePasswordMutationOptions = Apollo.BaseMutationOptions<CreateFranchiseOneTimePasswordMutation, CreateFranchiseOneTimePasswordMutationVariables>;
export const CreateTenantOneTimePasswordDocument = gql`
    mutation CreateTenantOneTimePassword($request: CreateAdminOneTimePasswordRequest!) {
  tenantOneTimePasswordCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateTenantOneTimePasswordMutationFn = Apollo.MutationFunction<CreateTenantOneTimePasswordMutation, CreateTenantOneTimePasswordMutationVariables>;

/**
 * __useCreateTenantOneTimePasswordMutation__
 *
 * To run a mutation, you first call `useCreateTenantOneTimePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantOneTimePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantOneTimePasswordMutation, { data, loading, error }] = useCreateTenantOneTimePasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateTenantOneTimePasswordMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantOneTimePasswordMutation, CreateTenantOneTimePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantOneTimePasswordMutation, CreateTenantOneTimePasswordMutationVariables>(CreateTenantOneTimePasswordDocument, options);
      }
export type CreateTenantOneTimePasswordMutationHookResult = ReturnType<typeof useCreateTenantOneTimePasswordMutation>;
export type CreateTenantOneTimePasswordMutationResult = Apollo.MutationResult<CreateTenantOneTimePasswordMutation>;
export type CreateTenantOneTimePasswordMutationOptions = Apollo.BaseMutationOptions<CreateTenantOneTimePasswordMutation, CreateTenantOneTimePasswordMutationVariables>;
export const CreateShopOneTimePasswordDocument = gql`
    mutation CreateShopOneTimePassword($request: CreateAdminOneTimePasswordRequest!) {
  shopOneTimePasswordCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateShopOneTimePasswordMutationFn = Apollo.MutationFunction<CreateShopOneTimePasswordMutation, CreateShopOneTimePasswordMutationVariables>;

/**
 * __useCreateShopOneTimePasswordMutation__
 *
 * To run a mutation, you first call `useCreateShopOneTimePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopOneTimePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopOneTimePasswordMutation, { data, loading, error }] = useCreateShopOneTimePasswordMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateShopOneTimePasswordMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopOneTimePasswordMutation, CreateShopOneTimePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopOneTimePasswordMutation, CreateShopOneTimePasswordMutationVariables>(CreateShopOneTimePasswordDocument, options);
      }
export type CreateShopOneTimePasswordMutationHookResult = ReturnType<typeof useCreateShopOneTimePasswordMutation>;
export type CreateShopOneTimePasswordMutationResult = Apollo.MutationResult<CreateShopOneTimePasswordMutation>;
export type CreateShopOneTimePasswordMutationOptions = Apollo.BaseMutationOptions<CreateShopOneTimePasswordMutation, CreateShopOneTimePasswordMutationVariables>;
export const GetManualsDocument = gql`
    query GetManuals($request: GetManualsRequest!) {
  manuals(request: $request) {
    dataSource {
      ...ManualItem
    }
    pages {
      ...Page
    }
  }
}
    ${ManualItemFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useGetManualsQuery__
 *
 * To run a query within a React component, call `useGetManualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetManualsQuery(baseOptions: Apollo.QueryHookOptions<GetManualsQuery, GetManualsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManualsQuery, GetManualsQueryVariables>(GetManualsDocument, options);
      }
export function useGetManualsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManualsQuery, GetManualsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManualsQuery, GetManualsQueryVariables>(GetManualsDocument, options);
        }
export type GetManualsQueryHookResult = ReturnType<typeof useGetManualsQuery>;
export type GetManualsLazyQueryHookResult = ReturnType<typeof useGetManualsLazyQuery>;
export type GetManualsQueryResult = Apollo.QueryResult<GetManualsQuery, GetManualsQueryVariables>;
export const CreateManualUrlDocument = gql`
    mutation CreateManualUrl($request: CreateUrlRequest!) {
  manualUrlCreate(request: $request) {
    ...UrlReply
  }
}
    ${UrlReplyFragmentDoc}`;
export type CreateManualUrlMutationFn = Apollo.MutationFunction<CreateManualUrlMutation, CreateManualUrlMutationVariables>;

/**
 * __useCreateManualUrlMutation__
 *
 * To run a mutation, you first call `useCreateManualUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualUrlMutation, { data, loading, error }] = useCreateManualUrlMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateManualUrlMutation(baseOptions?: Apollo.MutationHookOptions<CreateManualUrlMutation, CreateManualUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManualUrlMutation, CreateManualUrlMutationVariables>(CreateManualUrlDocument, options);
      }
export type CreateManualUrlMutationHookResult = ReturnType<typeof useCreateManualUrlMutation>;
export type CreateManualUrlMutationResult = Apollo.MutationResult<CreateManualUrlMutation>;
export type CreateManualUrlMutationOptions = Apollo.BaseMutationOptions<CreateManualUrlMutation, CreateManualUrlMutationVariables>;
export const CreateManualDocument = gql`
    mutation CreateManual($request: CreateManualRequest!) {
  manualCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateManualMutationFn = Apollo.MutationFunction<CreateManualMutation, CreateManualMutationVariables>;

/**
 * __useCreateManualMutation__
 *
 * To run a mutation, you first call `useCreateManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualMutation, { data, loading, error }] = useCreateManualMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateManualMutation(baseOptions?: Apollo.MutationHookOptions<CreateManualMutation, CreateManualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManualMutation, CreateManualMutationVariables>(CreateManualDocument, options);
      }
export type CreateManualMutationHookResult = ReturnType<typeof useCreateManualMutation>;
export type CreateManualMutationResult = Apollo.MutationResult<CreateManualMutation>;
export type CreateManualMutationOptions = Apollo.BaseMutationOptions<CreateManualMutation, CreateManualMutationVariables>;
export const MasterMemberDetailDocument = gql`
    query MasterMemberDetail($id: Int!) {
  masterMember(id: $id) {
    ...MasterMemberDetail
  }
}
    ${MasterMemberDetailFragmentDoc}`;

/**
 * __useMasterMemberDetailQuery__
 *
 * To run a query within a React component, call `useMasterMemberDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterMemberDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterMemberDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMasterMemberDetailQuery(baseOptions: Apollo.QueryHookOptions<MasterMemberDetailQuery, MasterMemberDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MasterMemberDetailQuery, MasterMemberDetailQueryVariables>(MasterMemberDetailDocument, options);
      }
export function useMasterMemberDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterMemberDetailQuery, MasterMemberDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MasterMemberDetailQuery, MasterMemberDetailQueryVariables>(MasterMemberDetailDocument, options);
        }
export type MasterMemberDetailQueryHookResult = ReturnType<typeof useMasterMemberDetailQuery>;
export type MasterMemberDetailLazyQueryHookResult = ReturnType<typeof useMasterMemberDetailLazyQuery>;
export type MasterMemberDetailQueryResult = Apollo.QueryResult<MasterMemberDetailQuery, MasterMemberDetailQueryVariables>;
export const MasterMembersDocument = gql`
    query MasterMembers($request: GetMasterMembersRequest!) {
  masterMembers(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...MasterMember
    }
  }
}
    ${PageFragmentDoc}
${MasterMemberFragmentDoc}`;

/**
 * __useMasterMembersQuery__
 *
 * To run a query within a React component, call `useMasterMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasterMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasterMembersQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useMasterMembersQuery(baseOptions: Apollo.QueryHookOptions<MasterMembersQuery, MasterMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MasterMembersQuery, MasterMembersQueryVariables>(MasterMembersDocument, options);
      }
export function useMasterMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MasterMembersQuery, MasterMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MasterMembersQuery, MasterMembersQueryVariables>(MasterMembersDocument, options);
        }
export type MasterMembersQueryHookResult = ReturnType<typeof useMasterMembersQuery>;
export type MasterMembersLazyQueryHookResult = ReturnType<typeof useMasterMembersLazyQuery>;
export type MasterMembersQueryResult = Apollo.QueryResult<MasterMembersQuery, MasterMembersQueryVariables>;
export const PasswordChangeMasterMemberDocument = gql`
    mutation PasswordChangeMasterMember($request: UpdatePasswordRequest!) {
  masterPasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type PasswordChangeMasterMemberMutationFn = Apollo.MutationFunction<PasswordChangeMasterMemberMutation, PasswordChangeMasterMemberMutationVariables>;

/**
 * __usePasswordChangeMasterMemberMutation__
 *
 * To run a mutation, you first call `usePasswordChangeMasterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeMasterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeMasterMemberMutation, { data, loading, error }] = usePasswordChangeMasterMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function usePasswordChangeMasterMemberMutation(baseOptions?: Apollo.MutationHookOptions<PasswordChangeMasterMemberMutation, PasswordChangeMasterMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordChangeMasterMemberMutation, PasswordChangeMasterMemberMutationVariables>(PasswordChangeMasterMemberDocument, options);
      }
export type PasswordChangeMasterMemberMutationHookResult = ReturnType<typeof usePasswordChangeMasterMemberMutation>;
export type PasswordChangeMasterMemberMutationResult = Apollo.MutationResult<PasswordChangeMasterMemberMutation>;
export type PasswordChangeMasterMemberMutationOptions = Apollo.BaseMutationOptions<PasswordChangeMasterMemberMutation, PasswordChangeMasterMemberMutationVariables>;
export const CreateMasterMemberDocument = gql`
    mutation CreateMasterMember($request: CreateMasterMember!) {
  masterMemberCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateMasterMemberMutationFn = Apollo.MutationFunction<CreateMasterMemberMutation, CreateMasterMemberMutationVariables>;

/**
 * __useCreateMasterMemberMutation__
 *
 * To run a mutation, you first call `useCreateMasterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMasterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMasterMemberMutation, { data, loading, error }] = useCreateMasterMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateMasterMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateMasterMemberMutation, CreateMasterMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMasterMemberMutation, CreateMasterMemberMutationVariables>(CreateMasterMemberDocument, options);
      }
export type CreateMasterMemberMutationHookResult = ReturnType<typeof useCreateMasterMemberMutation>;
export type CreateMasterMemberMutationResult = Apollo.MutationResult<CreateMasterMemberMutation>;
export type CreateMasterMemberMutationOptions = Apollo.BaseMutationOptions<CreateMasterMemberMutation, CreateMasterMemberMutationVariables>;
export const UpdateMasterMemberDocument = gql`
    mutation UpdateMasterMember($request: UpdateMasterMember!) {
  masterMemberUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateMasterMemberMutationFn = Apollo.MutationFunction<UpdateMasterMemberMutation, UpdateMasterMemberMutationVariables>;

/**
 * __useUpdateMasterMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMasterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasterMemberMutation, { data, loading, error }] = useUpdateMasterMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateMasterMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMasterMemberMutation, UpdateMasterMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMasterMemberMutation, UpdateMasterMemberMutationVariables>(UpdateMasterMemberDocument, options);
      }
export type UpdateMasterMemberMutationHookResult = ReturnType<typeof useUpdateMasterMemberMutation>;
export type UpdateMasterMemberMutationResult = Apollo.MutationResult<UpdateMasterMemberMutation>;
export type UpdateMasterMemberMutationOptions = Apollo.BaseMutationOptions<UpdateMasterMemberMutation, UpdateMasterMemberMutationVariables>;
export const DeleteMasterMemberDocument = gql`
    mutation DeleteMasterMember($id: Int!) {
  masterMemberDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteMasterMemberMutationFn = Apollo.MutationFunction<DeleteMasterMemberMutation, DeleteMasterMemberMutationVariables>;

/**
 * __useDeleteMasterMemberMutation__
 *
 * To run a mutation, you first call `useDeleteMasterMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasterMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasterMemberMutation, { data, loading, error }] = useDeleteMasterMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMasterMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMasterMemberMutation, DeleteMasterMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMasterMemberMutation, DeleteMasterMemberMutationVariables>(DeleteMasterMemberDocument, options);
      }
export type DeleteMasterMemberMutationHookResult = ReturnType<typeof useDeleteMasterMemberMutation>;
export type DeleteMasterMemberMutationResult = Apollo.MutationResult<DeleteMasterMemberMutation>;
export type DeleteMasterMemberMutationOptions = Apollo.BaseMutationOptions<DeleteMasterMemberMutation, DeleteMasterMemberMutationVariables>;
export const OrderDetailDocument = gql`
    query OrderDetail($id: Int!) {
  order(id: $id) {
    orderItems {
      ...OrderItemRrow
    }
    ...OrderTotalContainer
    shippingAddress {
      ...CustomerContainer
    }
    ...ShippedConteiner
    ...UndispatchedConteiner
    memo
  }
}
    ${OrderItemRrowFragmentDoc}
${OrderTotalContainerFragmentDoc}
${CustomerContainerFragmentDoc}
${ShippedConteinerFragmentDoc}
${UndispatchedConteinerFragmentDoc}`;

/**
 * __useOrderDetailQuery__
 *
 * To run a query within a React component, call `useOrderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderDetailQuery(baseOptions: Apollo.QueryHookOptions<OrderDetailQuery, OrderDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderDetailQuery, OrderDetailQueryVariables>(OrderDetailDocument, options);
      }
export function useOrderDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailQuery, OrderDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderDetailQuery, OrderDetailQueryVariables>(OrderDetailDocument, options);
        }
export type OrderDetailQueryHookResult = ReturnType<typeof useOrderDetailQuery>;
export type OrderDetailLazyQueryHookResult = ReturnType<typeof useOrderDetailLazyQuery>;
export type OrderDetailQueryResult = Apollo.QueryResult<OrderDetailQuery, OrderDetailQueryVariables>;
export const OrdersDocument = gql`
    query Orders($request: OrdersRequest!) {
  orders(request: $request) {
    dataSource {
      ...OrderColumn
    }
    pages {
      ...Page
    }
  }
}
    ${OrderColumnFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const GetCustomerActionsDocument = gql`
    query GetCustomerActions($request: ActionSearchRequest!) {
  customerActions(request: $request) {
    ...CustomerAction
  }
}
    ${CustomerActionFragmentDoc}`;

/**
 * __useGetCustomerActionsQuery__
 *
 * To run a query within a React component, call `useGetCustomerActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerActionsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetCustomerActionsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerActionsQuery, GetCustomerActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerActionsQuery, GetCustomerActionsQueryVariables>(GetCustomerActionsDocument, options);
      }
export function useGetCustomerActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerActionsQuery, GetCustomerActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerActionsQuery, GetCustomerActionsQueryVariables>(GetCustomerActionsDocument, options);
        }
export type GetCustomerActionsQueryHookResult = ReturnType<typeof useGetCustomerActionsQuery>;
export type GetCustomerActionsLazyQueryHookResult = ReturnType<typeof useGetCustomerActionsLazyQuery>;
export type GetCustomerActionsQueryResult = Apollo.QueryResult<GetCustomerActionsQuery, GetCustomerActionsQueryVariables>;
export const GetProductDocument = gql`
    query GetProduct($id: String!) {
  product(id: $id) {
    ...ProductTitleContainer
    ...ProductTaxContainer
    ...ProductPriceContainer
    ...VariationContainer
    ...ProductCategoryContainer
  }
}
    ${ProductTitleContainerFragmentDoc}
${ProductTaxContainerFragmentDoc}
${ProductPriceContainerFragmentDoc}
${VariationContainerFragmentDoc}
${ProductCategoryContainerFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const ProductsDocument = gql`
    query Products($request: SearchProductRequest) {
  productSearch(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...ProductColumn
    }
  }
}
    ${PageFragmentDoc}
${ProductColumnFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($request: CreateProductRequest!) {
  productCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const ProductFileUploadsDocument = gql`
    mutation ProductFileUploads($request: [CreateUrlRequest!]!) {
  productFileUrlsCreate(request: $request) {
    id
    key
    fileName
    fileType
    url
  }
}
    `;
export type ProductFileUploadsMutationFn = Apollo.MutationFunction<ProductFileUploadsMutation, ProductFileUploadsMutationVariables>;

/**
 * __useProductFileUploadsMutation__
 *
 * To run a mutation, you first call `useProductFileUploadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductFileUploadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productFileUploadsMutation, { data, loading, error }] = useProductFileUploadsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useProductFileUploadsMutation(baseOptions?: Apollo.MutationHookOptions<ProductFileUploadsMutation, ProductFileUploadsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductFileUploadsMutation, ProductFileUploadsMutationVariables>(ProductFileUploadsDocument, options);
      }
export type ProductFileUploadsMutationHookResult = ReturnType<typeof useProductFileUploadsMutation>;
export type ProductFileUploadsMutationResult = Apollo.MutationResult<ProductFileUploadsMutation>;
export type ProductFileUploadsMutationOptions = Apollo.BaseMutationOptions<ProductFileUploadsMutation, ProductFileUploadsMutationVariables>;
export const GetUpdateProductDocument = gql`
    query GetUpdateProduct($id: String!) {
  product(id: $id) {
    ...UpdateProduct
  }
}
    ${UpdateProductFragmentDoc}`;

/**
 * __useGetUpdateProductQuery__
 *
 * To run a query within a React component, call `useGetUpdateProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUpdateProductQuery(baseOptions: Apollo.QueryHookOptions<GetUpdateProductQuery, GetUpdateProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdateProductQuery, GetUpdateProductQueryVariables>(GetUpdateProductDocument, options);
      }
export function useGetUpdateProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateProductQuery, GetUpdateProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdateProductQuery, GetUpdateProductQueryVariables>(GetUpdateProductDocument, options);
        }
export type GetUpdateProductQueryHookResult = ReturnType<typeof useGetUpdateProductQuery>;
export type GetUpdateProductLazyQueryHookResult = ReturnType<typeof useGetUpdateProductLazyQuery>;
export type GetUpdateProductQueryResult = Apollo.QueryResult<GetUpdateProductQuery, GetUpdateProductQueryVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($request: UpdateProductRequest!) {
  productUpdate(request: $request) {
    status
    message
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateSkuDocument = gql`
    mutation UpdateSku($request: UpdateSkuRequest!) {
  skuUpdate(request: $request) {
    status
    message
  }
}
    `;
export type UpdateSkuMutationFn = Apollo.MutationFunction<UpdateSkuMutation, UpdateSkuMutationVariables>;

/**
 * __useUpdateSkuMutation__
 *
 * To run a mutation, you first call `useUpdateSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkuMutation, { data, loading, error }] = useUpdateSkuMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateSkuMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkuMutation, UpdateSkuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkuMutation, UpdateSkuMutationVariables>(UpdateSkuDocument, options);
      }
export type UpdateSkuMutationHookResult = ReturnType<typeof useUpdateSkuMutation>;
export type UpdateSkuMutationResult = Apollo.MutationResult<UpdateSkuMutation>;
export type UpdateSkuMutationOptions = Apollo.BaseMutationOptions<UpdateSkuMutation, UpdateSkuMutationVariables>;
export const CreateSkuDocument = gql`
    mutation CreateSku($request: AddSkuRequest!) {
  skuCreate(request: $request) {
    status
    message
  }
}
    `;
export type CreateSkuMutationFn = Apollo.MutationFunction<CreateSkuMutation, CreateSkuMutationVariables>;

/**
 * __useCreateSkuMutation__
 *
 * To run a mutation, you first call `useCreateSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkuMutation, { data, loading, error }] = useCreateSkuMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateSkuMutation(baseOptions?: Apollo.MutationHookOptions<CreateSkuMutation, CreateSkuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSkuMutation, CreateSkuMutationVariables>(CreateSkuDocument, options);
      }
export type CreateSkuMutationHookResult = ReturnType<typeof useCreateSkuMutation>;
export type CreateSkuMutationResult = Apollo.MutationResult<CreateSkuMutation>;
export type CreateSkuMutationOptions = Apollo.BaseMutationOptions<CreateSkuMutation, CreateSkuMutationVariables>;
export const DeleteSkuDocument = gql`
    mutation DeleteSku($request: DeleteSkuRequest!) {
  skuDelete(request: $request) {
    status
    message
  }
}
    `;
export type DeleteSkuMutationFn = Apollo.MutationFunction<DeleteSkuMutation, DeleteSkuMutationVariables>;

/**
 * __useDeleteSkuMutation__
 *
 * To run a mutation, you first call `useDeleteSkuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkuMutation, { data, loading, error }] = useDeleteSkuMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useDeleteSkuMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSkuMutation, DeleteSkuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSkuMutation, DeleteSkuMutationVariables>(DeleteSkuDocument, options);
      }
export type DeleteSkuMutationHookResult = ReturnType<typeof useDeleteSkuMutation>;
export type DeleteSkuMutationResult = Apollo.MutationResult<DeleteSkuMutation>;
export type DeleteSkuMutationOptions = Apollo.BaseMutationOptions<DeleteSkuMutation, DeleteSkuMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: String!) {
  productDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const ShopMemberDetailDocument = gql`
    query ShopMemberDetail($id: Int!) {
  shopMember(id: $id) {
    ...ShopMemberDetail
  }
}
    ${ShopMemberDetailFragmentDoc}`;

/**
 * __useShopMemberDetailQuery__
 *
 * To run a query within a React component, call `useShopMemberDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopMemberDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopMemberDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopMemberDetailQuery(baseOptions: Apollo.QueryHookOptions<ShopMemberDetailQuery, ShopMemberDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopMemberDetailQuery, ShopMemberDetailQueryVariables>(ShopMemberDetailDocument, options);
      }
export function useShopMemberDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopMemberDetailQuery, ShopMemberDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopMemberDetailQuery, ShopMemberDetailQueryVariables>(ShopMemberDetailDocument, options);
        }
export type ShopMemberDetailQueryHookResult = ReturnType<typeof useShopMemberDetailQuery>;
export type ShopMemberDetailLazyQueryHookResult = ReturnType<typeof useShopMemberDetailLazyQuery>;
export type ShopMemberDetailQueryResult = Apollo.QueryResult<ShopMemberDetailQuery, ShopMemberDetailQueryVariables>;
export const ShopMembersDocument = gql`
    query ShopMembers($request: GetShopMembersRequest!) {
  shopMembers(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...ShopMember
    }
  }
}
    ${PageFragmentDoc}
${ShopMemberFragmentDoc}`;

/**
 * __useShopMembersQuery__
 *
 * To run a query within a React component, call `useShopMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopMembersQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopMembersQuery(baseOptions: Apollo.QueryHookOptions<ShopMembersQuery, ShopMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopMembersQuery, ShopMembersQueryVariables>(ShopMembersDocument, options);
      }
export function useShopMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopMembersQuery, ShopMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopMembersQuery, ShopMembersQueryVariables>(ShopMembersDocument, options);
        }
export type ShopMembersQueryHookResult = ReturnType<typeof useShopMembersQuery>;
export type ShopMembersLazyQueryHookResult = ReturnType<typeof useShopMembersLazyQuery>;
export type ShopMembersQueryResult = Apollo.QueryResult<ShopMembersQuery, ShopMembersQueryVariables>;
export const PasswordChangeShopMemberDocument = gql`
    mutation PasswordChangeShopMember($request: UpdatePasswordRequest!) {
  shopPasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type PasswordChangeShopMemberMutationFn = Apollo.MutationFunction<PasswordChangeShopMemberMutation, PasswordChangeShopMemberMutationVariables>;

/**
 * __usePasswordChangeShopMemberMutation__
 *
 * To run a mutation, you first call `usePasswordChangeShopMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeShopMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeShopMemberMutation, { data, loading, error }] = usePasswordChangeShopMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function usePasswordChangeShopMemberMutation(baseOptions?: Apollo.MutationHookOptions<PasswordChangeShopMemberMutation, PasswordChangeShopMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordChangeShopMemberMutation, PasswordChangeShopMemberMutationVariables>(PasswordChangeShopMemberDocument, options);
      }
export type PasswordChangeShopMemberMutationHookResult = ReturnType<typeof usePasswordChangeShopMemberMutation>;
export type PasswordChangeShopMemberMutationResult = Apollo.MutationResult<PasswordChangeShopMemberMutation>;
export type PasswordChangeShopMemberMutationOptions = Apollo.BaseMutationOptions<PasswordChangeShopMemberMutation, PasswordChangeShopMemberMutationVariables>;
export const ShopMemberCreateDocument = gql`
    mutation ShopMemberCreate($request: CreateShopMember!) {
  shopMemberCreate(request: $request) {
    status
    message
  }
}
    `;
export type ShopMemberCreateMutationFn = Apollo.MutationFunction<ShopMemberCreateMutation, ShopMemberCreateMutationVariables>;

/**
 * __useShopMemberCreateMutation__
 *
 * To run a mutation, you first call `useShopMemberCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMemberCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMemberCreateMutation, { data, loading, error }] = useShopMemberCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopMemberCreateMutation(baseOptions?: Apollo.MutationHookOptions<ShopMemberCreateMutation, ShopMemberCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMemberCreateMutation, ShopMemberCreateMutationVariables>(ShopMemberCreateDocument, options);
      }
export type ShopMemberCreateMutationHookResult = ReturnType<typeof useShopMemberCreateMutation>;
export type ShopMemberCreateMutationResult = Apollo.MutationResult<ShopMemberCreateMutation>;
export type ShopMemberCreateMutationOptions = Apollo.BaseMutationOptions<ShopMemberCreateMutation, ShopMemberCreateMutationVariables>;
export const ShopMemberUpdateDocument = gql`
    mutation ShopMemberUpdate($request: UpdateShopMember!) {
  shopMemberUpdate(request: $request) {
    status
    message
  }
}
    `;
export type ShopMemberUpdateMutationFn = Apollo.MutationFunction<ShopMemberUpdateMutation, ShopMemberUpdateMutationVariables>;

/**
 * __useShopMemberUpdateMutation__
 *
 * To run a mutation, you first call `useShopMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMemberUpdateMutation, { data, loading, error }] = useShopMemberUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ShopMemberUpdateMutation, ShopMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMemberUpdateMutation, ShopMemberUpdateMutationVariables>(ShopMemberUpdateDocument, options);
      }
export type ShopMemberUpdateMutationHookResult = ReturnType<typeof useShopMemberUpdateMutation>;
export type ShopMemberUpdateMutationResult = Apollo.MutationResult<ShopMemberUpdateMutation>;
export type ShopMemberUpdateMutationOptions = Apollo.BaseMutationOptions<ShopMemberUpdateMutation, ShopMemberUpdateMutationVariables>;
export const ShopMemberDeleteDocument = gql`
    mutation ShopMemberDelete($shopMemberDeleteId: Int!) {
  shopMemberDelete(id: $shopMemberDeleteId) {
    message
    status
  }
}
    `;
export type ShopMemberDeleteMutationFn = Apollo.MutationFunction<ShopMemberDeleteMutation, ShopMemberDeleteMutationVariables>;

/**
 * __useShopMemberDeleteMutation__
 *
 * To run a mutation, you first call `useShopMemberDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMemberDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMemberDeleteMutation, { data, loading, error }] = useShopMemberDeleteMutation({
 *   variables: {
 *      shopMemberDeleteId: // value for 'shopMemberDeleteId'
 *   },
 * });
 */
export function useShopMemberDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ShopMemberDeleteMutation, ShopMemberDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMemberDeleteMutation, ShopMemberDeleteMutationVariables>(ShopMemberDeleteDocument, options);
      }
export type ShopMemberDeleteMutationHookResult = ReturnType<typeof useShopMemberDeleteMutation>;
export type ShopMemberDeleteMutationResult = Apollo.MutationResult<ShopMemberDeleteMutation>;
export type ShopMemberDeleteMutationOptions = Apollo.BaseMutationOptions<ShopMemberDeleteMutation, ShopMemberDeleteMutationVariables>;
export const ShopDocument = gql`
    query Shop($shopId: Int!) {
  shop(id: $shopId) {
    ...ShopDetail
  }
}
    ${ShopDetailFragmentDoc}`;

/**
 * __useShopQuery__
 *
 * To run a query within a React component, call `useShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopQuery(baseOptions: Apollo.QueryHookOptions<ShopQuery, ShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
      }
export function useShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
        }
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopQueryResult = Apollo.QueryResult<ShopQuery, ShopQueryVariables>;
export const ShopsDocument = gql`
    query Shops($request: GetShopsRequest!) {
  shops(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...Shop
    }
  }
}
    ${PageFragmentDoc}
${ShopFragmentDoc}`;

/**
 * __useShopsQuery__
 *
 * To run a query within a React component, call `useShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopsQuery(baseOptions: Apollo.QueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, options);
      }
export function useShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, options);
        }
export type ShopsQueryHookResult = ReturnType<typeof useShopsQuery>;
export type ShopsLazyQueryHookResult = ReturnType<typeof useShopsLazyQuery>;
export type ShopsQueryResult = Apollo.QueryResult<ShopsQuery, ShopsQueryVariables>;
export const ShopCreateDocument = gql`
    mutation ShopCreate($request: CreateShop!) {
  shopCreate(request: $request) {
    message
    status
  }
}
    `;
export type ShopCreateMutationFn = Apollo.MutationFunction<ShopCreateMutation, ShopCreateMutationVariables>;

/**
 * __useShopCreateMutation__
 *
 * To run a mutation, you first call `useShopCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopCreateMutation, { data, loading, error }] = useShopCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopCreateMutation(baseOptions?: Apollo.MutationHookOptions<ShopCreateMutation, ShopCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopCreateMutation, ShopCreateMutationVariables>(ShopCreateDocument, options);
      }
export type ShopCreateMutationHookResult = ReturnType<typeof useShopCreateMutation>;
export type ShopCreateMutationResult = Apollo.MutationResult<ShopCreateMutation>;
export type ShopCreateMutationOptions = Apollo.BaseMutationOptions<ShopCreateMutation, ShopCreateMutationVariables>;
export const ShopUpdateDocument = gql`
    mutation ShopUpdate($request: UpdateShop!) {
  shopUpdate(request: $request) {
    status
    message
  }
}
    `;
export type ShopUpdateMutationFn = Apollo.MutationFunction<ShopUpdateMutation, ShopUpdateMutationVariables>;

/**
 * __useShopUpdateMutation__
 *
 * To run a mutation, you first call `useShopUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopUpdateMutation, { data, loading, error }] = useShopUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ShopUpdateMutation, ShopUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopUpdateMutation, ShopUpdateMutationVariables>(ShopUpdateDocument, options);
      }
export type ShopUpdateMutationHookResult = ReturnType<typeof useShopUpdateMutation>;
export type ShopUpdateMutationResult = Apollo.MutationResult<ShopUpdateMutation>;
export type ShopUpdateMutationOptions = Apollo.BaseMutationOptions<ShopUpdateMutation, ShopUpdateMutationVariables>;
export const DeleteShopDocument = gql`
    mutation DeleteShop($id: Int!) {
  shopDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteShopMutationFn = Apollo.MutationFunction<DeleteShopMutation, DeleteShopMutationVariables>;

/**
 * __useDeleteShopMutation__
 *
 * To run a mutation, you first call `useDeleteShopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopMutation, { data, loading, error }] = useDeleteShopMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShopMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShopMutation, DeleteShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShopMutation, DeleteShopMutationVariables>(DeleteShopDocument, options);
      }
export type DeleteShopMutationHookResult = ReturnType<typeof useDeleteShopMutation>;
export type DeleteShopMutationResult = Apollo.MutationResult<DeleteShopMutation>;
export type DeleteShopMutationOptions = Apollo.BaseMutationOptions<DeleteShopMutation, DeleteShopMutationVariables>;
export const StampCardsDocument = gql`
    query StampCards($request: StampCardSearchInput!) {
  stampCards(request: $request) {
    datasource {
      ...StampCard
    }
    page {
      ...Page
    }
  }
}
    ${StampCardFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useStampCardsQuery__
 *
 * To run a query within a React component, call `useStampCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStampCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStampCardsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useStampCardsQuery(baseOptions: Apollo.QueryHookOptions<StampCardsQuery, StampCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StampCardsQuery, StampCardsQueryVariables>(StampCardsDocument, options);
      }
export function useStampCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StampCardsQuery, StampCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StampCardsQuery, StampCardsQueryVariables>(StampCardsDocument, options);
        }
export type StampCardsQueryHookResult = ReturnType<typeof useStampCardsQuery>;
export type StampCardsLazyQueryHookResult = ReturnType<typeof useStampCardsLazyQuery>;
export type StampCardsQueryResult = Apollo.QueryResult<StampCardsQuery, StampCardsQueryVariables>;
export const StampCardCreateDocument = gql`
    mutation StampCardCreate($request: StampCardCreateInput!) {
  stampCardCreate(request: $request) {
    message
    status
  }
}
    `;
export type StampCardCreateMutationFn = Apollo.MutationFunction<StampCardCreateMutation, StampCardCreateMutationVariables>;

/**
 * __useStampCardCreateMutation__
 *
 * To run a mutation, you first call `useStampCardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStampCardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stampCardCreateMutation, { data, loading, error }] = useStampCardCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useStampCardCreateMutation(baseOptions?: Apollo.MutationHookOptions<StampCardCreateMutation, StampCardCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StampCardCreateMutation, StampCardCreateMutationVariables>(StampCardCreateDocument, options);
      }
export type StampCardCreateMutationHookResult = ReturnType<typeof useStampCardCreateMutation>;
export type StampCardCreateMutationResult = Apollo.MutationResult<StampCardCreateMutation>;
export type StampCardCreateMutationOptions = Apollo.BaseMutationOptions<StampCardCreateMutation, StampCardCreateMutationVariables>;
export const StampCardUpdateDocument = gql`
    mutation StampCardUpdate($request: StampCardUpdateInput!) {
  stampCardUpdate(request: $request) {
    status
    message
  }
}
    `;
export type StampCardUpdateMutationFn = Apollo.MutationFunction<StampCardUpdateMutation, StampCardUpdateMutationVariables>;

/**
 * __useStampCardUpdateMutation__
 *
 * To run a mutation, you first call `useStampCardUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStampCardUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stampCardUpdateMutation, { data, loading, error }] = useStampCardUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useStampCardUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StampCardUpdateMutation, StampCardUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StampCardUpdateMutation, StampCardUpdateMutationVariables>(StampCardUpdateDocument, options);
      }
export type StampCardUpdateMutationHookResult = ReturnType<typeof useStampCardUpdateMutation>;
export type StampCardUpdateMutationResult = Apollo.MutationResult<StampCardUpdateMutation>;
export type StampCardUpdateMutationOptions = Apollo.BaseMutationOptions<StampCardUpdateMutation, StampCardUpdateMutationVariables>;
export const StampCardDocument = gql`
    query StampCard($stampCardId: String!) {
  stampCard(id: $stampCardId) {
    ...StampCard
  }
}
    ${StampCardFragmentDoc}`;

/**
 * __useStampCardQuery__
 *
 * To run a query within a React component, call `useStampCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useStampCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStampCardQuery({
 *   variables: {
 *      stampCardId: // value for 'stampCardId'
 *   },
 * });
 */
export function useStampCardQuery(baseOptions: Apollo.QueryHookOptions<StampCardQuery, StampCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StampCardQuery, StampCardQueryVariables>(StampCardDocument, options);
      }
export function useStampCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StampCardQuery, StampCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StampCardQuery, StampCardQueryVariables>(StampCardDocument, options);
        }
export type StampCardQueryHookResult = ReturnType<typeof useStampCardQuery>;
export type StampCardLazyQueryHookResult = ReturnType<typeof useStampCardLazyQuery>;
export type StampCardQueryResult = Apollo.QueryResult<StampCardQuery, StampCardQueryVariables>;
export const SurveysDocument = gql`
    query Surveys($request: SurveySearchInput!) {
  surveys(request: $request) {
    page {
      ...Page
    }
    datasource {
      ...Survey
    }
  }
}
    ${PageFragmentDoc}
${SurveyFragmentDoc}`;

/**
 * __useSurveysQuery__
 *
 * To run a query within a React component, call `useSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSurveysQuery(baseOptions: Apollo.QueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
      }
export function useSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
        }
export type SurveysQueryHookResult = ReturnType<typeof useSurveysQuery>;
export type SurveysLazyQueryHookResult = ReturnType<typeof useSurveysLazyQuery>;
export type SurveysQueryResult = Apollo.QueryResult<SurveysQuery, SurveysQueryVariables>;
export const SurveyCreateDocument = gql`
    mutation SurveyCreate($request: SurveyCreateInput!) {
  surveyCreate(request: $request) {
    status
    message
  }
}
    `;
export type SurveyCreateMutationFn = Apollo.MutationFunction<SurveyCreateMutation, SurveyCreateMutationVariables>;

/**
 * __useSurveyCreateMutation__
 *
 * To run a mutation, you first call `useSurveyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyCreateMutation, { data, loading, error }] = useSurveyCreateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSurveyCreateMutation(baseOptions?: Apollo.MutationHookOptions<SurveyCreateMutation, SurveyCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SurveyCreateMutation, SurveyCreateMutationVariables>(SurveyCreateDocument, options);
      }
export type SurveyCreateMutationHookResult = ReturnType<typeof useSurveyCreateMutation>;
export type SurveyCreateMutationResult = Apollo.MutationResult<SurveyCreateMutation>;
export type SurveyCreateMutationOptions = Apollo.BaseMutationOptions<SurveyCreateMutation, SurveyCreateMutationVariables>;
export const SurveyUpdateDocument = gql`
    mutation SurveyUpdate($request: SurveyUpdateInput!) {
  surveyUpdate(request: $request) {
    status
    message
  }
}
    `;
export type SurveyUpdateMutationFn = Apollo.MutationFunction<SurveyUpdateMutation, SurveyUpdateMutationVariables>;

/**
 * __useSurveyUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyUpdateMutation, { data, loading, error }] = useSurveyUpdateMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSurveyUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SurveyUpdateMutation, SurveyUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SurveyUpdateMutation, SurveyUpdateMutationVariables>(SurveyUpdateDocument, options);
      }
export type SurveyUpdateMutationHookResult = ReturnType<typeof useSurveyUpdateMutation>;
export type SurveyUpdateMutationResult = Apollo.MutationResult<SurveyUpdateMutation>;
export type SurveyUpdateMutationOptions = Apollo.BaseMutationOptions<SurveyUpdateMutation, SurveyUpdateMutationVariables>;
export const SurveyDocument = gql`
    query Survey($surveyId: String!) {
  survey(id: $surveyId) {
    ...Survey
  }
}
    ${SurveyFragmentDoc}`;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
      }
export function useSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
        }
export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<SurveyQuery, SurveyQueryVariables>;
export const TenantMemberDetailDocument = gql`
    query TenantMemberDetail($id: Int!) {
  tenantMember(id: $id) {
    ...TenantMemberDetail
  }
}
    ${TenantMemberDetailFragmentDoc}`;

/**
 * __useTenantMemberDetailQuery__
 *
 * To run a query within a React component, call `useTenantMemberDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantMemberDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantMemberDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTenantMemberDetailQuery(baseOptions: Apollo.QueryHookOptions<TenantMemberDetailQuery, TenantMemberDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantMemberDetailQuery, TenantMemberDetailQueryVariables>(TenantMemberDetailDocument, options);
      }
export function useTenantMemberDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantMemberDetailQuery, TenantMemberDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantMemberDetailQuery, TenantMemberDetailQueryVariables>(TenantMemberDetailDocument, options);
        }
export type TenantMemberDetailQueryHookResult = ReturnType<typeof useTenantMemberDetailQuery>;
export type TenantMemberDetailLazyQueryHookResult = ReturnType<typeof useTenantMemberDetailLazyQuery>;
export type TenantMemberDetailQueryResult = Apollo.QueryResult<TenantMemberDetailQuery, TenantMemberDetailQueryVariables>;
export const TenantMembersDocument = gql`
    query TenantMembers($request: GetTenantMembersRequest!) {
  tenantMembers(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...TenantMember
    }
  }
}
    ${PageFragmentDoc}
${TenantMemberFragmentDoc}`;

/**
 * __useTenantMembersQuery__
 *
 * To run a query within a React component, call `useTenantMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantMembersQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useTenantMembersQuery(baseOptions: Apollo.QueryHookOptions<TenantMembersQuery, TenantMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantMembersQuery, TenantMembersQueryVariables>(TenantMembersDocument, options);
      }
export function useTenantMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantMembersQuery, TenantMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantMembersQuery, TenantMembersQueryVariables>(TenantMembersDocument, options);
        }
export type TenantMembersQueryHookResult = ReturnType<typeof useTenantMembersQuery>;
export type TenantMembersLazyQueryHookResult = ReturnType<typeof useTenantMembersLazyQuery>;
export type TenantMembersQueryResult = Apollo.QueryResult<TenantMembersQuery, TenantMembersQueryVariables>;
export const PasswordChangeTenantMemberDocument = gql`
    mutation PasswordChangeTenantMember($request: UpdatePasswordRequest!) {
  tenantPasswordUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type PasswordChangeTenantMemberMutationFn = Apollo.MutationFunction<PasswordChangeTenantMemberMutation, PasswordChangeTenantMemberMutationVariables>;

/**
 * __usePasswordChangeTenantMemberMutation__
 *
 * To run a mutation, you first call `usePasswordChangeTenantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeTenantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeTenantMemberMutation, { data, loading, error }] = usePasswordChangeTenantMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function usePasswordChangeTenantMemberMutation(baseOptions?: Apollo.MutationHookOptions<PasswordChangeTenantMemberMutation, PasswordChangeTenantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordChangeTenantMemberMutation, PasswordChangeTenantMemberMutationVariables>(PasswordChangeTenantMemberDocument, options);
      }
export type PasswordChangeTenantMemberMutationHookResult = ReturnType<typeof usePasswordChangeTenantMemberMutation>;
export type PasswordChangeTenantMemberMutationResult = Apollo.MutationResult<PasswordChangeTenantMemberMutation>;
export type PasswordChangeTenantMemberMutationOptions = Apollo.BaseMutationOptions<PasswordChangeTenantMemberMutation, PasswordChangeTenantMemberMutationVariables>;
export const CreateTenantMemberDocument = gql`
    mutation CreateTenantMember($request: CreateTenantMember!) {
  tenantMemberCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateTenantMemberMutationFn = Apollo.MutationFunction<CreateTenantMemberMutation, CreateTenantMemberMutationVariables>;

/**
 * __useCreateTenantMemberMutation__
 *
 * To run a mutation, you first call `useCreateTenantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMemberMutation, { data, loading, error }] = useCreateTenantMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateTenantMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMemberMutation, CreateTenantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMemberMutation, CreateTenantMemberMutationVariables>(CreateTenantMemberDocument, options);
      }
export type CreateTenantMemberMutationHookResult = ReturnType<typeof useCreateTenantMemberMutation>;
export type CreateTenantMemberMutationResult = Apollo.MutationResult<CreateTenantMemberMutation>;
export type CreateTenantMemberMutationOptions = Apollo.BaseMutationOptions<CreateTenantMemberMutation, CreateTenantMemberMutationVariables>;
export const UpdateTenantMemberDocument = gql`
    mutation UpdateTenantMember($request: UpdateTenantMember!) {
  tenantMemberUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateTenantMemberMutationFn = Apollo.MutationFunction<UpdateTenantMemberMutation, UpdateTenantMemberMutationVariables>;

/**
 * __useUpdateTenantMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMemberMutation, { data, loading, error }] = useUpdateTenantMemberMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateTenantMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMemberMutation, UpdateTenantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMemberMutation, UpdateTenantMemberMutationVariables>(UpdateTenantMemberDocument, options);
      }
export type UpdateTenantMemberMutationHookResult = ReturnType<typeof useUpdateTenantMemberMutation>;
export type UpdateTenantMemberMutationResult = Apollo.MutationResult<UpdateTenantMemberMutation>;
export type UpdateTenantMemberMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMemberMutation, UpdateTenantMemberMutationVariables>;
export const DeleteTenantMemberDocument = gql`
    mutation DeleteTenantMember($id: Int!) {
  tenantMemberDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteTenantMemberMutationFn = Apollo.MutationFunction<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>;

/**
 * __useDeleteTenantMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMemberMutation, { data, loading, error }] = useDeleteTenantMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTenantMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>(DeleteTenantMemberDocument, options);
      }
export type DeleteTenantMemberMutationHookResult = ReturnType<typeof useDeleteTenantMemberMutation>;
export type DeleteTenantMemberMutationResult = Apollo.MutationResult<DeleteTenantMemberMutation>;
export type DeleteTenantMemberMutationOptions = Apollo.BaseMutationOptions<DeleteTenantMemberMutation, DeleteTenantMemberMutationVariables>;
export const TenantDetailDocument = gql`
    query TenantDetail($id: Int!) {
  tenant(id: $id) {
    ...TenantDetail
  }
}
    ${TenantDetailFragmentDoc}`;

/**
 * __useTenantDetailQuery__
 *
 * To run a query within a React component, call `useTenantDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTenantDetailQuery(baseOptions: Apollo.QueryHookOptions<TenantDetailQuery, TenantDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantDetailQuery, TenantDetailQueryVariables>(TenantDetailDocument, options);
      }
export function useTenantDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantDetailQuery, TenantDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantDetailQuery, TenantDetailQueryVariables>(TenantDetailDocument, options);
        }
export type TenantDetailQueryHookResult = ReturnType<typeof useTenantDetailQuery>;
export type TenantDetailLazyQueryHookResult = ReturnType<typeof useTenantDetailLazyQuery>;
export type TenantDetailQueryResult = Apollo.QueryResult<TenantDetailQuery, TenantDetailQueryVariables>;
export const TenantsDocument = gql`
    query Tenants($request: TenantsRequest!) {
  tenants(request: $request) {
    pages {
      ...Page
    }
    dataSource {
      ...Tenant
    }
  }
}
    ${PageFragmentDoc}
${TenantFragmentDoc}`;

/**
 * __useTenantsQuery__
 *
 * To run a query within a React component, call `useTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useTenantsQuery(baseOptions: Apollo.QueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
      }
export function useTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantsQuery, TenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantsQuery, TenantsQueryVariables>(TenantsDocument, options);
        }
export type TenantsQueryHookResult = ReturnType<typeof useTenantsQuery>;
export type TenantsLazyQueryHookResult = ReturnType<typeof useTenantsLazyQuery>;
export type TenantsQueryResult = Apollo.QueryResult<TenantsQuery, TenantsQueryVariables>;
export const CreateTenantDocument = gql`
    mutation CreateTenant($request: CreateTenant!) {
  tenantCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($request: UpdateTenant!) {
  tenantUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const DeleteTenantDocument = gql`
    mutation DeleteTenant($id: Int!) {
  tenantDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteTenantMutationFn = Apollo.MutationFunction<DeleteTenantMutation, DeleteTenantMutationVariables>;

/**
 * __useDeleteTenantMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMutation, { data, loading, error }] = useDeleteTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTenantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTenantMutation, DeleteTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTenantMutation, DeleteTenantMutationVariables>(DeleteTenantDocument, options);
      }
export type DeleteTenantMutationHookResult = ReturnType<typeof useDeleteTenantMutation>;
export type DeleteTenantMutationResult = Apollo.MutationResult<DeleteTenantMutation>;
export type DeleteTenantMutationOptions = Apollo.BaseMutationOptions<DeleteTenantMutation, DeleteTenantMutationVariables>;
export const TenantDocument = gql`
    query Tenant($tenantId: Int!) {
  tenant(id: $tenantId) {
    tenantName
  }
}
    `;

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useTenantQuery(baseOptions: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
      }
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
        }
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>;
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>;
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>;
export const CreateOnlyShopProductDocument = gql`
    mutation CreateOnlyShopProduct($request: CreateTenpoOnlyProductRequest!) {
  tenpoOnlyProductCreate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type CreateOnlyShopProductMutationFn = Apollo.MutationFunction<CreateOnlyShopProductMutation, CreateOnlyShopProductMutationVariables>;

/**
 * __useCreateOnlyShopProductMutation__
 *
 * To run a mutation, you first call `useCreateOnlyShopProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnlyShopProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnlyShopProductMutation, { data, loading, error }] = useCreateOnlyShopProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateOnlyShopProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnlyShopProductMutation, CreateOnlyShopProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnlyShopProductMutation, CreateOnlyShopProductMutationVariables>(CreateOnlyShopProductDocument, options);
      }
export type CreateOnlyShopProductMutationHookResult = ReturnType<typeof useCreateOnlyShopProductMutation>;
export type CreateOnlyShopProductMutationResult = Apollo.MutationResult<CreateOnlyShopProductMutation>;
export type CreateOnlyShopProductMutationOptions = Apollo.BaseMutationOptions<CreateOnlyShopProductMutation, CreateOnlyShopProductMutationVariables>;
export const UpdateOnlyShopProductDocument = gql`
    mutation UpdateOnlyShopProduct($request: UpdateTenpoOnlyProductRequest!) {
  tenpoOnlyProductUpdate(request: $request) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type UpdateOnlyShopProductMutationFn = Apollo.MutationFunction<UpdateOnlyShopProductMutation, UpdateOnlyShopProductMutationVariables>;

/**
 * __useUpdateOnlyShopProductMutation__
 *
 * To run a mutation, you first call `useUpdateOnlyShopProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnlyShopProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnlyShopProductMutation, { data, loading, error }] = useUpdateOnlyShopProductMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdateOnlyShopProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnlyShopProductMutation, UpdateOnlyShopProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnlyShopProductMutation, UpdateOnlyShopProductMutationVariables>(UpdateOnlyShopProductDocument, options);
      }
export type UpdateOnlyShopProductMutationHookResult = ReturnType<typeof useUpdateOnlyShopProductMutation>;
export type UpdateOnlyShopProductMutationResult = Apollo.MutationResult<UpdateOnlyShopProductMutation>;
export type UpdateOnlyShopProductMutationOptions = Apollo.BaseMutationOptions<UpdateOnlyShopProductMutation, UpdateOnlyShopProductMutationVariables>;
export const DeleteOnlyShopProductDocument = gql`
    mutation DeleteOnlyShopProduct($id: String!) {
  tenpoOnlyProductDelete(id: $id) {
    ...ActionReply
  }
}
    ${ActionReplyFragmentDoc}`;
export type DeleteOnlyShopProductMutationFn = Apollo.MutationFunction<DeleteOnlyShopProductMutation, DeleteOnlyShopProductMutationVariables>;

/**
 * __useDeleteOnlyShopProductMutation__
 *
 * To run a mutation, you first call `useDeleteOnlyShopProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnlyShopProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnlyShopProductMutation, { data, loading, error }] = useDeleteOnlyShopProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOnlyShopProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnlyShopProductMutation, DeleteOnlyShopProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnlyShopProductMutation, DeleteOnlyShopProductMutationVariables>(DeleteOnlyShopProductDocument, options);
      }
export type DeleteOnlyShopProductMutationHookResult = ReturnType<typeof useDeleteOnlyShopProductMutation>;
export type DeleteOnlyShopProductMutationResult = Apollo.MutationResult<DeleteOnlyShopProductMutation>;
export type DeleteOnlyShopProductMutationOptions = Apollo.BaseMutationOptions<DeleteOnlyShopProductMutation, DeleteOnlyShopProductMutationVariables>;
export const ShopProductSearchDocument = gql`
    query ShopProductSearch($request: SearchTenpoOnlyProductRequest) {
  tenpoOnlyProductSearch(request: $request) {
    dataSource {
      ...ShopProductItem
    }
    pages {
      ...Page
    }
  }
}
    ${ShopProductItemFragmentDoc}
${PageFragmentDoc}`;

/**
 * __useShopProductSearchQuery__
 *
 * To run a query within a React component, call `useShopProductSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopProductSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopProductSearchQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useShopProductSearchQuery(baseOptions?: Apollo.QueryHookOptions<ShopProductSearchQuery, ShopProductSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopProductSearchQuery, ShopProductSearchQueryVariables>(ShopProductSearchDocument, options);
      }
export function useShopProductSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopProductSearchQuery, ShopProductSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopProductSearchQuery, ShopProductSearchQueryVariables>(ShopProductSearchDocument, options);
        }
export type ShopProductSearchQueryHookResult = ReturnType<typeof useShopProductSearchQuery>;
export type ShopProductSearchLazyQueryHookResult = ReturnType<typeof useShopProductSearchLazyQuery>;
export type ShopProductSearchQueryResult = Apollo.QueryResult<ShopProductSearchQuery, ShopProductSearchQueryVariables>;