/** @jsxImportSource @emotion/react */
import { ReactNode } from "react"
import { css, SerializedStyles } from "@emotion/react"

// Components
import PageTitle from "views/components/atoms/display/pageTitle"

// Style
import { justify_content_between, ui, space, zIndex, px } from "views/style"
import { bg } from "views/style/palette"

// Const
import { MAX_WIDTH, MaxWidthType } from "logics/const/ui"
import { HeaderHight } from "views/components/organisms/header"

type Props = {
  children?: ReactNode
  style?: SerializedStyles
  aside?: ReactNode
  maxWidth?: MaxWidthType
}
export default function ContentHeader({
  children,
  style,
  aside,
  maxWidth = "list",
}: Props) {
  return (
    <div
      className="content-header"
      css={css`
        top: ${HeaderHight};
        z-index: ${zIndex.appbar};
        position: sticky;
        width: 100%;
        min-height: ${px._64};
        padding: 0 ${space._8};
        background-color: ${bg.contentHeader};
        margin-bottom: ${space._6};
        border-bottom: 2px solid ${bg.contentHeader};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: ${space._4} ${space._0} ${space._2};
          ${justify_content_between};
          max-width: ${maxWidth ? MAX_WIDTH[maxWidth] : ui.mainMaxWidth};
          margin: auto;
          ${style};
        `}
      >
        <PageTitle>{children}</PageTitle>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {aside}
        </div>
      </div>
    </div>
  )
}
