export const USER_MANUAL = {
  franchise: {
    key: "pdf/manual_franchise.pdf",
    name: "pdf/AZLM_管理画面マニュアル [フランチャイズ].pdf",
  },
  tenant: {
    key: "pdf/manual_tenpo.pdf",
    name: "pdf/AZLM_管理画面マニュアル [店舗].pdf",
  },
  shop: {
    key: "pdf/manual_joinmember.pdf",
    name: "pdf/AZLM_管理画面マニュアル [JoinMember].pdf",
  },
}

export const OPERATION_GUIDE = {
  key: "pdf/manual_generalguide.pdf",
  name: "pdf/AZLM運営ガイド.pdf",
}

export enum MANUAL_TYPE {
  General = 1,
  Franchise,
  Tenant,
  Shop,
}

export const ManualOpions = [
  { value: MANUAL_TYPE.General, label: "AZLM運営ガイド" },
  {
    value: MANUAL_TYPE.Franchise,
    label: "AZLM_管理画面マニュアル [フランチャイズ]",
  },
  { value: MANUAL_TYPE.Tenant, label: "AZLM_管理画面マニュアル [店舗]" },
  { value: MANUAL_TYPE.Shop, label: "AZLM_管理画面マニュアル [JoinMember]" },
]
