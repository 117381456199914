/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"

import { Pagination, PaginationProps } from "tw-custom-component"

// Style
import { border, px, space } from "views/style"
import { ReactNode } from "react"

export type Props = {
  children: ReactNode
  pagination: PaginationProps
  style?: SerializedStyles
  isVisible?: boolean
}

export const PaginationWrapper = ({
  children,
  pagination,
  style,
  isVisible = true,
}: Props) => (
  <div
    css={css`
      width: 100%;
      .table {
        width: 100%;
        tr {
          height: ${px._36};
        }
      }
      ${style}
    `}
  >
    {children}
    <div
      css={css`
        margin-top: ${space._5};
        .pagination-type2 {
          .btns-row {
            .btn {
              height: ${px._24};
              width: ${px._24};
              padding: ${space._5};
            }
          }
        }
      `}
    >
      {isVisible ? <Pagination {...pagination} /> : <></>}
    </div>
  </div>
)
