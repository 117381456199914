import { useCallback, useEffect } from "react"
import { atom, selector, useSetRecoilState, useRecoilValue } from "recoil"
import { LoginResponseFragment } from "../graphql"
import { useWs } from "./websocket"

const initialValue: Partial<LoginResponseFragment> = {}

const meState = atom({
  key: "me",
  default: initialValue,
})

export const meSelecter = selector({
  key: "me_handler",
  get: ({ get }) => get(meState),
})

export const useMeState = () => {
  const { handler: wsHandler } = useWs()
  const value = useRecoilValue(meSelecter)
  const handler = useSetRecoilState(meState)
  const onChange = useCallback(
    (data?: LoginResponseFragment | {}) => {
      handler({ ...value, ...data })
    },
    [handler, value]
  )

  useEffect(() => {
    if (!value) return
    wsHandler.sendUser(value as LoginResponseFragment)
  }, [value])

  const onClear = useCallback(() => {
    handler({})
  }, [handler])

  return {
    state: {
      ...value,
    },
    handler: {
      onChange,
      onClear,
    },
  }
}
