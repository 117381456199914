export const white = "#ffffff"
export const primary = "#6EB7DB"
export const error = "#c7243a"
export const success = "#40BFB0"
export const warn = "#F0BA32"
export const gray = "#E2E8F0"
export const link = "#0000FF"
export const font_color = "#3c4b64"
export const border_color = "rgba(243, 244, 246, 1)"
export const totalPayment_color = "#00bfff"
export const textarea_font_color = "#333"
