/** @jsxImportSource @emotion/react */
import { useMemo } from "react"
import { css } from "@emotion/react"
import { Toast as ToastComponent } from "tw-custom-component"
import { useToast } from "logics/store/toast"

// Component
import Card from "views/components/atoms/card"

// Style
import {
  error,
  success,
  warn,
  primary,
  white,
  space,
  align_items_center,
} from "views/style"

export const Toast = () => {
  const { props } = useToast()
  return (
    <ToastComponent
      {...props}
      duration={3000}
      style={css`
        top: ${space._16};
        right: -${space._12};
        ${props.visible && {
          right: `${space._16}`,
          transition: `right 0.3s`,
        }}
      `}
    >
      <Card
        style={css`
          color: ${white};
          ${props?.fail && { backgroundColor: error }}
          ${props?.success && { backgroundColor: success }} 
          ${props?.warn && { backgroundColor: warn }} 
          ${props?.info && { backgroundColor: primary }} 
          ${props.css}
        `}
      >
        <div css={align_items_center}>
          <Icon
            success={props?.success}
            fail={props.fail}
            warn={props.warn}
            info={props?.info}
          />
          <label
            css={css`
              margin-left: ${space._4};
            `}
          >
            {props?.message}
          </label>
        </div>
      </Card>
    </ToastComponent>
  )
}

const Icon = (props: {
  fail?: boolean
  success?: boolean
  warn?: boolean
  info?: boolean
}) => {
  const { fail, success, warn, info } = props
  const icon = useMemo(() => {
    if (fail) return "error"
    if (success) return "check_circle"
    if (warn) return "warning"
    if (info) return "info"
    return null
  }, [props])
  return <>{!!icon && <i className="material-icons">{icon}</i>}</>
}

export * from "./success"
export * from "./error"
