/** @jsxImportSource @emotion/react */
import { ReactNode } from "react"
import { Card as CardComponent } from "tw-custom-component"
import { css, SerializedStyles } from "@emotion/react"

import { space, shadow, radius, px, text } from "views/style"

type Props = {
  children: ReactNode
  style?: SerializedStyles
  noMargin?: boolean
}

export default function Card({ children, style, noMargin }: Props) {
  return (
    <CardComponent
      style={css`
        flex: 1 1 auto;
        background-color: white;
        border: none;
        border-radius: ${radius.md};
        ${!noMargin &&
        css`
          margin-bottom: ${space._6};
        `}
        box-shadow: ${shadow.card};
        ${style}
      `}
    >
      {children}
    </CardComponent>
  )
}
