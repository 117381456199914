import { ReactNode, useMemo } from "react"
import { useField } from "formik"

type Props = {
  name: string
  children: (errorMessage?: string) => ReactNode
}
export default function FormikErrorControl({ name, children }: Props) {
  const [_, meta] = useField(name)
  const errorMessage = useMemo(() => {
    if (meta.error && meta.touched) return meta.error
  }, [meta.error, meta.touched])

  return <>{children(errorMessage)}</>
}
