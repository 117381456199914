export const KEY_TEMPLATES = [
  { label: "", value: "" },
  { label: "サイズ", value: "サイズ" },
  { label: "色", value: "色" },
  { label: "素材", value: "素材" },
  { label: "タイトル", value: "タイトル" },
  { label: "スタイル", value: "スタイル" },
]

export const VARIATION_LIMIT = 3

export type VariationData = {
  id?: string
  label: string
  options: string[]
}

export type SkuEditableData = {
  id?: string
  pattern: { id?: string; label: string; option: string }[]
  price: number | null
  qty: number | null
  cost?: number | null
  sku?: string | null
  barcode?: string | null
}
export const INITIAL_VARIATIONS: {
  label: string
  options: string[]
} = { label: "", options: [] }

export const DEFAULT_SKU_DATA = {
  pattern: [],
  cost: null,
  price: null,
  qty: null,
  sku: null,
  barcode: null,
}
