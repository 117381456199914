import { w3cwebsocket as W3CWebSocket, IMessageEvent } from "websocket"
import { ErrorMessages } from "../../const/"
export class WebSocketHandler {
  client: W3CWebSocket
  constructor(reciver: IReciver) {
    const url = process.env.REACT_APP_WS
    if (!url) throw new Error(ErrorMessages.MustBeSetUrl)
    //
    // 接続
    //
    const client = new W3CWebSocket(url)
    // 接続成功時
    client.onopen = () => {
      console.log("*** connected ***")
    }
    // 接続切断時
    client.onclose = () => {
      console.log("*** closed ***")
    }
    client.onerror = () => {
      console.log("*** error ***")
    }
    // プッシュ受信時
    client.onmessage = (e: IMessageEvent) => {
      const arr = e?.data as unknown
      ;(arr as Blob).arrayBuffer().then((e) => {
        reciver.execute(new Uint8Array(e))
      })
    }
    this.client = client
  }
  // プッシュ送信
  emit = (command: IEmitCommand) => {
    if (this.client?.readyState === this.client?.CONNECTING) return false
    this.client.send(command.execute())
  }
}

export interface IEmitCommand {
  execute: () => Uint8Array
}

export interface IReciver {
  execute: (e: Uint8Array) => void
}
