import * as yup from "yup"
import { ErrorMessages, PRICE_MAX } from "logics/const"

export default function priceRequired() {
  return yup
    .number()
    .typeError(ErrorMessages.NotNumber)
    .required(ErrorMessages.Required)
    .min(0, ErrorMessages.MinusNumber)
    .max(PRICE_MAX, ErrorMessages.PriceOverflow)
}
