import { ui } from "views/style"

export type MaxWidthType = "list" | "register" | "miniRegister"

export const MAX_WIDTH: { [key in MaxWidthType]: string | null } = {
  list: ui.mainMaxWidth,
  register: ui.registerMaxWidth,
  miniRegister: ui.miniRegisterMaxWidth,
}
export const MIN_WIDTH: { [key in MaxWidthType]: string | null } = {
  list: ui.listMinWidth,
  register: "auto",
  miniRegister: "auto",
}
