// source: ws.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.ws.CafeOrder', null, global);
goog.exportSymbol('proto.ws.CafeOrderEvent', null, global);
goog.exportSymbol('proto.ws.CafeOrderItem', null, global);
goog.exportSymbol('proto.ws.Event', null, global);
goog.exportSymbol('proto.ws.EventType', null, global);
goog.exportSymbol('proto.ws.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ws.EventType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ws.EventType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ws.EventType.displayName = 'proto.ws.EventType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ws.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ws.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ws.User.displayName = 'proto.ws.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ws.CafeOrderEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ws.CafeOrderEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ws.CafeOrderEvent.displayName = 'proto.ws.CafeOrderEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ws.CafeOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ws.CafeOrder.repeatedFields_, null);
};
goog.inherits(proto.ws.CafeOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ws.CafeOrder.displayName = 'proto.ws.CafeOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ws.CafeOrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ws.CafeOrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ws.CafeOrderItem.displayName = 'proto.ws.CafeOrderItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ws.EventType.prototype.toObject = function(opt_includeInstance) {
  return proto.ws.EventType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ws.EventType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.EventType.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ws.EventType}
 */
proto.ws.EventType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ws.EventType;
  return proto.ws.EventType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ws.EventType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ws.EventType}
 */
proto.ws.EventType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ws.Event} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ws.EventType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ws.EventType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ws.EventType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.EventType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional Event event = 1;
 * @return {!proto.ws.Event}
 */
proto.ws.EventType.prototype.getEvent = function() {
  return /** @type {!proto.ws.Event} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ws.Event} value
 * @return {!proto.ws.EventType} returns this
 */
proto.ws.EventType.prototype.setEvent = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ws.User.prototype.toObject = function(opt_includeInstance) {
  return proto.ws.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ws.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    loginType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    joinMemberId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tenpoId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    franchiseId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ws.User}
 */
proto.ws.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ws.User;
  return proto.ws.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ws.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ws.User}
 */
proto.ws.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ws.Event} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLoginType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJoinMemberId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTenpoId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFranchiseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ws.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ws.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ws.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLoginType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional Event event = 1;
 * @return {!proto.ws.Event}
 */
proto.ws.User.prototype.getEvent = function() {
  return /** @type {!proto.ws.Event} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ws.Event} value
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.setEvent = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.ws.User.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 login_type = 3;
 * @return {number}
 */
proto.ws.User.prototype.getLoginType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.setLoginType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 join_member_id = 4;
 * @return {number}
 */
proto.ws.User.prototype.getJoinMemberId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.setJoinMemberId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.clearJoinMemberId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.User.prototype.hasJoinMemberId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 tenpo_id = 5;
 * @return {number}
 */
proto.ws.User.prototype.getTenpoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.setTenpoId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.clearTenpoId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.User.prototype.hasTenpoId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 franchise_id = 6;
 * @return {number}
 */
proto.ws.User.prototype.getFranchiseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.setFranchiseId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ws.User} returns this
 */
proto.ws.User.prototype.clearFranchiseId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.User.prototype.hasFranchiseId = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ws.CafeOrderEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.ws.CafeOrderEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ws.CafeOrderEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.CafeOrderEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: (f = msg.getOrder()) && proto.ws.CafeOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ws.CafeOrderEvent}
 */
proto.ws.CafeOrderEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ws.CafeOrderEvent;
  return proto.ws.CafeOrderEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ws.CafeOrderEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ws.CafeOrderEvent}
 */
proto.ws.CafeOrderEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ws.Event} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto.ws.CafeOrder;
      reader.readMessage(value,proto.ws.CafeOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ws.CafeOrderEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ws.CafeOrderEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ws.CafeOrderEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.CafeOrderEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ws.CafeOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional Event event = 1;
 * @return {!proto.ws.Event}
 */
proto.ws.CafeOrderEvent.prototype.getEvent = function() {
  return /** @type {!proto.ws.Event} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ws.Event} value
 * @return {!proto.ws.CafeOrderEvent} returns this
 */
proto.ws.CafeOrderEvent.prototype.setEvent = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional CafeOrder order = 2;
 * @return {?proto.ws.CafeOrder}
 */
proto.ws.CafeOrderEvent.prototype.getOrder = function() {
  return /** @type{?proto.ws.CafeOrder} */ (
    jspb.Message.getWrapperField(this, proto.ws.CafeOrder, 2));
};


/**
 * @param {?proto.ws.CafeOrder|undefined} value
 * @return {!proto.ws.CafeOrderEvent} returns this
*/
proto.ws.CafeOrderEvent.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ws.CafeOrderEvent} returns this
 */
proto.ws.CafeOrderEvent.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.CafeOrderEvent.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ws.CafeOrder.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ws.CafeOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.ws.CafeOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ws.CafeOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.CafeOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tenpoId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalTax: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalPayment: jspb.Message.getFieldWithDefault(msg, 7, 0),
    memo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paymentStatusId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    priceTypeId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    orderStatusId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    recordStatusId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    paymentVendorId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    memberId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    orderItemsList: jspb.Message.toObjectList(msg.getOrderItemsList(),
    proto.ws.CafeOrderItem.toObject, includeInstance),
    createDate: (f = msg.getCreateDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateDate: (f = msg.getUpdateDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ws.CafeOrder}
 */
proto.ws.CafeOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ws.CafeOrder;
  return proto.ws.CafeOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ws.CafeOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ws.CafeOrder}
 */
proto.ws.CafeOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTenpoId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPrice(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalTax(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPayment(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentStatusId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceTypeId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrderStatusId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordStatusId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPaymentVendorId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMemberId(value);
      break;
    case 15:
      var value = new proto.ws.CafeOrderItem;
      reader.readMessage(value,proto.ws.CafeOrderItem.deserializeBinaryFromReader);
      msg.addOrderItems(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateDate(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ws.CafeOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ws.CafeOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ws.CafeOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.CafeOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTenpoId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTotalTax();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotalPayment();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPaymentStatusId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPriceTypeId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getOrderStatusId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getRecordStatusId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPaymentVendorId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getMemberId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getOrderItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.ws.CafeOrderItem.serializeBinaryToWriter
    );
  }
  f = message.getCreateDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateDate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ws.CafeOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.ws.CafeOrder.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 customer_id = 3;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setCustomerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.clearCustomerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.CafeOrder.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 tenpo_id = 4;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getTenpoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setTenpoId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 total_price = 5;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 total_tax = 6;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getTotalTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setTotalTax = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 total_payment = 7;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getTotalPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setTotalPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string memo = 8;
 * @return {string}
 */
proto.ws.CafeOrder.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setMemo = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.clearMemo = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.CafeOrder.prototype.hasMemo = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 payment_status_id = 9;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getPaymentStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setPaymentStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 price_type_id = 10;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getPriceTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setPriceTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 order_status_id = 11;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getOrderStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setOrderStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 record_status_id = 12;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getRecordStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setRecordStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 payment_vendor_id = 13;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getPaymentVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setPaymentVendorId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 member_id = 14;
 * @return {number}
 */
proto.ws.CafeOrder.prototype.getMemberId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.setMemberId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated CafeOrderItem order_items = 15;
 * @return {!Array<!proto.ws.CafeOrderItem>}
 */
proto.ws.CafeOrder.prototype.getOrderItemsList = function() {
  return /** @type{!Array<!proto.ws.CafeOrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ws.CafeOrderItem, 15));
};


/**
 * @param {!Array<!proto.ws.CafeOrderItem>} value
 * @return {!proto.ws.CafeOrder} returns this
*/
proto.ws.CafeOrder.prototype.setOrderItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.ws.CafeOrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ws.CafeOrderItem}
 */
proto.ws.CafeOrder.prototype.addOrderItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.ws.CafeOrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.clearOrderItemsList = function() {
  return this.setOrderItemsList([]);
};


/**
 * optional google.protobuf.Timestamp create_date = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ws.CafeOrder.prototype.getCreateDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ws.CafeOrder} returns this
*/
proto.ws.CafeOrder.prototype.setCreateDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.clearCreateDate = function() {
  return this.setCreateDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.CafeOrder.prototype.hasCreateDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp update_date = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.ws.CafeOrder.prototype.getUpdateDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ws.CafeOrder} returns this
*/
proto.ws.CafeOrder.prototype.setUpdateDate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ws.CafeOrder} returns this
 */
proto.ws.CafeOrder.prototype.clearUpdateDate = function() {
  return this.setUpdateDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ws.CafeOrder.prototype.hasUpdateDate = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ws.CafeOrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ws.CafeOrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ws.CafeOrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.CafeOrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    skuId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    thumbnail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pattern: jspb.Message.getFieldWithDefault(msg, 7, ""),
    taxRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    price: jspb.Message.getFieldWithDefault(msg, 10, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalPrice: jspb.Message.getFieldWithDefault(msg, 12, 0),
    totalTax: jspb.Message.getFieldWithDefault(msg, 13, 0),
    totalPayment: jspb.Message.getFieldWithDefault(msg, 14, 0),
    recordStatusId: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ws.CafeOrderItem}
 */
proto.ws.CafeOrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ws.CafeOrderItem;
  return proto.ws.CafeOrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ws.CafeOrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ws.CafeOrderItem}
 */
proto.ws.CafeOrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkuId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPattern(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTaxRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPrice(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalTax(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPayment(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRecordStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ws.CafeOrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ws.CafeOrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ws.CafeOrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ws.CafeOrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkuId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getThumbnail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPattern();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTaxRate();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getTotalTax();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getTotalPayment();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getRecordStatusId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.ws.CafeOrderItem.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_id = 2;
 * @return {string}
 */
proto.ws.CafeOrderItem.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.ws.CafeOrderItem.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sku_id = 4;
 * @return {string}
 */
proto.ws.CafeOrderItem.prototype.getSkuId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setSkuId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string product_name = 5;
 * @return {string}
 */
proto.ws.CafeOrderItem.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string thumbnail = 6;
 * @return {string}
 */
proto.ws.CafeOrderItem.prototype.getThumbnail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setThumbnail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string pattern = 7;
 * @return {string}
 */
proto.ws.CafeOrderItem.prototype.getPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setPattern = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float tax_rate = 9;
 * @return {number}
 */
proto.ws.CafeOrderItem.prototype.getTaxRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional int32 price = 10;
 * @return {number}
 */
proto.ws.CafeOrderItem.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 quantity = 11;
 * @return {number}
 */
proto.ws.CafeOrderItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_price = 12;
 * @return {number}
 */
proto.ws.CafeOrderItem.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 total_tax = 13;
 * @return {number}
 */
proto.ws.CafeOrderItem.prototype.getTotalTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setTotalTax = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 total_payment = 14;
 * @return {number}
 */
proto.ws.CafeOrderItem.prototype.getTotalPayment = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setTotalPayment = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 record_status_id = 15;
 * @return {number}
 */
proto.ws.CafeOrderItem.prototype.getRecordStatusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.ws.CafeOrderItem} returns this
 */
proto.ws.CafeOrderItem.prototype.setRecordStatusId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * @enum {number}
 */
proto.ws.Event = {
  UNKNOWN: 0,
  USER: 2,
  CAFE_ORDER_COMPLETE: 3
};

goog.object.extend(exports, proto.ws);
