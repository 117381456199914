/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { WidthOption } from "views/components/molecules/form/fieldControl"
import { radius, space, px, white } from "views/style"
import { gray } from "views/style/palette"

type RadioOption = { value: number | boolean; label: string }

export type BasicRadioGroupProps = {
  name: string
  subLabel?: string
  formWidth?: WidthOption
  options: RadioOption[]
}
type AdvancedRadioGroupProps = {
  name: string
  options: RadioOption[]
  selectedValue: number | boolean
  onChange: (value: number | boolean) => void
}

export const RadioGroup: React.FC<AdvancedRadioGroupProps> = ({
  name,
  options,
  selectedValue,
  onChange,
}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        margin-top: ${space._2_5};
      `}
    >
      {options.map((option) => (
        <div
          key={String(option.value)}
          css={css`
            display: flex;
            align-items: center;
            margin-right: ${space._5};
          `}
        >
          <input
            type="radio"
            id={`${name}-${option.value}`}
            name={name}
            value={String(option.value)}
            checked={selectedValue === option.value}
            onChange={() => onChange(option.value)}
            style={{ display: "none" }}
          />
          <label
            htmlFor={`${name}-${option.value}`}
            css={css`
              display: inline-flex;
              align-items: center;
              cursor: pointer;
            `}
          >
            <div
              css={css`
                width: ${px._12};
                height: ${px._12};
                border: 2px solid ${gray._200};
                border-radius: ${radius.md};
                background: ${selectedValue === option.value ? "blue" : white};
                margin-right: ${space._2};
              `}
            ></div>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  )
}
