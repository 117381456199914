/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"
import { useField, FieldHelperProps } from "formik"
import { Input, Select, TableProps, Textarea } from "tw-custom-component"
import { ReactElement, ReactNode, useEffect, useRef } from "react"
import ErrorMessage from "./errorMessage"
import ToggleButtons, {
  ToggleButtonsData,
} from "views/components/atoms/input/toggleButtons"
import { Table } from "views/components/organisms/table"

// Style
import { px, border, text, space, textarea_font_color } from "views/style"
import { error } from "views/style/palette"
import {
  BasicRadioGroupProps,
  RadioGroup,
} from "views/components/atoms/input/radioButtons"

export type CommonProps = {
  style?: SerializedStyles
  name: string
  type?: string
  subLabel?: ReactNode
  formWidth?: WidthOption
  /** @description ↓ Formikに対応していないライブラリ系インプットなどで使う（nameが渡せないがために、touchedが動かない場合あり） */
  simulateTouched?: boolean
}
/** @description TWComponentsの「...Row」の影響範囲が怖いので、こちらで移植して修正した。
 * Formikと密結合しているため、使い方を制限することに注意（FormikProviderと組み合わせないとエラーが出るなど）。
 * */
export const WIDTH_OPTION = {
  TWENTY_FIVE: "25%",
  FIFTY: "50%",
  SEVENTY_FIVE: "75%",
  HUNDRED: "100%",
} as const

export type WidthOption = typeof WIDTH_OPTION[keyof typeof WIDTH_OPTION]

export const createFramedFormStyle = (formWidth?: WidthOption) => {
  const containerPadding = css`
    padding-top: ${space._3};
    padding-right: ${space._10};
    padding-bottom: ${space._3};
  `
  switch (formWidth) {
    case WIDTH_OPTION.TWENTY_FIVE:
      return css`
        display: inline-block;
        width: 25%;
        ${containerPadding}
      `
    case WIDTH_OPTION.FIFTY:
      return css`
        display: inline-block;
        width: 50%;
        ${containerPadding}
      `
    case WIDTH_OPTION.SEVENTY_FIVE:
      return css`
        display: inline-block;
        width: 75%;
        ${containerPadding}
      `
    case WIDTH_OPTION.HUNDRED:
      return css`
        display: flex;
        flex-direction: column;
        width: 100%;
        ${containerPadding}
      `
    default:
      return css``
  }
}

export default function FormikFieldControl({
  children,
  name,
  type,
  simulateTouched,
}: {
  children: (field: any, helpers: FieldHelperProps<any>) => ReactElement
} & CommonProps) {
  const [field, meta, helpers] = useField({ name, type })
  const isError = meta.error && meta.touched
  field.onBlur = simulateTouched ? () => helpers.setTouched(true) : field.onBlur

  return (
    <div
      css={
        isError
          ? css`
              input,
              select,
              textarea,
              .DraftEditor-root {
                border: 2px solid ${error.main} !important;
              }
            `
          : undefined
      }
    >
      {children(field, helpers)}
      {isError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  )
}

export const FormikInput = (
  props: JSX.IntrinsicElements["input"] & CommonProps
) => {
  const { name, type, children, subLabel, formWidth, ...nativeProps } = props
  const containerStyle = createFramedFormStyle(formWidth)
  return (
    <div css={containerStyle}>
      {subLabel && (
        <div
          css={css`
            padding-bottom: ${space._3};
          `}
        >
          {subLabel}
        </div>
      )}
      <FormikFieldControl {...{ name, type }}>
        {(field) => (
          <Input {...field} {...nativeProps} name={name} type={type} />
        )}
      </FormikFieldControl>
    </div>
  )
}

export const FormikSelect = <
  T extends {
    value: JSX.IntrinsicElements["option"]["value"]
    label?: string
  } & Object
>(
  props: JSX.IntrinsicElements["select"] & {
    dataSource?: T[]
  } & CommonProps
) => {
  const { name, children, dataSource, subLabel, formWidth, ...nativeProps } =
    props
  const containerStyle = createFramedFormStyle(formWidth)
  return (
    <div css={containerStyle}>
      {subLabel && (
        <div
          css={css`
            padding-bottom: ${space._3};
          `}
        >
          {subLabel}
        </div>
      )}
      <FormikFieldControl name={name}>
        {(field) => (
          <Select {...field} {...nativeProps} name={name}>
            <>
              {!!dataSource?.length
                ? dataSource?.map((data, index) => (
                    <option value={data.value} key={index}>
                      {data?.label ?? String(data.value)}
                    </option>
                  ))
                : children}
            </>
          </Select>
        )}
      </FormikFieldControl>
    </div>
  )
}

export const FormikTable = <T extends {}>(
  props: {
    tableProps: TableProps<T>
    loading?: boolean
    style?: SerializedStyles
  } & CommonProps
) => {
  const { name, tableProps, loading } = props
  const [_, __, helpers] = useField({ name })

  const prev = useRef<T[] | null>(null)
  useEffect(() => {
    // 場合によっては diff ライブラリ入れて判定
    if (JSON.stringify(prev.current) === JSON.stringify(tableProps.dataSource))
      return
    helpers.setValue(tableProps.dataSource)
    prev.current = tableProps.dataSource
  }, [tableProps.dataSource])

  return (
    <FormikFieldControl name={name}>
      {(field) => (
        <Table {...field} tableProps={tableProps} loading={loading} />
      )}
    </FormikFieldControl>
  )
}

export const FormikTextarea = (
  props: JSX.IntrinsicElements["textarea"] & CommonProps
) => {
  const { name, children, ...nativeProps } = props
  const textareaInputStyle = css`
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: ${textarea_font_color};
    padding: ${space._4}
    border-radius: 4px;
  `
  return (
    <FormikFieldControl {...{ name }}>
      {(field) => (
        <Textarea
          {...field}
          {...nativeProps}
          name={name}
          css={textareaInputStyle}
        />
      )}
    </FormikFieldControl>
  )
}

export const FormikToggleButtons = (
  props: { dataSource: ToggleButtonsData[] } & JSX.IntrinsicElements["input"] &
    CommonProps
) => {
  const { name, ...toggleButtonProps } = props
  return (
    <FormikFieldControl {...{ name }}>
      {(field, helpers) => {
        return (
          <ToggleButtons
            {...field}
            {...toggleButtonProps}
            name={name}
            onChange={(value) => {
              helpers.setValue(value)
            }}
          />
        )
      }}
    </FormikFieldControl>
  )
}

export const FormikRadioGroup: React.FC<BasicRadioGroupProps> = ({
  name,
  subLabel,
  formWidth,
  options,
}) => {
  const containerStyle = createFramedFormStyle(formWidth)

  return (
    <div css={containerStyle}>
      {subLabel && (
        <div
          css={css`
            padding-bottom: ${space._2};
          `}
        >
          {subLabel}
        </div>
      )}
      <FormikFieldControl name={name}>
        {(field, helpers) => {
          return (
            <RadioGroup
              name={name}
              options={options}
              selectedValue={field.value}
              onChange={(value) => helpers.setValue(value)}
            />
          )
        }}
      </FormikFieldControl>
    </div>
  )
}

export const TimePicker = (
  props: JSX.IntrinsicElements["input"] & CommonProps
) => (
  <FormikInput
    {...props}
    type="time"
    style={css`
      font-family: inherit;
      width: ${px._160}!important;
      font-size: ${text.xxs};
      ${border.css.normal}
    `}
  />
)
