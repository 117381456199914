export enum BannerType {
  Top = 1,
}

export const BannerTypeOptions = [
  { value: BannerType.Top, label: "トップ画面" },
]

export const BannerSortOptions = Array.from({ length: 100 }).map((_, i) => ({
  value: i,
}))
