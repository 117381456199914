import { css } from "@emotion/react"
import { SideMenuWidth } from "views/components/organisms/sideMenu"
import { HeaderHight } from "views/components/organisms/header"
import { bg } from "views/style/palette"

export const body = css`
  width: calc(100vw - ${SideMenuWidth});
  height: 100vh;
  margin-left: ${SideMenuWidth};
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  background-color: ${bg.primary};
`

export const close = css`
  width: 100vw;
  margin-left: 0;
`
export const main = css`
  min-hight: calc(100vh - ${HeaderHight});
`
