import * as yup from "yup"
import { ErrorMessages, PRICE_MAX } from "logics/const"

export default function priceOptional() {
  return yup
    .number()
    .nullable()
    .typeError(ErrorMessages.NotNumber)
    .min(0, ErrorMessages.MinusNumber)
    .max(PRICE_MAX, ErrorMessages.PriceOverflow)
}
