export const BASE_SPACE = 0.25

const space = {
  _0: 0 + "rem",
  _0_25: BASE_SPACE * 0.25 + "rem",
  _0_5: BASE_SPACE * 0.5 + "rem",
  _0_75: BASE_SPACE * 0.75 + "rem",
  _1: BASE_SPACE * 1 + "rem",
  _1_5: BASE_SPACE * 1.5 + "rem",
  _2: BASE_SPACE * 2 + "rem",
  _2_3: BASE_SPACE * 2.3 + "rem",
  _2_5: BASE_SPACE * 2.5 + "rem",
  _3: BASE_SPACE * 3 + "rem",
  _4: BASE_SPACE * 4 + "rem",
  _5: BASE_SPACE * 5 + "rem",
  _6: BASE_SPACE * 6 + "rem",
  _7: BASE_SPACE * 7 + "rem",
  _8: BASE_SPACE * 8 + "rem",
  _9: BASE_SPACE * 9 + "rem",
  _10: BASE_SPACE * 10 + "rem",
  _11: BASE_SPACE * 11 + "rem",
  _12: BASE_SPACE * 12 + "rem",
  _14: BASE_SPACE * 14 + "rem",
  _16: BASE_SPACE * 16 + "rem",
  _20: BASE_SPACE * 20 + "rem",
  _24: BASE_SPACE * 24 + "rem",
  _28: BASE_SPACE * 28 + "rem",
  _32: BASE_SPACE * 32 + "rem",
  _36: BASE_SPACE * 36 + "rem",
  _40: BASE_SPACE * 40 + "rem",
  _44: BASE_SPACE * 44 + "rem",
  _48: BASE_SPACE * 48 + "rem",
  _52: BASE_SPACE * 52 + "rem",
  _56: BASE_SPACE * 56 + "rem",
  _60: BASE_SPACE * 60 + "rem",
  _64: BASE_SPACE * 64 + "rem",
  _72: BASE_SPACE * 72 + "rem",
  _80: BASE_SPACE * 80 + "rem",
  _96: BASE_SPACE * 96 + "rem",
}

export default space
