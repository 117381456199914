/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"

import { gray, secondary } from "views/style/palette"
import { radius, space, text } from "views/style"

export const RequiredChip = (props: { style?: SerializedStyles }) => (
  <i
    css={css`
      display: inline-block;
      background-color: ${secondary.main};
      padding: ${space._1_5} ${space._1_5} ${space._1_5};
      color: ${gray._0};
      font-size: ${text.xxs};
      line-height: 1;
      ${props?.style};
      border-radius: ${radius.md};
      margin-left: ${space._2};
    `}
  >
    必須
  </i>
)
