/** @jsxImportSource @emotion/react */
import { ReactNode, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { css } from "@emotion/react"

// Logics
import { ScreenType, MANUAL_TYPE, Authority } from "logics/const"
import { useLogout } from "logics/account"

// Compoents
import { PATHS } from "views/routes"
import { gray, primary } from "views/style/palette"
import { radius, space } from "views/style"

export const useSideMenu = (authorityId?: number | null) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    handler: { logout },
  } = useLogout({ onCompleted: () => navigate(PATHS.login.top) })

  const [base, menus] = useMemo(() => {
    const env = process.env.REACT_APP_SCREEN_TYPE
    const domain =
      env === ScreenType.Shop
        ? process.env.REACT_APP_SITE_DOMAIN_SHOP
        : env === ScreenType.Tenant
        ? process.env.REACT_APP_SITE_DOMAIN_TENANT
        : env === ScreenType.Franchise
        ? process.env.REACT_APP_SITE_DOMAIN_FRANCHISE
        : process.env.REACT_APP_SITE_DOMAINL_MASTER
    const Logout = {
      title: "ログアウト",
      icon: "",
      onClick: () => logout(),
    }
    const Dashboard = {
      title: "ダッシュボード",
      icon: "",
      onClick: () => navigate(PATHS.dashboard),
    }
    const OperationGuide = {
      title: "運営ガイド",
      icon: "",
      onClick: () => {
        if (!domain) return
        window.open(
          `${domain}/#${PATHS.manual.detail}/${MANUAL_TYPE.General}`,
          "_blank"
        )
      },
    }
    const UserManual = {
      title: "ユーザーマニュアル",
      icon: "",
      onClick: () => {
        const type =
          env === ScreenType.Shop
            ? MANUAL_TYPE.Shop
            : env === ScreenType.Tenant
            ? MANUAL_TYPE.Tenant
            : env === ScreenType.Franchise
            ? MANUAL_TYPE.Franchise
            : null
        if (!domain || !type) return
        window.open(`${domain}/#${PATHS.manual.detail}/${type}`, "_blank")
      },
    }
    //
    // Shop
    //
    const ShopMenu = [
      Dashboard,
      {
        title: "商品管理",
        icon: "",
        defaultActive: !!pathname.match(new RegExp(`^/product/`)),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.product.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.product.register &&
                  pathname !== PATHS.product.onlyShopRegister &&
                  pathname !== `${PATHS.product.onlyShopProductList}/1` &&
                  !pathname.includes(PATHS.product.onlyShopUpdate) &&
                  !pathname.includes(PATHS.product.onlyShopReplicate) &&
                  pathname !== `${PATHS.product.analytics}/1` &&
                  !!pathname.match(new RegExp(`^/product/`))
                }
              >
                商品一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.product.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.product.register}>
                商品登録
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(`${PATHS.product.onlyShopProductList}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname === `${PATHS.product.onlyShopProductList}/1` ||
                  pathname.includes(PATHS.product.onlyShopUpdate) ||
                  pathname.includes(PATHS.product.onlyShopReplicate)
                }
              >
                販売物なし商品一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 4,
            onClick: () => navigate(PATHS.product.onlyShopRegister),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.product.onlyShopRegister}
              >
                販売物なし商品登録
              </SideMenuTitle>
            ),
          },
          {
            id: 5,
            onClick: () => navigate(`${PATHS.product.analytics}/1`),
            title: () => (
              <SideMenuTitle
                active={pathname === `${PATHS.product.analytics}/1`}
              >
                出展商品分析
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "出展申請",
        icon: "",
        defaultActive: !!pathname.match(new RegExp(`^/block/`)),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.block.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.list}/[0-9]+`))
                }
              >
                展示スペース一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.block.product.list),
            title: () => (
              <SideMenuTitle
                active={
                  pathname === PATHS.block.product.list ||
                  pathname.includes(PATHS.block.application)
                }
              >
                出展申請・管理
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "注文管理",
        icon: "",
        defaultActive: pathname.includes("/order"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.order.list}/1`),
            title: () => (
              <SideMenuTitle active={pathname.includes(PATHS.order.list)}>
                注文一覧
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "各種設定",
        icon: "",
        defaultActive: pathname.includes("/shop"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.shop.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.shop.list &&
                  pathname !== PATHS.shop.register &&
                  (pathname.includes(PATHS.shop.account.list) ||
                    pathname.includes(PATHS.shop.account.detail) ||
                    pathname.includes(PATHS.shop.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.shop.account.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.shop.account.register}>
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      UserManual,
      OperationGuide,
      Logout,
    ]
    //
    // Tenant
    //
    const CafeManagement = {
      title: "カフェ 管理",
      icon: "",
      defaultActive: pathname.includes("/cafe"),
      dataSource: [
        {
          id: 1,
          onClick: () => navigate(`${PATHS.cafe.product.list}/1`),
          title: () => (
            <SideMenuTitle
              active={
                pathname.includes(PATHS.cafe.product.list) ||
                pathname.includes(PATHS.cafe.product.update)
              }
            >
              商品一覧
            </SideMenuTitle>
          ),
        },
        {
          id: 2,
          onClick: () => navigate(PATHS.cafe.product.register),
          title: () => (
            <SideMenuTitle
              active={pathname.includes(PATHS.cafe.product.register)}
            >
              商品登録
            </SideMenuTitle>
          ),
        },
        {
          id: 3,
          onClick: () => navigate(PATHS.cafe.order.list),
          title: () => (
            <SideMenuTitle active={pathname.includes(PATHS.cafe.order.list)}>
              注文一覧
            </SideMenuTitle>
          ),
        },
      ],
    }

    const CafeAuthorityMenu = [
      CafeManagement,
      UserManual,
      OperationGuide,
      Logout,
    ]
    const TenantMenu = [
      Dashboard,
      CafeManagement,
      {
        title: "展示スペース管理",
        icon: "",
        defaultActive: !!pathname.match(new RegExp(`^/block/`)),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.block.settings}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.settings}/[0-9]+`))
                }
              >
                基本設定
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(`${PATHS.block.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.list}/[0-9]+`))
                }
              >
                展示スペース一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(PATHS.block.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.block.register}>
                展示スペース登録
              </SideMenuTitle>
            ),
          },
          {
            id: 4,
            onClick: () => navigate(PATHS.block.product.list),
            title: () => (
              <SideMenuTitle
                active={
                  pathname === PATHS.block.product.list ||
                  pathname.includes(PATHS.block.application)
                }
              >
                出展申請・管理
              </SideMenuTitle>
            ),
          },
          {
            id: 5,
            onClick: () => navigate(`${PATHS.block.schedule}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.schedule}/[0-9]+`))
                }
              >
                出展スケジュール
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "各種設定",
        icon: "",
        defaultActive: pathname.includes("/tenant"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.tenant.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.tenant.list &&
                  pathname !== PATHS.tenant.register &&
                  (pathname.includes(PATHS.tenant.account.list) ||
                    pathname.includes(PATHS.tenant.account.detail) ||
                    pathname.includes(PATHS.tenant.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.tenant.account.register),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.tenant.account.register}
              >
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      UserManual,
      OperationGuide,
      Logout,
    ]
    //
    // Franchise
    //
    const FranchiseMenu = [
      Dashboard,
      {
        title: "店舗管理",
        icon: "",
        defaultActive: pathname.includes("/tenant"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.tenant.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.tenant.register &&
                  !pathname.includes(PATHS.tenant.account.list) &&
                  !pathname.includes(PATHS.tenant.account.register) &&
                  !pathname.includes(PATHS.tenant.account.detail) &&
                  !pathname.includes(PATHS.tenant.account.update) &&
                  pathname.includes(PATHS.tenant.list)
                }
              >
                店舗一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.tenant.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.tenant.register}>
                店舗登録
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(`${PATHS.tenant.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.tenant.list &&
                  pathname !== PATHS.tenant.register &&
                  (pathname.includes(PATHS.tenant.account.list) ||
                    pathname.includes(PATHS.tenant.account.detail) ||
                    pathname.includes(PATHS.tenant.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 4,
            onClick: () => navigate(PATHS.tenant.account.register),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.tenant.account.register}
              >
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "Join Member 管理",
        icon: "",
        defaultActive: pathname.includes("/shop"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.shop.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.shop.register &&
                  !pathname.includes(PATHS.shop.account.list) &&
                  !pathname.includes(PATHS.shop.account.register) &&
                  !pathname.includes(PATHS.shop.account.detail) &&
                  !pathname.includes(PATHS.shop.account.update) &&
                  pathname.includes(PATHS.shop.list)
                }
              >
                Join Member 一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.shop.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.shop.register}>
                Join Member 登録
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(`${PATHS.shop.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.shop.list &&
                  pathname !== PATHS.shop.register &&
                  (pathname.includes(PATHS.shop.account.list) ||
                    pathname.includes(PATHS.shop.account.detail) ||
                    pathname.includes(PATHS.shop.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 4,
            onClick: () => navigate(PATHS.shop.account.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.shop.account.register}>
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "商品管理",
        icon: "",
        defaultActive: !!pathname.match(new RegExp(`^/product/`)),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.product.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.product.register &&
                  pathname !== PATHS.product.onlyShopRegister &&
                  !pathname.includes(PATHS.product.onlyShopProductList) &&
                  pathname !== `${PATHS.product.analytics}/1` &&
                  !!pathname.match(new RegExp(`^/product/`))
                }
              >
                商品一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.product.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.product.register}>
                商品登録
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(`${PATHS.product.onlyShopProductList}/1`),
            title: () => (
              <SideMenuTitle
                active={pathname === `${PATHS.product.onlyShopProductList}/1`}
              >
                販売物なし商品一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 4,
            onClick: () => navigate(PATHS.product.onlyShopRegister),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.product.onlyShopRegister}
              >
                販売物なし商品登録
              </SideMenuTitle>
            ),
          },
          {
            id: 5,
            onClick: () => navigate(`${PATHS.product.analytics}/1`),
            title: () => (
              <SideMenuTitle
                active={pathname === `${PATHS.product.analytics}/1`}
              >
                出展商品分析
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "展示スペース管理",
        icon: "",
        defaultActive: !!pathname.match(new RegExp(`^/block/`)),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.block.settings}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.settings}/[0-9]+`))
                }
              >
                基本設定
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(`${PATHS.block.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.list}/[0-9]+`))
                }
              >
                展示スペース一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(PATHS.block.product.list),
            title: () => (
              <SideMenuTitle
                active={
                  pathname === PATHS.block.product.list ||
                  pathname.includes(PATHS.block.application)
                }
              >
                出展申請・管理
              </SideMenuTitle>
            ),
          },
          {
            id: 5,
            onClick: () => navigate(`${PATHS.block.schedule}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.schedule}/[0-9]+`))
                }
              >
                出展スケジュール
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "アンケート設定",
        icon: "",
        defaultActive: pathname.includes("/survey"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.survey.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.survey.register &&
                  pathname.includes(PATHS.survey.list)
                }
              >
                アンケート 一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.survey.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.survey.register}>
                アンケート 登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "クーポン管理",
        icon: "",
        defaultActive: pathname.includes("/coupon"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.coupon.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.coupon.register &&
                  pathname.includes(PATHS.coupon.list)
                }
              >
                クーポン一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.coupon.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.coupon.register}>
                クーポン登録
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(`${PATHS.coupon.myList}/1`),
            title: () => (
              <SideMenuTitle active={pathname.includes(PATHS.coupon.myList)}>
                クーポン使用履歴
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "スタンプカード管理",
        icon: "",
        defaultActive: pathname.includes("/stampCard"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.stampCard.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.stampCard.register &&
                  pathname.includes(PATHS.stampCard.list)
                }
              >
                スタンプカード一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.stampCard.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.stampCard.register}>
                スタンプカード登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "各種設定",
        icon: "",
        defaultActive: pathname.includes("/franchise"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.franchise.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.franchise.list &&
                  pathname !== PATHS.franchise.register &&
                  (pathname.includes(PATHS.franchise.account.list) ||
                    pathname.includes(PATHS.franchise.account.detail) ||
                    pathname.includes(PATHS.franchise.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.franchise.account.register),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.franchise.account.register}
              >
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      UserManual,
      OperationGuide,
      Logout,
    ]
    //
    // Master
    //
    const MasterMenu = [
      Dashboard,
      {
        title: "顧客管理",
        icon: "",
        defaultActive: pathname.includes("/customer"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.customer.list}/1`),
            title: () => (
              <SideMenuTitle active={pathname.includes(PATHS.customer.list)}>
                顧客一覧
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "カフェ 管理",
        icon: "",
        defaultActive: pathname.includes("/cafe"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(PATHS.cafe.order.list),
            title: () => (
              <SideMenuTitle active={pathname.includes(PATHS.cafe.order.list)}>
                注文一覧
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "フランチャイズ管理",
        icon: "",
        defaultActive: pathname.includes("/franchise"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.franchise.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.franchise.register &&
                  !pathname.includes(PATHS.franchise.account.list) &&
                  !pathname.includes(PATHS.franchise.account.register) &&
                  !pathname.includes(PATHS.franchise.account.detail) &&
                  !pathname.includes(PATHS.franchise.account.update) &&
                  pathname.includes(PATHS.franchise.list)
                }
              >
                フランチャイズ 一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.franchise.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.franchise.register}>
                フランチャイズ 登録
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(`${PATHS.franchise.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.franchise.list &&
                  pathname !== PATHS.franchise.register &&
                  (pathname.includes(PATHS.franchise.account.list) ||
                    pathname.includes(PATHS.franchise.account.detail) ||
                    pathname.includes(PATHS.franchise.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 4,
            onClick: () => navigate(PATHS.franchise.account.register),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.franchise.account.register}
              >
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "店舗管理",
        icon: "",
        defaultActive: pathname.includes("/tenant"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.tenant.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.tenant.register &&
                  !pathname.includes(PATHS.tenant.account.list) &&
                  !pathname.includes(PATHS.tenant.account.register) &&
                  !pathname.includes(PATHS.tenant.account.detail) &&
                  !pathname.includes(PATHS.tenant.account.update) &&
                  pathname.includes(PATHS.tenant.list)
                }
              >
                店舗一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(`${PATHS.tenant.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.tenant.list &&
                  pathname !== PATHS.tenant.register &&
                  (pathname.includes(PATHS.tenant.account.list) ||
                    pathname.includes(PATHS.tenant.account.detail) ||
                    pathname.includes(PATHS.tenant.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(PATHS.tenant.account.register),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.tenant.account.register}
              >
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "Join Member 管理",
        icon: "",
        defaultActive: pathname.includes("/shop"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.shop.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.shop.register &&
                  !pathname.includes(PATHS.shop.account.list) &&
                  !pathname.includes(PATHS.shop.account.register) &&
                  !pathname.includes(PATHS.shop.account.detail) &&
                  !pathname.includes(PATHS.shop.account.update) &&
                  pathname.includes(PATHS.shop.list)
                }
              >
                Join Member 一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(`${PATHS.shop.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.shop.list &&
                  pathname !== PATHS.shop.register &&
                  (pathname.includes(PATHS.shop.account.list) ||
                    pathname.includes(PATHS.shop.account.detail) ||
                    pathname.includes(PATHS.shop.account.update))
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(PATHS.shop.account.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.shop.account.register}>
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "商品管理",
        icon: "",
        defaultActive: !!pathname.match(new RegExp(`^/product/`)),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.product.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.product.register &&
                  pathname !== PATHS.product.onlyShopRegister &&
                  pathname !== PATHS.product.onlyShopRegister &&
                  !pathname.includes(PATHS.product.onlyShopProductList) &&
                  pathname !== `${PATHS.product.analytics}/1` &&
                  !!pathname.match(new RegExp(`^/product/`))
                }
              >
                商品一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(`${PATHS.product.analytics}/1`),
            title: () => (
              <SideMenuTitle
                active={pathname === `${PATHS.product.analytics}/1`}
              >
                出展商品分析
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "展示スペース管理",
        icon: "",
        defaultActive: !!pathname.match(new RegExp(`^/block/`)),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.block.schedule}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  !!pathname.match(new RegExp(`${PATHS.block.schedule}/[0-9]+`))
                }
              >
                出展スケジュール
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "注文管理",
        icon: "",
        defaultActive:
          pathname.includes("/order") && !pathname.includes("/cafe/order/list"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.order.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname.includes(PATHS.order.list) &&
                  !pathname.includes("/cafe/order/list")
                }
              >
                注文一覧
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "アンケート設定",
        icon: "",
        defaultActive: pathname.includes("/survey"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.survey.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.survey.register &&
                  pathname.includes(PATHS.survey.list)
                }
              >
                アンケート 一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.survey.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.survey.register}>
                アンケート 登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "クーポン管理",
        icon: "",
        defaultActive: pathname.includes("/coupon"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.coupon.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname !== PATHS.coupon.register &&
                  pathname.includes(PATHS.coupon.list)
                }
              >
                クーポン一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.coupon.register),
            title: () => (
              <SideMenuTitle active={pathname === PATHS.coupon.register}>
                クーポン登録
              </SideMenuTitle>
            ),
          },
          {
            id: 3,
            onClick: () => navigate(`${PATHS.coupon.myList}/1`),
            title: () => (
              <SideMenuTitle active={pathname.includes(PATHS.coupon.myList)}>
                クーポン使用履歴
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "バナー管理",
        icon: "",
        defaultActive: pathname.includes("/banner"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.banner.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname.includes(PATHS.banner.list) &&
                  !pathname.includes(PATHS.banner.register)
                }
              >
                バナー一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 1,
            onClick: () => navigate(PATHS.banner.register),
            title: () => (
              <SideMenuTitle active={pathname.includes(PATHS.banner.register)}>
                バナー登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "マニュアル管理",
        icon: "",
        defaultActive: pathname.includes("/manual"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.manual.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname.includes(PATHS.manual.list) &&
                  !pathname.includes(PATHS.manual.register)
                }
              >
                マニュアル一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.manual.register),
            title: () => (
              <SideMenuTitle active={pathname.includes(PATHS.manual.register)}>
                マニュアル登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      {
        title: "各種設定",
        icon: "",
        defaultActive: pathname.includes("/master"),
        dataSource: [
          {
            id: 1,
            onClick: () => navigate(`${PATHS.master.account.list}/1`),
            title: () => (
              <SideMenuTitle
                active={
                  pathname.includes(PATHS.master.account.list) ||
                  pathname.includes(PATHS.master.account.detail) ||
                  pathname.includes(PATHS.master.account.update)
                }
              >
                アカウント一覧
              </SideMenuTitle>
            ),
          },
          {
            id: 2,
            onClick: () => navigate(PATHS.master.account.register),
            title: () => (
              <SideMenuTitle
                active={pathname === PATHS.master.account.register}
              >
                アカウント登録
              </SideMenuTitle>
            ),
          },
        ],
      },
      Logout,
    ]
    const menu: {
      title: string
      icon: string
      onClick?: () => void
      defaultActive?: boolean
      dataSource?: {
        id: number
        onClick: () => void
        title: () => JSX.Element
      }[]
    }[] =
      env === ScreenType.Shop
        ? ShopMenu
        : env === ScreenType.Tenant
        ? authorityId === Authority.Staff
          ? CafeAuthorityMenu
          : TenantMenu
        : env === ScreenType.Franchise
        ? FranchiseMenu
        : MasterMenu
    return [
      menu,
      menu.map(({ title, icon, ...other }) => ({
        title: () => (
          <div>
            {icon && <i className="material-icons">{icon}</i>}
            <label>{title}</label>
          </div>
        ),
        ...other,
      })),
    ]
  }, [])

  return { menus, base }
}

const SideMenuTitle = ({
  children,
  active,
}: {
  children: ReactNode
  active: boolean
}) => {
  return (
    <div
      css={css`
        border-radius: ${radius.sm} 0 0 ${radius.sm};
        padding: ${space._3} ${space._6};
        ${active &&
        css`
          font-weight: 500;
          // color: ${primary._500};
          // background-color: hsl(224, 34%, 100%);
          color: ${gray._0};
          background-color: ${gray._700};
        `}
      `}
    >
      {children}
    </div>
  )
}
