/** @jsxImportSource @emotion/react */
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ja from "date-fns/locale/ja"
import { css, SerializedStyles } from "@emotion/react"
import { radius, shadow, space, text, input_common } from "views/style"
import { gray, primary } from "views/style/palette"

export type Props = Pick<
  ReactDatePickerProps,
  | "showTimeInput"
  | "onChange"
  | "selected"
  | "isClearable"
  | "dateFormat"
  | "name"
  | "id"
  | "value"
  | "showMonthYearPicker"
  | "showFullMonthYearPicker"
  | "showFourColumnMonthYearPicker"
  | "placeholderText"
> & { style?: SerializedStyles }

export const DatePicker = (props: Props) => {
  registerLocale("ja", ja)
  return (
    <div
      css={css`
        input {
          ${input_common};
          border: 1px solid ${gray._300};
        }
        .react-datepicker {
          box-shadow: ${shadow.card};
          border: 1px solid ${gray._200};
        }
        .react-datepicker__header {
          border-bottom: none;
          background-color: white;
          padding: ${space._3} ${space._2};
          font-size: ${text.lg};
        }
        .react-datepicker__month {
          margin: ${space._4};
          .react-datepicker__month-text {
            padding: ${space._2} ${space._2};
            border-radius: ${radius.md};
            font-size: ${text.base};
            transition: 200ms ease;
            &.react-datepicker__month-text--keyboard-selected {
              background-color: ${primary._500};
            }
          }
        }
        ${props?.style}
      `}
    >
      <ReactDatePicker {...props} showPopperArrow={false} locale={ja} />
    </div>
  )
}
