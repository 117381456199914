import { useState, useCallback, useEffect } from "react"
import update from "immutability-helper"

import { readAsDataURLAsync } from "logics/utils/image"

export type ImageSource = { path: string; name?: string; type?: string }

export const useCreateImageInput = (defaultDataSource?: ImageSource[]) => {
  const [dataSource, setDataSource] = useState(defaultDataSource ?? [])

  const onDrop = async (data: any) => {
    const images = await Promise.all(
      data?.map(async (item: File) => readAsDataURLAsync(item))
    ).then((res) =>
      res.map((dataUrl: string, index) => {
        const file = data?.[index]
        const name = (file?.name ?? "") as string
        const type = (file?.type ?? "") as string
        return {
          ...(data?.[index] ?? {}),
          name,
          path: dataUrl,
          type,
        }
      })
    )
    setDataSource((prev) => [...prev, ...images])
  }

  const onDrag = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragTarget = dataSource[dragIndex]

      const _dataSource = update(dataSource, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragTarget],
        ],
      })

      setDataSource(_dataSource)
    },
    [dataSource, setDataSource]
  )

  const onDelete = useCallback(
    (index: number) => {
      const _dataSource = [...dataSource]
      _dataSource.splice(index, 1)
      setDataSource(_dataSource)
    },
    [dataSource, setDataSource]
  )

  useEffect(() => {
    defaultDataSource && setDataSource(defaultDataSource)
  }, [defaultDataSource])

  return {
    props: {
      dataSource,
      onDrop,
      onDrag,
      onDelete,
    },
  }
}
