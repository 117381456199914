import { SkuEditableData } from "./variations"

export enum CafeProductStatus {
  Draft = 1,
  Public,
}

export const CafeProductStatusOptions = [
  { value: CafeProductStatus.Draft, label: "下書き" },
  { value: CafeProductStatus.Public, label: "公開" },
]

export enum OrderType {
  EatIn = 1,
  TakeOut,
}

export enum MemberType {
  NonMember = 1,
  Member,
}

export type CafeSkuEditableData = {
  id?: string
  cost?: number | null
  pattern: { label: string; option: string }[]
  prices: {
    amount: number
    isReducedTax: boolean
    priceWithTax: number
    type: number
  }[]
  qty: number | null
  sku?: string | null
  barcode?: string | null
  eatInMemberPrice?: number | null
  takeOutMemberPrice?: number | null
  eatInNonMemberPrice?: number | null
  takeOutNonMemberPrice?: number | null
}

export const DEFAULT_CAFE_SKU_DATA = {
  cost: null,
  pattern: [],
  prices: [],
  qty: null,
  sku: null,
  barcode: null,
  eatInMemberPrice: null,
  takeOutMemberPrice: null,
  eatInNonMemberPrice: null,
  takeOutNonMemberPrice: null,
}
