import { useCallback } from "react"
import { SerializedStyles } from "@emotion/react"
import { atom, selector, useSetRecoilState, useRecoilValue } from "recoil"

export enum MessageType {
  Success = "success",
  Fail = "fail",
  Warn = "warn",
  Info = "info",
}

type Toast = {
  visible: boolean
  message: string | null
  success: boolean
  fail: boolean
  warn: boolean
  info: boolean
  css: SerializedStyles | null
}

const initialValue: Toast = {
  visible: false,
  message: null,
  success: false,
  fail: false,
  warn: false,
  info: false,
  css: null,
}

const toastState = atom({
  key: "toast",
  default: initialValue,
})

export const toastSelecter = selector({
  key: "toast_handler",
  get: ({ get }) => get(toastState),
})

export const useToast = () => {
  const value = useRecoilValue(toastSelecter)
  const handler = useSetRecoilState(toastState)
  const onClose = useCallback(
    () => handler({ ...initialValue, visible: false }),
    [value, handler]
  )
  const onOpen = useCallback(
    (val: { message: string; type: MessageType; css?: SerializedStyles }) => {
      const baseProps = {
        visible: true,
        message: val?.message,
        css: val?.css ?? null,
      }
      switch (val?.type) {
        case MessageType.Success:
          handler({
            ...initialValue,
            ...baseProps,
            success: true,
          })
          break
        case MessageType.Fail:
          handler({
            ...initialValue,
            ...baseProps,
            fail: true,
          })
          break
        case MessageType.Warn:
          handler({
            ...initialValue,
            ...baseProps,
            warn: true,
          })
          break
        case MessageType.Info:
          handler({
            ...initialValue,
            ...baseProps,
            info: true,
          })
          break
      }
    },
    [value, handler]
  )

  return {
    props: {
      ...value,
      onClose,
      onOpen,
    },
    handler: {
      onClose,
      onOpen,
    },
  }
}
