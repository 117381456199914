/** @jsxImportSource @emotion/react */
import React from "react"
import { css, SerializedStyles } from "@emotion/react"
import { SCREEN_TYPE_THEMES, ScreenType } from "logics/const"
import { px, radius, space, text } from "views/style"

type Props = {
  style?: SerializedStyles
  screenType: ScreenType
}
export default function ScreenTypeLabel({ style, screenType }: Props) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        ${style};
      `}
    >
      <div
        css={css`
          background-color: ${SCREEN_TYPE_THEMES[screenType]?.color};
          color: ${SCREEN_TYPE_THEMES[screenType]?.textColor};
          padding: ${space._1_5} ${space._6};
          line-height: 1;
          text-align: center;
          display: block;
          border-radius: ${radius.sm};
          font-weight: 700;
          font-family: "Noto Sans JP", sans-serif;
          font-size: ${text.xs};
          letter-spacing: 0.1em;
          border: 1px solid hsla(0, 0%, 100%, 0.1);
          min-width: ${px._128};
        `}
      >
        {SCREEN_TYPE_THEMES[screenType]?.label}
      </div>
    </div>
  )
}
