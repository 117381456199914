export const DEFAULT_TAKE: number = 50
export const DEFAULT_SKIP_VALUE: number = 0
export const DEFAULT_CURRENT_PAGE_VALUE: number = 0
export const DEFAULT_TAKES_VALUE: number = 1000
export const DEFAULT_TOTAL_PAGE_VALUE: number = 0

export const calculateListSkipValue = (page: string | undefined) => {
  return (Number(page) - 1) * DEFAULT_TAKE
}

export const calculateNextPageNumber = (currentPage: string | undefined) => {
  return Number(currentPage) + 1
}

export const calculatePreviousPageNumber = (
  currentPage: string | undefined
) => {
  return Number(currentPage) - 1
}
