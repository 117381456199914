/** @jsxImportSource @emotion/react */
import { FC } from "react"
import { css, SerializedStyles } from "@emotion/react"
import BlackLogo from "assets/images/logo_black.png"
import WhiteLogo from "assets/images/logo_white.png"
import { SCREEN_TYPE_THEMES, ScreenType } from "logics/const"
import ScreenTypeLabel from "./display/screenTypeLabel"
import { space } from "views/style"

export const Logo: FC<{
  style?: SerializedStyles
  isBlack?: boolean
  screenType?: ScreenType
}> = ({ style, isBlack, screenType }) => (
  <div css={style}>
    <img src={isBlack ? BlackLogo : WhiteLogo} alt="logo" />
    {screenType && !!SCREEN_TYPE_THEMES[screenType] && (
      <ScreenTypeLabel
        screenType={screenType}
        style={css`
          margin: ${space._4} auto ${space._0_5};
        `}
      />
    )}
  </div>
)
