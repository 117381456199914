import { object, string, number, date, InferType } from "yup"

import { ErrorMessages } from "logics/const"

export default function dateRequired() {
  return date()
    .nullable()
    .typeError(ErrorMessages.NotNumber)
    .required(ErrorMessages.Required)
}
