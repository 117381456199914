/** @jsxImportSource @emotion/react */
import React, { cloneElement } from "react"
import { css, SerializedStyles } from "@emotion/react"
import { ConnectDragSource } from "react-dnd"
import { useDragWrapper, Props as HooksProps } from "./hooks"

export { useDragWrapper } from "./hooks"

export type Props = Omit<HooksProps, "id"> & {
  children: React.ReactElement
  style?: SerializedStyles
  wrapperComponent?: React.ReactElement
}

export type ChildrenProps = {
  drag?: ConnectDragSource
}

export const DragWrapper = (props: Props) => {
  const {
    state: { isDragging, ref, drag },
  } = useDragWrapper({ ...props })

  return (
    <>
      {props?.wrapperComponent ? (
        <>
          {cloneElement(props.wrapperComponent, {
            ref,
            css: css`
              ${props?.style}
              ${isDragging && { opacity: 0.5 }}
            `,
            children: cloneElement(props.children, {
              drag,
            }),
          })}
        </>
      ) : (
        <div
          ref={ref}
          css={css`
            ${props?.style}
            ${isDragging && { opacity: 0.5 }}
          `}
        >
          {cloneElement(props.children, { drag })}
        </div>
      )}
    </>
  )
}
