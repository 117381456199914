import React from "react"
import {
  StampCardRegister,
  StampCardUpdate,
  StampCards,
} from "./components/pages/stampCard"

/*

ダッシュボード

*/
const DashboardComponent = React.lazy(() =>
  import("views/components/pages/dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
)

/*

ログイン

*/
const LoginComponent = React.lazy(() =>
  import("views/components/pages/login").then(({ Login }) => ({
    default: Login,
  }))
)

const FirstLoginComponent = React.lazy(() =>
  import("views/components/pages/login/firstLogin").then(({ FirstLogin }) => ({
    default: FirstLogin,
  }))
)

const OneTimePassword = React.lazy(() =>
  import("views/components/pages/login/oneTimePassword").then(
    ({ OneTimePassword }) => ({
      default: OneTimePassword,
    })
  )
)

const ForgetPasswordComponent = React.lazy(() =>
  import("views/components/pages/login/forgetPassword").then(
    ({ ForgetPassword }) => ({
      default: ForgetPassword,
    })
  )
)

const ServiceJoinMember = React.lazy(() =>
  import("views/components/pages/terms/serviceJoinMember").then(
    ({ ServiceJoinMember }) => ({
      default: ServiceJoinMember,
    })
  )
)

/*

商品

*/
const Products = React.lazy(() =>
  import("views/components/pages/product").then(({ Products }) => ({
    default: Products,
  }))
)

const ProductRegister = React.lazy(() =>
  import("views/components/pages/product").then(({ ProductRegister }) => ({
    default: ProductRegister,
  }))
)

const ProductUpdate = React.lazy(() =>
  import("views/components/pages/product").then(({ ProductUpdate }) => ({
    default: ProductUpdate,
  }))
)
const ProductReplicate = React.lazy(() =>
  import("views/components/pages/product").then(({ ProductReplicate }) => ({
    default: ProductReplicate,
  }))
)

const ProductDetail = React.lazy(() =>
  import("views/components/pages/product").then(({ ProductDetail }) => ({
    default: ProductDetail,
  }))
)

const OnlyShopProductList = React.lazy(() =>
  import("views/components/pages/product").then(({ OnlyShopProductList }) => ({
    default: OnlyShopProductList,
  }))
)

const OnlyShopRegister = React.lazy(() =>
  import("views/components/pages/product").then(({ OnlyShopRegister }) => ({
    default: OnlyShopRegister,
  }))
)

const OnlyShopUpdate = React.lazy(() =>
  import("views/components/pages/product").then(({ OnlyShopUpdate }) => ({
    default: OnlyShopUpdate,
  }))
)

const OnlyShopReplicate = React.lazy(() =>
  import("views/components/pages/product").then(({ OnlyShopReplicate }) => ({
    default: OnlyShopReplicate,
  }))
)

const ProductAnalyticsWrapper = React.lazy(() =>
  import("views/components/pages/product").then(
    ({ ProductAnalyticsWrapper }) => ({
      default: ProductAnalyticsWrapper,
    })
  )
)

/*

Join Member

*/
const Shops = React.lazy(() =>
  import("views/components/pages/shop").then(({ Shops }) => ({
    default: Shops,
  }))
)

const ShopRegister = React.lazy(() =>
  import("views/components/pages/shop").then(({ ShopRegister }) => ({
    default: ShopRegister,
  }))
)

const ShopDetail = React.lazy(() =>
  import("views/components/pages/shop").then(({ ShopDetail }) => ({
    default: ShopDetail,
  }))
)

const ShopUpdate = React.lazy(() =>
  import("views/components/pages/shop").then(({ ShopUpdate }) => ({
    default: ShopUpdate,
  }))
)
const ShopAccounts = React.lazy(() =>
  import("views/components/pages/shop").then(({ ShopAccountList }) => ({
    default: ShopAccountList,
  }))
)

const ShopAccountRegister = React.lazy(() =>
  import("views/components/pages/shop").then(({ ShopAccountRegister }) => ({
    default: ShopAccountRegister,
  }))
)

const ShopAccountDetail = React.lazy(() =>
  import("views/components/pages/shop").then(({ ShopAccountDetail }) => ({
    default: ShopAccountDetail,
  }))
)

const ShopAccountUpdate = React.lazy(() =>
  import("views/components/pages/shop").then(({ ShopAccountUpdate }) => ({
    default: ShopAccountUpdate,
  }))
)

const ShopAccountPasswordChange = React.lazy(() =>
  import("views/components/pages/shop").then(
    ({ ShopAccountPasswordChange }) => ({
      default: ShopAccountPasswordChange,
    })
  )
)

/*

店舗

*/
const Tenants = React.lazy(() =>
  import("views/components/pages/tenant").then(({ Tenants }) => ({
    default: Tenants,
  }))
)

const TenantRegister = React.lazy(() =>
  import("views/components/pages/tenant").then(({ TenantRegister }) => ({
    default: TenantRegister,
  }))
)

const TenantDetail = React.lazy(() =>
  import("views/components/pages/tenant").then(({ TenantDetail }) => ({
    default: TenantDetail,
  }))
)

const TenantUpdate = React.lazy(() =>
  import("views/components/pages/tenant").then(({ TenantUpdate }) => ({
    default: TenantUpdate,
  }))
)
const TenantAccounts = React.lazy(() =>
  import("views/components/pages/tenant").then(({ TenantAccountList }) => ({
    default: TenantAccountList,
  }))
)

const TenantAccountRegister = React.lazy(() =>
  import("views/components/pages/tenant").then(({ TenantAccountRegister }) => ({
    default: TenantAccountRegister,
  }))
)

const TenantAccountDetail = React.lazy(() =>
  import("views/components/pages/tenant").then(({ TenantAccountDetail }) => ({
    default: TenantAccountDetail,
  }))
)

const TenantAccountUpdate = React.lazy(() =>
  import("views/components/pages/tenant").then(({ TenantAccountUpdate }) => ({
    default: TenantAccountUpdate,
  }))
)

const TenantAccountPasswordChange = React.lazy(() =>
  import("views/components/pages/tenant").then(
    ({ TenantAccountPasswordChange }) => ({
      default: TenantAccountPasswordChange,
    })
  )
)

/*

フランチャイズ

*/
const Franchises = React.lazy(() =>
  import("views/components/pages/franchise").then(({ FranchiseList }) => ({
    default: FranchiseList,
  }))
)

const FranchiseRegister = React.lazy(() =>
  import("views/components/pages/franchise").then(({ FranchiseRegister }) => ({
    default: FranchiseRegister,
  }))
)

const FranchiseDetail = React.lazy(() =>
  import("views/components/pages/franchise").then(({ FranchiseDetail }) => ({
    default: FranchiseDetail,
  }))
)

const FranchiseUpdate = React.lazy(() =>
  import("views/components/pages/franchise").then(({ FranchiseUpdate }) => ({
    default: FranchiseUpdate,
  }))
)
const FranchiseAccounts = React.lazy(() =>
  import("views/components/pages/franchise").then(
    ({ FranchiseAccountList }) => ({
      default: FranchiseAccountList,
    })
  )
)

const FranchiseAccountRegister = React.lazy(() =>
  import("views/components/pages/franchise").then(
    ({ FranchiseAccountRegister }) => ({
      default: FranchiseAccountRegister,
    })
  )
)

const FranchiseAccountDetail = React.lazy(() =>
  import("views/components/pages/franchise").then(
    ({ FranchiseAccountDetail }) => ({
      default: FranchiseAccountDetail,
    })
  )
)

const FranchiseAccountUpdate = React.lazy(() =>
  import("views/components/pages/franchise").then(
    ({ FranchiseAccountUpdate }) => ({
      default: FranchiseAccountUpdate,
    })
  )
)

const FranchiseAccountPasswordChange = React.lazy(() =>
  import("views/components/pages/franchise").then(
    ({ FranchiseAccountPasswordChange }) => ({
      default: FranchiseAccountPasswordChange,
    })
  )
)
/*

本部

*/
const MasterAccounts = React.lazy(() =>
  import("views/components/pages/master").then(({ MasterAccountList }) => ({
    default: MasterAccountList,
  }))
)

const MasterAccountRegister = React.lazy(() =>
  import("views/components/pages/master").then(({ MasterAccountRegister }) => ({
    default: MasterAccountRegister,
  }))
)

const MasterAccountDetail = React.lazy(() =>
  import("views/components/pages/master").then(({ MasterAccountDetail }) => ({
    default: MasterAccountDetail,
  }))
)

const MasterAccountUpdate = React.lazy(() =>
  import("views/components/pages/master").then(({ MasterAccountUpdate }) => ({
    default: MasterAccountUpdate,
  }))
)

const MasterAccountPasswordChange = React.lazy(() =>
  import("views/components/pages/master").then(
    ({ MasterAccountPasswordChange }) => ({
      default: MasterAccountPasswordChange,
    })
  )
)

/*

展示スペース

*/
const Blocks = React.lazy(() =>
  import("views/components/pages/block").then(({ Blocks }) => ({
    default: Blocks,
  }))
)
const BlockDetail = React.lazy(() =>
  import("views/components/pages/block").then(({ BlockDetail }) => ({
    default: BlockDetail,
  }))
)

const BlockRegister = React.lazy(() =>
  import("views/components/pages/block").then(({ BlockRegister }) => ({
    default: BlockRegister,
  }))
)

const BlockProducts = React.lazy(() =>
  import("views/components/pages/block").then(({ BlockProducts }) => ({
    default: BlockProducts,
  }))
)

const BlockSettings = React.lazy(() =>
  import("views/components/pages/block").then(({ BlockSettings }) => ({
    default: BlockSettings,
  }))
)

const BlockApplication = React.lazy(() =>
  import("views/components/pages/block").then(({ BlockApplication }) => ({
    default: BlockApplication,
  }))
)

const BlockSchedule = React.lazy(() =>
  import("views/components/pages/block").then(({ BlockSchedule }) => ({
    default: BlockSchedule,
  }))
)

/*

注文

*/
const Orders = React.lazy(() =>
  import("views/components/pages/order").then(({ Orders }) => ({
    default: Orders,
  }))
)

const OrderDetail = React.lazy(() =>
  import("views/components/pages/order").then(({ OrderDetail }) => ({
    default: OrderDetail,
  }))
)

/*

アンケート

*/
const Surveys = React.lazy(() =>
  import("views/components/pages/survey").then(({ Surveys }) => ({
    default: Surveys,
  }))
)

const SurveyRegister = React.lazy(() =>
  import("views/components/pages/survey").then(({ SurveyRegister }) => ({
    default: SurveyRegister,
  }))
)
const SurveyUpdate = React.lazy(() =>
  import("views/components/pages/survey").then(({ SurveyUpdate }) => ({
    default: SurveyUpdate,
  }))
)

/*

クーポン

*/
const Coupons = React.lazy(() =>
  import("views/components/pages/coupon").then(({ Coupons }) => ({
    default: Coupons,
  }))
)
const MyCoupons = React.lazy(() =>
  import("views/components/pages/coupon").then(({ MyCoupons }) => ({
    default: MyCoupons,
  }))
)
const CouponRegister = React.lazy(() =>
  import("views/components/pages/coupon").then(({ CouponRegister }) => ({
    default: CouponRegister,
  }))
)
const CouponUpdate = React.lazy(() =>
  import("views/components/pages/coupon").then(({ CouponUpdate }) => ({
    default: CouponUpdate,
  }))
)

/*

バナー

*/
const Banners = React.lazy(() =>
  import("views/components/pages/banner").then(({ Banners }) => ({
    default: Banners,
  }))
)

const BannerRegister = React.lazy(() =>
  import("views/components/pages/banner").then(({ BannerRegister }) => ({
    default: BannerRegister,
  }))
)
const BannerUpdate = React.lazy(() =>
  import("views/components/pages/banner").then(({ BannerUpdate }) => ({
    default: BannerUpdate,
  }))
)

/*
顧客
*/
const Customers = React.lazy(() =>
  import("views/components/pages/customer").then(({ Customers }) => ({
    default: Customers,
  }))
)

/*

設定

*/
const DeliveryTop = React.lazy(() =>
  import("views/components/pages/delivery").then(({ Delivery }) => ({
    default: Delivery,
  }))
)

/*


マニュアル


*/
const Manuals = React.lazy(() =>
  import("views/components/pages/manual").then(({ Manuals }) => ({
    default: Manuals,
  }))
)

const ManualDetail = React.lazy(() =>
  import("views/components/pages/manual").then(({ ManualDetail }) => ({
    default: ManualDetail,
  }))
)
const ManualRegister = React.lazy(() =>
  import("views/components/pages/manual").then(({ ManualRegister }) => ({
    default: ManualRegister,
  }))
)

/*


カフェ


*/
const CafeProducts = React.lazy(() =>
  import("views/components/pages/cafe").then(({ CafeProducts }) => ({
    default: CafeProducts,
  }))
)
const CafeProductRegister = React.lazy(() =>
  import("views/components/pages/cafe").then(({ CafeProductRegister }) => ({
    default: CafeProductRegister,
  }))
)
const CafeProductUpdate = React.lazy(() =>
  import("views/components/pages/cafe").then(({ CafeProductUpdate }) => ({
    default: CafeProductUpdate,
  }))
)
const CafeOrderList = React.lazy(() =>
  import("views/components/pages/cafe").then(({ CafeOrderList }) => ({
    default: CafeOrderList,
  }))
)

export const PATHS = {
  dashboard: "/dashboard",
  login: {
    top: "/login",
    firstLogin: "/login/firstLogin",
    forgetPassword: "/login/forgetPassword",
    oneTimePassword: "/login/oneTimePassword",
  },
  terms: "/terms/serviceJoinMember",

  product: {
    list: "/product",
    register: "/product/register",
    detail: "/product/detail",
    update: "/product/update",
    replicate: "/product/replicate",
    onlyShopProductList: "/product/onlyShopProduct",
    onlyShopRegister: "/product/onlyShopRegister",
    onlyShopUpdate: "/product/onlyShopUpdate",
    onlyShopReplicate: "/product/onlyShopReplicate",
    analytics: "/product/analytics",
  },
  shop: {
    list: "/shop",
    register: "/shop/register",
    detail: "/shop/detail",
    update: "/shop/update",
    account: {
      list: "/shop/account/list",
      register: "/shop/account/register",
      detail: "/shop/account/detail",
      update: "/shop/account/update",
      passwordChange: "/shop/account/passwordChange",
    },
  },
  tenant: {
    list: "/tenant",
    register: "/tenant/register",
    detail: "/tenant/detail",
    update: "/tenant/update",
    account: {
      list: "/tenant/account/list",
      register: "/tenant/account/register",
      detail: "/tenant/account/detail",
      update: "/tenant/account/update",
      passwordChange: "/tenant/account/passwordChange",
    },
  },
  franchise: {
    list: "/franchise",
    register: "/franchise/register",
    detail: "/franchise/detail",
    update: "/franchise/update",
    account: {
      list: "/franchise/account/list",
      register: "/franchise/account/register",
      detail: "/franchise/account/detail",
      update: "/franchise/account/update",
      passwordChange: "/franchise/account/passwordChange",
    },
  },
  master: {
    account: {
      list: "/master/account/list",
      register: "/master/account/register",
      detail: "/master/account/detail",
      update: "/master/account/update",
      passwordChange: "/master/account/passwordChange",
    },
  },
  survey: {
    list: "/survey/list",
    register: "/survey/register",
    update: "/survey/update",
  },
  coupon: {
    list: "/coupon/list",
    myList: "/coupon/myList",
    register: "/coupon/register",
    update: "/coupon/update",
  },
  stampCard: {
    list: "/stampCard/list",
    register: "/stampCard/register",
    update: "/stampCard/update",
  },
  block: {
    list: "/block",
    register: "/block/register",
    detail: "/block/detail",
    product: {
      list: "/block/contract/product",
    },
    settings: "/block/settings",
    application: "/block/application",
    schedule: "/block/schedule",
  },
  order: {
    list: "/order",
    detail: "/order/detail",
  },
  delivery: {
    top: "/delivery",
  },
  customer: {
    list: "/customer",
  },
  banner: {
    list: "/banner",
    register: "/banner/register",
    update: "/banner/update",
  },
  manual: {
    list: "/manual",
    detail: "/manual/detail",
    register: "/manual/register",
  },
  cafe: {
    product: {
      list: "/cafe/product/list",
      register: "/cafe/product/register",
      update: "/cafe/product/update",
    },
    order: {
      list: "/cafe/order/list",
    },
  },
}

export const Dashboard = [
  {
    path: PATHS.dashboard,
    element: <DashboardComponent />,
  },
]

export const Login = [
  // ログイン
  {
    path: PATHS.login.top,
    element: <LoginComponent />,
  },
  // 初回ログイン時パスワード変更
  {
    path: PATHS.login.firstLogin,
    element: <FirstLoginComponent />,
  },
  // ワンタイムパスワード発行
  {
    path: PATHS.login.oneTimePassword,
    element: <OneTimePassword />,
  },
  // パスワードを忘れた場合
  {
    path: PATHS.login.forgetPassword,
    element: <ForgetPasswordComponent />,
  },
]

export const Terms = [
  {
    path: PATHS.terms,
    element: <ServiceJoinMember />,
  },
]

export const Product = [
  // 商品一覧
  {
    path: `${PATHS.product.list}/:page`,
    element: <Products />,
  },
  // 商品登録
  {
    path: PATHS.product.register,
    element: <ProductRegister />,
  },
  // 商品更新
  {
    path: `${PATHS.product.update}/:id`,
    element: <ProductUpdate />,
  },
  // 商品詳細
  {
    path: `${PATHS.product.detail}/:id`,
    element: <ProductDetail />,
  },
  // 商品複製
  {
    path: `${PATHS.product.replicate}/:id`,
    element: <ProductReplicate />,
  },
  // 販売物なし商品一覧
  {
    path: `${PATHS.product.onlyShopProductList}/:page`,
    element: <OnlyShopProductList />,
  },
  // 販売物なし商品登録
  {
    path: PATHS.product.onlyShopRegister,
    element: <OnlyShopRegister />,
  },
  // 販売物なし商品更新
  {
    path: `${PATHS.product.onlyShopUpdate}/:id`,
    element: <OnlyShopUpdate />,
  },
  // 販売物なし商品複製
  {
    path: `${PATHS.product.onlyShopReplicate}/:id`,
    element: <OnlyShopReplicate />,
  },
  // 出展商品分析
  {
    path: `${PATHS.product.analytics}/:id`,
    element: <ProductAnalyticsWrapper />,
  },
]

export const Shop = [
  // Join Member一覧
  {
    path: `${PATHS.shop.list}/:page`,
    element: <Shops />,
  },
  // Join Member 登録
  {
    path: PATHS.shop.register,
    element: <ShopRegister />,
  },
  // Join Member 詳細
  {
    path: `${PATHS.shop.detail}/:id`,
    element: <ShopDetail />,
  },
  // Join Member 更新
  {
    path: `${PATHS.shop.update}/:id`,
    element: <ShopUpdate />,
  },
  // Join Member アカウント一覧
  {
    path: `${PATHS.shop.account.list}/:page`,
    element: <ShopAccounts />,
  },
  // Join Member アカウント登録
  {
    path: PATHS.shop.account.register,
    element: <ShopAccountRegister />,
  },
  // Join Member アカウント詳細
  {
    path: `${PATHS.shop.account.detail}/:id`,
    element: <ShopAccountDetail />,
  },
  // Join Member アカウント更新
  {
    path: `${PATHS.shop.account.update}/:id`,
    element: <ShopAccountUpdate />,
  },
  // Join Member パスワード変更
  {
    path: PATHS.shop.account.passwordChange,
    element: <ShopAccountPasswordChange />,
  },
]

export const Tenant = [
  // 店舗一覧
  {
    path: `${PATHS.tenant.list}/:page`,
    element: <Tenants />,
  },
  // 店舗登録
  {
    path: PATHS.tenant.register,
    element: <TenantRegister />,
  },
  // 店舗詳細
  {
    path: `${PATHS.tenant.detail}/:id`,
    element: <TenantDetail />,
  },
  // 店舗更新
  {
    path: `${PATHS.tenant.update}/:id`,
    element: <TenantUpdate />,
  },
  // 店舗アカウント一覧
  {
    path: `${PATHS.tenant.account.list}/:page`,
    element: <TenantAccounts />,
  },
  // 店舗アカウント登録
  {
    path: PATHS.tenant.account.register,
    element: <TenantAccountRegister />,
  },
  // 店舗アカウント詳細
  {
    path: `${PATHS.tenant.account.detail}/:id`,
    element: <TenantAccountDetail />,
  },
  // 店舗アカウント更新
  {
    path: `${PATHS.tenant.account.update}/:id`,
    element: <TenantAccountUpdate />,
  },
  // 店舗パスワード変更
  {
    path: PATHS.tenant.account.passwordChange,
    element: <TenantAccountPasswordChange />,
  },
]

export const Franchise = [
  // フランチャイズ一覧
  {
    path: `${PATHS.franchise.list}/:page`,
    element: <Franchises />,
  },
  // フランチャイズ登録
  {
    path: PATHS.franchise.register,
    element: <FranchiseRegister />,
  },
  // フランチャイズ詳細
  {
    path: `${PATHS.franchise.detail}/:id`,
    element: <FranchiseDetail />,
  },
  // フランチャイズ更新
  {
    path: `${PATHS.franchise.update}/:id`,
    element: <FranchiseUpdate />,
  },
  // フランチャイズアカウント一覧
  {
    path: `${PATHS.franchise.account.list}/:page`,
    element: <FranchiseAccounts />,
  },
  // フランチャイズアカウント登録
  {
    path: PATHS.franchise.account.register,
    element: <FranchiseAccountRegister />,
  },
  // フランチャイズアカウント詳細
  {
    path: `${PATHS.franchise.account.detail}/:id`,
    element: <FranchiseAccountDetail />,
  },
  // フランチャイズアカウント更新
  {
    path: `${PATHS.franchise.account.update}/:id`,
    element: <FranchiseAccountUpdate />,
  },
  // フランチャイズパスワード変更
  {
    path: PATHS.franchise.account.passwordChange,
    element: <FranchiseAccountPasswordChange />,
  },
]

export const Master = [
  // 本部アカウント一覧
  {
    path: `${PATHS.master.account.list}/:page`,
    element: <MasterAccounts />,
  },
  // 本部アカウント登録
  {
    path: PATHS.master.account.register,
    element: <MasterAccountRegister />,
  },
  // 本部アカウント詳細
  {
    path: `${PATHS.master.account.detail}/:id`,
    element: <MasterAccountDetail />,
  },
  // 本部アカウント更新
  {
    path: `${PATHS.master.account.update}/:id`,
    element: <MasterAccountUpdate />,
  },
  // 本部パスワード変更
  {
    path: PATHS.master.account.passwordChange,
    element: <MasterAccountPasswordChange />,
  },
]

export const Block = [
  // 展示スペース・一覧
  {
    path: `${PATHS.block.list}/:page`,
    element: <Blocks />,
  },
  // 展示スペース・詳細
  {
    path: `${PATHS.block.detail}/:id`,
    element: <BlockDetail />,
  },
  // 展示スペース・登録
  {
    path: PATHS.block.register,
    element: <BlockRegister />,
  },
  // 展示スペース・商品
  {
    path: PATHS.block.product.list,
    element: <BlockProducts />,
  },
  // 展示スペース・基本設定
  {
    path: `${PATHS.block.settings}/:page`,
    element: <BlockSettings />,
  },
  // 展示スペース・出展申請
  {
    path: `${PATHS.block.application}/:id`,
    element: <BlockApplication />,
  },
  // 展示スペース・スケジュール
  {
    path: `${PATHS.block.schedule}/:page`,
    element: <BlockSchedule />,
  },
]

export const Order = [
  // 注文一覧
  {
    path: `${PATHS.order.list}/:page`,
    element: <Orders />,
  },
  // 注文詳細
  {
    path: `${PATHS.order.detail}/:id`,
    element: <OrderDetail />,
  },
]

export const Delivery = [
  // 配送設定・トップ
  {
    path: PATHS.delivery.top,
    element: <DeliveryTop />,
  },
]

export const Customer = [
  // 顧客一覧
  {
    path: `${PATHS.customer.list}/:page`,
    element: <Customers />,
  },
]

export const Survey = [
  // アンケート一覧
  {
    path: `${PATHS.survey.list}/:page`,
    element: <Surveys />,
  },
  {
    path: PATHS.survey.register,
    element: <SurveyRegister />,
  },
  {
    path: `${PATHS.survey.update}/:id`,
    element: <SurveyUpdate />,
  },
]

export const Coupon = [
  // クーポン一覧
  {
    path: `${PATHS.coupon.list}/:page`,
    element: <Coupons />,
  },
  {
    path: `${PATHS.coupon.myList}/:page`,
    element: <MyCoupons />,
  },
  {
    path: PATHS.coupon.register,
    element: <CouponRegister />,
  },
  {
    path: `${PATHS.coupon.update}/:id`,
    element: <CouponUpdate />,
  },
]
export const StampCard = [
  // スタンプカード
  {
    path: `${PATHS.stampCard.list}/:page`,
    element: <StampCards />,
  },
  {
    path: PATHS.stampCard.register,
    element: <StampCardRegister />,
  },
  {
    path: `${PATHS.stampCard.update}/:id`,
    element: <StampCardUpdate />,
  },
]

export const Banner = [
  // バナー一覧
  {
    path: `${PATHS.banner.list}/:page`,
    element: <Banners />,
  },
  {
    path: PATHS.banner.register,
    element: <BannerRegister />,
  },
  {
    path: `${PATHS.banner.update}/:id`,
    element: <BannerUpdate />,
  },
]

export const Manual = [
  // マニュアル・一覧
  {
    path: `${PATHS.manual.list}/:page`,
    element: <Manuals />,
  },
  // マニュアル・詳細
  {
    path: `${PATHS.manual.detail}/:type`,
    element: <ManualDetail />,
  },
  // マニュアル・登録
  {
    path: PATHS.manual.register,
    element: <ManualRegister />,
  },
]

export const Cafe = [
  // カフェ・商品・一覧
  {
    path: `${PATHS.cafe.product.list}/:page`,
    element: <CafeProducts />,
  },
  // カフェ・商品・登録
  {
    path: PATHS.cafe.product.register,
    element: <CafeProductRegister />,
  },
  // カフェ・商品・更新
  {
    path: `${PATHS.cafe.product.update}/:id`,
    element: <CafeProductUpdate />,
  },
  // カフェ・注文・一覧
  {
    path: PATHS.cafe.order.list,
    element: <CafeOrderList />,
  },
]

export const ROUTES = [
  ...Dashboard,
  ...Login,
  ...Terms,
  ...Product,
  ...Shop,
  ...Tenant,
  ...Franchise,
  ...Master,
  ...Block,
  ...Order,
  ...Delivery,
  ...Survey,
  ...Coupon,
  ...StampCard,
  ...Banner,
  ...Customer,
  ...Manual,
  ...Cafe,
]
