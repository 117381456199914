import { useCallback } from "react"
import { WebSocketHandler } from "../infrastructure/websocket"
import { ReciverFactory } from "../infrastructure/websocket/reciver"
import { UserCommand } from "../infrastructure/websocket/emitCommand"
import { LoginAccount } from "../graphql"

class WsFactory {
  ws: WebSocketHandler
  constructor() {
    this.ws = new WebSocketHandler(new ReciverFactory())
  }
  get = () => {
    if (!this.ws) {
      this.ws = new WebSocketHandler(new ReciverFactory())
      return this.ws
    }
    return this.ws
  }
}
const ws = new WsFactory()

export const useWs = () => {
  const sendUser = useCallback((user: LoginAccount) => {
    const value = ws.get()
    value.emit(new UserCommand(user))
  }, [])

  return {
    handler: {
      sendUser,
    },
  }
}
