/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

// Style
import { space, text, px, zIndex } from "views/style"
import { gray, primary } from "views/style/palette"

// Component
import useHeader from "./hooks"
import { ScreenType } from "logics/const"

export const HeaderHight = px._64
export const Header = (props: { onClick?: () => void }) => {
  const { me } = useHeader()

  return (
    <header
      css={css`
        height: ${HeaderHight};
        position: sticky;
        top: 0;
        background-color: ${gray._0};
        padding: ${space._3} ${space._4};
        z-index: ${zIndex.appbar};

        display: flex;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            width: ${px._36};
            height: ${px._36};
            color: ${gray._300};
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: ${space._4};
            cursor: pointer;
          `}
          onClick={props?.onClick}
        >
          <i
            className="material-icons"
            css={css`
              font-size: ${px._32};
              color: ${primary._500};
            `}
          >
            menu
          </i>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: ${space._6};
          color: ${gray._700};
        `}
      >
        {me.name && (
          <>
            <i
              className="material-icons-outlined"
              css={css`
                font-size: ${px._28};
                margin-right: ${space._3};
              `}
            >
              group
            </i>
            <span
              css={css`
                margin-right: ${space._2};
                font-size: ${text.sm};
                font-weight: 500;
              `}
            >
              アカウント:
            </span>
            <b
              css={css`
                font-weight: 500;
                font-size: ${text.xl};
                small {
                  font-size: ${text.base};
                  font-weight: 400;
                }
              `}
            >
              {me.name} <small> 様</small>
            </b>
          </>
        )}
      </div>
    </header>
  )
}
