export enum ErrorMessages {
  Required = "必須項目です",
  InvalidArgument = "不正な値です",
  InvalidPassword = "パスワードは8~20文字の半角英数字混合です",
  InvalidPasswordLength = "パスワードは8~20文字です",
  InvalidPostalCodeLength = "郵便番号は7桁です",
  InvalidPhoneNumberLength = "電話番号は10~11桁です",
  InvalidFaxLength = "FAX番号は10~11桁です",
  invoiceNumberLength = "適格請求書発行事業者登録番号はTを除いた13桁です",
  NotSelected = "選択されていません",
  NotSelectedIncentive = "インセンティブを選択してください",
  NotSelectedFranchise = "フランチャイズを選択してください",
  NotNumber = "数値ではありません",
  NotHalfSizeNumber = "半角数字ではありません",
  VariationNotRegistered = "バリエーションを1つ以上登録してください",
  FileNotRegistered = "ファイルを1つ以上登録してください",
  VariationValuesRequired = "価格／在庫数は必須入力です",
  VariationPriceRequired = "価格は必須入力です",
  Register = "登録に失敗しました",
  Update = "更新に失敗しました",
  Change = "変更に失敗しました",
  Delete = "削除に失敗しました",
  PasswordMismatch = "パスワードと確認用パスワードが一致しません",
  NotLogedIn = "ログインされていません",
  FailToLogIn = "ログインできませんでした",
  NumberOverflow = `6桁以内で入力してください`,
  PriceOverflow = `299,999円以内で入力してください`,
  MinusNumber = `正の数値を入力してください`,
  MustBeSetUrl = "URLが設定されていません",
  InvalidFile = "ファイル拡張子が正しくありません",
  DecimalNumber = "整数で入力してください",
}

export enum SuccessMessages {
  Register = "登録が完了しました",
  Update = "更新が完了しました",
  Change = "変更が完了しました",
  Delete = "削除が完了しました",
  MailSend = "メールを送信しました",
  AddCart = "カートに追加しました",
}
