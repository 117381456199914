import * as yup from "yup"
import { ErrorMessages } from "logics/const"

export default function wiziwigRequired() {
  return yup.string().test("test", ErrorMessages.Required, (value) => {
    const blocks = value && JSON.parse(value)?.blocks
    if (!blocks || (blocks.length <= 1 && !blocks[0]?.text)) {
      return false
    }
    return true
  })
}
