// React
import { useMemo, useCallback, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { filter } from "graphql-anywhere"
import { createSchema } from "tw-custom-component"

// Logics
import {
  StampCardFragment,
  StampCardFragmentDoc,
  StampCardUpdateInput,
  useCouponsQuery,
  useFranchiseQuery,
  useStampCardLazyQuery,
  useStampCardUpdateMutation,
} from "logics/graphql"
import {
  ErrorMessages,
  SuccessMessages,
  ActionStatus,
  DEFAULT_TAKES_VALUE,
  DEFAULT_SKIP_VALUE,
} from "logics/const"
import { useToast, MessageType } from "logics/store/toast"

// Component
import { PATHS } from "views/routes"
import { dateOptional, stringRequired } from "logics/utils/yup"

export const useStampCardUpdate = () => {
  const { couponOptions } = useCoupons()

  const {
    props: { stampCard, thumbnail },
  } = useApi()
  const { props: form } = useForm(stampCard)
  const { companyName } = useFranchise(stampCard.franchiseId)

  return {
    props: { ...form, thumbnail, companyName, couponOptions },
  }
}

/**フランチャイズ取得 */
const useFranchise = (franchiseId: number | undefined) => {
  const { data, loading } = useFranchiseQuery({
    fetchPolicy: "network-only",
    variables: {
      franchiseId: franchiseId,
    },
  })
  const companyName = useMemo(() => data?.franchise?.companyName ?? "", [data])
  return { companyName, loading }
}

/**クーポン一覧取得 */
const useCoupons = () => {
  const { data, loading } = useCouponsQuery({
    fetchPolicy: "network-only",
    variables: {
      request: {
        pages: {
          take: DEFAULT_TAKES_VALUE,
          skip: DEFAULT_SKIP_VALUE,
        },
        param: {},
      },
    },
  })

  const couponOptions = useMemo(() => {
    return data?.coupons.datasource.map(
      (coupon: { id: string; name: string }) => ({
        value: coupon.id,
        label: coupon.name,
      })
    )
  }, [data])

  return { couponOptions, loading }
}

/*

Api

*/
const useApi = () => {
  const { id } = useParams<"id">()
  const [fetch, { data }] = useStampCardLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      stampCardId: id as string,
    },
  })

  const stampCard = useMemo(
    () =>
      !!data?.stampCard
        ? filter<StampCardFragment>(StampCardFragmentDoc, data?.stampCard)
        : fallBackStampCard,
    [data]
  )

  const thumbnail = useMemo(
    () => stampCard?.thumbnail?.path ?? null,
    [stampCard]
  )

  useEffect(() => {
    const _id = id
    if (!id) return
    fetch({ variables: { stampCardId: _id as string } })
  }, [id])

  return {
    props: { stampCard, thumbnail },
  }
}

const fallBackStampCard: StampCardFragment = {
  couponId: "",
  createdAt: new Date(),
  description: "",
  from: null,
  id: "",
  limit: 0,
  franchiseId: 0,
  thumbnail: {
    fileType: "",
    key: "",
    path: "",
    id: "",
  },
  to: null,
  updatedAt: new Date(),
}

/*

Form

*/

const validations = createSchema([
  {
    id: "couponId",
    validation: stringRequired(),
  },
  {
    id: "description",
    validation: stringRequired(),
  },
  {
    id: "from",
    validation: dateOptional(),
  },
  {
    id: "to",
    validation: dateOptional(),
  },
])
const useForm = (stampCardUpdate: StampCardUpdateInput) => {
  const navigate = useNavigate()

  const {
    handler: { onOpen },
  } = useToast()
  const onError = useCallback(
    (message?: string | null) =>
      onOpen({
        message: message ?? ErrorMessages.InvalidArgument,
        type: MessageType.Fail,
      }),
    []
  )
  const onCompleted = useCallback(
    (message?: string | null) =>
      onOpen({
        message: message ?? SuccessMessages.Register,
        type: MessageType.Success,
      }),
    []
  )
  const [updateStampCard] = useStampCardUpdateMutation({
    onCompleted: ({ stampCardUpdate }) => {
      if (stampCardUpdate.status === ActionStatus.Success) {
        onCompleted(stampCardUpdate?.message)
        goToList()
        return
      }
      if (stampCardUpdate.status === ActionStatus.Fail) {
        onError(stampCardUpdate?.message)
        return
      }
    },
    onError: (e) => onError(e?.message),
  })

  // キャンセル
  const goToList = useCallback(() => {
    navigate(`${PATHS.stampCard.list}/1`)
  }, [])

  // onSubmit
  const onSubmit = useCallback(
    async (stampCardUpdate: StampCardUpdateInput) => {
      updateStampCard({
        variables: {
          request: {
            couponId: stampCardUpdate.couponId,
            description: stampCardUpdate.description,
            from: stampCardUpdate.from ? new Date(stampCardUpdate.from) : null,
            id: stampCardUpdate.id,
            thumbnail: stampCardUpdate.thumbnail,
            to: stampCardUpdate.to ? new Date(stampCardUpdate.to) : null,
          },
        },
      })
    },
    [updateStampCard]
  )

  const form = useFormik({
    initialValues: stampCardUpdate,
    validationSchema: validations,
    onSubmit,
    enableReinitialize: true,
  })

  // Date
  const setFormDate = useCallback(
    (date: Date | null) => form.setFieldValue("from", date),
    [form]
  )
  const setToDate = useCallback(
    (date: Date | null) => form.setFieldValue("to", date),
    [form]
  )

  return {
    props: {
      form,
      onCancel: goToList,
      date: {
        setFormDate,
        setToDate,
      },
    },
  }
}
