import { gray, primary } from "views/style/palette"

export enum ScreenType {
  Shop = "shop",
  Tenant = "tenant",
  Franchise = "franchise",
  Master = "master",
}

export const SCREEN_TYPE_THEMES = {
  [ScreenType.Franchise]: {
    label: "フランチャイズ",
    color: "hsl(234, 60%, 40%)",
    textColor: "white",
  },
  [ScreenType.Tenant]: {
    label: "店舗",
    color: "hsl(183, 100%, 20%)",
    textColor: "white",
  },
  [ScreenType.Shop]: {
    label: "Join Member",
    color: gray._25,
    textColor: gray._600,
  },
  [ScreenType.Master]: {
    label: "本部",
    color: "hsl(200, 16%, 62%)",
    textColor: "white",
  },
}
