import { Order } from "./sort"

export enum BlockStatus {
  Private = 1,
  Public,
  Delete,
}

export const BlockStatusOptions = [
  { value: BlockStatus.Private, label: "非公開" },
  { value: BlockStatus.Public, label: "公開" },
]

export enum BlockType {
  Nomal = 1,
  Refrigeration = 2,
  Frozen = 3,
}

export const BlockTypeOptions = [
  // { value: "", label: "商品を出展するスペースを選択してください" },
  { value: BlockType.Nomal, label: "常温スペース" },
  { value: BlockType.Refrigeration, label: "冷蔵スペース" },
  { value: BlockType.Frozen, label: "冷凍スペース" },
]

export enum DisplaySpaceProductShippedStatusId {
  Backlog = 1,
  Shipped,
  Received,
  Returned,
  None,
}

export const DisplaySpaceProductShippedStatus = [
  { id: DisplaySpaceProductShippedStatusId.Backlog, label: "未対応" },
  { id: DisplaySpaceProductShippedStatusId.Shipped, label: "発送済み" },
  { id: DisplaySpaceProductShippedStatusId.Received, label: "受領済み" },
  { id: DisplaySpaceProductShippedStatusId.Returned, label: "返却済み" },
  { id: DisplaySpaceProductShippedStatusId.None, label: "サンプルなし" },
]

export enum DisplaySpaceProductApplyStatusId {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export const DisplaySpaceProductApplyStatus = [
  { id: DisplaySpaceProductApplyStatusId.Pending, label: "申請中" },
  { id: DisplaySpaceProductApplyStatusId.Approved, label: "承認済み" },
  { id: DisplaySpaceProductApplyStatusId.Rejected, label: "却下" },
]

export enum SortKey {
  CreateDate = "create_date",
}

export const InitialSortParam = {
  key: SortKey.CreateDate,
  order: Order.Desc,
}
