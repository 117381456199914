/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"
import { FileUploadAreaWapper, ImgCard } from "tw-custom-component"

// Component
import {
  DragWrapper,
  ChildrenProps,
} from "views/components/molecules/container/drag"

// Style
import { flex_center, white, px, space, text, radius } from "views/style"
import { error, gray } from "views/style/palette"
import Button from "views/components//atoms/input/button"
import ErrorMessage from "views/components/molecules/form/errorMessage"
import { ReactNode } from "react"
import { VideoThumbnail } from "views/components/atoms/display/video"

export { useCreateImageInput } from "./hooks"
export type { ImageSource } from "./hooks"

export type Props<T extends { path: string }> = {
  style?: SerializedStyles
  onDrop?: (data: any) => void
  onDrag?: (dragIndex: number, hoverIndex: number) => void
  onDelete?: (index: number) => void
  dataSource?: T[]
  errorMessage?: string
  description?: ReactNode
}

export const ImageInput = <T extends { path: string; type?: string }>(
  props: Props<T>
) => (
  <FileUploadAreaWapper
    onClick={(e) => e.stopPropagation()}
    onDrop={props.onDrop}
  >
    <ImageInputInner {...props} />
  </FileUploadAreaWapper>
)

export const ImageInputInner = <
  T extends { path: string; type?: string; name?: string }
>({
  dataSource,
  onDrag,
  onDelete,
  style,
  isDragActive,
  open,
  errorMessage,
  description,
}: Props<T> & {
  isDragActive?: boolean
  open?: () => void
}) => {
  const isApplication = dataSource?.[0]?.type?.includes("application")
  return (
    <FileUploadAreaWapper onClick={(e) => e.stopPropagation()}>
      <>
        {!!dataSource?.length && (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            {dataSource.map(({ path, type, name }, index) => (
              <DragWrapper
                type={"IMAGES"}
                index={index}
                onDrag={onDrag ?? (() => {})}
                style={css`
                  margin: 0 ${space._4} ${space._4} 0;
                  ${!isApplication &&
                  css`
                    height: ${px._128};
                  `}
                  width: auto;
                `}
                key={index}
              >
                <DragInner
                  css={css``}
                  path={path}
                  type={type ?? ""}
                  index={index}
                  onDelete={onDelete}
                  name={isApplication ? name : ""}
                  isApplication={isApplication}
                />
              </DragWrapper>
            ))}
          </div>
        )}
        <div
          css={css`
            margin-top: ${space._2};
          `}
        >
          {errorMessage && (
            <ErrorMessage
              style={css`
                margin-bottom: ${space._3};
              `}
            >
              {errorMessage}
            </ErrorMessage>
          )}
          <div
            css={css`
              position: relative;
              padding: ${space._6};
              background-color: ${gray._10};
              border: 2px dashed ${gray._150};
              ${style}
              ${errorMessage &&
              css`
                border: 3px dashed ${error.main};
              `}
            `}
          >
            {isDragActive && (
              <div
                css={css`
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 999;
                  ${flex_center};
                  background-color: rgba(0, 0, 0, 0.3);
                `}
              >
                <span
                  css={css`
                    color: white;
                  `}
                >
                  ファイルをドラッグ＆ドロップしてアップロード
                </span>
              </div>
            )}
            <div
              css={css`
                ${flex_center};
                flex-direction: column;
                ${isDragActive && { opacity: 0.1 }}
                width: 100%;
                height: 100%;
                font-size: ${text.base};
                color: ${gray._300};
              `}
            >
              <p
                css={css`
                  :not(:first-of-type) {
                    padding: ${space._4};
                  }
                `}
              >
                ファイルをドラッグ＆ドロップしてアップロード
              </p>
              <p
                css={css`
                  :not(:first-of-type) {
                    padding: ${space._4};
                  }
                `}
              >
                または
              </p>
              <div
                css={css`
                  :not(:first-of-type) {
                    padding: ${space._4};
                  }
                `}
              >
                <Button type="button" onClick={open}>
                  <>ファイルを選択</>
                </Button>
              </div>
            </div>
            <input
              css={css`
                display: none;
              `}
              type="file"
            />
          </div>
          {description && (
            <div
              css={css`
                font-size: ${text.sm};
                margin-top: ${space._4};
                line-height: 1.8;
                color: ${gray._350};
                border-radius: ${radius.md};
              `}
            >
              {description}
            </div>
          )}
        </div>
      </>
    </FileUploadAreaWapper>
  )
}

const DragInner = <T extends { path: string; type: string; name?: string }>({
  drag,
  path,
  type,
  index,
  onDelete,
  name,
  isApplication,
}: ChildrenProps & {
  path: string
  type: string
  name?: string
  onDelete?: Props<T>["onDelete"]
  index: number
  isApplication?: boolean
}) => {
  return (
    <div
      ref={drag}
      css={css`
        //width: 100%;
        height: 100%;
        position: relative;
        border-radius: ${radius.xs};
        ${!isApplication &&
        css`
          border: 1px solid ${gray._150};
        `}
      `}
    >
      {type?.includes("video") ? (
        <VideoThumbnail src={path} />
      ) : isApplication ? (
        <p>{name}</p>
      ) : (
        <ImgCard src={path} />
      )}
      <div
        css={css`
          position: absolute;
          top: -${space._2_5};
          ${!isApplication
            ? css`
                right: -${space._2};
              `
            : css`
                right: -${space._7};
              `}
          width: ${px._24};
          height: ${px._24};
          border-radius: 50%;
          background-color: ${gray._200};
          cursor: pointer;
          transition: background-color 200ms ease;
          &:hover {
            background-color: ${gray._300};
          }
          ${flex_center};
        `}
        onClick={() => onDelete?.(index)}
      >
        <i
          className="material-icons"
          css={css`
            color: ${white};
            font-size: ${px._16};
          `}
        >
          close
        </i>
      </div>
    </div>
  )
}
