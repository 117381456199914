/** @jsxImportSource @emotion/react */

import { ReactNode } from "react"
import { css, SerializedStyles } from "@emotion/react"
import { px, space, text, input_common } from "views/style"
import { gray } from "views/style/palette"

import { RequiredChip } from "views/components/atoms/display/requiredChip"
import HelpPopover from "views/components/molecules/overlay/helpPopover"

export type Props = {
  label?: ReactNode
  help?: ReactNode
  required?: boolean
  content: ReactNode | ReactNode[]
  description?: ReactNode
  direction?: "row" | "column"
  align?: "start" | "center" | "end"
  style?: SerializedStyles
  doubleColumn?: number //0:1列 1:2列左側 2:2列右側
  hiddenBlock?: boolean
  labelStyle?: SerializedStyles
}

export default function FormRow({
  label,
  help,
  required,
  align = "start",
  direction = "column",
  style,
  content,
  description,
  doubleColumn = 0,
  hiddenBlock,
  labelStyle,
}: Props) {
  const display = hiddenBlock
    ? "none"
    : doubleColumn !== 0
    ? "inline-block"
    : "flex"

  return (
    <div
      css={css`
        display: ${display};
        flex-direction: ${direction};
        align-items: ${align === "start"
          ? "flex-start"
          : align === "end"
          ? "flex-end"
          : "center"};
        margin-bottom: ${space._4};
        padding-right: ${doubleColumn === 1 ? space._4 : 0};
        text-align: ${doubleColumn === 1
          ? "left"
          : doubleColumn === 2
          ? "right"
          : 0};
        ${style}
        vertical-align: top;
        ${doubleColumn !== 0 ? "width: 50%" : ""};
      `}
    >
      {label && (
        <label
          css={css`
            display: inline-flex;
            align-items: center;
            color: ${gray._600};
            margin: ${direction === "row"
              ? `0 ${space._1_5} 0 0`
              : `0 0 ${space._1_5} 0`};
            width: ${direction === "row" ? "40%" : "100%"};
            min-height: ${px._28};
            ${labelStyle}
          `}
        >
          {label}
          {required && <RequiredChip />}
          {help && <HelpPopover content={help} />}
        </label>
      )}
      <div
        css={css`
          ${direction === "row"
            ? css`
                flex: 1 1 auto;
              `
            : css`
                width: 100%;
              `};

          input,
          textarea,
          .DraftEditor-root {
            width: 100%;
            ${input_common};
            &.error {
              margin-top: ${space._1};
            }
          }
          select {
            width: 100%;
          }
          textarea {
            min-height: ${px._128};
          }

          .DraftEditor-root {
            padding: ${space._2} ${space._3};
            min-height: ${px._96};
            line-height: 1.5;
          }
        `}
      >
        {content}
      </div>
      {description && (
        <div
          css={css`
            margin-top: ${space._2};
            font-size: ${text.sm};
            color: ${gray._600};
            line-height: 1.25;
            margin-bottom: ${space._3};
          `}
        >
          {description}
        </div>
      )}
    </div>
  )
}
