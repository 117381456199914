/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { FormikProvider } from "formik"

// Components
import { MainLayout } from "views/components/layout/main"
import FormRow from "views/components/atoms/layout/formRow"
import Card from "views/components/atoms/card"
import {
  FormikSelect,
  FormikTextarea,
} from "views/components/molecules/form/fieldControl"
import Button from "views/components/atoms/input/button"
import { RegistryLayout } from "views/components/layout/registry"

// Hooks
import { useStampCardUpdate } from "./hooks"

import { px, space } from "views/style"
import { DatePicker } from "views/components/molecules/input/datepicker"
import { day } from "logics/utils/formatter"

export const StampCardUpdate = () => {
  const {
    props: { form, onCancel, couponOptions, thumbnail, date, companyName },
  } = useStampCardUpdate()
  return (
    <MainLayout>
      <FormikProvider value={form}>
        <RegistryLayout
          title="スタンプカード更新"
          aside={
            <>
              <Button type="button" variant="outlined" onClick={onCancel}>
                <>キャンセル</>
              </Button>

              <Button
                type="submit"
                css={css`
                  margin-left: ${space._4};
                `}
              >
                <>更新する</>
              </Button>
            </>
          }
          onSubmit={form?.handleSubmit}
        >
          <div
            css={css`
              display: flex;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                flex: 1 1 calc(50% - ${space._6});
              `}
            >
              <Card>
                <div>
                  <img
                    src={thumbnail ?? undefined}
                    css={css`
                      max-width: ${px._512};
                      max-height: ${px._512};
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                    `}
                  />
                </div>
                <FormRow
                  label="フランチャイズ名"
                  content={<span>{companyName}</span>}
                />
                <FormRow
                  label="説明文"
                  content={<FormikTextarea name="description" />}
                  required={true}
                />

                <FormRow
                  label={"景品選択"}
                  content={
                    <FormikSelect
                      name="couponId"
                      dataSource={[
                        { value: "", label: "クーポンを選択してください" },
                        ...(couponOptions || []),
                      ]}
                    />
                  }
                  required={true}
                  doubleColumn={1}
                />
              </Card>
            </div>
            <div
              css={css`
                flex: 0 0 ${px._512};
                margin-left: ${space._8};
              `}
            >
              <Card>
                <FormRow
                  label="掲載期間"
                  content={
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <DatePicker
                        name="from"
                        onChange={date.setFormDate}
                        value={
                          !!form?.values?.from
                            ? day(form?.values?.from)
                            : undefined
                        }
                      />
                      <span
                        css={css`
                          margin: 0 ${space._2};
                        `}
                      >
                        ～
                      </span>
                      <DatePicker
                        name="to"
                        onChange={date.setToDate}
                        value={
                          !!form?.values?.to ? day(form?.values?.to) : undefined
                        }
                      />
                    </div>
                  }
                />
              </Card>
            </div>
          </div>
        </RegistryLayout>
      </FormikProvider>
    </MainLayout>
  )
}
