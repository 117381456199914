import * as yup from "yup"
import { ErrorMessages } from "logics/const"

export default function numberRequired() {
  return yup
    .number()
    .typeError(ErrorMessages.NotNumber)
    .required(ErrorMessages.Required)
    .min(0, ErrorMessages.MinusNumber)
}
