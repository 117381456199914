import { EventType, Event, CafeOrder, CafeOrderEvent } from "../gen/ws_pb"
import { IReciver } from ".."
import { CafeOrderCompletedEvent } from "./event/cafeOrderCompleted"

export class ReciverFactory implements IReciver {
  constructor() {}

  execute = (e: Uint8Array) => {
    const type = EventType.deserializeBinary(e)
    if (!type || !type?.getEvent()) throw new Error()
    switch (type.getEvent()) {
      case Event.CAFE_ORDER_COMPLETE:
        const cafeOrderEvent = CafeOrderEvent.deserializeBinary(e)
        const cafeOrder = cafeOrderEvent.getOrder()
        if (!cafeOrder) throw new Error()
        new CafeOrderCompletedEvent().execute(cafeOrder)
        break
      default:
        break
    }
  }
}
