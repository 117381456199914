import { useCallback } from "react"
import { MessageType, useToast } from "logics/store/toast"
import { ErrorMessages } from "logics/const/message"

export const useRegisterError = () => {
  const {
    props: { onOpen: _onOpen },
  } = useToast()
  const onOpen = useCallback(
    (message: string = ErrorMessages?.Register) => {
      _onOpen({
        message,
        type: MessageType.Fail,
      })
    },
    [_onOpen]
  )

  return onOpen
}
