import { useRef, useEffect, useMemo } from "react"
import { v4 as uuid } from "uuid"
import { useDrop, useDrag } from "react-dnd"
import { XYCoord } from "dnd-core"

export type Props = {
  type: string
  index: number
  onDrag: (dragIndex: number, hoverIndex: number) => void
  vertical?: boolean
}

export const useDragWrapper = (props: Props) => {
  const { type, index, onDrag } = props
  const id = useMemo(() => uuid(), [])
  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop<{ index: number; id: string; type: string }>({
    accept: type,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddle = props?.vertical
        ? (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        : (hoverBoundingRect.right - hoverBoundingRect.left) / 2

      const clientOffset = monitor.getClientOffset() as XYCoord

      const hoverClient = props?.vertical
        ? clientOffset.y - hoverBoundingRect.top
        : clientOffset.x - hoverBoundingRect.left

      if (dragIndex < hoverIndex && hoverClient < hoverMiddle) {
        return
      }

      if (dragIndex > hoverIndex && hoverClient > hoverMiddle) {
        return
      }

      onDrag(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  useEffect(() => {
    preview(drop(ref))
  }, [])

  return {
    state: { isDragging, ref, drag },
  }
}
