export const BASE_PX = 0.0625

const px = {
  _1: BASE_PX * 1 + "rem",
  _2: BASE_PX * 2 + "rem",
  _4: BASE_PX * 4 + "rem",
  _6: BASE_PX * 6 + "rem",
  _8: BASE_PX * 8 + "rem",
  _10: BASE_PX * 10 + "rem",
  _12: BASE_PX * 12 + "rem",
  _14: BASE_PX * 14 + "rem",
  _16: BASE_PX * 16 + "rem",
  _20: BASE_PX * 20 + "rem",
  _24: BASE_PX * 24 + "rem",
  _28: BASE_PX * 28 + "rem",
  _32: BASE_PX * 32 + "rem",
  _36: BASE_PX * 36 + "rem",
  _40: BASE_PX * 40 + "rem",
  _44: BASE_PX * 44 + "rem",
  _48: BASE_PX * 48 + "rem",
  _50: BASE_PX * 50 + "rem",
  _56: BASE_PX * 56 + "rem",
  _64: BASE_PX * 64 + "rem",
  _72: BASE_PX * 72 + "rem",
  _80: BASE_PX * 80 + "rem",
  _96: BASE_PX * 96 + "rem",
  _112: BASE_PX * 112 + "rem",
  _128: BASE_PX * 128 + "rem",
  _144: BASE_PX * 144 + "rem",
  _160: BASE_PX * 160 + "rem",
  _176: BASE_PX * 176 + "rem",
  _192: BASE_PX * 192 + "rem",
  _208: BASE_PX * 208 + "rem",
  _224: BASE_PX * 224 + "rem",
  _232: BASE_PX * 232 + "rem",
  _240: BASE_PX * 240 + "rem",
  _256: BASE_PX * 256 + "rem",
  _272: BASE_PX * 272 + "rem",
  _288: BASE_PX * 288 + "rem",
  _320: BASE_PX * 320 + "rem",
  _384: BASE_PX * 384 + "rem",
  _448: BASE_PX * 448 + "rem",
  _512: BASE_PX * 512 + "rem",
  _576: BASE_PX * 576 + "rem",
  _640: BASE_PX * 640 + "rem",
  _704: BASE_PX * 704 + "rem",
  _736: BASE_PX * 768 + "rem",
  _768: BASE_PX * 768 + "rem",
  _832: BASE_PX * 832 + "rem",
  _896: BASE_PX * 896 + "rem",
  _960: BASE_PX * 960 + "rem",
}

export default px
