/** @jsxImportSource @emotion/react */
import React, { ReactNode, useCallback, useState } from "react"
import { css } from "@emotion/react"
import Tippy from "@tippyjs/react"
import Card from "views/components/atoms/card"

import { px, shadow, space, text } from "views/style"
import { gray, primary } from "views/style/palette"

type Props = {
  content: ReactNode
  toggleByClick?: boolean
  toggleByHover?: boolean
  children?: ReactNode
}
export default function HelpPopover({
  content,
  toggleByClick = true,
  toggleByHover,
  children,
}: Props) {
  const [visible, setVisible] = useState<boolean>(false)

  const Content = useCallback(() => {
    return (
      <Card
        style={css`
          background-color: ${primary._25};
          border: 1px solid ${primary._50};
          box-shadow: ${shadow.popover};
          font-size: ${text.sm};
          color: ${gray._700};
          line-height: 1.5;
          padding: ${space._3};
          margin-bottom: -${space._2};
        `}
      >
        {content}
      </Card>
    )
  }, [content])

  return (
    <Tippy
      content={<Content />}
      visible={toggleByClick ? visible : undefined}
      onClickOutside={toggleByClick ? () => setVisible(false) : undefined}
      placement="bottom-start"
    >
      <span
        onClick={toggleByClick ? () => setVisible(!visible) : undefined}
        onMouseOver={() => toggleByHover && setVisible(true)}
        onMouseOut={() => toggleByHover && setVisible(false)}
        css={css`
          position: relative;
          width: ${px._28};
          height: ${px._28};
          border-radius: 50%;
          cursor: pointer;
          transition: background-color 300ms ease;
          font-size: ${px._24};
          color: ${primary._300};
          margin: 0 ${space._3};
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: ${gray._100};
          }
          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      >
        {children ?? <i className="material-icons">help_outline</i>}
      </span>
    </Tippy>
  )
}
