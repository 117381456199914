import axios from "axios"
import { UrlReplyFragment } from "../graphql"
import { Config } from "../../config"

export const readAsDataURLAsync = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result)
      } else {
        resolve("")
      }
    }
    reader.onerror = () => {
      reject(reader.error)
    }
    reader.readAsDataURL(blob)
  })
}

export const dataUrlToFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl?.split(",")
  const mime = arr?.[0].match(/:(.*?);/)?.[1]
  const bstr = window.atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename ?? "", { type: mime })
}

export const onDrop = async <T>(data: Array<T & File>) => {
  const images = await Promise.all(
    data?.map(async (item: File) => readAsDataURLAsync(item))
  ).then((res) =>
    res.map((dataUrl: string, index) => {
      const file = data?.[index]
      const name = (file?.name ?? "") as string
      const type = (file?.type ?? "") as string
      return {
        ...(data?.[index] ?? {}),
        name,
        type,
        path: dataUrl,
      }
    })
  )
  return images
}

export const onUpload = async <T>(props: {
  data: UrlReplyFragment[]
  targets: Array<T & { name: string; type: string; path: string }>
}) => {
  const { data, targets } = props
  return await Promise.all(
    data?.map(async ({ fileName, url }) => {
      const target = targets?.find((i) => i.name === fileName) ?? null
      if (!target?.path || !target?.type || !target?.name) return
      const options = {
        headers: {
          "Content-Type": target.type as string,
        },
      }
      const file = dataUrlToFile(target?.path as string, target?.name as string)
      if (!file) throw new Error()
      await axios.put(url, file, options)
    }) ?? []
  )
    .catch((e) => {
      throw e
    })
    .then(() => true)
}

export const createPath = (path: string) => path?.split("?")[0] ?? null

export type DownloadType = {
  key: string
  fileName?: string
}
export const downloadObject = (key: string, fileName?: string) =>
  axios
    .post(
      Config.downloadUrl,
      {
        key,
      },
      { responseType: "blob" }
    )
    .then(async (res) => {
      const fileURL = window.URL.createObjectURL(res.data)
      const fileLink = document.createElement("a")
      fileLink.href = fileURL
      const [_, _fileName] =
        res.headers["content-disposition"]?.split("filename=")
      fileLink.setAttribute("download", fileName ?? _fileName)
      document.body.appendChild(fileLink)
      fileLink.click()
      fileLink.remove()
    })

export const bulkDownload = async (data: DownloadType[]) => {
  await Promise.all(
    data?.map(
      async (d: DownloadType) => await downloadObject(d?.key, d?.fileName)
    )
  )
}
