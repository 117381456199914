import { useCallback, useMemo, useEffect } from "react"
import { ApolloError } from "@apollo/client"
import { filter } from "graphql-anywhere"
import {
  useShopLoginMutation,
  useTenantLoginMutation,
  useFranchiseLoginMutation,
  useMasterLoginMutation,
  useMeLazyQuery,
  useLogoutMutation,
  LoginResponseFragment,
  LoginResponseFragmentDoc,
  AdminLogin,
  MasterLogin,
  LoginAccount,
} from "../graphql"
import { ScreenType, ErrorMessages, Authority } from "../const"
import { useMeState } from "../store/me"
import { PATHS } from "views/routes"
import { useNavigate } from "react-router-dom"

const SCREEN_TYPE = process.env.REACT_APP_SCREEN_TYPE
export type Props = {
  onCompleted?: (res: LoginAccount) => void
  onError?: (e: ApolloError) => void
  toFirstLogin?: () => void
}
export const useLogin = (props?: Props) => {
  const navigate = useNavigate()

  const [shopLogin, { loading: shopLoading }] = useShopLoginMutation({
    ...(props ?? {}),
    onCompleted: ({ shopLogin }) => {
      if (shopLogin?.isFirst) {
        props?.toFirstLogin && props?.toFirstLogin()
        return
      }
      props?.onCompleted && props?.onCompleted(shopLogin)
      navigate(PATHS.dashboard)
    },
  })
  const [tenantLogin, { loading: tenantLoading }] = useTenantLoginMutation({
    ...(props ?? {}),
    onCompleted: ({ tenantLogin }) => {
      if (tenantLogin?.isFirst) {
        props?.toFirstLogin && props?.toFirstLogin()
        return
      }
      props?.onCompleted && props?.onCompleted(tenantLogin)
      if (tenantLogin?.authorityId === Authority.Staff) {
        navigate(PATHS.cafe.product.list + "/1")
      }
      else {
        navigate(PATHS.dashboard)
      }
    },
  })
  const [franchiseLogin, { loading: franchiseLoading }] =
    useFranchiseLoginMutation({
      ...(props ?? {}),
      onCompleted: ({ franchiseLogin }) => {
        if (franchiseLogin?.isFirst) {
          props?.toFirstLogin && props?.toFirstLogin()
          return
        }
        props?.onCompleted && props?.onCompleted(franchiseLogin)
        navigate(PATHS.dashboard)
      },
    })
  const [masterLogin, { loading: masterLoading }] = useMasterLoginMutation({
    ...(props ?? {}),
    onCompleted: ({ masterLogin }) => {
      if (masterLogin?.isFirst) {
        props?.toFirstLogin && props?.toFirstLogin()
        return
      }
      props?.onCompleted && props?.onCompleted(masterLogin)
      navigate(PATHS.dashboard)
    },
  })
  const loading = useMemo(
    () => !!shopLoading || !!tenantLoading || !franchiseLoading,
    [shopLoading, tenantLoading, franchiseLoading]
  )
  const {
    handler: { onChange },
  } = useMeState()
  const login = useCallback(async (val: AdminLogin | MasterLogin) => {
    if (SCREEN_TYPE === ScreenType.Shop)
      return await shopLogin({
        variables: { request: val as AdminLogin },
      }).then((res) => {
        if (res?.data?.shopLogin)
          onChange(
            filter<LoginResponseFragment>(
              LoginResponseFragmentDoc,
              res?.data?.shopLogin
            )
          )
        return res
      })
    if (SCREEN_TYPE === ScreenType.Tenant)
      return await tenantLogin({
        variables: { request: val as AdminLogin },
      }).then((res) => {
        if (res?.data?.tenantLogin)
          onChange(
            filter<LoginResponseFragment>(
              LoginResponseFragmentDoc,
              res?.data?.tenantLogin
            )
          )
        return res
      })
    if (SCREEN_TYPE === ScreenType.Franchise)
      return await franchiseLogin({
        variables: { request: val as AdminLogin },
      }).then((res) => {
        if (res?.data?.franchiseLogin)
          onChange(
            filter<LoginResponseFragment>(
              LoginResponseFragmentDoc,
              res?.data?.franchiseLogin
            )
          )
        return res
      })
    if (SCREEN_TYPE === ScreenType.Master)
      return await masterLogin({
        variables: {
          request: {
            email: val?.email,
            password: val?.password,
          },
        },
      }).then((res) => {
        if (res?.data?.masterLogin)
          onChange(
            filter<LoginResponseFragment>(
              LoginResponseFragmentDoc,
              res?.data?.masterLogin
            )
          )
        return res
      })
    throw new Error(ErrorMessages.InvalidArgument)
  }, [])

  return {
    state: {
      loading,
    },
    handler: {
      login,
    },
  }
}

export const useMe = () => {
  const [fetch, { data }] = useMeLazyQuery({
    fetchPolicy: "network-only",
  })
  const me = useMemo(
    () =>
      data?.me
        ? filter<LoginResponseFragment>(LoginResponseFragmentDoc, data?.me)
        : null,
    [data]
  )

  const {
    handler: { onChange, onClear },
    state,
  } = useMeState()

  useEffect(() => {
    if (!state?.id) fetch()
  }, [state])

  useEffect(() => {
    if (me) {
      onChange(me)
      return
    }
  }, [me])

  return {
    state,
    handler: {
      fetch,
      onChange,
      onClear,
    },
  }
}

export const useLogout = (props?: {
  onCompleted?: () => void
  onError?: (e: ApolloError) => void
}) => {
  const {
    handler: { onClear },
  } = useMeState()
  const [logout] = useLogoutMutation({
    ...(props ?? {}),
    onCompleted: () => {
      props?.onCompleted && props?.onCompleted()
      onClear()
    },
  })
  return {
    handler: { logout },
  }
}
