import { EventEmitter } from "events"
import { useState, useEffect } from "react"

export const event = new EventEmitter()
export interface IEvent<T> {
  execute: (value: T) => void
}

export const useEvent = <T>(key: string) => {
  const [state, setState] = useState<T[]>([])
  useEffect(() => {
    event.on(key, (data: T) => {
      setState((pre) => [...pre, data])
    })
  }, [key])

  return {
    state,
  }
}
