export enum Authority {
  Admin = 1,
  General = 2,
  Staff = 3,
}

export const AUTHORITY = [
  { id: 1, name: "管理者" },
  { id: 2, name: "一般" },
  { id: 3, name: "店員" },
]

export const AuthoritySelect = [
  { value: 0, label: "選択してください" },
  {
    value: Authority.Admin,
    label: AUTHORITY?.find((d) => d?.id === Authority.Admin)?.name,
  },
  {
    value: Authority.General,
    label: AUTHORITY?.find((d) => d?.id === Authority.General)?.name,
  },
]
