/** @jsxImportSource @emotion/react */
import { FC, ReactNode, useEffect } from "react"
import { css } from "@emotion/react"
import { useNavigate } from "react-router-dom"

// Logics
import { ScreenType, LoginType as LOGIN_TYPE } from "logics/const"
import { useMeQuery } from "logics/graphql"
import { useLoading } from "logics/store/loading"

// Components
import { SideMenu } from "views/components/organisms/sideMenu"
import { Header } from "views/components/organisms/header"
import { Toast } from "views/components/organisms/toast"
import { PATHS } from "views/routes"

// Style
import { body, close as close_style, main } from "./style"
import { primary } from "views/style/palette"

// Hooks
import { useMainLayout } from "./hooks"

export const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const {
    props: { header, sideMenu },
    state: { close },
  } = useMainLayout()
  const { loading } = useLoading()
  return (
    <>
      <div>
        <SideMenu {...sideMenu} />
        <div
          css={css`
            ${body}
            ${close && close_style}
          `}
        >
          <Header {...header} />
          {loading ? (
            <div
              css={css`
                border-radius: 50%;
                width: 10em;
                height: 10em;
                margin: 60px auto;
                font-size: 10px;
                position: relative;
                text-indent: -9999em;
                border-top: 1.1em solid rgba(255, 255, 255, 0.2);
                border-right: 1.1em solid rgba(255, 255, 255, 0.2);
                border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
                border-left: 1.1em solid ${primary._500};
                -webkit-transform: translateZ(0);
                -ms-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-animation: load8 1.1s infinite linear;
                animation: load8 1.1s infinite linear;
                &:after {
                  border-radius: 50%;
                  width: 10em;
                  height: 10em;
                }
                @-webkit-keyframes load8 {
                  0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                  }
                  100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  }
                }
                @keyframes load8 {
                  0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                  }
                  100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                  }
                }
              `}
            ></div>
          ) : (
            <main css={main}>{children}</main>
          )}
        </div>
      </div>
      <AuthInner />
      <Toast />
    </>
  )
}

//
// 現在の画面と、アカウントのタイプが一致しているか？
//
const LoginType =
  process.env.REACT_APP_SCREEN_TYPE === ScreenType.Shop
    ? LOGIN_TYPE.Shop
    : process.env.REACT_APP_SCREEN_TYPE === ScreenType.Tenant
    ? LOGIN_TYPE.Tenant
    : process.env.REACT_APP_SCREEN_TYPE === ScreenType.Franchise
    ? LOGIN_TYPE.Franchise
    : process.env.REACT_APP_SCREEN_TYPE === ScreenType.Master
    ? LOGIN_TYPE.Master
    : null
const AuthInner = () => {
  const navigate = useNavigate()
  const { data, refetch } = useMeQuery({ fetchPolicy: "network-only" })

  useEffect(() => {
    if (!data) {
      refetch().then((res) => {
        if (!!res?.data?.me?.id && res?.data?.me?.loginType !== LoginType) {
          navigate(PATHS.login.top)
        }
      })
      return
    }
    if (!!data?.me?.id && data?.me?.loginType !== LoginType) {
      navigate(PATHS.login.top)
    }
  }, [data])

  return null
}
