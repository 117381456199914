/** @jsxImportSource @emotion/react */
import { ReactNode } from "react"
import { css, SerializedStyles } from "@emotion/react"

// Style
import { justify_content_center, px, space, ui } from "views/style"
import ContentHeader from "views/components/atoms/layout/contentHeader"
import Button from "views/components/atoms/input/button"
import ContentBody from "views/components/atoms/layout/contentBody"
import { MaxWidthType } from "logics/const"

export type Props = {
  onCancel?: () => void
  onSubmit?: (value?: any) => void
  title?: string
  submitTitle?: string
  style?: SerializedStyles
  children: ReactNode
  aside?: ReactNode
  maxWidth?: MaxWidthType
}

/** TODO: 最終的にRegistryLayoutとRegisterLayoutは統合してリファクタする */
export const RegistryLayout = ({
  onCancel,
  onSubmit,
  style,
  title,
  submitTitle,
  aside,
  children,
  maxWidth,
}: Props) => {
  return (
    <div
      css={css`
        ${justify_content_center}
        ${style}
      `}
    >
      <div
        css={css`
          width: 100%;
        `}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit?.()
          }}
        >
          <ContentHeader
            maxWidth={maxWidth ?? "register"}
            aside={
              aside ?? (
                <>
                  <Button type="button" variant="outlined" onClick={onCancel}>
                    <>キャンセル</>
                  </Button>
                  <Button
                    type="submit"
                    css={css`
                      margin-left: ${space._4};
                    `}
                  >
                    <>{submitTitle ?? "登録"}</>
                  </Button>
                </>
              )
            }
          >
            {title && <label>{title}</label>}
          </ContentHeader>
          <ContentBody widthType={maxWidth ?? "register"}>
            <>{children}</>
          </ContentBody>
        </form>
      </div>
    </div>
  )
}
