/** @jsxImportSource @emotion/react */
import { ReactElement } from "react"
import { TableProps as TableComponentProps } from "tw-custom-component"

// Style
import Paper from "views/components/atoms/layout/paper"
import ContentBody from "views/components/atoms/layout/contentBody"

export type Props<T> = {
  header?: ReactElement
  filter?: ReactElement
  children: ReactElement
} & T

export type TableProps<T> = Omit<
  Props<{ table: TableComponentProps<T> }>,
  "children"
>

export const ListLayout = <T,>(props: Props<T>) => (
  <>
    {!!props?.header && props?.header}
    <ContentBody widthType="list">
      <>{!!props?.filter && props?.filter}</>
      <Paper>{props.children}</Paper>
    </ContentBody>
  </>
)
