import { useCallback } from "react"
import { MessageType, useToast } from "logics/store/toast"
import { SuccessMessages } from "logics/const/message"

export const useRegisterSuccess = () => {
  const {
    props: { onOpen: _onOpen },
  } = useToast()
  const onOpen = useCallback(
    (message: string = SuccessMessages?.Register) => {
      _onOpen({
        message,
        type: MessageType.Success,
      })
    },
    [_onOpen]
  )

  return onOpen
}
