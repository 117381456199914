export const gray = {
  _0: `hsl(234, 11%, 100%)`,
  _10: `hsl(234, 11%, 99%)`,
  _25: `hsl(234, 11%, 97.5%)`,
  _50: `hsl(234, 11%, 95%)`,
  _75: `hsl(234, 11%, 92.5%)`,
  _100: `hsl(234, 11%, 90%)`,
  _150: `hsl(234, 11%, 85%)`,
  _200: `hsl(234, 11%, 80%)`,
  _250: `hsl(234, 11%, 75%)`,
  _300: `hsl(234, 11%, 70%)`,
  _350: `hsl(234, 11%, 65%)`,
  _400: `hsl(234, 11%, 60%)`,
  _500: `hsl(234, 11%, 50%)`,
  _600: `hsl(234, 11%, 40%)`,
  _700: `hsl(234, 11%, 30%)`,
  _750: `hsl(234, 11%, 25%)`,
  _800: `hsl(234, 11%, 20%)`,
  _850: `hsl(234, 11%, 15%)`,
  _900: `hsl(234, 11%, 10%)`,
  _1000: `hsl(234, 11%, 0%)`,
}

export const primary = {
  _0: `hsl(234, 64%, 100%)`,
  _10: `hsl(234, 64%, 99%)`,
  _25: `hsl(234, 64%, 97.5%)`,
  _50: `hsl(234, 64%, 95%)`,
  _75: `hsl(234, 64%, 92.5%)`,
  _100: `hsl(234, 64%, 90%)`,
  _150: `hsl(234, 64%, 85%)`,
  _200: `hsl(234, 64%, 80%)`,
  _300: `hsl(234, 64%, 70%)`,
  _400: `hsl(234, 64%, 50%)`,
  _500: `hsl(234, 64%, 28%)`,
  _600: `hsl(234, 64%, 24%)`,
  _700: `hsl(234, 64%, 20%)`,
  _800: `hsl(234, 64%, 15%)`,
  _900: `hsl(234, 64%, 10%)`,
  _1000: `hsl(234, 64%, 0%)`,
}

export const bg = {
  contentHeader: `hsla(234, 24%, 95%, 0.7)`,
  gray: `hsl(224, 12%, 97.5%)`,
  primary: `hsl(234, 24%, 96%)`,
  overlay: `hsla(234, 24%, 5%, 0.2)`,
  imageViewer: `hsla(234, 0%, 15%, 0.75)`,
}

export const error = {
  light: `hsl(350,80%,95%)`,
  main: `hsl(350,80%,50%)`,
  dark: `hsl(350,80%,30%)`,
}

export const secondary = {
  light: `hsl(245,80%,90%)`,
  midLight: `hsl(245,80%,65%)`,
  main: `hsl(240, 60%, 55%)`,
  dark: `hsl(245,80%,30%)`,
}
