/** @jsxImportSource @emotion/react */
import { ReactNode } from "react"
import { css, SerializedStyles } from "@emotion/react"
import { space, text } from "views/style"
import { gray } from "views/style/palette"

type Props = {
  children?: ReactNode
  style?: SerializedStyles
  selectMessage?: boolean
  tableType?: boolean
}

export default function Empty({
  children = "データがありません",
  style,
  selectMessage,
  tableType,
}: Props) {
  return (
    <div
      css={css`
        font-size: ${text.lg};
        font-weight: 400;
        color: ${gray._300};
        padding: ${space._8};
        text-align: center;
        ${style};
      `}
    >
      {tableType
        ? "Join Memberを選択してください"
        : selectMessage
        ? "店舗を選択してください"
        : children}
    </div>
  )
}
