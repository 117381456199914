import { useEffect } from "react"
import { atom, useRecoilState } from "recoil"
import { isNil } from "lodash"

export const loadingState = atom({
  key: "loading",
  default: false,
})

export const useLoading = (loading?: boolean) => {
  const [_loading, setLoading] = useRecoilState(loadingState)
  useEffect(() => {
    !isNil(loading) && setLoading(loading)
  }, [loading])

  return {
    loading: _loading,
    setLoading,
  }
}
