/** @jsxImportSource @emotion/react */
import React from "react"
import { css, SerializedStyles } from "@emotion/react"
import { space, radius } from "views/style"
import { gray } from "views/style/palette"

type Props = {
  children: React.ReactNode
  style?: SerializedStyles
}
export default function Paper({ children, style }: Props) {
  return (
    <div
      css={css`
        background-color: white;
        //border: 1px solid ${gray._200};
        border-radius: ${radius.lg};
        padding: ${space._4};
        thead tr th {
          padding: ${space._1} ${space._0};
        }
        tbody tr td {
          padding: ${space._1} ${space._3};
        }
        ${style}
      `}
    >
      {children}
    </div>
  )
}
