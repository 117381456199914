/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"

// Components
import { AccordionMenu } from "tw-custom-component"
import { Logo } from "views/components/atoms/logo"

// Style
import {
  container,
  container_close,
  logo,
  accordion,
  logo_spacing,
} from "./style"
export { SideMenuWidth } from "./style"

// Hooks
import { useSideMenu } from "./hooks"

import { ScreenType } from "logics/const"
import { useMe } from "logics/account"

export const SideMenu = (props: { close?: boolean }) => {
  const {
    state: { authorityId },
  } = useMe()
  const { menus } = useSideMenu(authorityId)
  return (
    <aside
      css={css`
        ${container}
        ${props?.close && container_close}
      `}
    >
      <Logo
        style={logo}
        screenType={process.env.REACT_APP_SCREEN_TYPE as ScreenType}
      />
      <div css={logo_spacing} />
      <div css={accordion}>
        <AccordionMenu dataSource={menus} />
      </div>
    </aside>
  )
}
