// React
import React from "react"
import { RecoilRoot } from "recoil"
import { HashRouter, Route, Navigate, Routes } from "react-router-dom"
import { Global, css } from "@emotion/react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

// Apollo
import { ApolloProvider } from "@apollo/client"
import { useApolloClient } from "logics/graphql/apolloClient"

// Component
import { ROUTES, PATHS } from "views/routes"

import { ress, base, fonts } from "views/style"

const AppInner = () => {
  return (
    <Routes>
      {ROUTES.map((route, index) => (
        <React.Fragment key={index}>
          <Route
            path={route.path}
            element={
              <React.Suspense fallback={<></>}>{route.element}</React.Suspense>
            }
          />
        </React.Fragment>
      ))}
      <Route path={"/"} element={<Navigate to={PATHS.login.top} replace />} />
    </Routes>
  )
}

export const App = () => {
  return (
    <HashRouter>
      <DndProvider backend={HTML5Backend}>
        <RecoilRoot>
          <ApolloInner>
            <Global
              styles={css`
                ${ress}
                ${base}
              ${fonts}
              `}
            />
            <AppInner />
          </ApolloInner>
        </RecoilRoot>
      </DndProvider>
    </HashRouter>
  )
}

const ApolloInner = (props: { children: React.ReactNode }) => {
  const client = useApolloClient()
  return <ApolloProvider client={client}>{props?.children}</ApolloProvider>
}
