import { SkuEditableData, VariationData } from "logics/const/variations"
import { ImageSource } from "views/components/molecules/input/image"

export type Values = Partial<{
  title: string
  isCool: boolean
  isAlone: boolean
  isReducedTax: boolean
  isExternal: boolean
  category1: number
  category2: number
  category3: number
  overview: string
  memo: string
  variations: VariationData[]
  images: ImageSource[]
  /** @description 更新APIがupdateSkuとcreateSkusに分かれているため、その便宜に合わせて、あえて分けている */
  sku: SkuEditableData // バリエーションなしの場合に使う
  skus: SkuEditableData[] // バリエーションありの場合に使う
  externalUrl?: string
  saleType?: number
  shopId?: number | null
  status: number
  startDate: Date | null
  endDate: Date | null
}>

export enum SaleType {
  AZLM = 1,
  External,
  ShopOnly,
}

export enum ProductStatus {
  Draft = 1,
  Public,
}

export const ProductStatusOptions = [
  { value: ProductStatus.Draft, label: "下書き" },
  { value: ProductStatus.Public, label: "公開" },
]
