/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import { useMemo, useCallback, useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { PATHS } from "views/routes"
import { TableProps } from "tw-custom-component"
import {
  PageFragmentDoc,
  PageFragment,
  useStampCardsQuery,
  StampCardFragment,
  StampCardFragmentDoc,
} from "logics/graphql"
import { filter } from "graphql-anywhere"
import {
  DEFAULT_CURRENT_PAGE_VALUE,
  DEFAULT_TAKE,
  DEFAULT_TOTAL_PAGE_VALUE,
  calculateNextPageNumber,
  calculatePreviousPageNumber,
} from "logics/const"
import { day } from "logics/utils/formatter"
import {
  StampCardListLabel,
  formatStampCardDescription,
} from "logics/const/stampCard"
import px from "views/style/px"

export const useStampCards = () => {
  const navigate = useNavigate()
  const {
    props: { stampCards, pages, loading },
  } = useApi()
  const {
    props: { table },
  } = useTable(stampCards)
  const { props: pagination } = usePagination({ totalPage: pages?.totalPage })
  const onToRegister = useCallback(() => navigate(PATHS.stampCard.register), [])
  return {
    props: {
      table,
      pagination,
      loading,
      onToRegister,
    },
  }
}

/*

Api

*/
const useApi = () => {
  const { page } = useParams<"page">()
  const { data, refetch, error, loading } = useStampCardsQuery({
    fetchPolicy: "network-only",
    variables: {
      request: {
        pages: {
          take: DEFAULT_TAKE,
          skip: (Number(page) - 1) * DEFAULT_TAKE,
        },
        param: {},
      },
    },
  })

  const stampCards = useMemo(
    () =>
      data?.stampCards
        ? filter<StampCardFragment[]>(
            StampCardFragmentDoc,
            data?.stampCards?.datasource
          )
        : [],
    [data]
  )
  const pages = useMemo(
    () =>
      data?.stampCards
        ? filter<PageFragment>(PageFragmentDoc, data?.stampCards?.page)
        : null,
    [data]
  )

  useEffect(() => {
    refetch({
      request: {
        pages: {
          take: DEFAULT_TAKE,
          skip: (Number(page) - 1) * DEFAULT_TAKE,
        },
        param: {},
      },
    })
  }, [page])

  return {
    props: {
      stampCards,
      pages,
      loading,
    },
  }
}

/*

Table

*/
const useTable = (dataSource: StampCardFragment[]) => {
  const navigate = useNavigate()
  const onClick = useCallback(
    (data: { data: StampCardFragment; id: number }) =>
      navigate(`${PATHS.stampCard.update}/${data?.data?.id}`),
    [dataSource]
  )
  const rows = useMemo<TableProps<StampCardFragment>["rows"]>(
    () => [
      //イメージ
      ({ data, id }) => (
        <img
          src={data?.thumbnail?.path}
          css={css`
            max-width: ${px._112};
            max-height: ${px._112};
            object-fit: contain;
          `}
        />
      ),
      // 説明文
      ({ data, id }) => <>{formatStampCardDescription(data?.description)}</>,
      // 登録日
      ({ data, id }) => <>{data?.createdAt && day(data?.createdAt)}</>,
    ],
    [dataSource]
  )

  const columns = useMemo<Array<string>>(
    () => ["", StampCardListLabel.description, StampCardListLabel.createdAt],
    []
  )

  const table: TableProps<StampCardFragment> = useMemo(() => {
    return {
      dataSource,
      columns,
      rows,
      option: { clickRow: onClick },
      style: css`
        tbody {
          tr {
            cursor: pointer;
          }
        }
      `,
    }
  }, [dataSource, columns, rows])

  return {
    props: {
      table,
    },
  }
}

/*

Pagination

*/
export const usePagination = (props: { totalPage?: number }) => {
  const { page } = useParams<"page">()
  const navigate = useNavigate()
  const nextPageNumber = calculateNextPageNumber(page)
  const previousPageNumber = calculatePreviousPageNumber(page)

  const onNext = useCallback(
    () => navigate(`${PATHS.stampCard.list}/${nextPageNumber}`),
    [page]
  )
  const onBefore = useCallback(
    () => navigate(`${PATHS.stampCard.list}/${previousPageNumber}`),
    [page]
  )

  const onClick = useCallback(
    (num: number) => navigate(`${PATHS.stampCard.list}/${num}`),
    []
  )
  return {
    props: {
      currentPage: Number(page) ?? DEFAULT_CURRENT_PAGE_VALUE,
      totalPage: props.totalPage ?? DEFAULT_TOTAL_PAGE_VALUE,
      onNext,
      onBefore,
      onClick,
    },
  }
}
