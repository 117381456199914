/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"
import { space } from "views/style"
import { ReactNode } from "react"
import { error } from "views/style/palette"

type Props = {
  children: ReactNode
  style?: SerializedStyles
}
export default function ErrorMessage({ children, style }: Props) {
  return (
    <div
      css={css`
        margin-top: ${space._2};
        color: ${error.main};
        ${style};
      `}
    >
      {children}
    </div>
  )
}
