/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react"
import { ReactNode } from "react"
import { text, space, radius, shadow } from "views/style"
import { error, primary } from "views/style/palette"
import { Btn, ButtonProps } from "tw-custom-component"

export default function Button({
  style,
  children,
  variant = "standard",
  ...props
}: {
  variant?: "standard" | "outlined" | "alert" | "simple"
  style?: SerializedStyles
  children?: ReactNode
} & ButtonProps) {
  return (
    <Btn
      {...props}
      style={css`
        font-size: ${text.base};
        font-weight: 400;
        padding: ${space._1_5} ${space._4};
        cursor: pointer;
        border-radius: ${radius.button};
        transition: opacity 200ms ease;
        &:hover {
          opacity: 0.7;
        }
        ${variant === "outlined"
          ? outlined
          : variant === "alert"
          ? alert
          : variant === "simple"
          ? simple
          : standard}
        ${style};
      `}
    >
      {children}
    </Btn>
  )
}

const standard = css`
  background-color: ${primary._500};
  border: 1px solid transparent;
  box-shadow: ${shadow.button};
  color: white;
`

const outlined = css`
  background-color: white;
  border: 1px solid ${primary._500};
  color: ${primary._500};
`
const alert = css`
  background-color: white;
  border: 1px solid ${error.main};
  color: ${error.main};
`

const simple = css`
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  color: ${primary._500};
`
